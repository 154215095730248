import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormElement from "../../uielements/FormElement";
import * as Yup from "yup";

import { Formik, Field, Form, useFormikContext, ErrorMessage } from "formik";

import { Button } from "@material-ui/core";
import { icicienachService } from "../../../_services/icicienach.service";
import { windowWhen } from "rxjs";
import { history } from "../../../_helpers";

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    this.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("");
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    width:"75%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

 
export default function AddIciciEnachModal({ enachData, setEnachData, data}) {

   console.log(data)

  const classes=useStyles();
  const [open, setOpen] = React.useState(false);

  var date = new Date();
  const bankList={
    'AUBL':'AU Bank',
    'SBIN':'State Bank of India',
    'ICIC':'ICICI Bank',
    'HDFC':'HDFC Bank',
    'BARB':'Bank of Baroda',
    'CNRB':'Canara bank',
    'CBIN':'Central Bank of India',
    'BKID':'Bank of India',
    'YESB':'Yes bank',
    'UTIB':'Axis Bank',
    'UBIN':'Union Bank of India',
    'RATN':'RBL Bank',
    'PUNB':'Punjab National Bank',
    'MAHB':'Bank of Maharasthra',
    'KKBK':'Kotak Mahindra Bank',
    'INDB':'IndusInd Bank',
    'IDFB':'IDFC First Bank',
    'FDRL':'Federal bank',
    'ESFB':'Equitas Small Fin Bank',
    'DLXB':'Dhanalaxmi Bank',
    'DCBL':'DCB Bank',
    'CSBK':'The catholic syrian bank',
    'USFB':'Ujjivan Small Fin bank',
    'BDBL':'Bandhan Bank',
    
  }  

  async function onSubmit(fields, { setSubmitting }) {
    let fieldValues={
        ...fields,
        bank_name: bankList[fields.bank_code],
        customer_ref_no: fields.loan_number+""+fields.contact,
        enach_provider:'ICICI',
        enach_status:'Pending',
    }
    console.log(fieldValues)
    icicienachService.addEnach(fieldValues)
    .then((enachitem)=> {
      console.log(enachitem)
      const encodeditem = btoa(JSON.stringify(enachitem))
      console.log(encodeditem)
      window.alert("Enach Request creared with id "+enachitem.token);
      enachData=[enachitem,...enachData]
      setEnachData(enachData)
      window.open('https://s3.ap-south-1.amazonaws.com/download.kaabilfinance.com/htmls/enachload.html?'+`${encodeditem}`);
      history.push("/home/icicienach")
    })
    .catch((err)=> {
      console.log(err);
      window.alert("Error while making Enach");
    })
    setSubmitting(false);
    handleClose();
  }
  let initialValues;
  
  if(data){
     initialValues = {
      emi_amount: 90000,
      loan_number: data[1],
      name: data[0],
      contact: data[2],
      // emi_amount: 90000,
      // loan_number: "",
      // name: "",
      // contact:"",
      email:"",
      bank_account: "",
      ifsc_code:"",
      bank_account_type: "SB",
      frequency_code: "ADHO",
      frequency_type: "RCUR",
      payment_type: "all",
      date_from: date.yyyymmdd(),
    };
  }else{
   initialValues = {
    // emi_amount: 90000,
    // loan_number: data[1],
    // name: data[0],
    // contact: data[2],
    emi_amount: 90000,
    loan_number: "",
    name: "",
    contact:"",
    email:"",
    bank_account: "",
    ifsc_code:"",
    bank_account_type: "SB",
    frequency_code: "ADHO",
    frequency_type: "RCUR",
    payment_type: "all",
    date_from: date.yyyymmdd(),
  };
}

const SetDates = () =>{
  const { values, setFieldValue } = useFormikContext();
  React.useEffect(() => {
    defaultDate(values, setFieldValue);
  }, [values, setFieldValue]);
  return null;
}


const defaultDate = (values, setFieldValue) =>{
  let start_date = new Date()
  start_date.setDate(start_date.getDate() + 1)
  let dd = String(start_date.getDate()).padStart(2,"0")
  let mm = String(start_date.getMonth()+1).padStart(2, "0");
  let yy = start_date.getFullYear()
  start_date = yy + "-" + mm + "-" + dd;

  let m = new Date().getMonth()+ 2*data[3]
  let end_date = new Date(new Date().setMonth(m))
  end_date.setDate(end_date.getDate() + 1)
  console.log(end_date, "end date")
  let end_day = String(end_date.getDate()).padStart(2, "0")
  let end_mnth = String(end_date.getMonth()+1).padStart(2, "0")
  let end_year = end_date.getFullYear()
  end_date = end_year + "-" + end_mnth + "-" + end_day
  setFieldValue('start_date', start_date)
  setFieldValue('end_date', end_date)
}

  const validationSchema = Yup.object().shape({
    emi_amount: Yup.number().required("This field is required").min(1,"minimum 1").max(90000,"maximum 90000 allowed"),
    loan_number: Yup.string().required("Please enter valid Loan Number").matches(/^[a-zA-Z0-9]+$/,"Need valid Loan Number"),
    name: Yup.string().max(40).required("This field is required"),
    contact: Yup.string().required("This field is required")
    .min(9,"minimum 9 digits required"),
    email:Yup.string().email().required("Email is required"),
    bank_account: Yup.string().required("This field is required"),
    ifsc_code: Yup.string().required("This field is required"),
    bank_account_type: Yup.string().required("This field is required"),
    bank_code:Yup.string().required("Bank Name is required"),
    frequency_code: Yup.string().required("This field is required"),
    frequency_type: Yup.string().required("This field is required"),
    payment_type: Yup.string().required("This field is required"),
    start_date: Yup.date().required("This field is required"),
    end_date: Yup.date().min(Yup.ref("start_date"),"End date has to be more than start date"),
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.history.back()
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">
        Add eNACH
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Form Details</h2>
            <hr />
            <div className="row justify-content-around">
              <Formik
                // innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
                {({ errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                      <SetDates/>
                      <div className="form-row">
                        <div className="form-group col">
                          <FormElement
                            label="Loan Number"
                            name="loan_number"
                            type="text"
                            errors={errors}
                            touched={touched}
                            // disabled
                          ></FormElement>
                          <ErrorMessage
                            name="loan_number"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        {/* <div className="form-group col">
                          <FormElement
                            label="Mandate Amount"
                            name="emi_amount"
                            type="number"
                            disabled={true}
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                          <ErrorMessage
                            name="emi_amount"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div> */}
                        <div className="form-group col">
                          <FormElement
                            label="First EMI Date"
                            name="start_date"
                            type="date"
                            onKeyDown={(e) => e.preventDefault()}
                            disabled
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group col">
                          <FormElement
                            label="Last EMI Date"
                            name="end_date"
                            type="date"
                            onKeyDown={(e) => e.preventDefault()}
                            disabled
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        
                        
                        </div>
                      <div className="form-row">
                      <div className="form-group col">
                          <FormElement
                            label="Customer Name"
                            name="name"
                            type="text"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        
                        <div className="form-group col">
                          <FormElement
                            label="Customer Mobile Number"
                            name="contact"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>

                         <ErrorMessage
                            name="contact"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Mandate Email Address"
                            name="email"
                            type="text"
                            errors={errors}
                            touched={touched}
                          ></FormElement>

                           <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                      <div className="form-group col">
                          <label>Payment Type</label>
                          <Field
                            name="payment_type"
                            as="select"
                            className={
                              "form-control" +
                              (errors.payment_type && touched.payment_type
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="DebitCard">DebitCard</option>
                            <option value="netBanking">NetBanking</option>
                            <option value="all">All</option>
                          </Field>
                          <ErrorMessage
                            name="payment_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group col">
                          <label>Bank Name</label>
                          <Field
                            name="bank_code"
                            as="select"
                            className={
                              "form-control" +
                              (errors.bankAccountType && touched.bankAccountType
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="" selected disabled></option>
                            {
                              Object.keys(bankList).map((bcode,idx)=>{
                                return (
                                  <option value={bcode}>{bankList[bcode]}</option>
                                )
                              })
                            }
                          </Field>
                          <ErrorMessage
                            name="bank_code"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Account Number"
                            name="bank_account"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="IFSC code"
                            name="ifsc_code"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <label>Account Type</label>
                          <Field
                            name="bank_account_type"
                            as="select"
                            className={
                              "form-control" +
                              (errors.bankAccountType && touched.bankAccountType
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="CA">CA</option>
                            <option value="SB">SB</option>
                          </Field>
                          <ErrorMessage
                            name="bank_account_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="form-row">

                      <div className="form-group col">
                          <label>Frequency</label>
                          <Field
                            name="frequency_code"
                            as="select"
                            className={
                              "form-control" +
                              (errors.frequencyCode && touched.frequencyCode
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="ADHO">ADHO</option>
                          </Field>
                          <ErrorMessage
                            name="frequency_code"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <label>Frequency Type</label>
                          <Field
                            name="frequency_type"
                            as="select"
                            className={
                              "form-control" +
                              (errors.frequencyType && touched.frequencyType
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="RCUR">RCUR</option>
                            <option value="OOFF">OOFF</option>
                          </Field>
                          <ErrorMessage
                            name="frequency_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        
                      </div>
                      <div className="row justify-content-around">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="contained"
                          color="secondary"
                        >
                          Save Details
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
