import React, { useState, useEffect } from "react";
import { virtualAccountService } from "../../../_services/virtualaccount.service";
import { Modal } from "@material-ui/core";
import { Box,Select,MenuItem, FormControl,InputLabel } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Reconciliation from "../../Collections/Reconciliation";
import { Edit, Delete, Add, Print } from "@material-ui/icons";
import { employeeService } from "../../../_services/employee.service";
import { Input } from "antd";
import { receiptService } from "../../../_services/receipt.service";
import { ManualReconciliatiion } from "../../Collections/ManualReconciliation";
import roleChecker from "../../../_helpers/rolechecker";
import VAQRModel from "./VAQRModel";
import displayhelper from "../../../_helpers/displayhelper";
import ReconcileWithAutoCloudModal from "../../Collections/ReconcileWithAutoCloudModal"

const { Search } = Input;

function VAPayments(props) {
  const { path } = props.match;
  const [payments, setPayments] = useState([]);
  const [searchFilter, setSearchFilter] = useState();
  const user = employeeService.userValue;

  const [field, setField] = useState({})
  const [open, setOpen] = useState(false)
  const [opens, setOpens] = useState(false)
  const [loan, setLoan] = useState(null)
  const [value, setValue] = useState('')
  const [customerPayment, setCustomerPayment] = useState()
  const [filterRecon,setFilterRecon]= useState("All")
  const [fpayments, setFPayments] = useState([]);
  
 
  const handleChange = (fieldKey, fieldVal) => {
    field[fieldKey] = fieldVal;
    setField(field);
  }
  const handleOpen = (payment) => {
    setOpen(true)
    setCustomerPayment(payment)
  }
  const handleOpens = (receiptdata) => {
    setOpens(true)
    setCustomerPayment(receiptdata)
  }
  const handleClose = () => {
    setOpen(false)
    setCustomerPayment()
  }
  const handleCloses = () => {
    setOpens(false)
    setCustomerPayment()
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 380,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };

  const handleSet = (loan_no, date) => {
      let dates = new Date(date);
      let year = dates.getFullYear();
      let month = dates.getMonth() + 1;
      let day = dates.getDate();
      let rand = Math.random() * 1000
      let randNum = Math.floor(rand);
      let receipt_no = `${day}${month}${year % 100}${loan_no}${randNum}`
      return receipt_no;
    
  }

  function convert(str) {
    let dateStr1 = str.split(" ")[0];
    let dateItems = dateStr1.split("-");
    return dateItems[2] + "-" + dateItems[1] + "-" + dateItems[0];
  }

  const generateReceipt = (payment) => {
console.log("payments",payment)
   const receipt_no = handleSet(payment.virtualaccount[0].loan_account, payment.updated)
   let dates = new Date(payment.updated);
   let year = dates.getFullYear();
   let month = dates.getMonth() + 1;
   let day = dates.getDate();
   let hour = dates.getHours()
   let minutes=dates.getMinutes()
   let invoice_no=`${year}${month.toString().padStart(2,'0')}${day.toString().padStart(2,'0')}${hour.toString().padStart(2,'0')}${minutes.toString().padStart(2,'0')}/${payment.virtualaccount[0].loan_account}`

    receiptService.Create({cust_name: payment.virtualaccount[0].customer_name,loan_no: payment.virtualaccount[0].loan_account, payment: payment.txn_amt, emp_name: user.first_name, date: payment.updated, receipt_no: receipt_no, mode: 'Online', reference_id: payment._id,invoice_no:invoice_no }).then((response) => {
      alert("Receipt Generated");
      window.location.reload();
    })
      .catch((err) => {
        alert("An error occured")
        console.log(err)
      })
  }



  const handleSearch = (search) => {
    setLoan(search)
     virtualAccountService.getPaymentsByAccount({'loan_account':search}).then((p)=> {
      console.log(p);
      setPayments(p);
      filterPayments(p);
     })
     .catch((err)=> {
       console.log(err);
     })
  }

  const dateCompare = () => {
    const b = new Date()
    b.setTime(1654194600000)
    // b.setTime(1655317800000)
    // console.log(b)
    let c = b.getTime()
    return c
  }

  const [isLoading, setIsLoading] = useState(true);


  function filterPayments(pl) {
    var fpl = pl.filter((y) => {

      if(filterRecon==='All')
      {
        return true;
      }
      if(filterRecon==="Done")
      {
        if(y.reconcilelist && y.reconcilelist.length > 0)
        {
          return true;
        }
        else
        {
          return false;
        }
      }

      if(filterRecon==="Pending")
      {
        if(y.reconcilelist && y.reconcilelist.length > 0)
        {
          return false;
        }
        else
        {
          return true;
        }
      }

       
    })
    setFPayments(fpl);
  }


  useEffect(() => {
    filterPayments(payments)
  }, [filterRecon])



  useEffect(() => {
    async function getPayments() {
      await virtualAccountService.getAllPayments().then((x, idx) => {
        console.log(x);
        if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isHOGoldAccess(user.role) || roleChecker.hasAccountsAccess(user.role) ) {
          setPayments(x);
          setIsLoading(false)
          setFPayments(x);
        }
        else if (roleChecker.isSalesTeamAccess(user.role)) {
          let y = x.filter((p) => p.virtualaccount[0].loan_account === user.branch_id)
          setPayments(y);
          setIsLoading(false)
          setFPayments(y);
        }
      });
    }
    if(roleChecker.hasAccountsAccess(user.role) || roleChecker.isPIVGAccess(user.role) || [20710].includes(user.emp_id))
    {
      getPayments();
    }
    
  }, []);

  const handleSubmit = async () => {
    await virtualAccountService.getPaymentsByDate(field)
      .then((data) => {
        console.log(data)
        setPayments(data)
        setFPayments(data)
      })
      .catch((err) => {
        console.log(err, "error")
      })
  }
  const handleClick = (e) => {
    e.preventDefault()
    props.history.push(`/home/printreceipt`, { receipt: { ...customerPayment, value } })
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        
        <Modal
          open={opens}
          onClose={handleCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <form onSubmit={(e) => handleClick(e)} >
                <label style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px'
                }} > For
                  <select style={{
                    width: '200px',
                    height: '30px',
                    borderRadius: '5px',
                    border: '1px solid grey'
                  }} onChange={(e) => setValue(e.target.value)} required>
                    <option value=""></option>
                    <option value="EMI">EMI</option>
                    <option value="Loan Charges">Loan Charges</option>
                  </select>
                </label>
                <button style={{ color: "white", margin: '20px', backgroundColor: '#0066cc', borderRadius: "5px", padding: "5px", border: 'none', width: '150px' }}  >Submit</button>
              </form>
            </Typography>
          </Box>
        </Modal>
        <h2>
          QR Payments Collected &nbsp;
        </h2>
        {roleChecker.isAdminAccess(user.role) && (
            <ManualReconciliatiion />
         )}
      </div>
   
      <br />
      <Search
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Loan Account"
          enterButton
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            width: "200px",
            marginTop: "2vh",
            marginRight:"25px"
          }}
        />
      {roleChecker.hasAccountsAccess(user.role) && (
        <>
      <span>From Date: </span>
      <input
        label=" Date"
        type="date"
        onKeyDown={(e) => e.preventDefault()}
        name="loan_date"
        onChange={(e) => handleChange('loan_from_date', e.target.value)}
      />

      <span style={{ marginLeft: "5px" }}>To Date:</span>
      <input
        label=" Date"
        style={{ marginLeft: "5px" }}
        type="date"
        onKeyDown={(e) => e.preventDefault()}
        name="loan_date"
        onChange={(e) => handleChange('loan_to_date', e.target.value)}
      />
      <button className="btn btn-primary" onClick={handleSubmit} style={{ marginLeft: "60px" }} >Load Payments</button>
      <FormControl style={{ marginLeft: "30px",width:"150px"}}>
      <InputLabel id="filter_recon_label">Filter Reconciliation</InputLabel>
      <Select
      labelId="filter_recon_label"
      name="reconFilter"
      label="Filter Reconcilliation"
      value={filterRecon}
      onChange={(e) => setFilterRecon(e.target.value)}
      >
        <MenuItem value="All">All</MenuItem>
        <MenuItem value="Done">Reconciled</MenuItem>
        <MenuItem value="Pending">Pending Reconciled</MenuItem>
        
      </Select>
      </FormControl>

      </>
      )}
      <br /><br />

      <VAQRModel loan = {loan}/><br/>

      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Payment Date</Th>
            <Th style={{ width: "10%" }}>Virtual Account Number</Th>
            <Th style={{ width: "5%" }}>Payment Amount</Th>
            <Th style={{ width: "10%" }}>Customer Name</Th>
            <Th style={{ width: "10%" }}>Loan Account</Th>
            <Th style={{ width: "10%" }}>application id</Th>

            <Th style={{ width: "15%" }}>Sender details</Th>
            <Th style={{ width: "15%" }}>Ref</Th>
            <Th style={{ width: "10%" }}>Receipt</Th>
            <Th style={{ width: "10%" }}></Th>
          </Tr>
        </Thead>
        {isLoading === "true" ? (<span className="spinner-border spinner-border-sm"></span>) :
          <Tbody>
            {fpayments &&
              fpayments.map((payment, idx) => (
                <Tr key={idx}>
                  <Td>     
                    {payment.txn_date.split(' ')[0]}
                  </Td>
                  <Td>{payment.vaccountnum}</Td>
                  <Td>Rs. {payment.txn_amt && displayhelper.displayNumberAccounting(Number(payment.txn_amt))}</Td>
                  <Td>{payment.virtualaccount.map((va) => va.customer_name)}</Td>
                  <Td>{payment.virtualaccount.map((va) => va.loan_account)}{payment.customer && payment.customer.length>0 && ("/"+ payment.customer[0].application_id)}</Td>
                  <Td>{payment.virtualaccount.map((va) => va.application_id)}</Td>
                  <Td >{payment.remitter_name}/{payment.remitter_account_num}/{payment.sender_ifsc_code}</Td>
                  <Td>{payment.utr_num}/{payment.sender_to_receiver_info}</Td>
                  <Td>{payment.receiptdata && payment.receiptdata.length > 0 ? <button style={{ color: "white", backgroundColor: 'green', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} onClick={() => handleOpens(payment.receiptdata[0])}><Print fontSize="small" /></button> : <button style={{ color: "white", backgroundColor: '#0066cc', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} onClick={() => generateReceipt(payment)}>Generate Receipt</button>}</Td>
                  { (roleChecker.hasAccountsAccess(user.role))  ? (
                  <Td>{payment.reconcilelist && payment.reconcilelist.length > 0 ? (<b>Reconciled <br/> {payment.reconcilelist[0].loan_account}</b>)  :
                      new Date(payment.updated).getTime()>=dateCompare()  && ( payment?.virtualaccount[0]?.application_id && !(payment.virtualaccount[0].application_id).includes("KFPLGL") ? <ReconcileWithAutoCloudModal txn_amt={payment.txn_amt} txn_date={convert(payment.txn_date)} payment_ref={payment.utr_num} payment_mode={"online"} reference_id={payment._id} application_id={payment.virtualaccount[0].application_id} /> :
                      <Reconciliation txn_amt={payment.txn_amt} txn_date={convert(payment.txn_date)} payment_ref={payment.utr_num} payment_mode={"online"} reference_id={payment._id} />)}
                  </Td>
                  ):null}
                  {/* <Td> <ReconcileWithAutoCloudModal/>  </Td> */}
                </Tr>
              ))}
            {!isLoading === "true" && (
              <Tr>
                <Td colSpan="4" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
          </Tbody>
        }
      </Table>
      {/* {
        console.log(user.first_name)
      } */}
    </>
  );
}

export { VAPayments };