import React from 'react'
import { Paper } from '@material-ui/core';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import urlhelper from '../../../../_helpers/urlhelper';
import { history } from '../../../../_helpers/history'
import { Button } from "@material-ui/core";



import { Input } from "antd";
import EditInsuranceDetailsModal from './EditInsuranceDetailsModal';
import InsuranceFileUpload from './InsuranceFileUpload';
const { Search } = Input;


const InsuranceDetails = (props) => {

    const formikRef = React.useRef();

    const cust = props.cust


    let initialValues = {
        insurance_details: {
            loan_number: "",
            insured_person_name: "",
            insured_person_dob: "",
            insured_person_gender: "",
            nominee_name: "",
            nominee_dob: "",
            nominee_gender: "",
            colender_name: "",
            amount_insured: "",
            policy_number: "",
            policy_institute: "",
            premium_amount: "",
            loan_disbursement_date: "",
            insurance_date: "",
            policy_term: "",
            loan_amount: "",
            insurance_report: "",
        }
    }

    let validationSchema = Yup.object().shape({
        // loan_number: Yup.string().required("This is Required"),

    })

    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }



    return (
        <div>
           
            <p style={{ fontSize: 20, textAlign: "center", marginTop: "20px" }} >INSURANCE DETAILS</p>

            {cust && cust.insurance_details && cust.insurance_details.insurance_paid === "SUCCESS" ||cust.insurance_details && cust.insurance_details.insurance_paid === "INITIATED"||cust.insurance_details && cust.insurance_details.insurance_paid === "INPROGRESS" || cust && cust.insurance_details && cust.insurance_details.insurance_paid === "undefined" ?"":<span style={{ display: "flex", justifyContent: "flex-end", marginTop: "-50px" }} >
                <EditInsuranceDetailsModal cust={cust} />
            </span>}
            {cust && cust.insurance_details && cust.insurance_details.insurance_paid === "SUCCESS" ||cust.insurance_details && cust.insurance_details.insurance_paid === "INITIATED"||cust.insurance_details && cust.insurance_details.insurance_paid === "INPROGRESS" || cust && cust.insurance_details && cust.insurance_details.insurance_paid === "undefined" ?<span style={{ display: "flex", justifyContent: "flex-end", marginTop: "-50px" }} ><InsuranceFileUpload cust={cust}/></span>:""
                                    }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                // onSubmit={onSubmit}
                innerRef={formikRef}
            >
                {({ errors, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <Form>
                            <div style={{ marginLeft: "50px", marginTop: "30px" }}>
                                <div className="form-row">
                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Loan Number</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)", color: "rgb(41,73,185)" }} name="loan_number" value={cust.application_id} className={
                                            "form-control" +
                                            (errors.loan_number && touched.loan_number ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="loan_number"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Insured Person Name</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="insured_person_name" value={ cust && cust.insurance_details && cust.insurance_details.insured_person_name} className={
                                            "form-control" +
                                            (errors.insured_person_name && touched.insured_person_name ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="insured_person_name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Insured Person DOB</label>
                                        <Field disabled onKeyDown={(e) => e.preventDefault()} style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="loan_number" type="date" value={dateFormat(cust && cust.insurance_details && cust.insurance_details.insured_person_dob)} className={
                                            "form-control" +
                                            (errors.insured_person_dob && touched.insured_person_dob ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="insured_person_dob"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Insured Person Gender</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="insured_person_gender" value={cust && cust.insurance_details && cust.insurance_details.insured_person_gender} className={
                                            "form-control" +
                                            (errors.insured_person_gender && touched.insured_person_gender ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="insured_person_gender"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Nominee Name</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)",borderColor: "rgb(41,73,185)" }} name="nominee_name" value={cust && cust.insurance_details && cust.insurance_details.nominee_name} className={
                                            "form-control" +
                                            (errors.nominee_name && touched.nominee_name ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="nominee_name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Nominee DOB</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="nominee_dob" onKeyDown={(e) => e.preventDefault()} type="date" value={dateFormat(cust && cust.insurance_details && cust.insurance_details.nominee_dob)} className={
                                            "form-control" +
                                            (errors.nominee_dob && touched.nominee_dob ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="nominee_dob"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Nominee Gender</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="nominee_gender" value={cust && cust.insurance_details && cust.insurance_details.nominee_gender} className={
                                            "form-control" +
                                            (errors.nominee_gender && touched.nominee_gender ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="nominee_gender"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col">
                                                    <label  style={{ color: "rgb(41,73,185)" }}>Nominee Relationship</label>
                                                    <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="nominee_gender" value={cust && cust.insurance_details && cust.insurance_details.nominee_relationship} className={
                                            "form-control" +
                                            (errors.nominee_relationship && touched.nominee_relationship ? " is-invalid" : "")
                                        } />
                                                    <ErrorMessage
                                                        name="nominee_relationship"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                    
                                </div>

                                <div className="form-row">
                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Amount Insured</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="amount_insured" value={cust && cust.insurance_details && cust.insurance_details.amount_insured} className={
                                            "form-control" +
                                            (errors.amount_insured && touched.amount_insured ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="amount_insured"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Co-Lender Name</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="colender_name" value={cust && cust.insurance_details && cust.insurance_details.colender_name} className={
                                            "form-control" +
                                            (errors.colender_name && touched.colender_name ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="colender_name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                                    <label  style={{ color: "rgb(41,73,185)" }}>Occupation</label>
                                                    <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="colender_name" value={cust && cust.insurance_details && cust.insurance_details.occupation_type} className={
                                            "form-control" +
                                            (errors.occupation_type && touched.occupation_type ? " is-invalid" : "")
                                        } />
                                                    <ErrorMessage
                                                        name="occupation_type "
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                    {/* <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Policy Number</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="policy_number" value={cust && cust.insurance_details && cust.insurance_details.policy_number} className={
                                            "form-control" +
                                            (errors.policy_number && touched.policy_number ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="policy_number"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div> */}

                                    {/* <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Policy Institute</label>
                                        <Field disabled style={{ width: "200px", height: "35px", backgroundColor:"white", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="policy_institute" value={cust && cust.insurance_details && cust.insurance_details.policy_institute} className={
                                            "form-control" +
                                            (errors.policy_institute && touched.policy_institute ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="policy_institute"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div> */}

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Premium Amount</label>
                                        <Field disabled style={{ width: "200px", height: "35px", backgroundColor:"white", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="premium_amount" value={cust && cust.insurance_details && cust.insurance_details.premium_amount} className={
                                            "form-control" +
                                            (errors.premium_amount && touched.premium_amount ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="premium_amount"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Disbursement Date</label>
                                        <Field disabled style={{ width: "200px", backgroundColor:"white",  height: "35px", backgroundColor:"white", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }}  name="loan_disbursement_date" onKeyDown={(e) => e.preventDefault()} type="date" value={dateFormat(cust && cust.loan && cust.loan.disbursement_date)} className={
                                            "form-control" +
                                            (errors.loan_disbursement_date && touched.loan_disbursement_date ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="loan_number"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    {/* <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Insurance Date</label>
                                        <Field disabled style={{ width: "200px", height: "35px", backgroundColor:"white", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="insurance_date" type="date" value={dateFormat(cust && cust.insurance_details && cust.insurance_details.insurance_date)} className={
                                            "form-control" +
                                            (errors.insurance_date && touched.insurance_date ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="insurance_date"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div> */}

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Policy Term</label>
                                        <Field disabled style={{ width: "200px", height: "35px", backgroundColor:"white", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="policy_term" value={cust && cust.insurance_details && cust.insurance_details.policy_term} className={
                                            "form-control" +
                                            (errors.policy_term && touched.policy_term ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="policy_term"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col">
                                        <label style={{ color: "rgb(41,73,185)" }}>Loan Amount</label>
                                        <Field disabled style={{ width: "200px", height: "35px", backgroundColor:"white", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="loan_amount" value={cust && cust.loan && cust.loan.sanction_amount} className={
                                            "form-control" +
                                            (errors.loan_amount && touched.loan_amount ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="loan_amount"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col">
                                                    <label  style={{ color: "rgb(41,73,185)" }}>Annual Income</label>
                                                    <Field disabled style={{ width: "200px", backgroundColor:"white", height: "35px", color:"rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="colender_name" value={cust && cust.insurance_details && cust.insurance_details.annual_income} className={
                                            "form-control" +
                                            (errors.annual_income && touched.annual_income ? " is-invalid" : "")
                                        } />
                                                    <ErrorMessage
                                                        name="annual_income"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                </div>
                              


                                    <table class="table-success" style={{marginBottom:"3rem"}}>
  <thead>
    <tr>
      <th scope="col">Policy Institute</th>
      <th scope="col">Insurance Type</th>
      <th scope="col">Insurance Amount</th>
      <th scope="col">Insurance Date</th>
      <th scope="col">Policy Number</th>
    </tr>

  </thead>
  <tbody>
  {cust && cust.insurance_details && cust.insurance_details?.insurances?.map((x) => {
        return <tr>
 
      
      <td >{x.insurance_partner}</td>
      <td >{x.insurance_type}</td>
      <td >{x.total_ins_amt}</td>
      <td >{dateFormat(x.insurance_date)}</td>
      <td >{x.policy_number}</td>
    </tr>
       })}
  </tbody>
</table>



<table class="table-success">
  <thead>
    <tr>
      <th scope="col">Payment Paid</th>
      <th scope="col">UTR Number</th>
      <th scope="col">Payment Status</th>
      <th scope="col">Payment date</th>
     
    </tr>

  </thead>
  <tbody>
    
    <tr>
      
      <td >{cust && cust.insurance_details && cust.insurance_details.payment_details && cust.insurance_details.payment_details.amount}</td>
      <td >{cust && cust.insurance_details && cust.insurance_details.payment_details && cust.insurance_details.payment_details.utr_number}</td>
      <td> {cust && cust.insurance_details && cust.insurance_details.payment_details && cust.insurance_details.payment_details.status ==="SUCCESS" ? 
             <span title="Verified">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16" style={{color:"green",paddingLeft:"3px",cursor:"pointer"}}>
             <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
             <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
         </svg>
          </span>
            :
            <span  title="Verify">  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red" className="bi bi-patch-plus" viewBox="0 0 16 16" style={{ transform: 'rotate(45deg)',paddingLeft:"3px",cursor:"pointer"}}>
            <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
        </svg>
        </span>

            }</td>
      <td>{dateFormat(cust && cust.insurance_details && cust.insurance_details.payment_details && cust.insurance_details.payment_details.request_date)}</td>
    </tr>
  </tbody>
</table>
                            </div>
                        </Form>
                    )
                }}
            </Formik>

            {cust && cust.insurance_details && cust.insurance_details.reports && cust.insurance_details.reports.length>0 && (
                <div>
                <p style={{fontSize:20, textAlign:"center", marginTop:"20px"}}>Insurance Reports</p>
            <table style={{ marginTop: "20px", marginLeft:"50px", marginBottom: "20px", alignItems: "center", width: "90%" }} >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>View File</th>
                    </tr>
                </thead>
                <tbody>
                    {cust?.insurance_details?.reports?.map((link) => {
                        return <tr>
                            <td>{link.name}</td>
                            <td>
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    onClick={() => history.push(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(link.url) + "&download=1")}
                                >View</Button>
                            </td>
                        </tr>

                    })}
                </tbody>
            </table>
            </div>
            )}
        </div>
    )
}

export default InsuranceDetails