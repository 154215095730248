import React from 'react'
import { Paper } from "@material-ui/core";
import { useState, useEffect } from 'react';
import { customerService, loanService } from '../../../_services';
import { history } from "../../../_helpers/history"
import datehelper from "../../../_helpers/datehelper"
import Webcam from "react-webcam";
import PreviewApplicationForm from './PreviewApplicationForm';
import { uploadCumtomerImage } from "../../../_helpers/upload"
import "../../../css/common.css"


function ApplicationForm(props) {

    const [show, setShow] = useState(false)
    const customerRef = React.useRef(null);
    const [same, setSame] = React.useState(false)

    const url = window.location.pathname;
    const temp = url.split("/")

    const loan = props.location.state.list

    const bucket = process.env.REACT_APP_s3sblBucket


    const [values, setValues] = useState({
        first_name: "", title: "", middle_name: "", last_name: "", gender: "", father_name: "", mother_name: "",
        address: "", landmark: "", tehsil: "", district: "", city: "", pincode: "", state: "", no_of_years: "",
        permanent_address: "", permanent_landmark: "", permanent_tehsil: "", permanent_district: "", permanent_city: "", permanent_pincode: "", permanent_state: "",
        current_residenceStatus: "", mobile: "", email: "", dob: "", marital_status: "", qualification: "", other_qualification: "",
        pan_no: "", aadhar_no: "", religion: "", other_religion: "", category: "", other_category: "",
        occupation: "", salaried_type: "", nature_of_business: "", nature_of_concern: "", work_address: "",
        work_pincode: "", work_city: "", work_state: "", application_date: "",
        reference_name: "", reference_relationship: "", reference_address: "", reference_mobile: "",
        reference_name2: "", reference_relationship2: "", reference_address2: "", reference_mobile2: "",
        account_holder: "", bank_name: "", branch: "", account_number: "", account_type: "",
        property_address: "", property_pin: "", nearest_landmark: "", land_area: "", house_area: "",
        property_type: "", ownership_type: "", construction: "", market_value: "", present_owner: "",
        loan_amount: "", loan_tenure: "", interest_ratetype: "", loan_purpose: "", date: "", link: "",
        processing_fee: "", cheque_number: "", cheque_date: "", cheque_purpose: "", name: "", signature: "", role: ""
    })

    const setData = async () => {
        const customer = await customerService.searchCustomer({ customer_id: props.location.state.customer_id })
        console.log(customer[0], "uuu")
        if (customer && customer.length > 0) {
            setValues({
                ...values, application_id: props.location.state.list.application_id, first_name: customer[0].general_details.customer_firstname, title: customer[0].general_details.customer_title , middle_name: customer[0].general_details.customer_middlename, last_name: customer[0].general_details.customer_lastname , gender: customer[0].general_details.gender , father_name: customer[0].general_details.fathername , mother_name: customer[0].general_details.mothername ,
                address: customer[0].personal_details.correspondence_address.address_line_1, landmark: customer[0].personal_details.correspondence_address.landmark, tehsil: customer[0].personal_details.correspondence_address.tehsil, district: customer[0].personal_details.correspondence_address.district, city: customer[0].personal_details.correspondence_address.city, pincode: customer[0].personal_details.correspondence_address.pin_code, state: customer[0].personal_details.correspondence_address.state, no_of_years: customer[0].personal_details.correspondence_address.no_of_years,
                permanent_address: customer[0].personal_details.permanent_address.address_line_1, permanent_landmark: customer[0].personal_details.permanent_address.landmark, permanent_tehsil: customer[0].personal_details.permanent_address.tehsil, permanent_district: customer[0].personal_details.permanent_address.district, permanent_city: customer[0].personal_details.permanent_address.city, permanent_pincode: customer[0].personal_details.permanent_address.pin_code, permanent_state: customer[0].personal_details.permanent_address.state,
                current_residenceStatus: customer[0].personal_details.correspondence_address.address_type, mobile: customer[0].general_details.customer_mobile_num, email: "", dob: customer[0].general_details.customer_dob, marital_status: customer[0].personal_details.marital_status, qualification: customer[0].personal_details.educational_qualification, other_qualification: "",
                pan_no: '', aadhar_no: customer[0].verification_details.aadhaar_mask_no, religion: customer[0].personal_details.religion, category: customer[0].personal_details.category, other_category: "",
                occupation: customer[0]&& customer[0].economic_details && customer[0].economic_details.occupation_type, salaried_type: "", nature_of_business: customer[0]&& customer[0].economic_details && customer[0].economic_details.nature_of_business_or_employment, nature_of_concern: "", work_address: customer[0]&& customer[0].economic_details && customer[0].economic_details.business_or_employment_address,
                work_pincode: customer[0]&& customer[0].economic_details && customer[0].economic_details.business_or_employment_pincode, work_city: customer[0]&& customer[0].economic_details && customer[0].economic_details.business_or_employment_city, work_state: customer[0]&& customer[0].economic_details && customer[0].economic_details.business_or_employment_state, application_date: "",
                reference_name: loan && loan.reference && loan.reference.length>0 && loan.reference[0].reference_name, reference_relationship: loan && loan.reference && loan.reference.length>0 && loan.reference[0].reference_relation, reference_address: loan && loan.reference && loan.reference.length>0 && loan.reference[0].reference_address, reference_mobile: loan && loan.reference && loan.reference.length>0 && loan.reference[0].reference_contact_no,
                loan_amount: props.location.state.list.loan.amount, loan_tenure: props.location.state.list.loan.tenure, interest_ratetype: "", loan_purpose: "", date: props.location.state.list.created, link: "",
            })
        }
    }

    const getImage = async () => {
        const front = customerRef.current.getScreenshot();
        const link = await uploadCumtomerImage(bucket, "image/jpeg", `customer.jpg`, front);
        setValues({ ...values, link: link })
        console.log(link)
    }

    const discard = () => {
        setValues({ ...values, link: "" })
    }

    const handleChange = (e) => {
        // console.log(e.target.value)
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        loanService.addApplicationForm(values)
            .then((x) => {
                alert("Form Generated Successfully")
                if (temp[3] !== "edit") {
                    history.push(`/home/sblloans/details/${props.location.state.list._id}`)
                } else {
                    history.push('/home/sblloans')
                }
            })
            .catch((err) => {

                alert("Something went wrong")
            })
        console.log(values)
    }

    const videoConstraints = {
        width: 200,
        height: 200,
        facingMode: "user",
    };

    const arr = {
        aadhar_no: '12',
        application_id: "B0200346"
    }

    const getFormData = () => {
        const data = props.history.location.state
        setValues(data)
        console.log(data)
    }

    const sameAddress = (e) => {
        console.log(e.target.checked)
        if (e.target.checked === true) {
            setValues({
                ...values,
                permanent_address: values.address,
                permanent_landmark: values.landmark,
                permanent_tehsil: values.tehsil,
                permanent_district: values.district,
                permanent_pincode: values.pincode,
                permanent_city: values.city,
                permanent_state: values.state
            })
        } else {
            setValues({
                ...values,
                permanent_address: "",
                permanent_landmark: "",
                permanent_tehsil: "",
                permanent_district: "",
                permanent_pincode: "",
                permanent_city: "",
                permanent_state: ""
            })
        }
    }


    useEffect(() => {
        if (temp[3] === "edit") {
            getFormData()
        } else {
            setData()
        }

    }, [])

    return (
        <>
            <h1>Application Form </h1><br /><br />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>Application Id <input type='text' disabled style={{ width: "200px" }} name='application_id' onChange={handleChange} value={values.application_id} /> </label>

                <label class="form-check-label" for="flexCheckDefault" style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                    Role: &nbsp; <b>{values.role}</b> <input class="form-check-input" type="checkbox" value={values.role} id="flexCheckDefault" checked />
                </label>
                <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>Application Date <input type='date' onKeyDown={(e) => e.preventDefault()}  disabled style={{ width: "200px" }} name='application_date' onChange={handleChange} value={datehelper.getPaymentDateString(new Date(values.date))} /> </label>
            </div>
            <hr style={{ border: "2px solid black" }}></hr>
            <form style={{ maxWidth: "1000px", margin: "auto" }} onSubmit={(e) => { handleSubmit(e) }} >
                <Paper style={{ padding: 20 }}>
                    <h2> Select Persons's Details</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around" }} >
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>Title <select className="form-select form-select-sm" name='title' style={{ width: "200px" }} aria-label=".form-select-sm example" value={values.title} onChange={handleChange}>
                            <option selected></option>
                            <option >Mr</option>
                            <option >Mrs</option>
                            <option >Ms</option>
                            <option >Dr</option>
                        </select></label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >First Name <input type='text' style={{ width: "200px" }} name='first_name' value={values.first_name} onChange={handleChange} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Middle Name <input type='text' style={{ width: "200px" }} name='middle_name' onChange={handleChange} value={values.middle_name} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Last Name <input type='text' style={{ width: "200px" }} name='last_name' onChange={handleChange} value={values.last_name} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Gender <select className="form-select form-select-sm" name='gender' style={{ width: "200px" }} aria-label=".form-select-sm example" value={values.gender} onChange={handleChange}>
                            <option selected></option>
                            <option >Male</option>
                            <option >Female</option>
                            <option >Other</option>
                        </select></label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>Father's / Spouse's Name <input type='text' style={{ width: "200px" }} name='father_name' value={values.father_name} onChange={handleChange} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Mother's Name <input type='text' name='mother_name' style={{ width: "200px" }} onChange={handleChange} value={values.mother_name} /> </label>

                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }} > Current Residence Address <textarea type='text' style={{ width: "200px", height: "80px" }} required name='address' value={values.address} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Landmark <input type='text' className='required' name='landmark' style={{ width: "200px" }} onChange={handleChange} required value={values.landmark} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Tehsil <input type='text' name='tehsil' style={{ width: "200px" }} onChange={handleChange} required value={values.tehsil} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > District <input type='text' name='district' style={{ width: "200px" }} onChange={handleChange} required value={values.district} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Pin Code <input type='number' name='pincode' style={{ width: "200px" }} onChange={handleChange} required value={values.pincode} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > City <input type='text' name='city' style={{ width: "200px" }} onChange={handleChange} required value={values.city} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}   > State <input type='text' name='state' style={{ width: "200px" }} required onChange={handleChange} value={values.state || "Rajasthan"} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > No. of Years at current residence <input type='number' name='no_of_years' style={{ width: "200px" }} required onChange={handleChange} value={values.no_of_years} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }} >Current residence is <select className="form-select form-select-sm" style={{ width: "200px" }} required name='current_residenceStatus' aria-label=".form-select-sm example" value={values.current_residenceStatus} onChange={handleChange}>
                            <option selected></option>
                            <option value="self_owned" >Self Owned</option>
                            <option value='residential' >Residence</option>
                            <option value='family' >Family</option>
                            <option value='rented' >Rented</option>
                            <option value='company' >Company's</option>
                        </select></label>

                        <label style={{ marginTop: "80px", marginRight: "40px", marginLeft: "-220px" }}> Same as above &nbsp; <input type="checkbox" onChange={(e) => { sameAddress(e) }} /> </label>

                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Permanent Address <textarea type='text' style={{ width: "200px", height: "80px" }} name='permanent_address' required value={values.permanent_address} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}> Landmark <input type='text' name='permanent_landmark' style={{ width: "200px" }} onChange={handleChange} required value={values.permanent_landmark} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Tehsil <input type='text' name='permanent_tehsil' style={{ width: "200px" }} onChange={handleChange} required value={values.permanent_tehsil} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > District <input type='text' name='permanent_district' style={{ width: "200px" }} onChange={handleChange} required value={values.permanent_district} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Pin Code <input type='number' name='permanent_pincode' style={{ width: "200px" }} onChange={handleChange} required value={values.permanent_pincode} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > City <input type='text' name='permanent_city' style={{ width: "200px" }} onChange={handleChange} required value={values.permanent_city} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}> State <input type='text' name='permanent_state' style={{ width: "200px" }} onChange={handleChange} required value={values.permanent_state || "Rajasthan"} /> </label>

                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}> Mobile <input type='number' style={{ width: "200px" }} name='mobile' value={values.mobile} onChange={handleChange} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Email Id <input type='text' style={{ width: "200px" }} name='email' value={values.email} onChange={handleChange} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > DOB <input type='date' onKeyDown={(e) => e.preventDefault()} name='dob' style={{ width: "200px" }} onChange={handleChange} value={values.dob} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Marital Status <select className="form-select form-select-sm" style={{ width: "200px" }} name='marital_status' value={values.marital_status} aria-label=".form-select-sm example" onChange={handleChange}>
                            <option selected></option>
                            <option >Single</option>
                            <option >Married</option>
                            <option >Other</option>
                        </select></label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>Qualifications <select className="form-select form-select-sm" style={{ width: "200px" }} name='qualification' value={values.qualification} aria-label=".form-select-sm example" onChange={handleChange}>
                            <option selected></option>
                            <option >Graduate</option>
                            <option >Post-Graduate</option>
                            <option >Other</option>
                        </select></label>
                        {values.qualification === "Other" ? (<label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}> <input type='text' name='other_qualification' value={values.other_qualification} style={{ width: "200px" }} /> </label>) : null}
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > PAN <input type='text' style={{ width: "200px" }} name='pan_no' value={values.pan_no}  disabled /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > AADHAR <input type='text' style={{ width: "200px" }} name='aadhar_no' value={values.aadhar_no}  disabled /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Religion <select className="form-select form-select-sm" style={{ width: "200px" }} name='religion' value={values.religion} aria-label=".form-select-sm example" onChange={handleChange}>
                            <option selected></option>
                            <option >Hindu</option>
                            <option >Muslim</option>
                            <option >Sikh</option>
                            <option >Christian</option>
                            <option >Other</option>
                        </select></label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Category <select className="form-select form-select-sm" style={{ width: "200px" }} name='category' value={values.category} aria-label=".form-select-sm example" onChange={handleChange}>
                            <option selected></option>
                            <option >OBC</option>
                            <option >SC</option>
                            <option >ST</option>
                            <option >DT/NT</option>
                            <option >Other</option>
                        </select></label>
                        {values.category === "Other" ? (<label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > <input type='text' name='other_category' value={values.other_category} style={{ width: "200px" }} /> </label>) : null}
                    </div>
                </Paper><br />
                <Paper style={{ padding: 20 }}>
                    <h2>Business/Employment Details</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >

                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Occupation <select className="form-select form-select-sm" name='occupation' required value={values.occupation} style={{ width: "200px" }} aria-label=".form-select-sm example" onChange={handleChange}>
                            <option selected></option>
                            <option value='salaried' >Salaried</option>
                            <option value='self_employed' >Self Employed</option>
                            <option value='self_employed_professional' >Self Employed Professional</option>
                        </select></label>
                        {values.occupation === "Salaried" ? (
                            <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Salaried Type <select className="form-select form-select-sm" style={{ width: "200px" }} name='salaried_type' value={values.salaried_type} aria-label=".form-select-sm example" onChange={handleChange}>
                                <option selected></option>
                                <option >Public Ltd.</option>
                                <option >Pvt. Ltd.</option>
                                <option >PSU</option>
                                <option >State</option>
                                <option >MNC</option>
                                <option >Others</option>
                            </select></label>
                        ) : null}
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Nature of Business / Empl. <input type='text' style={{ width: "200px" }} required name='nature_of_business' value={values.nature_of_business} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Nature of Concern / Empl. <input type='text' style={{ width: "200px" }} required name='nature_of_concern' value={values.nature_of_concern} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Business / Employment Address <textarea type='text' required style={{ width: "200px", height: "70px" }} name='work_address' value={values.work_address} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Pin Code<input type='number' name='work_pincode' required style={{ width: "200px" }} value={values.work_pincode} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > City <input type='text' name='work_city' required style={{ width: "200px" }} value={values.work_city} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column" }}  > State <input type='text' name='work_state' required style={{ width: "200px" }} onChange={handleChange} value={values.work_state} /> </label>

                    </div>
                </Paper><br />
                <Paper style={{ padding: 20 }}>
                    <h3>Reference (From relative only)</h3><br />
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}> Name <input type='text' style={{ width: "200px" }} name='reference_name' required value={values.reference_name} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Relationship <input type='text' style={{ width: "200px" }} name='reference_relationship' required value={values.reference_relationship} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Address <input type='text' name='reference_address' style={{ width: "200px" }} required onChange={handleChange} value={values.reference_address} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Mobile <input type='number' name='reference_mobile' style={{ width: "200px" }} required onChange={handleChange} value={values.reference_mobile} /> </label>
                    </div>
                </Paper><br />

                {/* <Paper style={{ padding: 20 }}>
                    <h3>Reference (From relative only)</h3><br />
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Name <input type='text' style={{ width: "200px" }} name='reference_name2' required value={values.reference_name2} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Relationship <input type='text' style={{ width: "200px" }} name='reference_relationship2' required value={values.reference_relationship2} onChange={handleChange} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Address <input type='text' name='reference_address2' style={{ width: "200px" }} required onChange={handleChange} value={values.reference_address2} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Mobile <input type='number' name='reference_mobile2' style={{ width: "200px" }} required onChange={handleChange} value={values.reference_mobile2} /> </label>
                    </div>
                </Paper><br /> */}

                {/* <Paper style={{ padding: 20 }}>
                    <h2>Bank Details</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Account Holder <input type='text' style={{ width: "200px" }} name='account_holder' value={values.account_holder} onChange={handleChange} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Bank Name <input type='text' style={{ width: "200px" }} name='bank_name' onChange={handleChange} value={values.bank_name} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Branch <input type='text' style={{ width: "200px" }} name='branch' onChange={handleChange} value={values.branch} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Account Number <input type='number' style={{ width: "200px" }} name='account_number' value={values.account_number} onChange={handleChange} /> </label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Account Type <input type='text' style={{ width: "200px" }} name='account_type' value={values.account_type} onChange={handleChange} /> </label>
                    </div>
                </Paper><br /> */}
                {/* <Paper style={{ padding: 20 }}>
                    <h2>Property Details / Agriculture Land Details</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px", }}  > Property Address <textarea type='textarea' style={{ width: "200px", height: "70px" }} name='property_address' onChange={handleChange} required value={values.property_address} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Pin Code <input type='number' style={{ width: "200px" }} name='property_pin' onChange={handleChange} value={values.property_pin} required /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Nearest Landmark <input type='text' style={{ width: "200px" }} name='nearest_landmark' onChange={handleChange} value={values.nearest_landmark} /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Area of Land <input type='text' style={{ width: "200px" }} name='land_area' value={values.land_area} onChange={handleChange} required /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Area of Flat / House / Office <input type='text' style={{ width: "200px" }} name='house_area' value={values.house_area} onChange={handleChange} required /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}> Property Type <select className="form-select form-select-sm" style={{ width: "200px" }} name='property_type' value={values.property_type} aria-label=".form-select-sm example" required onChange={handleChange}>
                            <option selected></option>
                            <option >Residential</option>
                            <option >Commercial</option>
                        </select></label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Ownership Type <select className="form-select form-select-sm" style={{ width: "200px" }} name='ownership_type' required value={values.ownership_type} aria-label=".form-select-sm example" onChange={handleChange} >
                            <option selected></option>
                            <option >Sole</option>
                            <option >Joint</option>
                        </select></label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Construction <select className="form-select form-select-sm" style={{ width: "200px" }} name='construction' required value={values.construction} aria-label=".form-select-sm example" onChange={handleChange} >
                            <option selected></option>
                            <option >Pakka</option>
                            <option >Semi Pakka</option>
                        </select></label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Market Value <input type='text' name='market_value' style={{ width: "200px" }} value={values.market_value} onChange={handleChange} required /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Present Owner <input type='text' name='present_owner' style={{ width: "200px" }} value={values.present_owner} onChange={handleChange} required /> </label>
                    </div>
                </Paper><br /> */}
                <Paper style={{ padding: 20 }}>
                    <h2>Loan Details</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Loan Amount <input type='number' name='loan_amount' style={{ width: "200px" }} value={values.loan_amount} onChange={handleChange} required /> </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Loan Tenure <input type='text' name='loan_tenure' style={{ width: "200px" }} value={values.loan_tenure} onChange={handleChange} required />(Months) </label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  >Interest Rate Type <select className="form-select form-select-sm" style={{ width: "200px" }} name='interest_ratetype' value={values.interest_ratetype} required aria-label=".form-select-sm example" onChange={handleChange}>
                            <option selected></option>
                            <option >Floating Rate</option>
                            <option >Fixed</option>
                            <option >Fixed and Floating Rate</option>
                        </select></label>
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Loan Purpose <input type='text' name='loan_purpose' value={values.loan_purpose} onChange={handleChange} style={{ width: "200px" }} required /></label>
                    </div>
                </Paper><br />
                {/* <Paper style={{ padding: 20 }}>
                    <h2>Processing Fee Details</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >
                        <label className='required' style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Processing fee(non-refundable) <input type='number' style={{ width: "200px" }} name='processing_fee' value={values.processing_fee} onChange={handleChange} required /></label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Cheque/DD Number  <input type='text' name='cheque_number' style={{ width: "200px" }} value={values.cheque_number} onChange={handleChange} /></label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Dated <input type='date' name='cheque_date' style={{ width: "200px" }} onChange={handleChange} value={values.cheque_date} /></label>
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}  > Drawn on <input type='text' name='cheque_purpose' style={{ width: "200px" }} onChange={handleChange} value={values.cheque_purpose} /> in favour of Kaabil Finance Private Limited</label>
                    </div>
                </Paper><br /> */}
                {/* <Paper style={{ padding: 20 }}>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }} >
                        <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }} name='link' > Customer Image</label>
                        {values.link ? (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <img
                                    src={values.link}
                                    alt="Id photo"
                                />
                                <button type='button' style={{ marginTop: "30px", width: "150px", height: "40px", marginRight: "40%" }} className='btn btn-danger' onClick={() => { discard() }}> Discard</button>
                            </div>
                        ) : <div style={{ display: "flex", flexDirection: "column" }}>
                            <Webcam
                                height={200}
                                audio={false}
                                ref={customerRef}
                                screenshotFormat="image/jpeg"
                                width={200}
                                videoConstraints={videoConstraints}
                            />
                            <button style={{ marginTop: "30px", width: "150px", height: "40px", marginRight: "40%", backgroundColor: "#FF4500" }} onClick={() => { getImage() }} type='button'>Capture</button>
                        </div >
                        }
                    </div>
                </Paper><br /> */}
                <div>
                    <button className='btn btn-primary' style={{ width: "200px", marginLeft: "40%" }} type='submit'>Save Data</button>
                </div>
            </form>
        </>
    )
}

export default ApplicationForm
