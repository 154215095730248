import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";

import { Button } from "@material-ui/core";
import { misService } from "../../_services";
import FormElement from "../uielements/FormElement";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import TextField from "@material-ui/core/TextField";
import datehelper from "../../_helpers/datehelper";

import { authPersonService } from "../../_services/auth_person.service";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function BankModal(props) {
  const loan = props.loan;
  const user = props.user;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [bank, setBank] = React.useState(null);

  const initialValues = {
    gold: {
      // ornaments: [],
      gross_weight: "",
      net_weight: "",
      // total_items: "",
    },
    loan_account: "",
    disburse_amount: "",
    sanction_amount: "",
    pf: "",
    roi: "",
    approved: false,
    maker: user._id,
    bank: "Federal",
    disburse_date: new Date(),
  };

  const decimalRoundOff = (num) => {
    return Math.round(num * 100) / 100;
  };

  const validationSchema = Yup.object().shape({
    loan_account: Yup.string().required("This field is required"),
    disburse_amount: Yup.number().required("This field is required"),
    sanction_amount: Yup.number().required("This field is required"),
    pf: Yup.number().required("This field is required"),
    roi: Yup.number().required("This field is required"),
  });

  const getLoanDetails = async (id) => {
    const bank_details = await misService.getBankAccount(id);
    setBank(bank_details);
    console.log('hii', bank_details)
    bank_details && bank_details.sanction_amount && setField({ ...field, sanction_amount: bank_details.sanction_amount, disburse_amount: bank_details.disburse_amount })
  };

  const handleOpen = async () => {
    if (loan.bank_details) {
      await getLoanDetails(loan.bank_details);
    }
    setOpen(true);
  };

  const approveBankDetails = () => {
    misService.approvebank(loan.bank_details, user._id).then(() => {
      getLoanDetails(loan.bank_details);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(fields, { setSubmitting }) {
    if (fields.disburse_amount <= fields.sanction_amount) {
      misService.addBankAccount(loan._id, fields).then((bank_details) => {
        alert("Bank Details Updated. Awaiting Verification.");
        // window.location.reload();
        setBank(bank_details);
        setField({ ...field, disburse_amount: fields.disburse_amount, sanction_amount: fields.sanction_amount })
        handleClose();
      });
    } else {
      alert('Disburse amount must be less or equal to sanction amount')
    }
    setSubmitting(false);
  }

  const [account, setAccount] = useState(false)
  const [amount, setAmount] = useState(false)
  const [sanction, setSanction] = useState(false)
  const [pfCharge, setPfCharge] = useState(false)
  const [date, setDate] = useState(false)
  const [change, setChange] = useState(false)
  const [field, setField] = useState({
  })


  const changeAccount = () => {
    setAccount(true)

  }
  const changeAmount = () => {
    setAmount(true)

  }
  const changeSanction = () => {
    setSanction(true)

  }
  const changePfCharge = () => {
    setPfCharge(true)

  }
  const changeDate = () => {
    setDate(true)

  }

  const maxDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  const handleChange = (fieldKey, fieldValue) => {
    field[fieldKey] = fieldValue
    setField(field)
    console.log(field)
  }

  const modify = async (e) => {
    console.log('bank id ', bank._id)
    if (field.disburse_amount <= field.sanction_amount) {
      misService.renewBankDetails(bank._id, field)
        .then((data) => {
          alert("Successfully Updated")
          window.location.reload()
        })
        .catch((err) => {
          alert("Something went wrong")
        })
    } else {
      alert('Disburse amount must be less or equal to sanction amount')
    }
  }


  return (
    <div>
      <Button style={{ width: "100%" ,border: '2px solid #3760F1'}} onClick={handleOpen} color="primary">
        {
          <span
            style={{
              color: loan.bank_details ? "green" : "red",
            }}
            data-toggle="tooltip"
            data-placement="bottom"
          >
            &nbsp;●
          </span>
        }
       <Add/> Co-Lending Details
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Co-lending Details</h2>
            {bank ? (
              <div>
                <p id="transition-modal-description">
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '30px' }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                      <b>Bank Name</b>
                      <b>{bank.bank}</b>
                      <br />
                    </div> */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                      {change !== true ?
                        <><b>Bank Name</b><b>{bank.bank}</b></>
                        : <label><b>Bank Name</b><select style={{ width: '90%', borderRadius: '5px',height:'30px',border:'2px solid grey' }} defaultValue={bank.bank} name="new_bank" onChange={(e) => handleChange("bank", e.target.value)}>
                          <option value="Federal">Federal</option>
                          <option value="CSB">CSB</option>
                          <option value="SIB">SIB</option>
                        </select> </label>}
                      {/* &nbsp;<button  onClick={changeDate}>Edit</button> */}

                      <br />

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                      {change !== true ?
                        <><b>Disbursement Date</b><b>{bank.disburse_date.split("T")[0]}</b></>
                        : <label><b>Disbursement Date</b><input label=" Date" onKeyDown={(e) => e.preventDefault()} type="date" style={{ width: '90%', borderRadius: '5px' }} defaultValue={datehelper.getDateInputString(new Date(bank.disburse_date))} name="new_date" max={maxDate()} onChange={(e) => handleChange("disburse_date", e.target.value)} /></label>}
                      {/* &nbsp;<button  onClick={changeDate}>Edit</button> */}

                      <br />

                    </div>

                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                      {change !== true ?
                        <><b>Account Number</b><b>{bank.loan_account}</b></>
                        : <label><b>Account Number</b><input style={{ width: '90%', borderRadius: '5px' }} type="text" name="new_account" defaultValue={bank.loan_account} placeholder="Account Number" onChange={(e) => handleChange("loan_account", e.target.value)} /></label>}
                      {/* &nbsp;<button  onClick={changeAccount}>Edit</button> */}

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                      {change !== true ?
                        <><b>Sanction Amount</b> <b> {bank.sanction_amount && <>{bank.sanction_amount}</>}</b></>
                        : <label><b>Sanction Amount</b> <input style={{ width: '90%', borderRadius: '5px' }} type="number" name="new_amount" defaultValue={bank.sanction_amount} placeholder="Sanction Amount" onChange={(e) => handleChange("sanction_amount", e.target.value)} /></label>}
                      {/* &nbsp;<button  onClick={changeSanction}>Edit</button> */}
                      <br />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      {change !== true ?
                        <><b>PF (₹)</b><b> {bank.pf && <>{bank.pf}</>}</b></>
                        : <lebel><b>PF (₹)</b><input style={{ width: '90%', borderRadius: '5px' }} defaultValue={bank.pf} type="number" name="new_amount" placeholder="PF Charges" onChange={(e) => handleChange("pf", e.target.value)} /></lebel>}
                      {/* &nbsp;<button  onClick={changePfCharge}>Edit</button> */}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      {change !== true ?
                        <> <b>ROI (%)</b> <b>{bank.roi && <>{bank.roi}</>}</b> </>
                        : <label><b>ROI (%)</b> <input style={{ width: '90%', borderRadius: '5px' }} defaultValue={bank.roi} type="number" name="new_amount" placeholder="ROI" onChange={(e) => handleChange("roi", e.target.value)} /></label>}
                      {/* &nbsp;<button  onClick={changePfCharge}>Edit</button> */}
                      <br />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                      {change !== true ?
                        <><b>Disbursement Amount</b><b>{bank.disburse_amount}</b></>
                        : <label><b>Disbursement Amount</b> <input style={{ width: '90%', borderRadius: '5px' }} type="text" name="new_amount" defaultValue={bank.disburse_amount} placeholder="Disbursement Amount" onChange={(e) => handleChange("disburse_amount", e.target.value)} /></label>}
                      {/* &nbsp;<button  onClick={changeAmount}>Edit</button> */}
                      <br />
                    </div>
                  </div>
                  <button style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', height: '37px', width: '65px', border: 'none' }} onClick={() => setChange(!change)}>Edit</button>

                  <button style={{ marginLeft: "50px" }} type="submit" onClick={(e) => modify(e)} className="btn btn-warning">Save</button>

                  <br />
                  <br />
                  <b>Ornaments&nbsp;</b>
                  <br />
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">No of Items</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loan.details && loan.details.gold && loan.details.gold.ornaments.map((row, idx) => (
                        <TableRow key={idx}>
                          <TableCell component="th" scope="row">
                            {row.desc}
                          </TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <br />
                  <div className="row justify-content-around">
                    <div>
                      {/* <b>Gross Weight(gm):&nbsp;</b>
                      {bank.gold.gross_weight} */}
                      {change !== true ?
                        <> <b>Gross Weight(gm):&nbsp;</b>{bank.gold.gross_weight}</>
                        : <label><b>Gross Weight(gm):&nbsp;</b> <input style={{ width: '70%', borderRadius: '5px' }} type="number" name="new_amount" defaultValue={bank.gold.gross_weight} placeholder="Gross Weight" onBlur={(e) => handleChange("gold.gross_weight", e.target.value)} onChange={(e) => handleChange("gross_weight", e.target.value)} /></label>}
                    </div>
                    <div>
                      {/* <b>Net Weight(gm):&nbsp;</b>{bank.gold.net_weight} */}
                      {change !== true ?
                        <><b>Net Weight(gm):&nbsp;</b>{bank.gold.net_weight}</>
                        : <label><b>Net Weight(gm):&nbsp;</b> <input style={{ width: '70%', borderRadius: '5px' }} type="number" name="new_amount" defaultValue={bank.gold.net_weight} placeholder="Net Weight" onBlur={(e) => handleChange("gold.net_weight", e.target.value)} onChange={(e) => handleChange("net_weight", e.target.value)} /></label>}
                    </div>
                  </div>
                  <div className="row justify-content-around">
                    <div>
                      <b>KFPL Gross Weight(gm):&nbsp;</b>
                      {loan.gross_weight}
                    </div>
                    <div>
                      <b>KFPL  Net Weight(gm):&nbsp;</b>
                      {loan.net_weight}
                    </div>

                  </div>
                  <div className="row justify-content-around">
                    <div>
                      <b>Difference Gross Weight(gm):&nbsp;</b>
                      {decimalRoundOff(
                        bank.gold.gross_weight - loan.gross_weight
                      )}
                    </div>
                    <div>
                      <b>Difference Net Weight(gm):&nbsp;</b>
                      {decimalRoundOff(bank.gold.net_weight - loan.net_weight)}
                    </div>
                  </div>
                  <br />
                  {/* {bank.approved === false ? (
                    <div>
                      <Button
                        disabled={user._id === bank.maker ? true : false}
                        onClick={() => approveBankDetails()}
                      >
                        Approve
                      </Button>
                      <p style={{ color: "red" }}>Approval Pending</p>
                    </div>
                  ) : (
                    <b style={{ color: "green" }}>Loan Approved</b>
                  )} */}
                </p>
              </div>
            ) : (
              <Formik
                // innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
                {({ errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="form-row">
                        <div className="form-group col">
                          <label>Bank Name</label>
                          <Field
                            name="bank"
                            as="select"
                            className={
                              "form-control" +
                              (errors.bank && touched.bank ? " is-invalid" : "")
                            }
                          >
                            <option value="Federal">Federal</option>
                            <option value="CSB">CSB</option>
                            <option value="SIB">SIB</option>
                          </Field>
                          <ErrorMessage
                            name="bank"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Bank Loan No."
                            name="loan_account"
                            type="text"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Sanction Amount(₹)"
                            name="sanction_amount"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="PF Charges(₹)"
                            name="pf"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="ROI (%)"
                            name="roi"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Disbursement Amount(₹)"
                            name="disburse_amount"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                      </div>
                      <br />
                      <b>Ornaments&nbsp;</b>
                      <br />
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">No of Items</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loan.details.gold && loan.details.gold.ornaments.map((row, idx) => (
                            <TableRow key={idx}>
                              <TableCell component="th" scope="row">
                                {row.desc}
                              </TableCell>
                              <TableCell align="right">
                                {row.quantity}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <br />
                      <br />
                      <div className="form-row">
                        <div className="form-group col">
                          <FormElement
                            label="Gross Weight(gm)"
                            name="gold.gross_weight"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                        <div className="form-group col">
                          <FormElement
                            label="Net Weight(gm)"
                            name="gold.net_weight"
                            type="number"
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                      </div>
                      <br />
                      <div className="form-row justify-content-center">
                        <div className="form-group col-md-4">
                          <FormElement
                            label="Disbursement Date"
                            name="disburse_date"
                            type="date"
                            onKeyDown={(e) => e.preventDefault()}
                            errors={errors}
                            touched={touched}
                          ></FormElement>
                        </div>
                      </div>
                      <hr />
                      <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </Fade>
      </Modal>
      {console.log(field)}
    </div>
  );
}
