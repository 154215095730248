import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
const EndUseDeclaration = (props) => {

  const data = props.data
    const branches = props.branches
  // const EndUseDeclarationRef = React.useRef();



  // const handleSubmissionPrint = useReactToPrint({
  //   content: () => EndUseDeclarationRef.current,
  //   pageStyle: "print",
  // });



  return (
    <>
      {/* <div style={{ textAlign: "center" }}>
        <button onClick={handleSubmissionPrint}>Print Letter</button>
      </div> */}

      <div
        style={{
          width: "290mm",
          height: "410mm",
          backgroundColor: "white",
          padding: 40,
          position: "relative",
          fontSize: "18px"
        }}
      >
        <span style={{ marginLeft: "40%" }}><b>End Use Declaration</b></span><br /><br /><br />
        <div style={{textAlign:"justify"}}>
        <span>To</span><br />
        <span>
                        Kaabil Finance Private Limited,<br />
                        Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, <br/>Jagatpura, Jaipur Rajasthan 302017, (“Lender 1”)
                    </span><br /><br />
          <span> Vistaar Financial Services Pvt Ltd <br />
                    Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout, <br />
                    2nd  Stage NA Bengaluru Bangalore KA 560076(“Lender 2”).</span><br /><br />

        <span>(Collectively “Lenders”)</span><br /><br />

        <span>Ref: Term loan of <b>Rs. {data && data.loan && data.loan.sanction_amount}/-</b> approved by the Lenders, vide the Sanction Letter dated  <b>{datehelper.displayDate(new Date(data.created))} </b> 
          (“Loan”)</span><br /><br />

        <span>Dear Sir / Madam,</span><br /><br />

        <span>With reference to our aforesaid Loan, I/we, the undersigned, hereby solemnly state, declare and undertake, as under:</span> <br/>
        <span>1) That the Loan shall be utilized only for the purpose of <b> Business Use </b>(“End Use”).</span><br />
        <span>2) That the amount of the Loan shall not be utilized for any illegal activities or speculative purposes or any
                 other purposes, other than the End Use.</span><br />
        <span>3) That any change in the End Use of the Loan amount, without prior written approval of the Lenders, will
                 constitute an event of default under the terms of the loan documents executed in relation to the Loan by
                 me/us and the Lenders shall be entitled to initiate any action against me/us in accordance with the
                 provisions of such loan documents and applicable laws.</span><br /><br />
        <span>Executed at&nbsp; {branches.branch_city}, on ___________ day of the _________________, 20_____ by;</span><br /><br />

        <div style={{display:"flex", justifyContent:"space-between"}}>
                    <span style={{width:"300px"}}>Name of the Borrower/s</span>
                    <span style={{width:"300px"}}>Signature/ Thumb Impression</span>
                </div><br/>
                <div style={{display:"flex", justifyContent:"space-between"}}>    
                    <span span style={{width:"300px"}}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                    <span span style={{width:"200px"}}>________________________</span><br /><br/>
                 </div>   
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{display:"flex", justifyContent:"space-between"}}>
                            <span span style={{width:"300px"}}>{e.general_information.name}</span><br />
                            <span span style={{width:"200px"}}>________________________</span><br /><br/>
                        </div>
                    })}
      </div>
      </div>
    </>
  )
}

export default EndUseDeclaration