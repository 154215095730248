import React from "react";
import { Delete } from "@material-ui/icons";
import { employeeService } from "../../../../_services";
import roleChecker from "../../../../_helpers/rolechecker";


export default function DocumentListInputSheetItem({
  item,
  idx,
  handleChange,
  handleRemoveSpecificRow,
}) {
  const {
    document_type,
    original_type,
    document_url,
    is_lod,
  } = item;

  const user = employeeService.userValue;

  return (
    <tr id="addr0" key={idx}>
      <td style={{ textAlign: "center" }}>{idx}</td>
      <td>
        {/* <input
          type="text"
          name="document_type"
          value={document_type}
          onChange={handleChange(idx)}
          className="form-control"
        /> */}
        <select
  name="document_type"
  value={document_type}
  onChange={handleChange(idx)}
  className="form-control"
>
<option value="" >{document_type}</option>
  <option value="Approval">Approval</option>
  {/* <option value="Valuation Report">Valuation Report</option>
  <option value="Legal Report">Legal Report</option>
  <option value="RCU Report">RCU Report</option> */}
  <option value="PDC">PDC</option>
  <option value="Udyam Aadhar">Udyam Aadhar</option>
  <option value="Property Paper">Property Paper</option>
  <option value="PD Sheet">PD Sheet</option>
  <option value="ROI/Otherwaiver Approval">ROI/Otherwaiver Approval</option>
  <option value="Registered Mortgage Deed">Registered Mortgage Deed</option>
  <option value="Bank Statement">Bank Statement</option>
  <option value="Property Paper with PDC">Property Paper with PDC</option>
  <option value="Pendency">Pendency</option>
  {/* <option value="Other">Other</option> */}
</select>

      </td>
      <td>
        <input
          type="text"
          name="original_type"
          value={original_type}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        {document_url ? <div style={{ display: "flex" }}><a href={document_url} target="blank"> View File </a>
        {/* <label htmlFor="filePicker" style={{width:"60px", marginLeft:"30px"}}>ReUpload</label> */}
          {!roleChecker.isCreditTeamAccess(user.role) && <input
            type="file"
            name="document_url"
            accept=".pdf"
            // id="filePicker"
            //  style={{ visibility: "hidden", width:"5px" }}
            className="form-control"
            onChange={handleChange(idx)}
          />}

          {/* <input id="filePicker" style={{ visibility: "hidden" }} type={"file"}></input> */}
        </div> :
          <input
            type="file"
            name="document_url"
            accept=".pdf"
            // value={document_url}
            className="form-control"
            onChange={handleChange(idx)}
          />
        }
      </td>
      <td>
        <select
         name="is_lod"
          value={is_lod}
          onChange={handleChange(idx)}
          style={{ width: "50px" }}
        >
          <option value={false} selected>
           No
          </option>
          <option value={true} >
           Yes
          </option>
          
        </select>
      </td>
      <td>
        {roleChecker.isGMLevelAccess(user.role) && (<a className="btn btn-sm" onClick={handleRemoveSpecificRow(idx)}>
          <Delete />
        </a>)}

      </td>
    </tr >
  );
}
