import React,{useState,useEffect} from 'react'
import { LoanStages } from '../../../../constants/loanstages'
import { employeeService } from '../../../../_services';
import { cibil } from '../../../../_services/cibil.service';
import datehelper from '../../../../_helpers/datehelper';
import roleChecker from '../../../../_helpers/rolechecker';
import urlhelper from '../../../../_helpers/urlhelper';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../../../css/common.css"
import Cibil from '../../Cibil';
// import HighMark from '../../HighMark'
import Upload from '../../Upload';
import { TabContext } from '../TabBar';
import { useContext } from "react";


const CibilData = () => {

    let TabContextValue = useContext(TabContext)
    let cust = TabContextValue.cust
    const [employee,setEmployees]=useState({})
    const [data, setData] = React.useState({})

    const user = employeeService.userValue;
    const getEmployees = () => {
        employeeService.getAll()
            .then(res => {
                let list = {}
                if (res.length > 0) {
                    res.map(emp => {
                        list[`${emp.emp_id}`] = emp.first_name + ' ' + emp.middle_name + ' ' + emp.last_name
                    })
                }
                setEmployees(list)
            })
    }
    useEffect(() => {
         getEmployees()
    }, [])

    const getData = () => {
        cibil.cibilRequests({ application_id: cust.application_id })
          .then((x) => {
            console.log(x,"gggggggggggg")
            setData(x)
          })
          .catch((err) => {
            console.log(err, "err here")
          })
      }
    
      React.useEffect(() => {
        getData();
      }, [])

    return (
        <div>
            <h2>Cibil Request List</h2>
            {![LoanStages.Closed, LoanStages.Rejected, LoanStages.Discarded].includes(cust.stage) && (
               <div>

                <Cibil id={cust._id} isEdit={false} />
                {/* <HighMark id={cust._id} isEdit={false} /> */}
               </div>
            )}
            <div>
                {data && data.length > 0 && data[0].cibil && data[0].cibil.length > 0 ?
                    <div className='table-border'>
                        <Table>
                            <Thead style={{ borderBottom: "2px solid black" }}>
                                <Tr>
                                    <Th className='table-row-th' style={{ width: "10%" }}>Date</Th>
                                    <Th className='table-row-th' style={{ width: "10%" }}>Name</Th>
                                    <Th className='table-row-th' style={{ width: "10%" }}>Role</Th>
                                    <Th className='table-row-th' style={{ width: "10%" }}>AADHAR</Th>
                                    <Th className='table-row-th' style={{ width: "10%" }}>Other ID</Th>
                                    <Th className='table-row-th' style={{ width: "10%" }}>Other Id Number</Th>
                                    {/* <Th className='table-row-th' style={{ width: "10%" }}>Branch ID</Th> */}
                                    <Th className='table-row-th' style={{ width: "10%" }}>Requested _by</Th>
                                    <Th className='table-row-th' style={{ width: "10%" }}>Cibil Score</Th>
                                    <Th className='table-row-th' style={{ width: "10%" }}>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data && data.length > 0 && data[0].cibil && data[0].cibil.map((e) => {
                                    console.log("eeeeee",e)
                                    return <Tr key={e._id}>
                                        <Td className='table-row-td'>{datehelper.displayDate(new Date(e.date))}</Td>
                                        <Td className='table-row-td'> {e.name || `${e.first_name} ${e.middle_name || ''} ${e.last_name || ''}`}</Td>
                                        <Td className='table-row-td'>{e.role}</Td>
                                        <Td className='table-row-td'>{e.aadhar}</Td>
                                        <Td className='table-row-td'>{e.verification_id_type}</Td>
                                        <Td className='table-row-td'>{e.pan || e.verification_id}</Td>
                                        {/* <Td className='table-row-td'>{data[0].branch_id}</Td> */}
                                        {/* <Td className='table-row-td'>{data[0].application_id}</Td> */}
                                        <Td className='table-row-td'>{employee[e.requested_by]}</Td>
                                        <Td className='table-row-td'>{e ? (e.tu_cibil_score ? e.tu_cibil_score : (e.crif_cibil_score ? e.crif_cibil_score : 'N/A')) : 'N/A'}</Td>
                                        <Td className='table-row-td'>
                                            {e.report && e.report != "" ? (
                                                <> <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(e.report) + "&download=1"} target='_blank' >Cibil Report (TU)</a> <Upload aadhar={e.aadhar} id={e._id} />  </>
                                            ) :( e.crif_report_link && e.crif_report_link != "" ? (
                                                <> <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(e.crif_report_link) + "&download=1"} target='_blank' >Cibil Report (CRIF)</a> <Upload aadhar={e.aadhar} id={e._id} />   </>
                                            )  : (roleChecker.isBMLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role) || roleChecker.isHOLevelAccess(user.role) || roleChecker.isColendingAccess(user.role)) &&
                                            (<> <Upload aadhar={e.aadhar} id={e._id} />
                                               { !(e.crif_report_link || e.report) && <Cibil id={cust._id} isEdit={true} request_id={e._id} customer_id={e.customer_id} />}
                                            </>))}
                                            { }
                                        </Td>
                                    </Tr>
                                })}
                            </Tbody>
                        </Table>
                    </div> : <h3 style={{ color: "red", textAlign:"center" }}> No CIBIL Requests made.</h3>
                }
            </div><br /><br />
        </div>
    )
}

export default CibilData