import React, { useState,useEffect } from 'react'
 import { employeeService } from '../../../../../_services';
import { Button } from '@material-ui/core';
 import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loanService } from '../../../../../_services';
import { uploadFileToBucket } from '../../../../../_helpers/upload';
 import { Edit } from "@material-ui/icons";
import { Modal, Box, Typography } from "@material-ui/core";
import roleChecker from '../../../../../_helpers/rolechecker';

const LegalReport = (props) => {
  const [providerList,setProviderList]=useState([])
const [open,setOpen]=useState(false)
 console.log('props is ',props)
  let cust = props.cust;
  let objId = props.objId
  const legal_data = props.val
     const user = employeeService.userValue;
    const bucket = process.env.REACT_APP_s3docsBucket
     const validationSchema = yup.object({
  provider: yup.string().required('Provider is required'),
  legal_date: yup.date().required('Legal date is required'),
   legal_charges: yup.number().required('Legal charges is required'),
  legal_report_doc: yup.string().required('Legal report PDF is required'),
  vetting_legal_report: yup.string().required('vetting  report PDF is required'),

  property_type: yup.string().required('property type is required'),
  
});
 
  const initialValues = {
    provider: '',
    legal_date: '',
     legal_charges:'',
    legal_report_doc: '',
    vetting_legal_report:'',
    property_type:'',
    type_of_property:'',
    title_document:'',
    document_type:'',
    remark:''
   }

 

 if(legal_data){

   const date = legal_data?.legal_report?.legal_date
   const formattedDate = date ? (new Date(date).toISOString().split('T')[0]) : '' ;
   initialValues.provider = legal_data?.legal_report?.provider || '';
   initialValues.legal_date = formattedDate;
    initialValues.legal_charges = legal_data?.legal_report?.legal_charges || '';
   initialValues.legal_report_doc = legal_data?.legal_report?.legal_report_doc || '';
   initialValues.vetting_legal_report = legal_data?.legal_report?.vetting_legal_report || '';

   initialValues.property_type = legal_data?.legal_report?.property_type || '';
   initialValues.type_of_property = legal_data?.legal_report?.type_of_property || '';
   initialValues.title_document = legal_data?.legal_report?.title_document || '';
   initialValues.document_type = legal_data?.legal_report?.document_type || '';
   initialValues.remark = legal_data?.legal_report?.remark || '';
 }
    
    const onSubmit = async (values) => {
      console.log("Submitting form values:", values);
      try {
       let res= await loanService.valuationReport(objId,{ values,"type":"Legal"});
       window.alert('data added successfully',res)
        console.log("Submission successful");
        window.location.reload()
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };
    
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 800,
      height: 600,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflow: 'scroll',
  };

 
    const propertyTypes = [
      'Commercial Properties',
      'Industrial Properties',
      'Mix Used',
      'Vacant',
      'Residential Properties',
      'Shop',
      'Open Plot',
      'Rented Residencial Properties',
      'Rented Commercial Properties'

    ];
  const handleLegalReport = async (e, formik,setFieldValue,values) => {
    let file = e.target.files[0];
    let name = file.name.split('.')[0];
    let num1 = Math.floor(Math.random() * 1000);
    let num2 = Math.floor(Math.random() * 100);
    let fileName = `${name}_${num1}${num2}.pdf`
   await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/loan/legal_report/${cust._id}/${fileName}`, file)
    .then(link => {
      console.log('link',link)
      setFieldValue('legal_report_doc', link);
       console.log(values)
    })
    .catch(error => {
      console.error(error);
    });
}
const handleWaitingLegalReport = async (e, formik,setFieldValue,values) => {
  let file = e.target.files[0];
  let name = file.name.split('.')[0];
  let num1 = Math.floor(Math.random() * 1000);
  let num2 = Math.floor(Math.random() * 100);
  let fileName = `${name}_${num1}${num2}.pdf`
 await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/loan/legal_report/${cust._id}/${fileName}`, file)
  .then(link => {
    console.log('link',link)
    setFieldValue('vetting_legal_report', link);
     console.log(values)
  })
  .catch(error => {
    console.error(error);
  });
}
 const handleClose=()=>{
  setOpen(false)
}

 
  return (
    <div>
     
            <Button onClick={()=>{setOpen(true);console.log('open')}}><Edit/></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
  <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue,formik }) => (
         
        <Form>
        <div  style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',flexWrap:'wrap',gap:'1rem'}}>

          <div className="form-group col-md-5">
            <h6 className="required">Provider Name</h6>
            <Field
              label="provider"
              type="text"
              name="provider"
              className={
                'form-control' +
                (errors.provider && touched.provider ? ' is-invalid' : '')
              }
              disabled
            />
           
           
            
            <ErrorMessage
              name="provider"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Legal Date</h6>
            <Field
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              name="legal_date"
              className={
                'form-control' +
                (errors && errors.legal_date && touched && touched.legal_date
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="legal_date"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Type of property</h6>
            <Field
              type="text"
              as = "select"
              name="type_of_property"
              className={
                'form-control' +
                (errors.type_of_property && touched.type_of_property
                  ? ' is-invalid'
                  : '')
              }
            > 
            <option value='' disabled selected>Select</option>
            <option value='free hold'  >Free Hold</option>
            <option value='lease hold'  >Lease Hold</option>
 
            </Field>
            <ErrorMessage
              name="type_of_property"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group col-md-5">
            <h6 className="required">Title Document</h6>
            <Field
              type="text"
              as = "select"
              name="title_document"
              className={
                'form-control' +
                (errors.title_document && touched.title_document
                  ? ' is-invalid'
                  : '')
              }
            > 
            <option value='' disabled selected>Select</option>
            <option value='registered'  >Registered</option>
            <option value='unregistered'  >Un-Registered</option>
            <option value='poa'  >POA</option>

            </Field>
            <ErrorMessage
              name="title_document"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group col-md-5">
            <h6 className="required">Document Type</h6>
            <Field
              type="text"
              as = "select"
              name="document_type"
              className={
                'form-control' +
                (errors.document_type && touched.document_type
                  ? ' is-invalid'
                  : '')
              }
            > 
            <option value='' disabled selected>Select</option>
            <option value='agreement'  >Agreement</option>
            <option value='cell deed'  >Cell Deed</option>
            <option value='gift deed'  >Gift Deed</option>
            <option value='will registered'  >Will Registered</option>
            <option value='will unregistered'  >Will Un-Registered</option>
            <option value='leese deed'  >Lease Deed</option>

            </Field>
            <ErrorMessage
              name="document_type"
              component="div"
              className="invalid-feedback"
            />
          </div>
         
          {/* <div className="form-group col-md-5">
            <h6 className="required">Legal Charges</h6>
            <Field
              type="number"
              name="legal_charges"
              disabled
              className={
                'form-control' +
                (errors.legal_charges && touched.legal_charges
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="legal_charges"
              component="div"
              className="invalid-feedback"
            />
          </div> */}
          <div className="form-group col-md-5">
  <h6 className="required">Property Type</h6>
  <Field
    as="select"
    name="property_type"
    className={
      'form-control' +
      (errors.property_type && touched.property_type
        ? ' is-invalid'
        : '')
    }
  >
    <option value="">Select Property Type</option>
    {/* Map the list of property types here */}
    {propertyTypes.map((type, idx) => (
      <option value={type} key={idx}>
        {type}
      </option>
    ))}
  </Field>
  <ErrorMessage
    name="property_type"
    component="div"
    className="invalid-feedback"
  />
</div>

<div className="form-group col-md-5">
            <h6 className="required">Remark</h6>
            <Field
              type="text"
              name="remark"
               
              className={
                'form-control' +
                (errors.remark && touched.remark
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="remark"
              component="div"
              className="invalid-feedback"
            />
          </div>
         {roleChecker.isGMLevelAccess(user.role) && <div className="form-group col-md-5">
            <h6 className="required">Legal Report PDF</h6>
            <input
              type="file"
              name="legal_report_doc"
              accept=".pdf"
              onChange={(e) => handleLegalReport(e, formik,setFieldValue,values)}
              className={
                'form-control-file' +
                (errors && errors.legal_report_doc && touched && touched.legal_report_doc
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="legal_report"
              component="div"
              className="invalid-feedback"
            />
          </div>}

          {roleChecker.isGMLevelAccess(user.role) && <div className="form-group col-md-5">
            <h6 className="required">Legal Vetting Report PDF</h6>
            <input
              type="file"
              name="vetting_legal_report"
              accept=".pdf"
              onChange={(e) => handleWaitingLegalReport(e, formik,setFieldValue,values)}
              className={
                'form-control-file' +
                (errors && errors.vetting_legal_report && touched && touched.vetting_legal_report
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="vetting_legal_report"
              component="div"
              className="invalid-feedback"
            />
          </div>}
          <div className="form-group col-md-5" style={{display:'flex',justifyContent:'center'}}>

          <Button type='submit'  color='primary' variant='contained' disabled={isSubmitting}>Submit</Button>
          </div> 
        </div>
        </Form>
       
      )}
    </Formik>
    </Typography>
                </Box>
            </Modal>
  </div>
  )
}

export default LegalReport