import React from 'react'
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { loanService } from '../../_services';
import datehelper from "../../_helpers/datehelper"
import { Field } from 'formik';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: "40%",
        overflow: "scroll",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {
        minWidth: 650,
    },
}));



const ColendingForClosureModel = (props) => {

    const [open, setOpen] = useState(false);
    const [emiList, setEmiList] = React.useState([]);
    const [payableAmount, setPayableAmount] = React.useState(null)
    const [lastRemainingAmount, setLastRemainingAmount] = React.useState(null)
    const [daysDifference, setDaysDifference] = React.useState(null)
    const [interestDue, setInterestDue] = React.useState(null) 
    const [emiAmount, setEmiAmount] = React.useState(null)
    const [emiPaid, setEmiPaid] = React.useState(null)

    const classes = useStyles();
    const data = props.application;


    const handleOpen = () => {
        setOpen(true);
        fetchData()
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        if (open) {
            calc()
        }
    }, [emiList])



    const fetchData = async () => {
        loanService.getUpdateColenderRepaymentSchedule(data._id, data.co_lender_data.co_lender_name).
            then((el) => {

                console.log(el, "ellll");
                if (el && el.repayment_schedule, "here result") {
                    console.log(el.repayment_schedule)
                    setEmiList(el.repayment_schedule);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const minDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
      }

    const calc = (date) => {
        // console.log("entering")
        let due_date;
        let diff;
        let today_date
        if(date){
            today_date = new Date(date)
        }else{
            today_date = new Date()
        }
        today_date.setHours(0, 0, 0, 0)
        if (emiList) {
            // console.log("enter in 2")
            emiList.map((x, idx) => {
                due_date = new Date(x.due_date)
                let temp = new Date(today_date.getTime() - due_date.getTime())
                 temp =Math.ceil(temp / (1000 * 3600 * 24))
            if (temp <= 31 && temp >= 0) {
                if(diff == undefined){
                    diff={...x, diff:temp, idx:idx}
                }else{
                    if(Number(temp) < Number(diff.diff)){
                        diff={...x, diff:temp, idx:idx}
                    }
                }
            }
            })
            setDaysDifference(diff.diff)
            console.log(diff)
            setLastRemainingAmount(diff.balance_remaining)
            setEmiAmount(diff.payment_amount)
            setEmiPaid(Number(diff.idx) + 1)
                let tempBalance = Math.ceil((diff.balance_remaining * diff.diff * data.co_lender_data.co_lender_interest_rate) / 36000)
                setInterestDue(tempBalance)
                setPayableAmount(Number(diff.balance_remaining) + tempBalance)
        }
    }




    return (
        <div>
            <Button onClick={handleOpen} style={{ color: "blue" }}>
                ForClosure
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className={classes.paper}>
                            <h5 style={{ marginTop: "20px" }}>ForClosure Calculation</h5>
                            <h6>Loan No:- {data.application_id}</h6>

                            <div style={{marginTop:"20px"}}>
                                <label style={{ width: "120px" }}>Select Date
                                   
                                </label><input
                                        name="disbursement_date"
                                        type="date"
                                        onKeyDown={(e) => e.preventDefault()}
                                        defaultValue={minDate()}
                                        min={minDate()}
                                        onChange={(e) => calc(e.target.value)}
                                    />  
                            </div>

                            {data && data.co_lender_data && (
                                <table style={{ border: "2px solid", marginTop: "30px", fontSize:"18px" }}>
                                    <tr style={{ border: "2px solid" }}>
                                        <th style={{ border: "2px solid", textAlign: "left" }}>CoLender Name</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>{data.co_lender_data.co_lender_name}</td>
                                    </tr>

                                    <tr style={{ border: "2px solid" }}>
                                        <th style={{ border: "2px solid", textAlign: "left" }}>CoLender Sanction Amount</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>₹{data.co_lender_data.co_lender_sanction_amount}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid" }}>
                                        <th style={{ border: "2px solid", textAlign: "left" }}>CoLender Received Amount</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>₹{data.co_lender_data.co_lender_received_amount}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid" }}>
                                        <th style={{ border: "2px solid", textAlign: "left" }}>CoLender Interest Rate(%)</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>{data.co_lender_data.co_lender_interest_rate}%</td>
                                    </tr>
                                    <tr style={{ border: "2px solid" }}>
                                        <th style={{ border: "2px solid", textAlign: "left" }}>CoLender Received Date</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>{datehelper.displayDate(new Date(data.co_lender_data.co_lender_received_date))}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid" }}>    
                                        <th style={{ border: "2px solid", textAlign: "left" }}>EMI Every Month</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>₹{emiAmount}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid" }}>    
                                        <th style={{ border: "2px solid", textAlign: "left" }}>No.of EMI Paid</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>{emiPaid}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid" }}>    
                                        <th style={{ border: "2px solid", textAlign: "left" }}>Principal Outstanding Amount</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>₹{lastRemainingAmount}</td>
                                    </tr>

                                    <tr style={{ border: "2px solid" }}>
                                        <th style={{ border: "2px solid", textAlign: "left" }}>Difference between Last Paid Date</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>{daysDifference} days</td>
                                    </tr>

                                    <tr style={{ border: "2px solid" }}>    
                                        <th style={{ border: "2px solid", textAlign: "left" }}>Interest Due</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>₹{interestDue}</td>
                                    </tr>

                                    <tr style={{ border: "2px solid" }}>
                                        <th style={{ border: "2px solid", textAlign: "left" }}>Remaining balance Till Date</th>
                                        <td style={{ border: "2px solid", textAlign: "right" }}>₹{payableAmount}</td>
                                    </tr>
                                </table>
                            )}
                            <span style={{color:"red", fontSize:"25px", textAlign:"center", marginTop:"15px"}}> Remaining ForClosure Amount :- ₹{payableAmount}</span>
                        </div>
                    </Typography>
                </Fade>
            </Modal>
        </div>
    )
}

export default ColendingForClosureModel