import React, { useState, useEffect }from 'react'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';
import { FormFields } from './ApplicantFormSteps';
import * as Yup from "yup";
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from 'react-router-dom';
import {
    makeStyles,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { TabContext } from '../TabBar'
import { useContext } from 'react';
import { Delete } from "@material-ui/icons";
import { TabSteps } from './ApplicantFormSteps';
import { sanction } from '../../../../_services/sanctionletter.service';
import { customerService, employeeService, loanService} from '../../../../_services';
import "../../../../css/common.css"
import { OtpVerificationModal } from './OtpVerificationModal';
import { history } from '../../../../_helpers';
import { LoanStages } from '../../../../constants/loanstages';
import roleChecker from '../../../../_helpers/rolechecker';
import { adminservice } from '../../../../_services/admin.services';
import { Paper } from "@material-ui/core";
import BankStatementData from './BankStatementData';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

 


export const ApplicantForm = (props) => {
    console.log(props.applicant.customer_id,"propspropsprops")
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [edit, setEdit] = useState(true)
    const [applicant, setApplicant] = useState({})
    const [checked, setChecked] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [promotApplicationId,setPromoteApplicationId]=useState();
    const [promoteApplicantCustomerId,setPromoteApplicantCustomerId]=useState();
    const [promoteCoApplicnatCustomerId,setPromoteCoApplicnatCustomerId]=useState()
const [negativePinCode,SetNegativePinCode] = useState([]);
    const TabContextValue = useContext(TabContext)
    const cust = TabContextValue.cust;
    const user = employeeService.userValue
//    const [errorsInFrom,setErrorsInForm]=useState();
    const [show, setShow] = useState(false)
    const [isPinCode, setIsPinCode] = useState(false)
    const [employeeList,setEmployeeList] =React.useState([]);
const [isConsentResponseExist,setIsConsentResponseExist] = useState()
const [bankStatement,setBankStatement] = useState()
    const cust_id = props?.applicant?.customer_id
    console.log(props,cust_id,"uetwrtyu")
    console.log(props.applicant._id,"uu")
console.log("cussss is",cust)


// Assuming aadharNumber is a 12-digit string
const maskAadharNumber = (aadhaar_mask_no) => {
    const lastFourDigits = aadhaar_mask_no.slice(-4);
    return '************' + lastFourDigits;
};


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let initialValues = {
        general_details: {
            customer_title: "",
            customer_firstname: "",
            customer_lastname: "",
            customer_dob: "",
            fathername: "",
            gender: "",
            customer_mobile_num: "",
            alternate_mobile_num: "",
            email: "",
        },
        personal_details: {
            religion: "",
            category: "",
            kyc_relation_type:"",
            kyc_relation:"",
            name_of_spouse:"",
            educational_qualification: "",
            marital_status: "",
            smoker:"",
            ispinCodeVerified:false,
            pincodecomment:"",
            correspondence_address: {
                address_type: "",
                address_line_1: "",
                landmark: "",
                tehsil: "",
                district: "",
                pin_code: "",
                no_of_years: "",
                city: "",
                state: "",
            },
            permanent_address: {
                address_type: "",
                address_type: "",
                address_line_1: "",
                landmark: "",
                tehsil: "",
                district: "",
                pin_code: "",
                no_of_years: "",
                city: "",
                state: "",
            },
        },
        economic_details: {
            annual_income: "",
            occupation_type: "",
            designation: "",
            nature_of_business_or_employment: "",
            other_business:"",
            business_or_employment_address: "",
            business_or_employment_pincode: "",
            business_or_employment_city: "",
            business_or_employment_state: "",
            no_of_years: "",
            bank_details: {
                account_holder: "",
                ifsc_code: "",
                account_number: "",
                bank_name: "",
            }
        },

        property_details: {
            property_type: "",
            count_of_property: "",
            property_market_val: "",
            property_age: "",
            land_size_bigha: "",
        },

        family_data: {
            sons: "",
            daughters: "",
        },

        verification_details: {
            karza_aadhar_verified:false,
            karza_aadhar_verified_date: new Date(),
            aadhaar_no:"",
            pan_no:"",
            voter_id:"",
        }
    }
    {
        applicant && applicant.kyc_images ?
            initialValues = { ...applicant } :
            initialValues = { ...applicant, kyc_images: [] }
    }

    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }

    let current_date = new Date()
    let minAge = new Date(
        current_date.getFullYear() - 18,
        current_date.getMonth(),
        current_date.getDate(),
        current_date.getHours(),
        current_date.getMinutes()
    )
    let maxAge = new Date(
        current_date.getFullYear() - 100,
        current_date.getMonth(),
        current_date.getDate(),
        current_date.getHours(),
        current_date.getMinutes()
    )


    async function getApplicant() {
        // console.log({customer_id:props.applicant.customer_id},"hhh")
        await customerService.searchCustomer({ customer_id: props.applicant.customer_id })
            .then((res) => {
                console.log('resssssssssssss is',res[0])
                setApplicant(res[0])
                initialValues = { ...res[0]}
                // res[0].kyc_images && res[0].kyc_images.map((x)=>{
                //     if(x.ref_id === "customer_photo"){
                //         props.setImage(x.uri)
                //     }else{
                    
                //         props.setImage('')
                //     }
                // })
            })
            .catch(err => console.log(err,"iiiii"))
    }

    async function getConsentResponse(params){
        try{
       let responseData = await customerService.getConsentResponse(params)
       console.log('responseData',responseData)
       setIsConsentResponseExist(responseData)
        }
        catch(err){
          console.log('responseData err',err)
        }
    }
    async function fetchAllBankStatements(params){
        try{
       let responseData = await customerService.fetchAllBankStatements(params)
       console.log('fetch All Bank Statements',responseData)
       setBankStatement(responseData)
        }
        catch(err){
          console.log('fetch All Bank Statements err',err)
        }
    }

    useEffect(() => {
        getApplicant()
    }, [props.applicant])

    useEffect(() => {
        if(cust_id){

            getConsentResponse({customer_id:cust_id})
            fetchAllBankStatements({customer_id:cust_id})

        }
    }, [cust_id])
    const validationSchema = Yup.object().shape({
        general_details: Yup.object().shape({
            customer_title: Yup.string().required('This field is required'),
            customer_firstname: Yup.string().required('This field is required'),
            customer_lastname: Yup.string().required('This field is required'),
            customer_dob: Yup.date().max(dateFormat(minAge), "Age must be greater than or equal to 18 years old").min(dateFormat(maxAge), "Age can not be grater than 100 years").required("This is Required"),
            fathername: Yup.string().required('This field is required'),
            gender: Yup.string().required('This field is required'),
            customer_mobile_num: Yup.string().length(10).matches(/^[0-9]+$/, "Must be only digits").required('This field is required'),
            alternate_mobile_num: Yup.string().length(10).matches(/^[0-9]+$/, "Must be only digits"),
            email: Yup.string().email('Invalid email'),
        }),
        personal_details: Yup.object().shape({
            religion: Yup.string().required('This field is required'),
            category: Yup.string().required('This field is required'),
            kyc_relation_type: Yup.string().required('This field is required'),
            kyc_relation: Yup.string().required('This field is required'),
            // name_of_spouse:Yup.string().required('This field is required'),
            educational_qualification: Yup.string().required('This field is required'),
            marital_status: Yup.string().required('This field is required'),
            marital_status: Yup.string().required('This field is required'),
            correspondence_address: Yup.object().shape({
                address_type: Yup.string().required('This field is required'),
                address_line_1: Yup.string().required('This field is required'),
                landmark: Yup.string().required('This Field is required'),
                tehsil: Yup.string().required('This field is required'),
                district: Yup.string().required("this Field is required"),
                pin_code: Yup.string().length(6).required('This field is required').matches(/^[0-9]+$/, "Must be only digits"),
                no_of_years: Yup.number().required('This field is required'),
                city: Yup.string().required('This field is required'),
                state: Yup.string().required('This field is required'),
            }),
            permanent_address: Yup.object().shape({
                address_type: Yup.string().required('This field is required'),
                address_line_1: Yup.string().required('This field is required'),
                landmark: Yup.string().required('This Field is required'),
                tehsil: Yup.string().required('This field is required'),
                district: Yup.string().required("this Field is required"),
                pin_code: Yup.string().length(6).required('This field is required').matches(/^[0-9]+$/, "Must be only digits"),
                no_of_years: Yup.number().required('This field is required'),
                city: Yup.string().required('This field is required'),
                state: Yup.string().required('This field is required'),
            }),
        }),
        economic_details: Yup.object().shape({
            // annual_income: Yup.number(),
            // occupation_type: Yup.string(),
            // designation: Yup.string(),
            // nature_of_business_or_employment: Yup.string(),
            // business_or_employment_address: Yup.string(),
            // business_or_employment_pincode: Yup.number(),
            // business_or_employment_city: Yup.string(),
            // business_or_employment_state: Yup.string(),
            // no_of_years: Yup.number(),
            // bank_details: Yup.object().shape({
            //     account_holder: Yup.string(),
            //     ifsc_code: Yup.string(),
            //     account_number: Yup.number(),
            //     bank_name: Yup.string(),
            // })
        }),

        property_details: Yup.object().shape({
            // property_type: Yup.string(),
            // count_of_property: Yup.string(),
            // property_market_val: Yup.string(),
            // property_age: Yup.string(),
            // land_size_bigha: Yup.number(),
        }),

        family_data: Yup.object().shape({
            // sons: Yup.number(),
            // daughters: Yup.number(),
        }),
        verification_details: Yup.object().shape({
            // aadhaar_mask_no: Yup.string().matches(/^[0-9]{12}$/, "Valid aadhar number").required("aadhar required"),
            pan_no: Yup.string().matches(/^[a-zA-Z0-9]{10}$/, "Valid PAN number"),
        }),
    })

    const handleRelation = (values, setFieldValue, value) => {
        if (value === "S/o" || value === "D/o") {
            console.log(values.general_details.fathername, "uuu")
            setFieldValue('personal_details.kyc_relation_type', value)
            setFieldValue('personal_details.kyc_relation', values.general_details.fathername)
        } else if (value === "W/o") {
            setFieldValue('personal_details.kyc_relation_type', value)
            setFieldValue('personal_details.kyc_relation', values.personal_details.name_of_spouse)
        }
        else {
            setFieldValue('personal_details.kyc_relation_type', value)
            setFieldValue('personal_details.kyc_relation', "")
        }

    }

    const handleGenderAccToType = (values, setFieldValue, value) => {
        console.log("value",value)
        if (value === "Mr") {
            setFieldValue('general_details.customer_title', value)
            setFieldValue('general_details.gender',"Male")
        } else if (value === "Mrs" || value==="Ms") {
            setFieldValue('general_details.customer_title', value)
            setFieldValue('general_details.gender',"Female")
        }
        else {
            setFieldValue('general_details.customer_title', value)
            setFieldValue('general_details.gender', "")
        }

    }

    // const checkSpouse = (values, setFieldValue, value) => {
    //     console.log(value)
    //     if (values.general_details.gender === 'Female' && value === "married") {
    //         console.log(true)
    //         setFieldValue('personal_details.marital_status', value)
    //         setShow(true)
    //     } else {
    //         setFieldValue('personal_details.marital_status', value)
    //         setShow(false)
    //     }
    // }


    const onSubmit = (values, setFieldValue) => {
        const errors = validate(values);
        console.log('valuesvaluesvaluesvalues',values)
        if (Object.keys(errors).length === 0) {
            // No validation errors, proceed with form submission
            // console.log('Form submitted successfully:', values);
            console.log("enter", values)
            if(applicant.general_details.customer_mobile_num!=values.general_details.customer_mobile_num){
                values.general_details.mobile_no_verified=false;
                console.log("numbers are not equal")
                customerService.numberNotMatches(applicant._id)
                .then(res=>{
                    console.log("number updated so",res);
                })
                .catch(err=>{
                    console.log("error is",err)
                })
            }
            customerService.updateCustomerInLoan({...values, id:applicant._id, application_id:cust.application_id})
                .then(res => {
                    console.log(res, "res here")
                    window.alert('Customer updated successfully')
                    window.location.reload();
                })
                .catch(err => {
                    window.alert('Some error occurred')
                    console.log(err)
                })
          } else {
            // Display error messages or handle invalid fields
            window.alert(errors)
            // setErrorsInForm(errors);
            console.log('Form validation errors:', errors);
          }



      

    }

    const deleteCustomer = (values) => {
        customerService.deleteCustomer({application_id:cust.application_id, id:props.applicant._id})
        .then((data) => {
            console.log(data)
            alert("Deleted Successfully")
            history.push(`/home/sblloans/details/${cust._id}?isnew_view=1`)
            window.location.reload()
        })
        .catch((err)=>{
            console.log(err)
        })
    }




    const fillPermanentAddress = (values, setFieldValue, event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            setFieldValue('personal_details.permanent_address.address_type', values.personal_details.correspondence_address.address_type)
            setFieldValue('personal_details.permanent_address.address_line_1', values.personal_details.correspondence_address.address_line_1)
            setFieldValue('personal_details.permanent_address.landmark', values.personal_details.correspondence_address.landmark)
            setFieldValue('personal_details.permanent_address.tehsil', values.personal_details.correspondence_address.tehsil)
            setFieldValue('personal_details.permanent_address.district', values.personal_details.correspondence_address.district)
            setFieldValue('personal_details.permanent_address.pin_code', values.personal_details.correspondence_address.pin_code)
            setFieldValue('personal_details.permanent_address.no_of_years', values.personal_details.correspondence_address.no_of_years)
            setFieldValue('personal_details.permanent_address.city', values.personal_details.correspondence_address.city)
            setFieldValue('personal_details.permanent_address.state', values.personal_details.correspondence_address.state)
        } else {
            setFieldValue('personal_details.permanent_address.address_type', "")
            setFieldValue('personal_details.permanent_address.address_line_1', "")
            setFieldValue('personal_details.permanent_address.landmark', "")
            setFieldValue('personal_details.permanent_address.tehsil', "")
            setFieldValue('personal_details.permanent_address.district', "")
            setFieldValue('personal_details.permanent_address.pin_code', "")
            setFieldValue('personal_details.permanent_address.no_of_years', "")
            setFieldValue('personal_details.permanent_address.city', "")
            setFieldValue('personal_details.permanent_address.state', "")
        }
    }
    const validate = (values) => {
        let errors = '';
      
        // General Details
        if (!values.general_details.customer_title) {
          errors += 'Please select a title\n';
        }
      
        if (!values.general_details.customer_firstname) {
          errors += 'Please enter the first name\n';
        }
      
        if (!values.general_details.customer_lastname) {
          errors += 'Please enter the last name\n';
        }
      
        if (!values.general_details.customer_dob) {
          errors += 'Please enter the date of birth\n';
        } else {
          const dateFormat = (date) => new Date(date).getTime();
          const maxAge = new Date();
          maxAge.setFullYear(maxAge.getFullYear() - 100); // Set maximum age to 100 years ago
          const minAge = new Date();
          minAge.setFullYear(minAge.getFullYear() - 18); // Set minimum age to 18 years ago
      
          const dob = dateFormat(values.general_details.customer_dob);
          if (dob > dateFormat(new Date()) || dob < dateFormat(maxAge) || dob > dateFormat(minAge)) {
            errors += 'Age must be between 18 and 100 years old\n';
          }
        }
      
        if (!values.general_details.fathername) {
          errors += "Please enter the father's name\n";
        }
      
        if (!values.general_details.gender) {
          errors += 'Please select a gender\n';
        }
      
        if (!values.general_details.customer_mobile_num) {
          errors += 'Please enter the mobile number\n';
        } else if (!/^[0-9]+$/.test(values.general_details.customer_mobile_num)) {
          errors += 'Mobile number must contain only digits\n';
        } else if (values.general_details.customer_mobile_num.toString().length !== 10) {
          errors += 'Mobile number must be exactly 10 digits\n';
        }
      
        // Personal Details
        if (!values.personal_details.religion) {
          errors += 'Please select a religion\n';
        }
      
        if (!values.personal_details.category) {
          errors += 'Please select a category\n';
        }
      
        if (!values.personal_details.kyc_relation_type) {
          errors += 'Please select a KYC relation type\n';
        }
      
        if (!values.personal_details.kyc_relation) {
          errors += 'Please select a KYC relation\n';
        }
      
        if (!values.personal_details.educational_qualification) {
          errors += 'Please enter the educational qualification\n';
        }
      
        if (!values.personal_details.marital_status) {
          errors += 'Please select a marital status\n';
        }
      
        // Correspondence Address
        if (!values.personal_details.correspondence_address.address_type) {
          errors += 'Please select an address type for correspondence\n';
        }
      
        if (!values.personal_details.correspondence_address.address_line_1) {
          errors += 'Please enter the address line 1 for correspondence\n';
        }
      
        if (!values.personal_details.correspondence_address.landmark) {
          errors += 'Please enter the landmark for correspondence\n';
        }
      
        if (!values.personal_details.correspondence_address.tehsil) {
          errors += 'Please enter the tehsil for correspondence\n';
        }
      
        if (!values.personal_details.correspondence_address.district) {
          errors += 'Please enter the district for correspondence\n';
        }
      
        if (!values.personal_details.correspondence_address.pin_code) {
          errors += 'Please enter the pin code for correspondence\n';
        } else if (!/^[0-9]+$/.test(values.personal_details.correspondence_address.pin_code)) {
          errors += 'Pin code must contain only digits\n';
        } else if (values.personal_details.correspondence_address.pin_code.toString().length !== 6) {
          errors += `Pin code must be exactly 6 digits\n`;
        }
      
        if (!values.personal_details.correspondence_address.no_of_years) {
          errors += 'Please enter the number of years for correspondence address\n';
        }
      
        if (!values.personal_details.correspondence_address.city) {
          errors += 'Please enter the city for correspondence\n';
        }
      
        if (!values.personal_details.correspondence_address.state) {
          errors += 'Please enter the state for correspondence\n';
        }
      
        // Permanent Address
        if (!values.personal_details.permanent_address.address_type) {
          errors += 'Please select an address type for permanent address\n';
        }
      
        if (!values.personal_details.permanent_address.address_line_1) {
          errors += 'Please enter the address line 1 for permanent address\n';
        }
      
        if (!values.personal_details.permanent_address.landmark) {
          errors += 'Please enter the landmark for permanent address\n';
        }
      
        if (!values.personal_details.permanent_address.tehsil) {
          errors += 'Please enter the tehsil for permanent address\n';
        }
       
        if (!values.personal_details.permanent_address.district) {
          errors += 'Please enter the district for permanent address\n';
        } 
        if (values.personal_details.permanent_address.ispinCodeVerified && (!values.personal_details.permanent_address.pincodecomment || values?.personal_details?.permanent_address?.pincodecomment?.length<50)) {
            errors += 'Please enter appropriate comment\n';
          }
        
        if (!values.personal_details.permanent_address.pin_code) {
          errors += 'Please enter the pin code for permanent address\n';
        } else if (!/^[0-9]+$/.test(values.personal_details.permanent_address.pin_code)) {
          errors += 'Pin code must contain only digits\n';
        } else if (values.personal_details.permanent_address.pin_code.toString().length !== 6) {
          errors += 'Pin code must be exactly 6 digits\n';
        }
      
        if (!values.personal_details.permanent_address.no_of_years) {
          errors += 'Please enter the number of years for permanent address\n';
        }
      
        if (!values.personal_details.permanent_address.city) {
          errors += 'Please enter the city for permanent address\n';
        }
      
        if (!values.personal_details.permanent_address.state) {
          errors += 'Please enter the state for permanent address\n';
        }
      
        // Verification Details
        if (values.verification_details.pan_no) {
          const panRegex = /^[a-zA-Z0-9]{10}$/;
          if (!panRegex.test(values.verification_details.pan_no)) {
            errors += 'Valid PAN number should be 10 alphanumeric characters\n';
          }
        }
      
        // Add more validation checks for other fields in the Verification Details section...
      
        return errors;
      };
      const handleOpen = () => {
        setOpen(true);
        setPromoteApplicationId(cust.application_id)
        setPromoteApplicantCustomerId(cust.applicant.customer_id);
        setPromoteCoApplicnatCustomerId(initialValues.customer_id)
      };
    
      const handleClose = () => {
        setOpen(false);
      };


      const promoteCoAppplicantToApplicant=()=>{
           console.log("promote",promotApplicationId,promoteApplicantCustomerId,promoteCoApplicnatCustomerId)
           if(window.confirm('are you sure you want to promote coapplicant to applicant')){
            // console.log("promoted")
            customerService.promoteApplicantToCoApplicant({"loan_no": promotApplicationId,
            "applicant_cid":promoteApplicantCustomerId,
            "coapplicant_cid":promoteCoApplicnatCustomerId}
            ).then((res)=>{
                if(res.status==="success"){
                    
                        window.alert('Coapplicant promoted');
                        window.location.reload();
                    
                }else{
                    window.alert('Wrong Customer id')
                }
            })
            .catch(err=>console.log(err))
           }
      }


const [existingLoanResponse, setExistingLoanResponse] = useState("");
const [existingLoan, setExistingLoan] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const existingloan = async () => {
    try {
      const data = await customerService.getExistingLoan({ application_id: cust.application_id });
      setExistingLoanResponse(data.data);
      setExistingLoan(data);
    } catch (err) {
      console.log(err);
      setErrorMessage("An error occurred while fetching existing loans.");
    }
  };

  useEffect(() => {
    existingloan();

    adminservice.getNegativePinCode()
    .then(res=>{console.log('res',res); SetNegativePinCode(res)})
    .catch(err=>console.log('err',err))

     employeeService.getAll().then((res) => {
        let list = {};
        console.log(res)
        res.map((e) => {
          list[e.emp_id] = e.first_name + " " + e.last_name;
        });
        console.log(list)
        setEmployeeList(list);
      })
      .catch((err)=> {
         console.log("unable to get employee list");
      })
  }, []);
  

console.log(existingLoanResponse,"existingLoanResponse")
// const hasApplicants = Object?.values(existingLoanResponse)?.some(value => value?.applicant?.applicant_count > 0);
// const hasCo_applicants = Object?.values(existingLoanResponse)?.some(value => value?.co_applicant?.co_applicant_count > 0);
// const hasGuarantor = Object?.values(existingLoanResponse)?.some(value => value?.guarantors?.guarantors_count > 0);
const hasApplicants = existingLoanResponse && Object.values(existingLoanResponse).some(value => {
    // Ensure value.applicant exists and is an object
    if (value && typeof value.applicant === 'object') {
      // Check if applicant_count is a number and greater than 0
      return typeof value.applicant.applicant_count === 'number' && value.applicant.applicant_count > 0;
    }
    return false;
  });
  
  // Similar checks for co-applicants
  const hasCo_applicants = existingLoanResponse && Object.values(existingLoanResponse).some(value => {
    if (value && typeof value.co_applicant === 'object') {
      return typeof value.co_applicant.co_applicant_count === 'number' && value.co_applicant.co_applicant_count > 0;
    }
    return false;
  });
  
  // Similar checks for guarantors
  const hasGuarantor = existingLoanResponse && Object.values(existingLoanResponse).some(value => {
    if (value && typeof value.guarantors === 'object') {
      return typeof value.guarantors.guarantors_count === 'number' && value.guarantors.guarantors_count > 0;
    }
    return false;
  });
        async function startSearch(value) {
          console.log(value, "SL3511428SL3511428SL3511428SL3511428");
          console.log("start search");
      
          if (value && value.length >= 3) {
            loanService.getBySearch({ 'application_id': value })
              .then((res) => {
                console.log('hlo', res);
                if (res && res.length === 1) {
                  let loanObj = res[0];
                  console.log(loanObj);
                  console.log(user);
                  const accessCheck = (fieldName) => {
                    if (user.role === fieldName && user.branch_id !== loanObj.branches[0][fieldName]) {
                      window.alert("You don't have permission for this loan");
                      return false;
                    }
                    return true;
                  };
      
                  if (roleChecker.isZonalLevelAccess(user.role) && !accessCheck('branch_zone')) {
                    return;
                  }
                  if (roleChecker.isRegionLevelAccess(user.role) && !accessCheck('branch_region')) {
                    return;
                  }
                  if (roleChecker.isAreaLevelAccess(user.role) && !accessCheck('branch_area')) {
                    return;
                  }
                  if (roleChecker.isClusterLevelAccess(user.role) && !accessCheck('branch_cluster')) {
                    return;
                  }
                  if (roleChecker.isBranchLevelAccess(user.role) && !accessCheck('branch_id')) {
                    return;
                  }
      
                  console.log("adding to history..");
                  window.open('/home/sblloans/details/' + res[0]._id, '_blank');
                } else {
                  window.alert("Loan not found: " + value);
                }
              })
              .catch((err) => {
                console.log(err);
                window.alert("Error searching loan " + value);
              });
          }
        }

const consentRequest=async(customer_id)=>{
    console.log('customer id ',customer_id)
   try {
    let res = await customerService.consentRequest({cust_id:customer_id})
        window.alert(res.message)
        window.location.reload()

   } catch (error) {
    window.alert('Error on Consent Request API call')
    window.location.reload()

   } 
} 
const consentStatus=async(customer_id)=>{
    console.log('customer id ',customer_id)
    try{

  let res = await  customerService.consentStatus({cust_id:customer_id})
  window.alert(res.message)
    window.location.reload()
    }
    catch (err){
        window.alert('Error on Consent Status API call')
        window.location.reload()

    }
} 
const requestBankStatements=async(customer_id)=>{
    console.log('customer id ',customer_id)
    try{

  let res = await  customerService.requestBankStatements({cust_id:customer_id})
  console.log('response of request bank statement message is',res)
  window.alert(res.message)
    window.location.reload()
    }
    catch (err){
        window.alert('Error on Consent Status API call')
        window.location.reload()

    }
}
function isTodayWithinOneYear(dateString) {
    const givenDate = new Date(dateString);
    const today = new Date();
  
    // Calculate the difference in milliseconds
    const timeDifference = Math.abs(today - givenDate);
  
    // Convert milliseconds to days
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
  console.log('dayDifference',dayDifference)
    // Check if the difference in days is within one year (365 days)
    return dayDifference <= 365;
}

const [letters, setLetters] = useState([])
const getLetters = () => {
    sanction.getLetterByLoan({ application_id: cust?.application_id })
        .then((data) => {
            console.log('applicant data is',data)
            setLetters(data)
        })
        .catch((err) => {
            console.log(err)
        })
}

useEffect(() => {
    getLetters()
}, [])

    return (
        <div>
            {/* <div style={{ color: 'blue', margin: '30px', display: "flex", flexDirection: "column" }}>
                {applicant.kyc_images.map((x, idx) => {
                    if (x.tag === "Customer Photo") {
                        return <Avatar style={{marginLeft:"20px", width:"80px", height:"80px"}} src={x.uri} alt={x.tag} />
                    }
                })}
                <span style={{ marginTop: "20px" }}>
                    {cust && cust.applicant && cust.applicant.general_information && cust.applicant.general_information.name}
                </span>
            </div> */}

            <AppBar position="static">
                {cust && (
                    <div >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            variant="scrollable"

                             scrollButtons="auto"
                            style={{ backgroundColor: "#D4F1F4", color: "black" }}
                        >
                         
                            {TabSteps.map((step, idx) => {
                                return <Tab label={`${step}`} key={idx} {...a11yProps(idx)} style={{ minWidth: "12%" }} />
                            })}
                             
                        </Tabs>
                    </div>
                )}
            </AppBar>
{/* {errorsInFrom && <p>{errorsInFrom}</p>} */}
            {applicant && 
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                // validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                    return <fieldset   >
                        <Form >
                            <TabPanel value={value} index={0}>
                                {applicant &&
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <div>
      <div
        style={{ marginTop: "1%" }}
        className="d-flex justify-content-around"
        onClick={handleOpen}
      >
       {[LoanStages.Credit ].includes(cust.stage) && roleChecker.isGMLevelAccess(user.role) && props.role === 'CoApplicant' &&
        <Button color='secondary'  variant="contained" style={{fontSize:'12px' , background:'red'}}>
                                                Promote Co-Applicant
                                            </Button>}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Promote Co-Applicant to Applicant</h2>
            <form id="transition-modal-description">
              <div className="form-group">
                <label htmlFor="branch_id">Application No.</label>
                <input
                disabled
                  style={{width:"500px"}}
                  type="text"
                  className="form-control"
                  id="name"
                  value={promotApplicationId}
                  onChange={(e) => setPromoteApplicationId(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="branch_id">Applicant Customer Id</label>
                <input
                disabled
                  style={{width:"500px"}}
                  type="text"
                  className="form-control"
                  id="id"
                  value={promoteApplicantCustomerId}
                  onChange={(e) => setPromoteApplicantCustomerId(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="branch_id">Co-Applicant Customer Id</label>
                <input
                disabled
                  style={{width:"500px"}}
                  type="text"
                  className="form-control"
                  id="contact"
                  value={promoteCoApplicnatCustomerId}
                  onChange={(e) => setPromoteCoApplicnatCustomerId(e.target.value)}
                />
              </div>
              <hr />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => promoteCoAppplicantToApplicant()}
              >
                Submit
              </button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
                                          { ![LoanStages.Collection,LoanStages.Disbursal,LoanStages.Operation].includes(cust.stage) && <Button type='button' onClick={() => { setEdit(!edit) }}><EditIcon style={{color: 'blue'}} /></Button>}
                                            {((!([LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense, LoanStages.Collection,LoanStages.Operation,LoanStages.Disbursal].includes(cust.stage) ) && ( !letters || !letters.length>0 || !letters[0]?.verify_details || !letters[0]?.verify_details?.is_verified)) || (roleChecker.isGMLevelAccess(user.role) && ![LoanStages.Collection].includes(cust.stage)) ) && props.role !== 'Applicant'    && <Button
                                                onClick={() =>{if(window.confirm(`are you sure you want to delete this ${props.role}` )) {
                                                    deleteCustomer(values)}}}
                                                // variant="contained"
                                                // style={{ backgroundColor: "red" }}
                                            >
                                                <Delete style={{color: 'red'}} />
                                            </Button>}
                                            
  
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6>CIF ID</h6>
                                            <Field
                                                label='CIF ID'
                                                type='string'
                                                name='customer_id'
                                                disabled 
                                                className={
                                                    "form-control"
                                                    // (errors && errors.general_details && errors.general_details.customer_firstname && touched.general_details.customer_firstname
                                                    //     ? " is-invalid"
                                                    //     : "")
                                                }
                                            />
                                            {/* <ErrorMessage
                                                name='general_details.customer_firstname'
                                                component="div"
                                                className="invalid-feedback"
                                            /> */}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Title</h6>
                                            <Field
                                                label='Title'
                                                as='select'
                                                name='general_details.customer_title'
                                                disabled={edit}
                                                onChange={(e) => handleGenderAccToType(values, setFieldValue, e.target.value)}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_title && touched && touched.general_details && touched.general_details.customer_title
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Ms">Ms</option>
                                            </Field>
                                            <ErrorMessage
                                                name='general_details.customer_title'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Gender</h6>
                                            <Field
                                                label='Gender'
                                                as='select'
                                                name='general_details.gender'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.gender && touched && touched.general_details && touched.general_details.gender
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                            <ErrorMessage
                                                name='general_details.gender'
                                                component="div"
                                                className="invalid-feedback"
                                            />

                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">First Name</h6>
                                            <Field
                                                label='First Name'
                                                type='string'
                                                name='general_details.customer_firstname'
                                                disabled={edit}
                                                // onChange={(e) => {setFieldValue('general_details.customer_firstname', e.target.value) }}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_firstname && touched && touched.general_details && touched.general_details.customer_firstname
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.customer_firstname'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6>Middle Name</h6>
                                            <Field
                                                label='Middle Name'
                                                type='string'
                                                name='general_details.customer_middlename'
                                                disabled={edit}
                                                className={
                                                    "form-control" /*+
                                                                (errors.ledger_name && touched.ledger_name
                                                                    ? " is-invalid"
                                                                    : "")*/
                                                }
                                            />
                                            {/* <ErrorMessage
                                                        name={values.name}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    /> */}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Last Name</h6>
                                            <Field
                                                label='Last Name'
                                                type='string'
                                                name='general_details.customer_lastname'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_lastname && touched && touched.general_details && touched.general_details.customer_lastname
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.customer_lastname'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">DOB</h6>
                                            <Field
                                                label='DOB'
                                                type='date'
                                                onKeyDown={(e) => e.preventDefault()} 
                                                name='general_details.customer_dob'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_dob && touched && touched.general_details && touched.general_details.customer_dob
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.customer_dob'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Father Name</h6>
                                            <Field
                                                label='Father Name'
                                                type='string'
                                                name='general_details.fathername'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.fathername && touched && touched.general_details && touched.general_details.fathername
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.fathername'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                       
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Religion</h6>
                                            <Field
                                                label='Religion'
                                                as='select'
                                                name='personal_details.religion'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.religion && touched && touched.personal_details && touched.personal_details.religion
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Hindu">Hindu</option>
                                                <option value="Muslim">Muslim</option>
                                                <option value="Sikh">Sikh</option>
                                                <option value="Christian">Christian</option>
                                                <option value="Other">Other</option>
                                                <ErrorMessage
                                                    name='personal_details.religion'
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </Field>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Category</h6>
                                            <Field
                                                label='Category'
                                                as='select'
                                                name='personal_details.category'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.category && touched && touched.personal_details && touched.personal_details.category
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="General">General</option>
                                                <option value="OBC">OBC</option>
                                                <option value="SC">SC</option>
                                                <option value="ST">ST</option>
                                                <option value="DT/NT">DT/NT</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.category'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        {!edit ?
                                            <Button color='primary' type='submit' variant='contained'>Save Details</Button> :
                                            <Button color='primary' type='button' variant='contained' style={{ height: '40px' }} onClick={(e) => { handleChange(e, 1) }}>Next</Button>
                                        }
                                    </div>}
                            </TabPanel>
                            {applicant && <TabPanel value={value} index={1}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                   {![LoanStages.Collection,LoanStages.Disbursal,LoanStages.Operation].includes(cust.stage) &&   <Button type='button' onClick={() => { setEdit(!edit) }}><EditIcon /></Button>}
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                    <div className="form-group col-md-4">
                                    <div style={{display:'flex',justifyContent:'space-between'}}>

                                        <h6 className="required">Mobile Number </h6>
                                        <h6 style={{color:"green"}}> <>
                                            {applicant && applicant.general_details && applicant.general_details.mobile_no_verified ? <> Verified <VerifiedUserOutlinedIcon/> </>:<OtpVerificationModal applicant={applicant} role={props.role} application_id={cust.application_id}/>}
                                        </></h6>
                                    </div>
                                        <Field
                                            label='Mobile Number'
                                            type='number'
                                            name='general_details.customer_mobile_num'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.general_details && errors.general_details.customer_mobile_num && touched && touched.general_details && touched.general_details.customer_mobile_num
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='general_details.customer_mobile_num'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6>Alternate Mobile Number</h6>
                                        <Field
                                            label='Alternate Mobile Number'
                                            type='number'
                                            name='general_details.alternate_mobile_num'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.general_details && errors.general_details.alternate_mobile_num && touched && touched.general_details && touched.general_details.alternate_mobile_num
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='general_details.alternate_mobile_num'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6>Email Id</h6>
                                        <Field
                                            label='Email id'
                                            type='string'
                                            name='general_details.email'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.general_details && errors.general_details.email && touched && touched.general_details && touched.general_details.email
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='general_details.email'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>

                                <h3>Correspondence Address</h3>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Current Residence Address</h6>
                                        <Field
                                            label='Current Residence Address'
                                            type='string'
                                            name='personal_details.correspondence_address.address_line_1'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.address_line_1 && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.address_line_1
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.address_line_1'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Current Residence Is</h6>
                                        <Field
                                            label='Current Residence is'
                                            as='select'
                                            name='personal_details.correspondence_address.address_type'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.address_type && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.address_type
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        >

                                            <option value=""></option>
                                            <option value="self_owned">Self Owned</option>
                                            <option value="family">Family</option>
                                            <option value="rented">Rented</option>
                                            <option value="company">Company</option>
                                            <option value="residential">Residential</option>
                                        </Field>
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.address_type'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Landmark</h6>
                                        <Field
                                            label='Landmark'
                                            type='string'
                                            name='personal_details.correspondence_address.landmark'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.landmark && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.landmark
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.landmark'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Tehsil</h6>
                                        <Field
                                            label='Tehsil'
                                            type='string'
                                            name='personal_details.correspondence_address.tehsil'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.tehsil && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.tehsil
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.tehsil'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Pin Code</h6>
                                        <Field
                                            label='Pin code'
                                            type='number'
                                            name='personal_details.correspondence_address.pin_code'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.pin_code && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.pin_code
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.pin_code'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">City</h6>
                                        <Field
                                            label='City'
                                            type='string'
                                            name='personal_details.correspondence_address.city'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.city && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.city
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.city'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">District</h6>
                                        <Field
                                            label='District'
                                            type='string'
                                            name='personal_details.correspondence_address.district'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.district && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.district
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.district'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div className="form-group col-md-4">
                                        <h6 className="required">State</h6>
                                        <Field
                                             label='State'
                                             as='select'
                                             name='personal_details.correspondence_address.state'
                                             disabled={edit}
                                              className={
                                                "form-control"
                                                 +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.state && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.state
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        >

                                            <option value=""></option>
                                            <option value="Andhra Pradesh">ANDHRA PRADESH</option>
                                            <option value="Arunachal Pradesh">ARUNACHAL PRADESH</option>
                                            <option value="Assam">ASSAM</option>
                                            <option value="Bihar">BIHAR</option>
                                            <option value="Chhattisgarh">CHHATTISGARH</option>
                                            <option value="Goa">GOA</option>
                                            <option value="Gujarat">GUJARAT</option>
                                            <option value="Haryana">HARYANA</option>
                                            <option value="Himachal Pradesh">HIMACHAL PRADESH</option>
                                            <option value="Jammu and Kashmir">JAMMU AND KASHMIR</option>
                                            <option value="Jharkhand">JHARKHAND</option>
                                            <option value="Karnataka">KARNATAKA</option>
                                            <option value="Kerala">KERALA</option>
                                            <option value="Madhya Pradesh">MADHYA PRADESH</option>
                                            <option value="Maharashtra">MAHARASHTRA</option>
                                            <option value="Manipur">MANIPUR</option>
                                            <option value="Meghalaya">MEGHALAYA</option>
                                            <option value="Mizoram">MIZORAM</option>
                                            <option value="Nagaland">NAGALAND</option>
                                            <option value="Odisha">ODISHA</option>
                                            <option value="Punjab">PUNJAB</option>
                                            <option value="Rajasthan">RAJASTHAN</option>
                                            <option value="Sikkim">SIKKIM</option>
                                            <option value="Tamil Nadu">TAMIL NADU</option>
                                            <option value="Telangana">TELANGANA</option>
                                            <option value="Tripura">TRIPURA</option>
                                            <option value="Uttar Pradesh">UTTAR PRADESH</option>
                                            <option value="Uttarakhand">UTTARAKHAND</option>
                                            <option value="West Bengal">WEST BENGAL</option>
                                        </Field>
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.state'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    {/* <div className="form-group col-md-4">
                                        <h6 className="required">State</h6>
                                        <Field
                                            label='State'
                                            type='string'
                                            name='personal_details.correspondence_address.state'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.state && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.state
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.state'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div> */}
                                    <div className="form-group col-md-4">
                                        <h6 className="required">No. of Years at Current Residence</h6>
                                        <Field
                                            label='No. of Years at Current Residence'
                                            type='number'
                                            name='personal_details.correspondence_address.no_of_years'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.no_of_years && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.no_of_years
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.correspondence_address.no_of_years'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <h3>Permanent Address </h3>
                                {/* <Checkbox
                                    checked={checked}
                                    onChange={(event) => fillPermanentAddress(values, setFieldValue, event)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                /> Same as above address */}

                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Current Residence Address</h6>
                                        <Field
                                            label='Current Residence Address'
                                            type='string'
                                            name='personal_details.permanent_address.address_line_1'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.address_line_1 && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.address_line_1
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.address_line_1'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Current Residence Is</h6>
                                        <Field
                                            label='Current Residence is'
                                            as='select'
                                            name='personal_details.permanent_address.address_type'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.address_type && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.address_type
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        >

                                            <option value=""></option>
                                            <option value="self_owned">Self Owned</option>
                                            <option value="family">Family</option>
                                            <option value="rented">Rented</option>
                                            <option value="company">Company</option>
                                            <option value="residential">Residential</option>
                                        </Field>
                                        <ErrorMessage
                                            name='personal_details.permanent_address.address_type'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Landmark</h6>
                                        <Field
                                            label='Landmark'
                                            type='string'
                                            name='personal_details.permanent_address.landmark'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.landmark && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.landmark
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.landmark'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Tehsil</h6>
                                        <Field
                                            label='Tehsil'
                                            type='string'
                                            name='personal_details.permanent_address.tehsil'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.tehsil && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.tehsil
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.tehsil'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                   {  <div className="form-group col-md-4">
                                   <h6 className="required">Pin Code 
    {roleChecker.isGMLevelAccess(user.role) && props.role === 'Applicant' && 
        <Field 
            type='checkbox' 
            name='personal_details.permanent_address.ispinCodeVerified' 
            disabled={edit} 
            style={{width: '40px',
    margin: '2px'}}
            onChange={(e) => {
                setFieldValue('personal_details.permanent_address.ispinCodeVerified', e.target.checked);
                setIsPinCode(true);
            }}  
        />
    }
    {
        props.role === 'Applicant' &&   cust?.applicant?.general_information?.
ispincodeverifiedby && <b> Verified by {employeeList[cust.applicant.general_information.ispincodeverifiedby]}</b>
 
    }
</h6>

                                         {isPinCode && props.role == 'Applicant' && 
                                         <Field
                                         style={{marginBottom: '10px'}}
                                            label='Comment'
                                            type='text'
                                            placeholder='Enter your comment here'
                                            name='personal_details.permanent_address.pincodecomment'
                                            disabled={edit}
                                             
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.pin_code && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.pincodecomment
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                         }
                                        <Field
                                            label='Pin code'
                                            type='number'
                                            name='personal_details.permanent_address.pin_code'
                                            
                                            disabled={edit}
                                            style={{backgroundColor:(props.role == 'Applicant' && negativePinCode.includes(applicant?.personal_details?.permanent_address?.pin_code))?'red':null}}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.pin_code && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.pin_code
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.pin_code'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>}
                                    <div className="form-group col-md-4">
                                        <h6 className="required">City</h6>
                                        <Field
                                            label='City'
                                            type='string'
                                            name='personal_details.permanent_address.city'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.city && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.city
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.city'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">District</h6>
                                        <Field
                                            label='District'
                                            type='string'
                                            name='personal_details.permanent_address.district'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.district && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.district
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.district'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <h6 className="required">State</h6>
                                        <Field
                                             label='State'
                                             as='select'
                                             name='personal_details.permanent_address.state'
                                             disabled={edit}
                                             className={
                                                 "form-control" +
                                                 (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.state && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.state
                                                     ? " is-invalid"
                                                     : "")
                                             }
                                        >

<option value=""></option>
                                            <option value="Andhra Pradesh">ANDHRA PRADESH</option>
                                            <option value="Arunachal Pradesh">ARUNACHAL PRADESH</option>
                                            <option value="Assam">ASSAM</option>
                                            <option value="Bihar">BIHAR</option>
                                            <option value="Chhattisgarh">CHHATTISGARH</option>
                                            <option value="Goa">GOA</option>
                                            <option value="Gujarat">GUJARAT</option>
                                            <option value="Haryana">HARYANA</option>
                                            <option value="Himachal Pradesh">HIMACHAL PRADESH</option>
                                            <option value="Jammu and Kashmir">JAMMU AND KASHMIR</option>
                                            <option value="Jharkhand">JHARKHAND</option>
                                            <option value="Karnataka">KARNATAKA</option>
                                            <option value="Kerala">KERALA</option>
                                            <option value="Madhya Pradesh">MADHYA PRADESH</option>
                                            <option value="Maharashtra">MAHARASHTRA</option>
                                            <option value="Manipur">MANIPUR</option> 
                                            <option value="Meghalaya">MEGHALAYA</option> 
                                            <option value="Mizoram">MIZORAM</option>
                                            <option value="Nagaland">NAGALAND</option>
                                            <option value="Odisha">ODISHA</option>
                                            <option value="Punjab">PUNJAB</option>
                                            <option value="Rajasthan">RAJASTHAN</option>
                                            <option value="Sikkim">SIKKIM</option>
                                            <option value="Tamil Nadu">TAMIL NADU</option>
                                            <option value="Telangana">TELANGANA</option>
                                            <option value="Tripura">TRIPURA</option>
                                            <option value="Uttar Pradesh">UTTAR PRADESH</option>
                                            <option value="Uttarakhand">UTTARAKHAND</option>
                                            <option value="West Bengal">WEST BENGAL</option>
                                        </Field>
                                        <ErrorMessage
                                             name='personal_details.permanent_address.state'
                                             component="div"
                                             className="invalid-feedback"
                                        />
                                    </div>
                                    {/* <div className="form-group col-md-4">
                                        <h6 className="required">State</h6>
                                        <Field
                                            label='State'
                                            type='string'
                                            name='personal_details.permanent_address.state'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.state && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.state
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.state'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div> */}
                                    <div className="form-group col-md-4">
                                        <h6 className="required">No. of Years at Current Residence</h6>
                                        <Field
                                            label='No. of Years at Current Residence'
                                            type='number'
                                            name='personal_details.permanent_address.no_of_years'
                                            disabled={edit}
                                            className={
                                                "form-control" +
                                                (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.no_of_years && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.no_of_years
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name='personal_details.permanent_address.no_of_years'
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                {!edit ?
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button color='primary' type='submit' variant='contained'>Save Details</Button> :
                                    </div> :
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button type='button' color='secondary' variant='contained' style={{ height: '40px' }} onClick={(e) => { handleChange(e, 0) }}>Previous</Button>
                                        <Button type='button' color='primary' variant='contained' style={{ height: '40px', marginLeft: '20px' }} onClick={(e) => { handleChange(e, 2) }}>Next</Button>
                                    </div>
                                }

                            </TabPanel>}
                            <TabPanel value={value} index={2}>
                                {applicant && <>
                                    <h3 style={{ width: '100%' }}>KYC Details</h3><br/>

                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                    <div className="form-group col-md-3">
                                        <h6 className="required">Mask Aadhaar Number</h6>
                                        <Field
                                                type='string'
                                                disabled={edit   || !roleChecker.isHOCreditApproverAccess(user.role)}
                                                name='verification_details.aadhaar_mask_no'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.verification_details && errors.verification_details.aadhaar_mask_no && touched && touched.verification_details && touched.verification_details.aadhaar_mask_no
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                        <ErrorMessage
                                            name='verification_details.aadhaar_mask_no'
                                            component="div"
                                            className="invalid-feedback"
                                        />  
                                    </div>
                                        <div className="form-group col-md-3">
                                            <h6 className="required">Aadhaar Number</h6>
                                            <Field
                                                type='string'
                                                disabled={edit || !roleChecker.isHOCreditApproverAccess(user.role)}
                                                name='verification_details.aadhaar_no'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.verification_details && errors.verification_details.aadhaar_no && touched && touched.verification_details && touched.verification_details.aadhaar_no
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='verification_details.aadhaar_no'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-3">
                                            <h6 >PAN Number</h6>
                                            <Field
                                                type='string'
                                                name='verification_details.pan_no'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.verification_details && errors.verification_details.pan_no && touched && touched.verification_details && touched.verification_details.pan_no
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='verification_details.pan_no'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <h6 >Voter ID</h6>
                                            <Field
                                                type='string'
                                                name='verification_details.voter_id'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.verification_details && errors.verification_details.voter_id && touched && touched.verification_details && touched.verification_details.voter_id
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='verification_details.voter_id'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        {/* <div className="form-group col-md-4">
                                            <Button type='button' color='primary' variant='contained' onClick={()=>sendOtp(values)} >Send OTP</Button>
                                        </div> */}
                                    </div><br/>

                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        {applicant && applicant.kyc_images && applicant.kyc_images.length > 0 && applicant.kyc_images.map((x, idx) => {
                                            return <div key={idx} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                <span><b>{x.tag}</b></span>
                                                <img style={{ width: "250px", height: "250px" }} src={x.uri} alt={x.tag} /><br />
                                            </div>

                                        })}
                                    </div>
                                </>}
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: "50px" }}>
                                    <Button type='button' color='secondary' variant='contained' style={{ height: '40px' }} onClick={(e) => { handleChange(e, 1) }}>Previous</Button>
                                    <Button type='button' color='primary' variant='contained' style={{ height: '40px', marginLeft: '20px' }} onClick={(e) => { handleChange(e, 3) }}>Next</Button>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                   { ![LoanStages.Collection,LoanStages.Disbursal,LoanStages.Operation].includes(cust.stage) && <Button type='button' onClick={() => { setEdit(!edit) }}><EditIcon /></Button>}
                                </div>
                                <h3>Income Details</h3>
                                {applicant &&
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6  >Annual Income</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.annual_income'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.annual_income && touched && touched.economic_details && touched.economic_details.annual_income
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.annual_income'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Occupation Type</h6>
                                            <Field

                                                as='select'
                                                name='economic_details.occupation_type'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.occupation_type && touched && touched.economic_details && touched.economic_details.occupation_type
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="salaried">Salaried</option>
                                                <option value="self_employed">Self Employed</option>
                                                <option value="self_employed_professional">Self Employed Professional</option>
                                            </Field>
                                            <ErrorMessage
                                                name='economic_details.occupation_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        {/* <Field
                                                    type='string'
                                                    name='economic_details.nature_of_business_or_employment'
                                                    disabled={edit}
                                                    className={
                                                        "form-control" +
                                                        (errors && errors.economic_details && errors.economic_details.nature_of_business_or_employment && touched && touched.economic_details && touched.economic_details.nature_of_business_or_employment
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                /> */}


                                          <div className="form-group col-md-4">
                                        <h6 >Nature of Business/Employment</h6>
                                        <Field
                                             label='Nature of Business/Employment'
                                             as='select'
                                             name='economic_details.nature_of_business_or_employment'
                                             disabled={edit}
                                             className={
                                                 "form-control" +
                                                 (errors && errors.economic_details && errors.economic_details.nature_of_business_or_employment && touched && touched.economic_details && touched.economic_details.nature_of_business_or_employment
                                                     ? " is-invalid"
                                                     : "")
                                             }

                                             onBlur={(event) => {
                                                const selectedValue = event.target.value;
                                                if (selectedValue !== 'Other' && selectedValue.trim() !== '') {
                                                  setFieldValue('economic_details.other_business', '');
                                                }
                                              }}
                                        >

                                                    <option value={values?.economic_details?.nature_of_business_or_employment}>{values?.economic_details?.nature_of_business_or_employment}</option>
                                                    <option value="Agency">Agency</option>
                                                    <option value="Auto/Rickshaw">Auto/Rickshaw</option>
                                                    <option value="Chef/Cook">Chef/Cook</option>
                                                    <option value="Clothing Shop">Clothing Shop</option>
                                                    <option value="Dairy Farm Shop">Dairy Farm Shop</option>
                                                    <option value="Driver">Driver</option>
                                                    <option value="Electronics Shop">Electronics Shop</option>
                                                    <option value="Farmer">Farmer</option>
                                                    <option value="Flower Shop">Flower Shop</option>
                                                    <option value="Fruit/vegetable Shop">Fruit/vegetable Shop</option>
                                                    <option value="Furniture Shop">Furniture Shop</option>
                                                    <option value="Grocery Shop">Grocery Shop</option>
                                                    <option value="Labour">Labour</option>
                                                    <option value="Manufacturer">Manufacturer</option>
                                                    <option value="Mason">Mason</option>
                                                    <option value="Painter">Painter</option>
                                                    <option value="Salon Shop">Salon Shop</option>
                                                    <option value="Services">Services</option>
                                                    <option value="Shoes Shop">Shoes Shop</option>
                                                    <option value="Stationery Shop">Stationery Shop</option>
                                                    <option value="Supplier">Supplier</option>
                                                    <option value="Sweet Shop">Sweet Shop</option>
                                                    <option value="Teacher">Teacher</option>
                                                    <option value="Tea/coffee Shop">Tea/coffee Shop</option>
                                                    <option value="Trader">Trader</option>
                                                    <option value="Other">Other</option>


                                        </Field>
                                        <ErrorMessage
                                             name='economic_details.nature_of_business_or_employment'
                                             component="div"
                                             className="invalid-feedback"
                                        />
                                        
                                        {values?.economic_details?.nature_of_business_or_employment === 'Other' && (
            <div>
              <label>Other Business</label>
              <Field
                 type='string'
                 name='economic_details.other_business'
                 disabled={edit}
                 className={
                     "form-control" +
                     (errors && errors.economic_details && errors.economic_details.other_business && touched && touched.economic_details && touched.economic_details.other_business
                         ? " is-invalid"
                         : "")
                 }
              />
               <ErrorMessage
                                             name='economic_details.other_business'
                                             component="div"
                                             className="invalid-feedback"
                                        />
            </div>
          )}
                                    </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Business/Employment Address</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.business_or_employment_address'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_address && touched && touched.economic_details && touched.economic_details.business_or_employment_address
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_address'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Pin Code</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.business_or_employment_pincode'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_pincode && touched && touched.economic_details && touched.economic_details.business_or_employment_pincode
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_pincode'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >City</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.business_or_employment_city'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_city && touched && touched.economic_details && touched.economic_details.business_or_employment_city
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_city'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >State</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.business_or_employment_state'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_state && touched && touched.economic_details && touched.economic_details.business_or_employment_state
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_state'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Number of years in current employment</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.no_of_years'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.no_of_years && touched && touched.economic_details && touched.economic_details.no_of_years
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.no_of_years'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6>Designation (if salaried)</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.designation'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.designation && touched && touched.economic_details && touched.economic_details.designation
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.designation'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>}
                                <h3>Assets</h3>
                                {applicant &&
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6 >Asset Type</h6>
                                            <Field
                                                as='select'
                                                name='property_details.property_type'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_type &&
                                                        touched && touched.property_details && touched.property_details.property_type ? " is-invalid" : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="property">Property</option>
                                                <option value="land">Land</option>
                                                <option value="vehicle">Vehicle</option>
                                                <option value="farm_animal">Farm Animal</option>
                                                <option value="others" others>Others</option>
                                            </Field>
                                            <ErrorMessage
                                                name='property_details.property_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Count Of Asset</h6>
                                            <Field
                                                type='number'
                                                name='property_details.count_of_property'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_type &&
                                                        touched && touched.property_details && touched.property_details.property_type ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.count_of_property'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Market Value Of Asset</h6>
                                            <Field
                                                type='number'
                                                name='property_details.property_market_val'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_market_val &&
                                                        touched && touched.property_details && touched.property_details.property_market_val ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.property_market_val'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Asset Age</h6>
                                            <Field
                                                type='number'
                                                name='property_details.property_age'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_age &&
                                                        touched && touched.property_details && touched.property_details.property_age ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.property_age'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6>If Property / Agricultural Land then ask Agricultural  Land size(in bigha)</h6>
                                            <Field
                                                type='number'
                                                name='property_details.land_size_bigha'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.land_size_bigha && touched && touched.property_details && touched.property_details.land_size_bigha
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.land_size_bigha'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>}
                                <h3>Bank Details</h3>
                                {applicant &&
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6 >Bank Name</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.bank_details.bank_name'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.bank_name && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.bank_name
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.bank_name'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Account Number</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.bank_details.account_number'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.account_number && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.account_number
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.account_number'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >IFSC Code</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.bank_details.ifsc_code'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.ifsc_code && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.ifsc_code
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.ifsc_code'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Account Holder Name</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.bank_details.account_holder'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.account_holder && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.account_holder
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.account_holder'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>}
                                <h3>Credit history</h3>
                                {applicant &&
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div className="form-group col-md-4">
                                            <h6>Cibil Score</h6>
                                            <Field
                                                type='number'
                                                name='financial_condition.cibil_score'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.financial_condition && errors.financial_condition.cibil_score && touched && touched.financial_condition && touched.financial_condition.cibil_score
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='financial_condition.cibil_score'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6>Last date of Cibil score/ Last check date</h6>
                                            <Field
                                                type='date'
                                                onKeyDown={(e) => e.preventDefault()}
                                                name='financial_condition.cibil_score_last_check_date'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.financial_condition && errors.financial_condition.cibil_score_last_check_date && touched && touched.financial_condition && touched.financial_condition.cibil_score_last_check_date
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='financial_condition.cibil_score_last_check_date'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>}
                                {!edit ?
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button color='primary' type='submit' variant='contained'>Save Details</Button> :
                                    </div> :
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button type='button' color='secondary' variant='contained' style={{ height: '40px' }} onClick={(e) => { handleChange(e, 2) }}>Previous</Button>
                                        <Button type='button' color='primary' variant='contained' style={{ height: '40px', marginLeft: '20px' }} onClick={(e) => { handleChange(e, 4) }}>Next</Button>
                                    </div>
                                }
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                   { ![LoanStages.Collection,LoanStages.Disbursal,LoanStages.Operation].includes(cust.stage) && <Button type='button' onClick={() => { setEdit(!edit) }}><EditIcon /></Button>}
                                </div>
                                {applicant &&
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', columnGap:'1rem' }}>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Marital Status</h6>
                                            <Field
                                                as='select'
                                                name='personal_details.marital_status'
                                                disabled={edit}
                                                // onChange={(e) => checkSpouse(values, setFieldValue, e.target.value)}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.marital_status && touched && touched.personal_details && touched.personal_details.marital_status
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Single">Single</option>
                                                <option value="Married">Married</option>
                                                <option value="Divorcee">Divorcee</option>
                                                <option value="Widowed">Widowed</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.marital_status'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>                          
                                       <div className="form-group col-md-4">
                                            <h6   >Husband/Wife Name</h6>
                                            <Field
                                                type='string'
                                                name='personal_details.name_of_spouse'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.name_of_spouse && touched && touched.personal_details && touched.personal_details.name_of_spouse
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.name_of_spouse'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6 className="required">KYC Relation Type</h6>
                                            <Field
                                                as='select'
                                                name='personal_details.kyc_relation_type'
                                                disabled={edit}
                                                onChange={(e) => handleRelation(values, setFieldValue, e.target.value)}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.kyc_relation_type && touched && touched.personal_details && touched.personal_details.kyc_relation_type
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="S/o">S/o</option>
                                                <option value="W/o">W/o</option>
                                                <option value="D/o">D/o</option>
                                                <option value="C/o">C/o</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.kyc_relation_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6>KYC Relation</h6>
                                            <Field
                                                type='string'
                                                name='personal_details.kyc_relation'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.kyc_relation && touched && touched.personal_details && touched.personal_details.kyc_relation
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.kyc_relation'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6 >Son Count</h6>
                                            <Field
                                                type='number'
                                                name='family_data.sons'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.family_data && errors.family_data.sons && touched && touched.family_data && touched.family_data.sons
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='family_data.sons'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 >Daughter Count</h6>
                                            <Field
                                                type='number'
                                                name='family_data.daughters'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.family_data && errors.family_data.daughters && touched && touched.family_data && touched.family_data.daughters
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='family_data.daughters'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Education Qualification</h6>
                                            <Field
                                                as='select'
                                                name='personal_details.educational_qualification'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.educational_qualification && touched && touched.personal_details && touched.personal_details.educational_qualification
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="No Education">No Education</option>
                                                <option value="10th">10th</option>
                                                <option value="12th">12th</option>
                                                <option value="Graduate">Graduate</option>
                                                <option value="Post Graduate">Post Graduate</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.educational_qualification'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 className="required">Smoker/Non-Smoker</h6>
                                            <Field
                                                as='select'
                                                name='personal_details.smoker'
                                                disabled={edit}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.smoker && touched && touched.personal_details && touched.personal_details.smoker
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Smoker">Smoker</option>
                                                <option value="Non smoker">Non Smoker</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.smoker'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>}
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '30px' }}>
                                        <Button color='secondary' variant='contained' style={{ height: '40px' }} onClick={(e) => { handleChange(e, 3) }}>Previous</Button>
                                    {!edit &&
                                        <Button color='primary' type='submit' variant='contained'>Save Details</Button>
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                       
                               <div>
                               {existingLoan?.status === "Success" ? (
    <>               
<div class="row row-cols-1 row-cols-md-3 g-4">
    <div>
    <div class="card-header" style={{fontWeight: 'bold'}}>Customer as Applicant in Loans </div>  
   
 {hasApplicants ? (
  <>
    {Object.entries(existingLoanResponse).map(([key, value]) => (
      <React.Fragment key={key}>
        {value.applicant.applicant_count > 0 && props.applicant.customer_id===key && (
          <>
            {value.applicant.applicant_ids.map((id, index) => (
              <div key={index}>
                <div class="col mt-1">
                  <div class="card" style={{ border: "2px solid #eaeaea", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", overflow: "hidden" }}>
                    <div class="card-body">
                      <p class="card-text" >Application ID :- <b><Link to={`/home/sblloans/details/${value.applicant.applicant_obj_ids[index]}`} target="_blank" 
      rel="noopener noreferrer">{id}</Link></b></p>
                      <p class="card-text">Loan Stage :- <b>{value.applicant.applicant_stage[index]}</b></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </React.Fragment>
    ))}
  </>
) : (
  <div class="col mt-1">
    <div class="alert alert-info" role="alert">
    Customer doesn't exist in any Loan as Applicant.
    </div>
  </div>
)}




    </div>
    <div>

<div class="card-header" style={{fontWeight: 'bold'}}>Customer as Co-Applicant in Loans </div>   
{hasCo_applicants ? (
  <>
  {Object.entries(existingLoanResponse).map(([key, value]) => (
    <React.Fragment key={key}>
       
      {value.co_applicant.co_applicant_count > 0 && props.applicant.customer_id===key && (
        <>
        {value.co_applicant.co_applicant_ids.map((id, index) => (
                <div key={index}>
        <div class="col mt-1">
          <div class="card" style={{ border: "2px solid #eaeaea", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", overflow: "hidden" }}>
            <div class="card-body">
                  <p class="card-text">Application ID :- <b><Link to={`/home/sblloans/details/${value.co_applicant.co_applicant_obj_ids[index]}`} target="_blank">{id}</Link></b></p>
                  <p class="card-text">Loan Stage :- <b>{value.co_applicant.co_applicant_stage[index]}</b></p>
                </div>
            
            </div>
          </div>
        </div>
          ))}
        </>
      )}
    </React.Fragment>
  ))}
   </>
) : (
  <div class="col mt-1">
    <div class="alert alert-info" role="alert">
    Customer doesn't exist in any Loan as Co-applicant
    </div>
  </div>
)}
</div> 
<div>
<div class="card-header" style={{fontWeight: 'bold'}}>Customer as Guarantor in Loans </div>
{hasGuarantor ? (
  <>
  {Object.entries(existingLoanResponse).map(([key, value]) => (
    <React.Fragment key={key}>
      {value.guarantors.guarantors_count > 0 && props.applicant.customer_id===key &&(
        <>
        {value.guarantors.guarantors_ids.map((id, index) => (
                <div key={index}>
        <div class="col mt-1">
          <div class="card" style={{ border: "2px solid #eaeaea", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", overflow: "hidden" }}>
            <div class="card-body">
                  <p class="card-text">Application ID :- <b><Link to={`/home/sblloans/details/${value.guarantors.gua_obj_ids[index]}`} target="_blank">{id}</Link></b></p>
                  <p class="card-text">Loan Stage :- <b>{value.guarantors.guarantors_stage[index]}</b></p>
                </div>
            
            </div>
          </div>
        </div>
          ))}
        </>
      )}
    </React.Fragment>
  ))}
   </>
) : (
  <div class="col mt-1">
    <div class="alert alert-info" role="alert">
    Customer doesn't exist in any Loan as Guarantor
    </div>
  </div>
)}
</div>
</div>

      </>
  ) : (
    <div class="col mt-1">
    <div class="alert alert-info" role="alert">
    {existingLoan?.message}
    </div>
  </div>
  )}
    </div>
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <div>
                                 <div style={{display:'flex',justifyContent:'flex-start'}}>

                                   {((!isConsentResponseExist || !isConsentResponseExist?.consent_st_api?.api_call_time) || (isConsentResponseExist?.consent_st_api?.api_call_time &&  !isTodayWithinOneYear(isConsentResponseExist?.consent_st_api?.api_call_time))) &&  <div  style={{margin:'1rem'}}>
                                        <Button color='primary' variant='outlined' onClick={()=>{ if(window.confirm('Do you Want to send a consent request ?')){
                                         consentRequest(values.customer_id)
                                        } }}>Consent Request</Button>
                                    </div>}
                                    {(isConsentResponseExist  &&   (!isConsentResponseExist.consent_st_api || isConsentResponseExist?.consent_st_api?.api_call_time && !isTodayWithinOneYear(isConsentResponseExist?.consent_st_api?.api_call_time))) &&  <div style={{margin:'1rem'}}>
                                       <Button color='primary' variant='outlined' onClick={()=>consentStatus(values.customer_id)}>Consent Status</Button> 
                                    </div>}    
                                    { isConsentResponseExist?.consent_st_api?.response_data?.result?.consentStatus && isConsentResponseExist.consent_st_api.response_data.result.consentStatus=="ACCEPTED" && (!bankStatement || (bankStatement && ['Collection'].includes(cust.stage)))

                                        && <div style={{margin:'1rem'}}>
                                       <Button color='primary' variant='outlined' onClick={()=>requestBankStatements(values.customer_id)}>Get Bank Statement </Button> 
                                    </div>}
                                    </div>
                                    {bankStatement && <div>
                                        <BankStatementData data={bankStatement} value={values}/>
                                    </div>}

                                </div>
                            </TabPanel>
                        </Form>
                    </fieldset>
                }}
            </Formik>
            }
            {
                console.log('initialvalues', initialValues)
            }
        </div>
    )
}