import React from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { Typography, Paper, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import datehelper from '../../../_helpers/datehelper';
import Save from '@material-ui/icons/Save';
import CourtCaseServices from '../../../_services/courtcase.service';

export const StageForm = (props) => {
    let CourtCase = props.case;
    let stages = CourtCase.stages
    let ndate = new Date()

    const formik = useFormik({
        initialValues:{
            stages:[...stages]
        },
        onSubmit
    })

    const addArray = () => {
        let arr = [...formik.values.stages, { stage: 'LRN Notice', date: new Date(), name: '', claim: null, comment: '' }]
        formik.setFieldValue('stages',arr)
    }

    const removeArray = (e, idx) => {
        let arr = [...formik.values.stages]
        // console.log(idx, arr)
        arr.splice(idx, 1)
        formik.setFieldValue('stages',arr)
    }

     async function onSubmit(){ 
        await CourtCaseServices.UpdateCase(CourtCase._id, { stages: formik.values.stages })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }

    return (
        <div>
            <form onSubmit={(e) => { e.stopPropagation(); formik.handleSubmit(e) }} id='StageForm'>
                {
                    formik.values.stages && formik.values.stages.length > 0 ?
                    formik.values.stages.map((m, idx) => {
                            return <Paper style={{ marginTop: '20px' }} key={m._id ? m._id : m.hearing_date}>
                                <span style={{ marginLeft: '5px', fontSize: 'large' }}>{idx + 1}.</span>
                                <Typography style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} key={m.hearing_date} >
                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Stage
                                        <select name={`stages[${idx}].stage`} style={{ border: '.25px solid grey', height: '28px' }} onChange={formik.handleChange} defaultValue={m.stage} >
                                            <option value="LRN Notice">LRN Notice</option>
                                            <option value="Arbitrator Appoint">Arbitrator Appoint</option>
                                            <option value="Claim & Interim Application">Claim & Interim Application</option>
                                            <option value="1st Notice">1st Notice</option>
                                            <option value="2nd Notice">2nd Notice</option>
                                            <option value="Evidance">Evidance</option>
                                            <option value="Argument">Argument</option>
                                            <option value="Award">Award</option>
                                            <option value="Execution">Execution</option>
                                        </select>
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Date
                                        <input type="date" onKeyDown={(e) => e.preventDefault()} name={`stages[${idx}].date`} style={{ border: '.25px solid grey' }} max={datehelper.getDateInputString(ndate)} onChange={formik.handleChange} defaultValue={datehelper.getDateInputString(new Date(m.date))} required />
                                    </label>

                                    {m && m.stage && m.stage === "Arbitrator Appoint" &&
                                        <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                            Name
                                            <input type="text" name={`stages[${idx}].name`} style={{ border: '.25px solid grey' }} onChange={formik.handleChange} defaultValue={m.name} required/>
                                        </label>}

                                    {m && m.stage && m.stage === 'Claim & Interim Application' &&
                                        <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                            claim
                                            <input type="number" name={`stages[${idx}].claim`} style={{ border: '.25px solid grey' }} defaultValue={m.claim} onChange={formik.handleChange} required/>
                                        </label>}
                                    {m && m.stage && (m.stage !== 'Claim & Interim Application' && m.stage !== "LRN Notice" && m.stage !== "Arbitrator Appoint") &&
                                        <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                            Comment
                                            <input type="text" name={`stages[${idx}].comment`} style={{ border: '.25px solid grey' }} defaultValue={m.comment} onChange={formik.handleChange} required/>
                                        </label>}
                                </Typography>
                                <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button type='button' onClick={(e) => removeArray(e, idx)}><Delete /></Button>
                                </Typography>
                            </Paper>
                        }) :
                        null
                }
                <Paper style={{ width: '63px', margin: 'auto', marginTop: '20px' }}>
                    <Button type='button' onClick={() => { addArray() }}>
                        Add
                    </Button>
                </Paper>
                <hr style={{width:'100%'}} />
                <Paper style={{ width: '63px', height: '63px', margin: 'auto', marginTop: '10px', paddingTop: '12.5px', borderRadius: '70px' }}>
                    <Button type='submit' style={{ color: 'blue' }}>
                        <Save />
                    </Button>
                </Paper>
            </form>
            {
                console.log(formik.values.stages)
            }
        </div>
    )

}