import { Button, Modal, Box } from '@material-ui/core'
import React, { useState } from 'react'
import { Edit, Save } from "@material-ui/icons";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { uploadFileToBucket } from "../../../_helpers/upload"
import { useRef } from 'react';
import * as Yup from "yup";
import { collectionService } from '../../../_services'
export const DepositConfirmModal = (props) => {
    console.log("Test props", props)
    const id = props.data._id
    const slip_number = props.data.slip_number
    const formikRef = useRef(null);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 850,
        height: 460,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflow: "scroll"
    };
    const bucket = process.env.REACT_APP_s3docsBucket
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const initialValues = {
        transaction_number: '',
        bank_name: '',
        branch_name: '',
        date: '',
        slip_photo: '',
        status:"PAID"
    };
    const validationSchema = Yup.object({
        transaction_number: Yup.string().required('Transaction no is required'),
        bank_name: Yup.string().required('Bank Name is required'),
        branch_name: Yup.string().required('Branch is required'),
        date: Yup.date().required('Date is required'),
        // slip_photo: Yup.string().required("This is required")
    });

    const handleImage = async (e, formik) => {
        let image = e.target.files[0];
        let name = image.name.split('.')[0];
        let num1 = Math.floor(Math.random() * 1000);
        let fileName = `${name}_${num1}.jpeg`
        let link = await uploadFileToBucket(bucket, 'image/jpeg', `${bucket}/depositslip/${slip_number}/${fileName}`, image);
        formikRef.current.setFieldValue('slip_photo', link)
    }
    const onSubmit = (values) => {
        if(values.slip_photo)
       {collectionService.confirmSlip(id, values)
            .then((res) => { 
                alert("Confirm Slip !!") 
                window.location.reload()})
            .catch((err) => console.log(err))
        }
        else{
            alert("Please Upload Photo")
        }
    }
    return (
        <div>
            <Button onClick={handleOpen}
                style={{ width: '90px', height: "27px", background: "green", marginTop: '10px', color: "white" }}>Confirm</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        innerRef={formikRef}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, isSubmitting, values, formik }) => {
                            return (
                                <Form>
                                    <h2 style={{ textAlign: 'center', marginBottom: "40px",fontWeight:"bold" }}>Slip</h2>
                                    <div style={{ display: "flex", justifyContent: "space-around", }}>
                                        <div style={{ display: "flex", flexDirection: "column", fontSize:'18px'}}>
                                            <label>Bank Name</label>
                                            <Field
                                                name="bank_name"
                                                placeholder="Enter Bank Name"
                                                className={
                                                    "form-control" +
                                                    (errors.bank_name && touched.bank_name ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="bank_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column",fontSize:'18px'}}>
                                            <label>Branch</label>
                                            <Field
                                                name="branch_name"
                                                placeholder='Enter branch Name'
                                                className={
                                                    "form-control" +
                                                    (errors.branch_name && touched.branch_name ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="branch_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", fontSize:'18px'}}>
                                            <label>Transaction Number</label>
                                            <Field
                                                name="transaction_number"
                                                placeholder="Enter transaction number"
                                                className={
                                                    "form-control" +
                                                    (errors.transaction_number && touched.transaction_number ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="transaction_number"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div style={{ display: "flex", justifyContent: "space-around",fontSize:'18px' }}>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Date</label>
                                            <Field
                                                type="date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                name="date"
                                                className={
                                                    "form-control" +
                                                    (errors.date && touched.date ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="date"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column",fontSize:'18px' }}>
                                            <label>Slip Photo</label>
                                            <input
                                                style={{ width: "260px", height: "38px", fontSize: '20px', }}
                                                // accept="image/*"
                                                accept=".jpg,.jpeg,.png"
                                                type="file"
                                                name="slip_photo"
                                                onChange={(e) => handleImage(e, formik)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'blue', margin: "0 auto", color: 'white', borderRadius: '4px',
                                            width:"90px",height:"40px"
                                        }}><Save />Save</button>
                                </Form>)
                        }}
                    </Formik>
                </Box>
            </Modal>

        </div>
    )
}
