import React from "react";
import { useState, useEffect } from "react";
import { loanService } from "../../../_services";
import { Paper, Button } from "@material-ui/core";
import { sblLetter } from "../../../_services/letter.services";
import { history } from "../../../_helpers";
import "../../../css/common.css"
import discription from "../../../_helpers/discription";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import "./cameLetterForm.css"

const CamLetterForm = (props) => {
  let temp = window.location.pathname.split("/");
  temp = temp[4];
  console.log("temp", temp)

  let id = props.history.location.state;
  console.log("iddd", id)
  console.log(props.history.location.state)

  console.log("props", props);
  const [values, setValues] = useState({
    applicant: "",
    application_id: "",
    age: "",
    relationship: "",
    cibil_score: "",
    income_consider: "",
    property_owner: "",
    sanction_amount: "",
    financial_status: "",
    roi: "",
    tenure: "",
    emi_amount: "",
    security_valuation: "",
    loan_category: "",
    loan_type: "",
    loan_purpose: "",
    credit_buerau_analysis: "",
    customer_income: "",
    property_type: "",
    market_value: "",
    owner_name: "",
    property_address: "",
    customer_description: "",
    relationship1_description: "",
    relationship2_description: "",
    customer_income: "",
    relation_name1: "",
    relation_income1: 0,
    relation_bussiness_name1: "",
    relation_name2: "",
    relation_income2: 0,
    relation_bussiness_name2: "",
    living_expenses: 0,
    other_expenses: 0,
  });

  const [coapplicants, setCoapplicants] = useState([]);
  const [guarantors, setGuarantors] = useState([]);
  const [allCoAppAndGuarantors, setAllCoAppAndGuarantors] = useState([]);
  const [item, setItem] = useState([])

  const [data, setData] = useState();



  const getData = async () => {
    if (temp !== "edit") {
      try {

        let x = await loanService
          .getById(id);

        console.log("x", x);
        setData(x);
        // const customer_description = await discription({ customer_id: x.applicant.customer_id });
        // console.log('customer_description', customer_description)
        // { customer_description && console.log("customer_description", customer_description) }
        setValues({
          applicant: x.applicant && x.applicant.general_information && x.applicant.general_information.name,
          sanction_amount: x.loan && x.loan.sanction_amount,
          roi: x.loan && x.loan.roi,
          tenure: x.loan && x.loan.tenure,
          application_id: x.application_id,
          age: x.applicant.general_information.age,
          relationship: "Self",
          property_type: x.property_details && x.property_details.type,
          market_value: x.property_details && x.property_details.estimated_cost,
          owner_name: x.property_details && x.property_details.owner,
          property_address: x.property_details && x.property_details.address,
          // customer_description: customer_description
        });
        let co_applicant_name = x.co_applicants.map((e) => {
          return { name: e.general_information.name, age: e.general_information.age, customer_id: e.customer_id }
        });
        setCoapplicants(co_applicant_name);

        let guarantor_name = x.guarantors.map((e) => {
          return { name: e.general_information.name, age: e.general_information.age, customer_id: e.customer_id }
        });
        setGuarantors(guarantor_name);
        setAllCoAppAndGuarantors([...co_applicant_name, ...guarantor_name]);

      }
      catch (err) {
        console.log(err);
      }
    } else {
      sblLetter
        .getSingleCam(id)
        .then((x) => {
          console.log(x)
          setValues(x);
          //setCoapplicants(x.coapplicants);
          // setGuarantors(x.guarantors);
          setItem(x.credit_history)

          setAllCoAppAndGuarantors([...x.coapplicants, ...x.guarantors]);
          console.log("console.log application id", x.application_id)
          loanService.getBySearch({ application_id: x.application_id })
            .then((res) => {
              console.log("ressssssss", res[0].co_applicants)

              filterAndAddByNameCoapplicant(x.coapplicants, res[0].co_applicants);
              filterAndAddByNameguarantor(x.guarantors, res[0].guarantors);
            })
            .catch((err) => console.log(err))
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function filterAndAddByNameCoapplicant(array1, array2) {
    console.log("name ", array1, array2)
    const existingObjects = array1.map((item) => ({
      name: item.name,
      age: item.age,
      customer_id: item.customer_id,
    }));
    console.log("existingObjects", existingObjects)
    const filteredObjects = array2.filter((obj) => {
      console.log("obj2", obj)
      const name = obj.general_information.name
      const age = obj.general_information.age
      const customer_id = obj.customer_id

      return !existingObjects.some(
        (item) => item.name === name || item.age === age || item.customer_id === customer_id
      );
    });
    console.log("filteredObjects", filteredObjects)
    filteredObjects.forEach((obj) => {
      const { name, age } = obj.general_information;
      const customer_id = obj.customer_id

      array1.push({ name, customer_id, financial_status: "", cibil_score: "", income_consider: "", relationship: "", property_owner: "", age: "" });
    });
    // console.log("array1",array1)
    setCoapplicants(array1)
  }
  function filterAndAddByNameguarantor(array1, array2) {
    console.log("name ", array1, array2)
    const existingObjects = array1.map((item) => ({
      name: item.name,
      age: item.age,
      customer_id: item.customer_id,
    }));
    console.log("existingObjects", existingObjects)
    const filteredObjects = array2.filter((obj) => {
      console.log("obj2", obj)
      const name = obj.general_information.name
      const age = obj.general_information.age
      const customer_id = obj.customer_id

      return !existingObjects.some(
        (item) => item.name === name || item.age === age || item.customer_id === customer_id
      );
    });
    console.log("filteredObjects", filteredObjects)
    filteredObjects.forEach((obj) => {
      const { name, age } = obj.general_information;
      const customer_id = obj.customer_id

      array1.push({ name, customer_id, financial_status: "", cibil_score: "", income_consider: "", relationship: "", property_owner: "", age: "" });
    });
    // console.log("array1",array1)
    setGuarantors(array1)
  }

  //  console.log("applicant and coapplicant data is",allCoAppAndGuarantors)
  const handlecoapp = (e, index) => {
    const { name, value } = e.target;

    const temp = [...coapplicants];
    temp[index][name] = value;
    console.log(temp);
    setCoapplicants(temp);
  };

  const handleguar = (e, index) => {
    const { name, value } = e.target;

    const temp = [...guarantors];
    temp[index][name] = value;
    setGuarantors(temp);
  };

  const addData = () => {
    setItem([...item, {
      financier_name: "", product: "", loan_start_date: "", loan_amount: "", tenure: "",
      emi: "", outstanding_amount: "", dpd_status: "", obligation_considerd: ""
    }])
  }

  const removeData = (index) => {
    const list = [...item]
    list.splice(index, 1)
    setItem(list)
  };
  const removeCoAppData = (index) => {
    console.log("index is", index)
    const list = [...coapplicants]
    console.log("list before deleting coapplicant", list)
    list.splice(index, 1)
    console.log("list after deleting coapp", list)
    setCoapplicants(list)
  };
  const removeGuarantorData = (index) => {
    console.log("index is", index)
    const list = [...guarantors]
    console.log("list before deleting coapplicant", list)
    list.splice(index, 1)
    console.log("list after deleting coapp", list)
    setGuarantors(list)
  };
  const handleItem = (e, index) => {
    const { name, value } = e.target
    const list = [...item]
    list[index][name] = value;
    setItem(list)
  }


  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  async function handleChangeRelative1(selectedName, customerIDs) {

    try {
      console.log("selectedName:", selectedName);
      console.log("customerID:", customerIDs);

      // const customer_description = await discription({ customer_id: customerIDs });

      // console.log("customer_description:", customer_description);

      setValues({
        ...values,
        relation_name1: selectedName,
        // relationship1_description: customer_description,
      });
    } catch (error) {
      // Handle errors here
      console.error("Error fetching customer description:", error);
      // You can display an error message to the user or perform any other error handling actions.
    }
  };
  async function handleChangeRelative2(selectedName, customerIDs) {

    try {
      console.log("selectedName:", selectedName);
      console.log("customerID:", customerIDs);

      // const customer_description = await discription({ customer_id: customerIDs });

      // console.log("customer_description:", customer_description);

      setValues({
        ...values,
        relation_name2: selectedName,
        // relationship2_description: customer_description,
      });
    } catch (error) {

      console.error("Error fetching customer description:", error);

    }
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    sblLetter
      .createCamLetter({ ...values, application_id: data.application_id, coapplicants, guarantors, credit_history: item })
      .then((data) => {
        alert(" Data Successfully Saved");
        history.push("/home/letterlist/camlist");
      })
      .catch((err) => {
        alert("Something Went Wrong");
      });
  };

  const editData = () => {
    console.log(id)
    sblLetter
      .updateCamLetter({ ...values, coapplicants, guarantors, id: id.id, credit_history: item })
      .then((data) => {
        alert(" Data Successfully Updated");
        history.push("/home/letterlist/camlist");
      })
      .catch((err) => {
        alert("Something Went Wrong");
      });
  };
  console.log("coapplicantssssss", coapplicants)
  return (
    <>
      <div className="cameletter">
        <h1 style={{ textAlign: "center" }}>Cam Letter Details</h1>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Paper
            className="paper"
          >
            <div

            >
              <div className="applicant" >
                <div>
                  <label className="required"  >
                    Applicant</label>
                  <input className="caminput"

                    id="standard-basic" label="Standard"
                    type="text"
                    name="applicant"
                    required
                    value={values.applicant}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <label className="required"  >
                    Status</label>
                  <select
                    className="form-select form-select-sm required caminput"
                    required
                    name="financial_status"
                    value={values.financial_status}
                    aria-label=".form-select-sm example"
                    onChange={(e) => handleChange(e)}
                  >
                    <option selected>N/A</option>
                    <option>Financial</option>
                    <option>Non-Financial</option>
                  </select>
                </div>
                <div>
                  <label className="required" >
                    Age  </label>
                  <input className="caminput"

                    type="number"
                    required
                    value={values.age}
                    name="age"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <label className="required"  >
                    Relationship  </label>
                  <input className="caminput"
                    type="text"
                    required
                    value={values.relationship}
                    name="relationship"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <label className="required"  >
                    Cibil Score  </label>
                  <input className="caminput"
                    type="number"
                    required
                    value={values.cibil_score}
                    name="cibil_score"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <label className="required" >
                    Income Consider                </label>

                  <select
                    className="form-select form-select-sm required caminput"
                    value={values.income_consider}
                    required
                    name="income_consider"
                    aria-label=".form-select-sm example"
                    onChange={(e) => handleChange(e)}
                  >
                    <option selected></option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
                <div>
                  <label className="required"  >
                    Property Owner                </label>

                  <select
                    className="form-select form-select-sm caminput"
                    required
                    value={values.property_owner}
                    name="property_owner"
                    aria-label=".form-select-sm example"
                    onChange={(e) => handleChange(e)}
                  >
                    <option selected></option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
              {coapplicants &&
                coapplicants.map((e, index) => {
                  console.log("ee", e.name, index)
                  return (
                    <div
                      key={index}
                      style={{ display: 'flex' }}
                    >
                      <div className="applicant">
                        <div>
                          <label className="required"  >
                            Co-Applicant                      </label>

                          <input className="caminput"
                            type="text"
                            required
                            name="name"
                            value={e.name}
                            onChange={(e) => handlecoapp(e, index)}
                          /></div>
                        <div>
                          <label className="required"  >
                            Status</label>
                          <select
                            className="form-select form-select-sm required caminput"
                            required
                            name="financial_status"
                            value={e.financial_status}
                            onChange={(e) => handlecoapp(e, index)}
                            aria-label=".form-select-sm example"
                          >
                            <option selected>N/A</option>
                            <option>Financial</option>
                            <option>Non-Financial</option>
                          </select>
                        </div>
                        <div>
                          <label className="required"  >
                            Age                      </label>

                          <input className="caminput"
                            type="number"
                            required
                            name="age"
                            value={e.age}
                            onChange={(e) => handlecoapp(e, index)}
                          />
                        </div>
                        <div>
                          <label className="required"  >
                            Relationship   </label>
                          <select
                            className="form-select form-select-sm required caminput"
                            value={e.relationship}
                            required
                            name="relationship"
                            aria-label=".form-select-sm example"
                            onChange={(e) => handlecoapp(e, index)}
                          >
                            <option selected></option>
                            <option>Father</option>
                            <option>Husband</option>
                            <option>Mother</option>
                            <option>Brother</option>
                            <option>Sister</option>
                            <option>Wife</option>
                            <option>Son</option>
                            <option>Daughter</option>
                            <option>Father-in-law</option>
                            <option>Mother-in-law</option>
                            <option>Brother-in-law</option>
                            <option>Sister-in-law</option>
                            <option>Daughter-in-law</option>
                            <option>Maternal Uncle</option>
                            <option>Maternal Aunty</option>
                            <option>Nephew</option>
                            <option>Niece</option>
                            <option>Friend</option>
                            <option>Grand Father</option>
                            <option>Grand Mother</option>
                            <option>Grand Son</option>
                            <option>Grand Daughter</option>
                            <option>Uncle</option>
                            <option>Aunty</option>
                            <option>Cousin</option>
                            <option>Distant Relative</option>
                            <option>Other</option>
                          </select>
                        </div>
                        <div>
                          <label className="required"  >
                            Cibil Score                      </label>

                          <input className="caminput"
                            type="number"
                            required
                            name="cibil_score"
                            value={e.cibil_score}
                            onChange={(e) => handlecoapp(e, index)}
                          />
                        </div>
                        <div>
                          <label className="required"  >
                            Income Consider                      </label>

                          <select
                            className="form-select form-select-sm required caminput"
                            value={e.income_consider}
                            required
                            name="income_consider"
                            aria-label=".form-select-sm example"
                            onChange={(e) => handlecoapp(e, index)}
                          >
                            <option selected></option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                        <div>
                          <label className="required"  >
                            Property Owner </label>
                          <select
                            className="form-select form-select-sm required caminput"
                            required
                            name="property_owner"
                            value={e.property_owner}
                            aria-label=".form-select-sm example"
                            onChange={(e) => handlecoapp(e, index)}
                          >
                            <option selected></option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>

                        </div>
                      </div>
                      <button style={{
                        border: 'none',
                        background: 'transparent',
                        margin: '0',
                        padding: '0',
                        display: 'initial'
                      }} type='button' onClick={() => removeCoAppData(index)} ><DeleteForeverIcon /></button> <br /> <br /> <br />
                    </div>
                  );
                })}

              {guarantors &&
                guarantors.map((e, index) => {
                  return (
                    <div
                      key={index}
                      style={{ display: 'flex' }}
                    >
                      <div className="applicant">
                        <div>
                          <label className="required" >
                            Guarantor </label>
                          <input className="caminput"
                            type="text"
                            required
                            name="name"
                            value={e.name}
                            onChange={(e) => handleguar(e, index)}
                          />
                        </div>
                        <div>
                          <label className="required"  >
                            Status</label>
                          <select
                            className="form-select form-select-sm required caminput"
                            required
                            name="financial_status"
                            onChange={(e) => handleguar(e, index)}
                            value={e.financial_status}
                            aria-label=".form-select-sm example"
                          >
                            <option selected>N/A</option>
                            <option>Financial</option>
                            <option>Non-Financial</option>
                          </select>
                        </div>
                        <div>
                          <label className="required"  >
                            Age </label>
                          <input className="caminput"
                            type="number"
                            required
                            name="age"
                            value={e.age}
                            onChange={(e) => handleguar(e, index)}
                          />

                        </div>
                        <div>
                          <label className="required"  >
                            Relationship </label>
                          <select
                            className="form-select form-select-sm required caminput"
                            value={e.relationship}
                            required
                            name="relationship"
                            aria-label=".form-select-sm example"
                            onChange={(e) => handleguar(e, index)}
                          >
                            <option selected></option>
                            <option>Father</option>
                            <option>Mother</option>
                            <option>Brother</option>
                            <option>Sister</option>
                            <option>Wife</option>
                            <option>Son</option>
                            <option>Daughter</option>
                            <option>Friend</option>
                            <option>Grand Father</option>
                            <option>Grand Mother</option>
                            <option>Uncle</option>
                            <option>Aunty</option>
                            <option>Cousin</option>
                            <option>Relative</option>
                            <option>Other</option>
                          </select>

                        </div>
                        <div>
                          <label className="required"  >
                            Cibil Score                      </label>

                          <input className="caminput"
                            type="number"
                            required
                            name="cibil_score"
                            value={e.cibil_score}
                            onChange={(e) => handleguar(e, index)}
                          />
                        </div>

                        <div>
                          <label className="required"  >
                            Income Conciderd                      </label>

                          <select
                            className="form-select form-select-sm required caminput"
                            value={e.income_consider}
                            required
                            name="income_consider"
                            aria-label=".form-select-sm example"
                            onChange={(e) => handleguar(e, index)}
                          >
                            <option selected></option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                        <div>
                          <label className="required"  >
                            Property Owner                      </label>

                          <select
                            className="form-select form-select-sm required caminput"
                            required
                            name="property_owner"
                            value={e.property_owner}
                            aria-label=".form-select-sm example"
                            onChange={(e) => handleguar(e, index)}
                          >
                            <option selected></option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                      <button style={{
                        border: 'none',
                        background: 'transparent',
                        margin: '0',
                        padding: '0',
                        display: 'initial'
                      }} type='button' onClick={() => removeGuarantorData(index)} ><DeleteForeverIcon /></button> <br /> <br /> <br />
                    </div>
                  );
                })}
            </div>

          </Paper>
          <br />

          <Paper
            className="paper"
          >
            <div className="form-row" >
              <div className="applicant">
                <div>
                  <label

                    className="required"
                  >

                    Sanction Amount</label>
                  <input className="caminput"

                    name="sanction_amount"
                    type="number"
                    min="1"
                    required
                    value={values.sanction_amount}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >
                    ROI(%)              </label>

                  <input className="caminput"

                    name="roi"
                    type="number"
                    required
                    value={values.roi}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <label

                    className="required"
                  >

                    Tenure              </label>

                  <input className="caminput"
                    name="tenure"
                    type="number"
                    required
                    value={values.tenure}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <label

                    className="required"
                  >

                    Emi Amount              </label>

                  <input className="caminput"

                    name="emi_amount"
                    type="number"
                    required
                    value={values.emi_amount}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="applicant">
                <div>
                  <label

                    className="required"
                  >

                    Security Valuation </label>
                  <input className="caminput"
                    name="security_valuation"
                    type="number"
                    required
                    value={values.security_valuation}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    Loan Category </label>
                  <select
                    className="form-select form-select-sm required caminput"
                    required
                    name="loan_category"
                    value={values.loan_category}
                    onChange={(e) => handleChange(e)}
                    aria-label=".form-select-sm example"
                  >
                    <option selected></option>
                    <option>PSL</option>
                    <option>Non-PSL</option>
                  </select>

                </div><div>
                  <label

                    className="required"
                  >

                    Loan Type  </label>
                  <select
                    className="form-select form-select-sm caminput"
                    required
                    name="loan_type"
                    value={values.loan_type}
                    onChange={(e) => handleChange(e)}
                    aria-label=".form-select-sm example"
                  >
                    <option selected></option>
                    <option>LAP</option>
                    <option>LAP BT</option>
                    <option>Home Loan</option>
                  </select>

                </div><div>
                  <label

                    className="required"
                  >

                    Customer Income </label>
                  <input className="caminput"
                    name="customer_income"
                    type="number"
                    required
                    value={values.customer_income}
                    onChange={(e) => handleChange(e)}
                  />

                </div><div>
                  <label

                    className="required"
                  >

                    Loan Purpose </label>
                  <select
                    className="form-select form-select-sm required caminput"
                    required
                    name="loan_purpose"
                    value={values.loan_purpose}
                    onChange={(e) => handleChange(e)}
                    aria-label=".form-select-sm example"
                  >
                    <option selected></option>
                    <option>Bussiness Expansion</option>
                    <option>Construction For House</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
              <br />
              {values.credit_buerau_analysis && (
                <div>
                  <label

                    className="required"
                  >Credit bureau Analysis: </label>
                  <textarea
                    name="credit_buerau_analysis"
                    type="text"
                    minlength="10"
                    required
                    value={values.credit_buerau_analysis}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
              )}
            </div>

          </Paper>
          <br />
          <Paper className="paper"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              /* padding: 1rem; */
              padding: '1rem 0 1rem 0'
            }}
          >
            <b>Credit History ( All Live Loan Detail to be Captured)</b><br /><br />
            {item && item.map((item, index) => {
              return <div className="applicant" >
                {index + 1}
                <div>
                  <label

                    className="required"
                  >
                    Financier Name </label>
                  <input className="caminput"
                    name="financier_name"
                    type="text"
                    required
                    value={item.financier_name}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    Product </label>
                  <input className="caminput"
                    name="product"
                    type="text"
                    required
                    value={item.product}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    Loan Start Date   </label>
                  <input className="caminput"
                    name="loan_start_date"
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    required
                    value={item.loan_start_date}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    Loan Amount  </label>
                  <input className="caminput"
                    name="loan_amount"
                    type="number"
                    required
                    value={item.loan_amount}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>

                <div>
                  <label

                    className="required"
                  >

                    Tenure </label>
                  <input className="caminput"
                    name="tenure"
                    type="number"
                    required
                    value={item.tenure}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    EMI   </label>
                  <input className="caminput"
                    name="emi"
                    type="number"
                    required
                    value={item.emi}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>

                <div>
                  <label

                    className="required"
                  >

                    Outstanding Amt(In Lacs)</label>
                  <input className="caminput"
                    name="outstanding_amount"
                    type="number"
                    required
                    value={item.outstanding_amount}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    DPD Status - Average DPD and Peak DPD</label>
                  <input className="caminput"
                    name="dpd_status"
                    type="text"
                    required
                    value={item.dpd_status}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>

                <div>
                  <label

                    className="required"
                  >

                    Obligation Considered- Yes/ No </label>
                  <input className="caminput"
                    name="obligation_considerd"
                    type="text"
                    required
                    value={item.obligation_considerd}
                    onChange={(e) => handleItem(e, index)}
                  />

                </div>

                <button type='button' style={{ background: 'red' }} onClick={() => removeData(index)} >Remove</button> <br /> <br /> <br />
              </div>
            })}

            <Button color='primary' variant="contained" onClick={() => addData()} >Add Item</Button>

          </Paper>
          <br />
          <br />
          <Paper
            className="paper"
          >
            <div className="applicant" >
              <div className="applicant">
                <div>
                  <label

                    className="required"
                  >

                    Ist Relative Name </label>
                  {/* <input className="caminput"
                  style={{ width: "200px", marginLeft: "10px" }}
                  name="relation_name1"
                  type="text"
                  required
                  value={values.relation_name1}
                  onChange={(e) => handleChange(e)}
                /> */}
                  <select
                    className="form-select form-select-sm required caminput"
                    value={values.relation_name1}
                    required
                    name="relation_name1"
                    aria-label=".form-select-sm example"
                    onChange={(e) => {
                      const selectedName = e.target.value;
                      const selectedCustomers = allCoAppAndGuarantors.filter((item) => item.name === selectedName);
                      console.log("selectedCustomers", selectedCustomers)
                      const customerIDs = selectedCustomers.map((customer) => customer.customer_id);
                      console.log("customerIDs", customerIDs)
                      handleChangeRelative1(selectedName, customerIDs);
                    }}
                  >
                    <option selected></option>
                    {allCoAppAndGuarantors &&
                      allCoAppAndGuarantors.map((e) => (
                        <option key={e.customer_id}>{e.name}</option>
                      ))}

                  </select>

                </div>
                <div>
                  <label

                    className="required"
                  >

                    Ist Relative Income </label>
                  <input className="caminput"
                    name="relation_income1"
                    type="number"
                    required
                    value={values.relation_income1}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    Ist Relative Bussiness </label>
                  <input className="caminput"
                    name="relation_bussiness_name1"
                    type="text"
                    required
                    value={values.relation_bussiness_name1}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
              </div>
              <br />
              <div className="applicant">
                <div>
                  <label

                    className="required"
                  >

                    2nd Relative Name </label>
                  <select
                    className="form-select form-select-sm required caminput"
                    value={values.relation_name2}
                    required
                    name="relation_name2"
                    aria-label=".form-select-sm example"
                    onChange={(e) => {
                      const selectedName = e.target.value;
                      const selectedCustomers = allCoAppAndGuarantors.filter((item) => item.name === selectedName);
                      console.log("selectedCustomers", selectedCustomers)
                      const customerIDs = selectedCustomers.map((customer) => customer.customer_id);
                      console.log("customerIDs", customerIDs)
                      handleChangeRelative2(selectedName, customerIDs);
                    }}
                  >
                    <option selected></option>
                    {allCoAppAndGuarantors &&
                      allCoAppAndGuarantors.map((e) => (
                        <option key={e.customer_id}>{e.name}</option>
                      ))}

                  </select>

                </div>
                <div>
                  <label

                    className="required"
                  >

                    2nd Relative Income </label>
                  <input className="caminput"
                    name="relation_income2"
                    type="number"
                    required
                    value={values.relation_income2}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    2nd Relative Bussiness </label>
                  <input className="caminput"
                    name="relation_bussiness_name2"
                    type="text"
                    required
                    value={values.relation_bussiness_name2}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
              </div>
              <br />
              <div className="applicant">
                <div>
                  <label

                    className="required"
                  >

                    Living Expenses  </label>
                  <input className="caminput"
                    name="living_expenses"
                    type="number"
                    required
                    value={values.living_expenses}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
                <div>
                  <label

                    className="required"
                  >

                    Other Expenses(Including Existing Emi) </label>
                  <input className="caminput"
                    name="other_expenses"
                    type="number"
                    required
                    value={values.other_expenses}
                    onChange={(e) => handleChange(e)}
                  />

                </div>
              </div>
            </div>
            <br />
          </Paper>
          <br />

          <Paper
            className="paper"
          >
            <div>
              <div className="description">
                <label

                  className="required"
                >

                  Customer Description              </label>

                <textarea
                  name="customer_description"
                  type="text"
                  minlength="10"
                  required
                  value={values.customer_description}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <br />
              <div className="description">
                <label

                  className="required"
                >

                  Relative Description              </label>

                <textarea
                  name="relationship1_description"
                  type="text"
                  minlength="10"
                  required
                  value={values.relationship1_description}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <br />
              <div className="description">
                <label

                  className="required"
                >

                  Relative Description              </label>

                <textarea
                  name="relationship2_description"
                  type="text"
                  minlength="10"
                  required
                  value={values.relationship2_description}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </Paper>
          <br />

          <Paper className="paper"
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '2rem',
              alignItems: 'center'
            }}
          >
            <div className="applicant">
              <div>
                <label

                  className="required"
                >
                  Property Address              </label>

                <textarea
                  name="property_address"
                  type="text"
                  minlength="10"
                  required
                  value={values.property_address}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <label

                  className="required"
                >

                  Owner Name              </label>

                <textarea
                  name="owner_name"
                  type="text"
                  minlength="10"
                  required
                  value={values.owner_name}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <label

                  className="required"
                >

                  Market Value(INR)  </label>
                <textarea
                  name="market_value"
                  type="text"
                  minlength="10"
                  required
                  value={values.market_value}
                  onChange={(e) => handleChange(e)}
                />

              </div>
              <div>
                <label

                  className="required"
                >

                  Property Type   </label>
                <textarea
                  name="property_type"
                  type="text"
                  minlength="10"
                  required
                  value={values.property_type}
                  onChange={(e) => handleChange(e)}
                />

              </div>
            </div>
            <br />
            {temp !== "edit" ? (
              <button
                className="btn btn-primary"
                type="submit"
              >
                Create Form
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => editData()}
              >
                Save Data
              </button>
            )}
          </Paper>
        </form>
      </div>
    </>
  );
};

export default CamLetterForm;
