import React, { useState } from 'react'
import { Paper,Button } from '@material-ui/core'
import AlreadyUplaodDocuments from './AlreadyUplaodDocuments'
import NestedDropdownWithUpload from "./NestedDropdownWithUpload"
import UploadDocuments from './UploadedDocuments'
function AllDocuments(props){
     const cust = props.cust
   return (
    <div>
      <div>
           <Paper style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px', padding: '10px', paddingBottom: '10px', fontSize:'xx-large'  }}>
               All Documents of the loan
           </Paper>
          <NestedDropdownWithUpload cust={cust}/>
          <UploadDocuments cust={cust} />
           <AlreadyUplaodDocuments cust={cust}/>
      </div>
    </div>
  )
}

export default AllDocuments