import React from "react";
import { LoanStages } from "../../../../constants/loanstages";
import datehelper from "../../../../_helpers/datehelper";
import roleChecker from "../../../../_helpers/rolechecker";
import { employeeService, loanService } from "../../../../_services";
import AddDisbursementDetails from "./AddDisbursementDetails";
// import { loanService } from "../../../../_services/loan.service";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DisbursementDetailMemo from "./DisbursementDetailMemo";
import displayhelper from "../../../../_helpers/displayhelper";
import { Button } from "@material-ui/core";
import AddDisbursementPayment from "./AddDisbursementPayment";


const DisbursementMemoList = (props) => {
  console.log('props of disbursement is',props)
  const customer = props.customer;
  const user =employeeService.userValue;


  const confirmVerification = (idx) =>{
    
    let params = [];
    let account_details = [];

    if (customer && customer.disbursement && customer.disbursement.disbursement_accounts) {
        account_details = customer.disbursement.disbursement_accounts;
    } else {
        account_details = [];
    }
    if (customer && customer.disbursement && customer.disbursement.disbursement_details) {
        params = customer.disbursement.disbursement_details;
    } else {
        params = [];
    }
    let disbursement_memo = [];
    let temp ;
    if (customer && customer.disbursement && customer.disbursement.disbursement_memo) {
        disbursement_memo = customer.disbursement.disbursement_memo;
        temp = {...disbursement_memo[idx], verified_by:user.emp_id, verification:true, verified_date: new Date()};
        disbursement_memo[idx] = {...temp}
    } else {
        disbursement_memo = []
    }

    let updateObject = {
      disbursement: {
          disbursement_details: [...params],
          disbursement_accounts: [...account_details],
          disbursement_memo: [...disbursement_memo]
      }
  }

    if(window.confirm("Do you really want to verify the details ?")){
      console.log(updateObject)
      loanService.updateDisbursement(customer._id, updateObject)
      .then((data)=>{
        alert("Verified Successfully")
        window.location.reload();
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  return (
    <div>
      <h2
        style={{
          color: "wheat",
          backgroundColor: "black",
          textAlign: "center",
        }}
      >
        Disbursement Memo List
      </h2>
      <table style={{ margintop: "20px" }}>
        <tr style={{ border: "1px solid" }}>
          <th style={{ border: "1px solid" }}>Customer Name</th>
          <th style={{ border: "1px solid" }}>Bank Account Number</th>
          <th style={{ border: "1px solid" }}>Bank Name</th>
          <th style={{ border: "1px solid" }}>IFSC Code</th>
          <th style={{ border: "1px solid" }}>Payment Amount</th>
          <th style={{ border: "1px solid" }}>Payment Date</th>
          <th style={{ border: "1px solid" }}>Payment Type</th>
          <th style={{ border: "1px solid" }}>Payment Remark</th>
          <th style={{ border: "1px solid" }}>Action</th>
        </tr>
        {customer &&
          customer.disbursement &&
          customer.disbursement.disbursement_memo &&
          customer.disbursement.disbursement_memo.map((e, idx) => {
            return (
              <tr style={{ border: "1px solid" }}>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.customer_name}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.customer_acc_no}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.customer_bank_name && e.customer_bank_name}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.customer_bank_ifsc_code && e.customer_bank_ifsc_code}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.payment_amount && displayhelper.displayNumberAccounting(e.payment_amount)} &nbsp; {e.tranche_percent && `(${e.tranche_percent}%)`}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                {datehelper.displayDate(
                          new Date(e.payment_date)
                        )}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.payment_type && e.payment_type}
                </td>
                <td style={{ border: "1px solid", textAlign: "center" }}>
                  {e.payment_remark && e.payment_remark}
                </td>
                <td> {e.paid_status ? <>Paid <VerifiedUserIcon /> </> : <> {e.verification ? <>{((customer.allcloudcreateddata && customer.allcloudcreateddata.length != 0) || ["Collection"].includes(customer.stage))? <>{roleChecker.hasAccountsAccess(user.role) && <AddDisbursementPayment customer={props.customer} account_data={e} idx={idx} button={" Add Payment"} isEdit={false} />}</> : <h5>First Create AllCloud Loan</h5>}<p style={{color:"green"}} >Verified by Emp Id:- {e.verified_by} on {datehelper.displayDateTime(new Date(e.verified_date))}</p></> :<> <p style={{color:"red"}} >Please verify the details to add payment!!</p> {roleChecker.isHOOpsApproverAccess(user.role) && user.emp_id !== Number(e.added_by) && <Button style={{color:"blue"}} onClick = {()=>confirmVerification(idx)} >Verify</Button>}</>}  <DisbursementDetailMemo cust={customer} idx={idx} account_data={e} button={"Edit"} isEdit={true} /> </>} </td>
                {/* <td>{e.verification ? <p>Verified by {e.verified_by} on {datehelper.displayDateTime(new Date(e.verified_date))}</p>: <p style={{color:"red"}} >Verify the details to add payment</p>}</td> */}
              </tr>
            );
          })}
      </table>
    </div>
  );
};

export default DisbursementMemoList;