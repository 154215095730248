import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, Close } from "@material-ui/icons";

function PreviewModal({ documents = [] }) {
  console.log('documents PreviewModal is', documents)
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false); // Control modal visibility

  const handleNext = () => {
    setCurrentDocIndex((prevIndex) => (prevIndex + 1) % documents.length);
  };

  const handlePrevious = () => {
    setCurrentDocIndex((prevIndex) =>
      prevIndex === 0 ? documents.length - 1 : prevIndex - 1
    );
  };

  const openPreview = () => {
    setIsOpen(true)
    setCurrentDocIndex(0)
  };
  const closePreview = () => setIsOpen(false);

  if (!documents.length) {
    return null; // Prevent rendering if no documents are provided
  }

  const currentDoc = documents[currentDocIndex];

  return (
    <>
      {/* Preview Open Button */}
      <Button variant="contained" color="primary" onClick={openPreview} style={{ float: 'right', marginTop: '1rem', marginBottom: '1rem' }}>
        Preview
      </Button>

      {/* Modal */}
      <Dialog open={isOpen} maxWidth="md"   PaperProps={{
        style: {
          height: "90vh", // Set height of the modal
          minHeight: "60vh",
          width:'100%',
          overflow:'none'
        },
      }} >
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
        <div>
          <DialogTitle style={{ display: "flex", justifyContent: "space-between",padding:'5px' }}>
            {currentDoc?.category || "Document Preview"}
          </DialogTitle>
          {currentDoc.comment && <div style={{ display: "flex", justifyContent: "space-between" }}>
            Comment : {currentDoc.comment }
          </div>}
          </div>
          <IconButton onClick={closePreview} aria-label="Close Preview">
            <Close />
          </IconButton>
        </div>
        <DialogContent style={{ textAlign: "center" }}>
          {currentDoc?.uri?.endsWith(".pdf") ? (
            <embed
              src={currentDoc.uri}
              width="100%"
              height="100%"
              title="PDF Preview"
            />
          ) : (
            <img
              src={currentDoc.uri}
              alt="Document Preview"
              style={{ maxWidth: "100%", maxHeight: "60vh" }}
            />
          )}
        </DialogContent>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={handlePrevious} disabled={currentDocIndex == 0} aria-label="Previous Document">
              <ArrowBack />
            </IconButton>
            <IconButton
              onClick={handleNext}
              disabled={currentDocIndex == documents.length - 1}
              aria-label="Next Document"
              style={{ marginLeft: "10px" }}
            >
              <ArrowForward />
            </IconButton>
          </div>
      </Dialog>
    </>
  );
}

export default PreviewModal;
