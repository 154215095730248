import React, { useEffect, useState } from 'react'
import { Button, Modal, Box } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Add } from '@material-ui/icons';
import { employeeService } from '../../_services';
import * as Yup from "yup";
import { ledgerService } from '../../_services/ledger.service';
const BankDepositModal = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };
    const user = employeeService.userValue
    const [open, setOpen] = useState(false)
    const [bankDetails, setBankDetails] = useState()
    const [ledegerCurrentBalance, setLedgerCurrentBalance] = useState()
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const getBanks = () => {
        ledgerService.getBanks()
            .then((res) => setBankDetails(res))
            .catch((err) => console.log(err))
    }
    const getLedgerCurrectBalance = async () => {
        await ledgerService.getLedgerCurrectBalance()
            .then((res) => setLedgerCurrentBalance(res))
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        getBanks()
        getLedgerCurrectBalance()
    }, [])

    let initialValues = {
        deposit_amount: '',
        bank_name: '',
        account_no: '',
        deposit_date: '',
        branch_id: user.branch.branch_id,
        status: 'Pending',
        requested_by: user.emp_id
    }
    let validationSchema = Yup.object().shape({
        deposit_amount: Yup.number().required('This is Required').min(1, 'Amount must be greater 0').max(ledegerCurrentBalance && ledegerCurrentBalance.current_balance, `Amount must be less than or equal to ${ledegerCurrentBalance && ledegerCurrentBalance.current_balance.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}`),
        bank_name: Yup.string().required('This is Required'),
        account_no: Yup.string().required('This is Required'),
        deposit_date: Yup.date().required('This is Required')
    })
    const onSubmit = (values) => {
        ledgerService.createBankDepositRequest(values)
            .then((res) => {
                alert("Bank Deposit Request Created !!")
                window.location.reload()
            })
            .catch((err) => console.log(err))
    }
    return (
        <div>
            <Button onClick={handleOpen}
                style={{ display: 'flex', float: "right", marginBottom: "15px", background: "#3251F5", color: 'white' }}>
                <Add />Bank Deposit Request</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <h2 style={{ textAlign: "center", marginBottom: "50px" }}>Bank Deposit Request</h2>
                                    <div style={{ display: "flex", justifyContent: "space-around", fontSize: '20px' }}>
                                        <div style={{ display: "flex", flexDirection: "column", color: "blue" }}>
                                            <label>Bank Deposit Amount</label>
                                            <Field
                                                name="deposit_amount"
                                                type="number"
                                                placeholder="Enter Amount"
                                                style={{ width: "250px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.deposit_amount && touched.deposit_amount ? " is-invalid" : "")
                                                } />
                                            <ErrorMessage
                                                name="deposit_amount"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", color: "blue" }}>
                                            <label>Choose Bank</label>
                                            <Field
                                                name="bank_name"
                                                as="select"
                                                style={{ width: "250px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.bank_name && touched.bank_name ? " is-invalid" : "")
                                                }>
                                                <option value="">Chose Bank</option>
                                                {bankDetails.map((x, idx) => <option value={x.bank_name}>{x.bank_name}</option>)}
                                            </Field>
                                            <ErrorMessage
                                                name="bank_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", color: "blue" }}>
                                            <label>Account No</label>
                                            <Field
                                                name="account_no"
                                                as="select"
                                                style={{ width: "250px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.account_no && touched.account_no ? " is-invalid" : "")
                                                }>
                                                <option value="">Chose Bank</option>
                                                {bankDetails.filter(bank => bank.bank_name === values.bank_name).map((x, idx) => <option value={x.account_number}>{x.account_number}</option>)}
                                            </Field>
                                            <ErrorMessage
                                                name="account_no"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ display: "flex", justifyContent: "space-around", fontSize: '20px' }}>
                                        <div style={{ display: "flex", flexDirection: "column", color: "blue" }}>
                                            <label>Branch Id</label>
                                            <Field
                                                name="branch_id"
                                                disabled
                                                style={{ width: "250px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", fontSize: "20px" }}>
                                            <label style={{ color: "blue" }}>Deposit Date</label>
                                            <Field
                                                name="deposit_date"
                                                type="date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                style={{ width: "250px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                                className={
                                                    "form-control" +
                                                    (errors.deposit_date && touched.deposit_date ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="deposit_date"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", fontSize: "20px" }}>
                                            <label style={{ color: "blue" }}>Requested By</label>
                                            <Field
                                                // name="requested_by"
                                                value={user.first_name}
                                                disabled
                                                style={{ width: "250px", height: "37px", border: "2px solid #3251F5", borderRadius: '2px' }}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        style={{
                                            display: 'flex', justifyContent: 'center', background: '#16C007',
                                            width: '120px',
                                            margin: 'auto',
                                            color: "white",
                                            border: "1px solid #16C007",
                                            fontSize: "22px"
                                        }}
                                    >
                                        {isSubmitting && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        Create
                                    </button>
                                </Form>)
                        }}
                    </Formik>
                </Box>
            </Modal>
        </div>
    )
}
export default BankDepositModal;
