import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { enachService } from '../../../_services/enach.service';

const MyPieChart = () => {
  // Define state to store the dynamically fetched value
  const [pieData, setPieData] = useState([
    { name: 'Enach Request Sent', value:0 },
    { name: 'Enach Not Started', value:0 },
    { name: 'Enach In Progress', value:0 },
  ]);

  // Define colors for the chart slices
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const getAllData = () => {
    enachService
      .getNachRequestSent()
      .then((response) => {
        console.log(response, 'ssssssssssss');
        // Update the pieData state with the new value from the API response
        setPieData([
            { name: 'Enach Request Sent', value: response?.requestSentCount },
            { name: 'Enach Not Started', value: response?.notStartedCount },
            { name: 'Enach In Process', value: response?.inProcessCount },
          ])
      })
      .catch((err) => {
        console.log('Data Not Found', err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div style={{ width: '60%', height: 250 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          {/* Pie chart with dynamic data */}
          <Pie
            data={pieData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={100}
            fill="#8884d8"
            label
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          {/* Tooltip */}
          <Tooltip />
          {/* Legend */}
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MyPieChart;
