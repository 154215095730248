import React, { useState, useCallback ,useRef} from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Modal, Box, Typography, MenuItem, FormControl, Select, InputLabel, FormHelperText, TextField } from "@material-ui/core";
import AWS from 'aws-sdk';
import { Formik, Form, Field, ErrorMessage,useFormikContext } from 'formik';
import * as Yup from 'yup';
import { uploadFileToBucket } from '../../../../../_helpers/upload';
import { loanService } from '../../../../../_services';
import { notifyInfo, notifyFailed } from "../../../../../_helpers/notify"
import { ToastContainer } from "react-toastify";

const UploadDocumentsModal = ({cust}) => {
  console.log('cust is',cust)
  const [open, setOpen] = useState(false);
      const bucket = process.env.REACT_APP_s3docsBucket;
 const [spinner,setSpinner] = useState(false)
//  const { setFieldValue } = useFormikContext();
 const fileUrlRef = useRef(null);

  // Categories and Subcategories
  const kycCategories = ['Aadhar Front', 'Aadhar Back', 'PAN', 'Voter ID', 'Driving License', 'Other Documents'];
  const collateralCategories = ['Approval', 'PDC', 'Udyam Aadhar','Property Paper', 'PD Sheet', 'ROI/Otherwaiver Approval','Registered Mortgage Deed', 'Bank Statement', 'Property Paper with PDC',"Pendency"];
const pdPics = ['House PD', 'Work PD']
const PDCApp = ["Applicant","Co-Applicant 1","Co-Applicant 2", "Co-Applicant 3","Guarantor"]
  // AWS S3 Setup
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDrop = useCallback((acceptedFiles) => {
    console.log('acceptedFiles',acceptedFiles)
    handleFileUpload(acceptedFiles[0])
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const handleFileUpload = async (file, setFieldValue) => {
    setSpinner(true);
    
    // let file = e?.target?.files[0];
    if (!file) {
      setSpinner(false);
      alert("No File Selected");
      return;
    }
  
    let name = file.name.split('.')[0];
    let extension = file.name.split('.').pop()?.toLowerCase();
    const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
    const fileType = file.type || 'application/octet-stream';
  
    // Validate file extension and type
    if (!extension || !allowedExtensions.includes(extension)) {
      console.error("Invalid file type. Only PDFs and images are allowed.");
      setSpinner(false);
      alert("Invalid file type. Please upload a PDF or an image file (jpg, jpeg, png).");
      return;
    }
  
    let num1 = Math.floor(Math.random() * 1000);
    let num2 = Math.floor(Math.random() * 100);
    const filePath = `${bucket}/loan/${num1}/Documents/${name}_${num2}.${extension}`;
  
    await uploadFileToBucket(bucket, fileType, filePath, file)
      .then(link => {
        fileUrlRef.current.setFieldValue('url', link);
        setSpinner(false);
      })
      .catch(error => {
        console.error(error);
        setSpinner(false);
      });
  };
  

  // Formik validation schema
  const validationSchema = Yup.object({
    selectedCategory: Yup.string().required('Document category is required'),
    subCategory: Yup.string().when('selectedCategory', {
      is: (selectedCategory) => ['KYC','Collateral','PD Pics','PDC'].includes(selectedCategory) ,
      then: Yup.string().required('Subcategory is required'),
      otherwise: Yup.string(),
    }),
    applicantType: Yup.string().when('selectedCategory', {
      is: 'KYC',
      then: Yup.string().required('Applicant type is required'),
      otherwise: Yup.string(),
    }),
    url: Yup.string().required('URL is required'),
  });
  
  const onSubmit = (values) => {
    setSpinner(true)

     const data = {
      category: `${values.selectedCategory || ''} ${values.applicantType || ''} ${values.subCategory || ''}`.trim(),
      url: values.url,
      comment:values.comment
    };

      loanService
      .updateDocumentsPreview(cust._id, data)
      .then((res) => {
        console.log('Response:', res);
        setSpinner(false)
        notifyInfo(`Document added successfully`);

 
      })
      .catch((err) => {
        console.error('Error:', err);
        notifyFailed('An error occurred while uploading the documents');

        setSpinner(false)
       });
  
    console.log('Final submit data:', data);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen} style={{   marginTop: '1rem', marginBottom: '1rem' }}>
        Upload Documents
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            height: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
            Upload Your Documents
          </Typography>
          <Typography sx={{ mt: 2, mb: 2, color: 'gray' }}>
            Select a category and upload your files.
          </Typography>

          <Formik
          innerRef={fileUrlRef}
            initialValues={{
              selectedCategory: '',
              subCategory: '',
              applicantType: '',
              url: '',
              comment: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => (
              <Form>
              <ToastContainer />
                <div>
                {spinner && (<span className="spinner-border spinner-border-sm"></span>)}

                  <FormControl fullWidth error={touched.selectedCategory && Boolean(errors.selectedCategory)} sx={{ mb: 2 }}>
                    <InputLabel id="category-select-label">Document Category</InputLabel>
                    <Select
                      labelId="category-select-label"
                      name="selectedCategory"
                      value={values.selectedCategory}
                      onChange={(e) => {
                         setFieldValue('selectedCategory', e.target.value);
                      }}
                      label="Document Category"
                    >
                      <MenuItem value="Bank Proof"> Bank Proofs</MenuItem>
                      <MenuItem value="Collateral">Collateral</MenuItem>
                     <MenuItem value="KYC">KYC</MenuItem>
                      <MenuItem value="PDC">PDC</MenuItem>
                      <MenuItem value="PD Pics">PD Pics</MenuItem>
                      <MenuItem value="Property Paper">Property Paper</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    {touched.selectedCategory && errors.selectedCategory && <FormHelperText>{errors.selectedCategory}</FormHelperText>}
                  </FormControl>

                  {/* Applicant Type Dropdown (only for KYC) */}
                  {values.selectedCategory === 'KYC' && (
                    <FormControl fullWidth error={touched.applicantType && Boolean(errors.applicantType)} sx={{ mb: 2 }}>
                      <InputLabel id="applicant-type-select-label">Applicant Type</InputLabel>
                      <Select
                        labelId="applicant-type-select-label"
                        name="applicantType"
                        value={values.applicantType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Applicant Type"
                      >
                        <MenuItem value="applicant">Applicant</MenuItem>
                        <MenuItem value="coapplicant">Coapplicant</MenuItem>
                        <MenuItem value="guarantor">Guarantor</MenuItem>
                      </Select>
                      {touched.applicantType && errors.applicantType && <FormHelperText>{errors.applicantType}</FormHelperText>}
                    </FormControl>
                  )}

                  {/* Conditional Subcategory Dropdown */}
                  {values.selectedCategory === 'KYC' && (
                    <FormControl fullWidth error={touched.subCategory && Boolean(errors.subCategory)} sx={{ mb: 2 }}>
                      <InputLabel id="KYC-subcategory-select-label">KYC Subcategory</InputLabel>
                      <Select
                        labelId="KYC-subcategory-select-label"
                        name="subCategory"
                        value={values.subCategory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="KYC Subcategory"
                      >
                        {kycCategories.map((category, index) => (
                          <MenuItem key={index} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.subCategory && errors.subCategory && <FormHelperText>{errors.subCategory}</FormHelperText>}
                    </FormControl>
                  )}

                  {values.selectedCategory === 'Collateral' && (
                    <FormControl fullWidth error={touched.subCategory && Boolean(errors.subCategory)} sx={{ mb: 2 }}>
                      <InputLabel id="Collateral-subcategory-select-label">Collateral Subcategory</InputLabel>
                      <Select
                        labelId="Collateral-subcategory-select-label"
                        name="subCategory"
                        value={values.subCategory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Collateral Subcategory"
                      >
                        {collateralCategories.map((category, index) => (
                          <MenuItem key={index} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.subCategory && errors.subCategory && <FormHelperText>{errors.subCategory}</FormHelperText>}
                    </FormControl>
                  )}
                  {values.selectedCategory === 'PD Pics' && (
                    <FormControl fullWidth error={touched.subCategory && Boolean(errors.subCategory)} sx={{ mb: 2 }}>
                      <InputLabel id="PD Pics-subcategory-select-label">Collateral Subcategory</InputLabel>
                      <Select
                        labelId="PD Pics-subcategory-select-label"
                        name="subCategory"
                        value={values.subCategory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Collateral Subcategory"
                      >
                        {pdPics.map((category, index) => (
                          <MenuItem key={index} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.subCategory && errors.subCategory && <FormHelperText>{errors.subCategory}</FormHelperText>}
                    </FormControl>
                  )}
                  {values.selectedCategory === 'PDC' && (
                    <FormControl fullWidth error={touched.subCategory && Boolean(errors.subCategory)} sx={{ mb: 2 }}>
                      <InputLabel id="PDC-subcategory-select-label">Collateral Subcategory</InputLabel>
                      <Select
                        labelId="PDC-subcategory-select-label"
                        name="subCategory"
                        value={values.subCategory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="PDC Subcategory"
                      >
                        {PDCApp.map((category, index) => (
                          <MenuItem key={index} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.subCategory && errors.subCategory && <FormHelperText>{errors.subCategory}</FormHelperText>}
                    </FormControl>
                  )}

                  {/* Conditional Subcategory Input for 'Other' Category */}
                  {values.selectedCategory === 'Other' && (
                    <div style={{    marginTop: '1rem'
}}>
                       <TextField
                        name="subCategory"
                         label="subCategory"
                          variant="outlined"
                          fullWidth
                          multiline
                        placeholder="Enter Document Name"
                         value={values.subCategory}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={touched.comment && errors.comment}
                    sx={{ mb: 2 }}
                      />
                      <ErrorMessage name="subCategory" component="div" style={{ color: 'red' }} />
                    </div>
                  )}

 <div style={{    marginTop: '1rem'}}>                   <TextField
                    name="comment"
                    label="Comment"
                    variant="outlined"
                    fullWidth
                    multiline
                     value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={touched.comment && errors.comment}
                    sx={{ mb: 2 ,mt:2}}
                  />
                  </div>
                  {/* File Upload */}
                  <div
                    {...getRootProps()}
                    style={{
                      border: '2px dashed #007bff',
                      borderRadius: '10px',
                      padding: '20px',
                      textAlign: 'center',
                      background: isDragActive ? '#e6f7ff' : '#f9f9f9',
                      cursor: 'pointer',
                      marginTop: '20px',
                    }}
                  >
                    <input
                      {...getInputProps()}
                      onChange={(e) => {if(!e.target){
                        alert('No File Selected')
                      }else{

                       handleFileUpload(e.target.files[0], setFieldValue)
                      }
                       }}
                    />
                    {isDragActive ? <p>Drop the files here...</p> : <p>Drag & drop files here, or click to select files</p>}
                    {touched.subCategory && errors.subCategory && <FormHelperText>{errors.subCategory}</FormHelperText>}
                  </div>
                 {values.url && <div>
                      <a href={values.url} target="_blank">View Uploaded File</a>
                  </div>}

                  {/* Submit Button */}
                  <Button variant="contained" color="primary" disabled={spinner} type="submit" style={{ marginTop: '20px' }}>
                    Upload Files
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          {/* Close Button */}
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default UploadDocumentsModal;
