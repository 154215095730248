import React, { useState,useEffect } from 'react'
 import { employeeService } from '../../../_services';
import { Button } from '@material-ui/core';
 import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loanService } from '../../../_services';
import { uploadFileToBucket } from '../../../_helpers/upload';
 import { Edit } from "@material-ui/icons";
import { Modal, Box, Typography } from "@material-ui/core";
 
const ValuationAssignment = (props) => {

  const [providerList,setProviderList]=useState([])
const [open,setOpen]=useState(false)

//  console.log('props is ',props)
   let objId =props.val._id
  let valuation_data = props.val
     const user = employeeService.userValue;
    const bucket = process.env.REACT_APP_s3docsBucket

     const validationSchema = yup.object({
  provider: yup.string().required('Provider is required'),
  valuation_date: yup.date().required('Valuation date is required'),
  valuation_amount: yup.number().required('Valuation amount is required'),
  valuation_charges: yup.number().required('Valuation charges is required'),
  valuation_report_doc: yup.string().required('Valuation report PDF is required'),
  property_type: yup.string().required('property type is required'),
  plotHouseFlatnumber:yup.string().required('Plot/House/Flat number is required'),
  srvyMncplNumber:yup.string().required('Survey/Municipal/Patta number is required'),  // Patta number
  landSize:yup.string().required('Property size(should be in Square Feet) is required'),
  pincode:yup.string().required('property pincode is required'),
  panchayatSamiti_tehsil:yup.string().required('property tehsil is required'),
  district:yup.string().required('property district is required'),
  stateName:yup.string().required('property state name is required'),
  propertyAddress:yup.string().required('property address is required')
});

   
  const initialValues = {
    provider: '',
    valuation_date: '',
    valuation_amount: '',
    valuation_charges:'',
    valuation_report_doc: '',
    property_type:'',
    property_distress_value:'',
    lat:'',
    long:'',
    plotHouseFlatnumber:'',
    srvyMncplNumber:'',  // Patta number
    landSize:'',
    pincode:'',
    panchayatSamiti_tehsil:'',
    district:'',
    stateName:'',
    propertyAddress:''

  }
  if(valuation_data){

    const date = valuation_data?.valuation_report?.valuation_date
    const formattedDate = date ? (new Date(date).toISOString().split('T')[0]) : '' ;
    initialValues.provider = valuation_data?.valuation_report?.provider || '';
    initialValues.valuation_date = formattedDate;
    initialValues.valuation_amount = valuation_data?.valuation_report?.valuation_amount || '';
    initialValues.valuation_charges = valuation_data?.valuation_report?.valuation_charges || '';
    initialValues.valuation_report_doc = valuation_data?.valuation_report?.valuation_report_doc || '';
    initialValues.property_type = valuation_data?.valuation_report?.property_type || '';
    initialValues.property_distress_value = valuation_data?.valuation_report?.property_distress_value || '';
    initialValues.lat = valuation_data?.valuation_report?.lat || '';
    initialValues.long = valuation_data?.valuation_report?.long || '';
    initialValues.plotHouseFlatnumber = valuation_data?.valuation_report?.plotHouseFlatnumber || '';
    initialValues.srvyMncplNumber = valuation_data?.valuation_report?.srvyMncplNumber || '';
    initialValues.landSize = valuation_data?.valuation_report?.landSize || '';
    initialValues.pincode = valuation_data?.valuation_report?.pincode || '';
    initialValues.panchayatSamiti_tehsil = valuation_data?.valuation_report?.panchayatSamiti_tehsil || '';
    initialValues.district = valuation_data?.valuation_report?.district || '';
    initialValues.stateName = valuation_data?.valuation_report?.stateName || '';
    initialValues.propertyAddress = valuation_data?.valuation_report?.propertyAddress || '';
  }


       

   
  
    const onSubmit = async (values) => {
      console.log("Submitting form values:", values);
    
      try {
        // Your asynchronous operations here
        await loanService.valuationReport(objId,{ values,"type":"Valuation" });
        // console.log("Submission successful");
        window.alert('data added successfully')

        window.location.reload()
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };
    
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 800,
      height: 600,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflow: 'scroll',
      //paddingBottom: '30px',
  };

 
    const propertyTypes = [
      'Commercial Properties',
      'Industrial Properties',
      'Mix Used',
      'Vacant',
      'Residential Properties',
      'Shop',
      'Open Plot',
      'Rented Residencial Properties',
      'Rented Commercial Properties'

      // Add more property types as needed
    ];
  const handleValuationReport = async (e, formik,setFieldValue,values) => {
    let file = e.target.files[0];
    let name = file.name.split('.')[0];
    let num1 = Math.floor(Math.random() * 1000);
    let num2 = Math.floor(Math.random() * 100);
    let fileName = `${name}_${num1}${num2}.pdf`
   await uploadFileToBucket(bucket, 'application/pdf', `${bucket}/loan/valuation_report/${objId}/${fileName}`, file)
    .then(link => {
      // console.log('link',link)
      setFieldValue('valuation_report_doc', link);
      //  console.log(values)
    })
    .catch(error => {
      console.error(error);
    });
}

const verifyValuationReport=(id)=>{
  // console.log("verified")
  loanService.verifyValuationReport({id:id,user:user})  .then((data) => {
      // console.log('valuation report data is',data)
      window.location.reload();
      // setLetters(data)
  })
  .catch((err) => {
      console.log(err)
  })
}
const resetValuationReport=(id)=>{
  // console.log("reset",id)
  loanService.resetValuationReport({id:id,user:user}).then((data) => {
      // console.log('reset report data is',data)
      window.location.reload();
      // setLetters(data)
  })
  .catch((err) => {
      console.log(err)
  })
}

const handleClose=()=>{
  setOpen(false)
}

  return (
    <div>
   

            <Button onClick={()=>{setOpen(true) }}><Edit/></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
  <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue,formik }) => (
         
        <Form>
        <div  style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',flexWrap:'wrap',gap:'1rem'}}>

          <div className="form-group col-md-5">
            <h6 className="required">Provider Name</h6>
            <Field
              label="provider"
              type="text"
              name="provider"
              className={
                'form-control' +
                (errors.provider && touched.provider ? ' is-invalid' : '')
              }
              disabled
            />
           
           
            
            <ErrorMessage
              name="provider"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Valuation Date</h6>
            <Field
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              name="valuation_date"
              className={
                'form-control' +
                (errors && errors.valuation_date && touched && touched.valuation_date
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="valuation_date"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Valuation Amount</h6>
            <Field
              type="number"
              name="valuation_amount"
              className={
                'form-control' +
                (errors.valuation_amount && touched.valuation_amount
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="valuation_amount"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group col-md-5">
            <h6 className="required">Distress value</h6>
            <Field
              type="number"
              name="property_distress_value"
              className={
                'form-control' +
                (errors.property_distress_value && touched.property_distress_value
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="property_distress_value"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group col-md-5">
            <h6  >Latitude</h6>
            <Field
              type="text"
              name="lat"
              className={
                'form-control' +
                (errors.lat && touched.lat
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="lat"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group col-md-5">
            <h6  >Longitude</h6>
            <Field
              type="text"
              name="long"
              className={
                'form-control' +
                (errors.long && touched.long
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="long"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group col-md-5">
            <h6 className="required">Plot/House/Flat number(*If not please Enter "NA")</h6>
            <Field
              type="text"
              name="plotHouseFlatnumber"
              className={
                'form-control' +
                (errors.plotHouseFlatnumber && touched.plotHouseFlatnumber
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="plotHouseFlatnumber"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Survey/Municipal/Patta Number</h6>
            <Field
              type="text"
              name="srvyMncplNumber"
              className={
                'form-control' +
                (errors.srvyMncplNumber && touched.srvyMncplNumber
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="srvyMncplNumber"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Property size(Should be in Square Feet)</h6>
            <Field
              type="text"
              name="landSize"
              className={
                'form-control' +
                (errors.landSize && touched.landSize
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="landSize"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Property Area Pincode</h6>
            <Field
              type="text"
              name="pincode"
              className={
                'form-control' +
                (errors.pincode && touched.pincode
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="pincode"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Tehsil Name</h6>
            <Field
              type="text"
              name="panchayatSamiti_tehsil"
              className={
                'form-control' +
                (errors.panchayatSamiti_tehsil && touched.panchayatSamiti_tehsil
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="panchayatSamiti_tehsil"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">District Name</h6>
            <Field
              type="text"
              name="district"
              className={
                'form-control' +
                (errors.district && touched.district
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="district"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">State Name</h6>
            <Field
              type="text"
              name="stateName"
              className={
                'form-control' +
                (errors.stateName && touched.stateName
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="stateName"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5">
            <h6 className="required">Property Address</h6>
            <Field
              type="text"
              name="propertyAddress"
              className={
                'form-control' +
                (errors.propertyAddress && touched.propertyAddress
                  ? ' is-invalid'
                  : '')
              }
            /> 
            <ErrorMessage
              name="propertyAddress"
              component="div"
              className="invalid-feedback"
            />
          </div>
          {/* <div className="form-group col-md-5">
            <h6 className="required">Valuation Charges</h6>
            <Field
              type="number"
              name="valuation_charges"
              disabled
              className={
                'form-control' +
                (errors.valuation_charges && touched.valuation_charges
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="valuation_charges"
              component="div"
              className="invalid-feedback"
            />
          </div> */}
          <div className="form-group col-md-5">
  <h6 className="required">Property Type</h6>
  <Field
    as="select"
    name="property_type"
    className={
      'form-control' +
      (errors.property_type && touched.property_type
        ? ' is-invalid'
        : '')
    }
  >
    <option value="">Select Property Type</option>
    {/* Map the list of property types here */}
    {propertyTypes.map((type, idx) => (
      <option value={type} key={idx}>
        {type}
      </option>
    ))}
  </Field>
  <ErrorMessage
    name="property_type"
    component="div"
    className="invalid-feedback"
  />
</div>

          <div className="form-group col-md-5">
            <h6 className="required">Valuation Report PDF</h6>
            <input
              type="file"
              name="valuation_report_doc"
              accept=".pdf"
              onChange={(e) => handleValuationReport(e, formik,setFieldValue,values)}
              className={
                'form-control-file' +
                (errors && errors.valuation_report_doc && touched && touched.valuation_report_doc
                  ? ' is-invalid'
                  : '')
              }
            />
            <ErrorMessage
              name="valuation_report"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group col-md-5" style={{display:'flex',justifyContent:'center'}}>

          <Button type='submit'  color='primary' variant='contained' disabled={isSubmitting}>Submit</Button>
          </div> 
        </div>
        </Form>
       
      )}
    </Formik>
    </Typography>
                </Box>
            </Modal>
  </div>
  )
}

export default ValuationAssignment