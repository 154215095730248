import React from "react";
import {
  useFormikContext,
  Formik,
  Field,
  Form,
  ErrorMessage,
  getIn,
} from "formik";
import * as Yup from "yup";
import JobSheet from "./JobSheetCSB/JobSheet";
import Webcam from "react-webcam";
import { Button, Paper, Typography } from "@material-ui/core";
import { kaabilService } from "../../_services/kaabil.service";
import { uploadFile } from "../../_helpers/upload";
import { branchService } from "../../_services/branch.service";
import { misService } from "../../_services";
import { employeeService } from "../../_services";

export default function PledgeForm(props) {
  const user = employeeService.userValue;
  const [branches, setBranches] = React.useState([]);
  const [loanno, setLoanno] = React.useState([]);
  const query = new URLSearchParams(props.location.search);
  const renewOf=(query.get("renew_of")?query.get("renew_of"):"");
  const [loanDetails,setLoanDetails]=React.useState(null);
  const net_due_amount=(query.get("net_due_amount")?query.get("net_due_amount"):"")
  console.log("Test  Net Due amount",net_due_amount)
  // const { id } = props.match.params;
  // const isAddMode = !id;

  let disabledInput=true;
  let insurance_amount=300
  const formikRef = React.useRef();

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  React.useEffect(() => {
    misService.getLoanNo(user.branch_id).then((result) => {
      setLoanno(result.loan_no);
    });
    branchService.getAll().then((branches) => {
      setBranches(branches);
    });
  
  }, []);

  React.useEffect(()=>{
    if(renewOf)
    {
     misService.getLoanByAccount(renewOf).then((tmpLDList)=> {
       console.log(tmpLDList);
       let tmpLD=tmpLDList[0];
       setLoanDetails(tmpLD);
       if(formikRef.current)
       {
          tmpLD.sonof && formikRef.current.setFieldValue("sonof",tmpLD.sonof);
          tmpLD.gold_packet && formikRef.current.setFieldValue("gold_packet",tmpLD.gold_packet);
          tmpLD.name && formikRef.current.setFieldValue("name",tmpLD.name);
          tmpLD.address && formikRef.current.setFieldValue("address",tmpLD.address);
          tmpLD.kyc && formikRef.current.setFieldValue("kyc",tmpLD.kyc);
          tmpLD.details.gold_pic && formikRef.current.setFieldValue("gold_pic",tmpLD.details.gold_pic);
          
       }
     })
     .catch((err)=> {
       console.log("error getting loan details");
     })
    }
  },[renewOf]);


  const initialValues = {
    date: new Date(),
    name: "",
    sonof: "",
    address: "",
    loan_request: "",
    period: "",
    gold_approx: "",
    purpose: "",
    gold: {
      ornaments: [],
      gross_weight: "",
      net_weight: "",
      total_items: "",
      deduction: "",
      valuation: "",
      eligible_advance: "",
      rpg: "",
    },
    customer_pic: "",
    gold_pic: "",
    loan_account: "",
    loan_amount: "",
    payment_frequency: "EVERY_30_DAYS",
    disburse_amount: "",
    emi: "",
    closure_due_date: "",
    emi_due_date: new Date().addDays(29).toISOString().substring(0, 10),
    contact: "",
    bank_name: "",
    account_no: "",
    kyc: "Adhaar",
    gold_packet: "",
    total_items: "",
    roi: "",
    // stamp_charge: 0.3,
    // pf_charge: 0.5,
    pf: "",
    auction_date: "",
    branch_name: user.branch.branch_name,
    approved: false,
    bank: "Kaabil",
    branch_code: user.branch_id,
    renew_of:renewOf,
    payment_details: [{
      payment_amount:net_due_amount,
      payment_date:new Date().toISOString().slice(0, 10),
      payment_mode:"Adjustment",
      payment_type: "disbursement",
      payment_ref:`renewed_of-${renewOf}`,
    }]
  };

  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };

  const custcamRef = React.useRef(null);
  const goldcamRef = React.useRef(null);

  const capture = React.useCallback(
    async (setFieldValue, target, targetcam, loan_no) => {
      if (!loan_no) {
        alert("Please Enter Loan Number");
        return;
      }

      var imageSrc;

      if (targetcam === "cust") imageSrc = custcamRef.current.getScreenshot();
      else imageSrc = goldcamRef.current.getScreenshot();

      const link = await uploadFile(`${loan_no}/${target}.jpg`, imageSrc);

      setFieldValue(target, link);
    },
    [custcamRef, goldcamRef]
  );

  const discard = React.useCallback((setFieldValue, target) => {
    setFieldValue(target, "");
  }, []);

  const validationSchema = Yup.object().shape({
    auction_date: Yup.date().required("This field is required"),
    // pf: Yup.number().required("This field is required"),
    pf: Yup.number().required("This field is required"),
    // stamp_charge: Yup.number(),
    // pf_charge: Yup.number(),
    roi: Yup.number().required("This field is required"),
    gold: Yup.object().shape({
      rpg: Yup.number().required("Required"),
    }),
    gold_packet: Yup.string().required("This field is required")
        .min(8,"Gold pocket must be 8 letters long")
        .max(8,"Gold pocket must be 8 letters long"),
    kyc: Yup.string().required("This field is required"),
    contact: Yup.string().required("This field is required"),
    name: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    loan_request: Yup.number().required("This field is required"),
    period: Yup.number().required("This field is required"),
    gold_approx: Yup.number(),
    purpose: Yup.string().required("This field is required"),
    loan_account: Yup.string().required("This field is required"),
    sonof: Yup.string().required("This field is required"),
    payment_frequency: Yup.string().required("This field is required"),
    disburse_amount: Yup.number().required("This field is required"),
    loan_amount: Yup.number().required("This field is required"),
    emi: Yup.number().required("This field is required"),
    emi_due_date: Yup.date().required("This field is required"),
    closure_due_date: Yup.date().required("This field is required"),
    branch_name: Yup.string().required("This field is required"),
  });

  // async function handleCharges(vals, setFieldValue) {
  //   setFieldValue(
  //     "stamp_duty",
  //     Math.round(((vals.loan_amount * vals.stamp_charge) / 100) * 1.3)
  //   );
  //   setFieldValue(
  //     "pf",
  //     Math.round(((vals.loan_amount * vals.pf_charge) / 100) * 1.18)
  //   );
  //   setFieldValue(
  //     "disburse_amount",
  //     Math.round(
  //       vals.loan_amount -
  //         ((vals.loan_amount * vals.stamp_charge) / 100) * 1.3 -
  //         ((vals.loan_amount * vals.pf_charge) / 100) * 1.18
  //     )
  //   );
  // }

  function updateDisburse(vals, setFieldValue) {
    setFieldValue(
      "disburse_amount",
      Math.round(vals.loan_amount - vals.stamp_duty)
    );
  }

  function setGoldPacket(vals, setFieldValue) {
    setFieldValue("gold_packet", vals.gold_packet);
  }

  function setCharges(vals, setFieldValue) {
    // setFieldValue(
    //   "stamp_duty",
    //   Math.round(((vals.loan_amount * vals.stamp_charge) / 100) * 1.3)
    // );

    const pf = Math.round(vals.loan_amount*.0035);
    setFieldValue("pf", pf);

    setFieldValue("disburse_amount", Math.round(vals.loan_amount - pf));
    let emi = Math.round(vals.loan_amount*vals.roi/100);
    setFieldValue('emi',emi);
  }

  function setAuctionClosure(vals, setFieldValue) {
    setFieldValue(
      "auction_date",
      new Date(new Date().setMonth(new Date().getMonth() + vals.period))
        .toISOString()
        .substring(0, 10)
    );
    setFieldValue(
      "closure_due_date",
      new Date(new Date().setMonth(new Date().getMonth() + vals.period))
        .toISOString()
        .substring(0, 10)
    );
  }

  async function onSubmit(fields, { setSubmitting }) {
    if (fields.gold.ornaments.length === 0) alert("Please Add Gold Items");
    else if (!fields.customer_pic) alert("Please Upload Customer Picture");
    else if (!fields.gold_pic) alert("Please Upload Ornaments Picture");
    else if(fields.loan_amount<(parseInt(net_due_amount)+insurance_amount+fields.pf)) alert(`Loan amount must be more than ${insurance_amount+parseInt(net_due_amount)+fields.pf}`)
    else {
    
      const sendObject = {
        ...fields,
        details: fields,
        total_items: fields.gold.total_items,
        valuation: fields.gold.valuation,
        net_weight: fields.gold.net_weight,
        deduction: fields.gold.deduction,
        ornaments: fields.gold.ornaments,
        gross_weight: fields.gold.gross_weight,
        rpg: fields.gold.rpg,
        loan_status:'Disbursed',
        net_due_amount:net_due_amount,
        loan_details_id:loanDetails&&loanDetails._id,
        bank_details:(loanDetails && loanDetails.bank_details)?loanDetails.bank_details:null
      };
      console.log(sendObject);
      console.log(loanDetails);
    
      await kaabilService
        .create(sendObject)
        .then((lo) => {
          props.history.push("/home/pledge/print", sendObject);
        })
        .catch((err) => {
          console.log(err);
          alert("An Error Occured. Please Try Again.");
        });
      setSubmitting(false);
    }
  }

  React.useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "loan_account",
        "KFPLGL" +
          user.branch_id.slice(0, -3) +
          loanno.toString().padStart(5, "0")
      );
    }
  }, [loanno]);

  const SetValuationDependent = () => {
    const { values, setFieldValue } = useFormikContext();
    React.useEffect(() => {
      setFieldValue("gold_approx", Math.round(values.gold.valuation));
      setFieldValue("loan_amount", Math.round(values.gold.valuation));
    }, [values.gold.valuation]);
    return null;
  };

  const SetLoanDependent = () => {
    const { values, setFieldValue } = useFormikContext();
    React.useEffect(() => {
      setFieldValue("loan_request", Math.round(values.loan_amount));
      setCharges(values, setFieldValue);
    }, [values.loan_amount, values.roi]);
    return null;
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue }) => (
        <Form>
          <SetValuationDependent />
          <SetLoanDependent />
          <h2 className="card-header">Submit Details</h2>
          <div className="card-body">
            <div className="form-group col-md-12">
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <div className="d-flex justify-content-between">
                  <Typography variant="h5">Personal Details</Typography>
                  <div className="form-group col-md-3">
                    <Field
                      placeholder="Loan Number"
                      name="loan_account"
                      type="text"
                      className={
                        "form-control" +
                        (errors.loan_account && touched.loan_account
                          ? " is-invalid"
                          : "")
                        }
                      disabled={disabledInput}
                    />
                    <ErrorMessage
                      name="loan_account"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <div className="form-group col-md-3">
                    <label>Name</label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Son/Daughter of</label>
                    <Field
                      name="sonof"
                      type="text"
                      className={
                        "form-control" +
                        (errors.sonof && touched.sonof ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="sonof"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Address</label>
                    <Field
                      name="address"
                      type="text"
                      className={
                        "form-control" +
                        (errors.address && touched.address ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group col-md-3">
                    <label>Contact</label>
                    <Field
                      name="contact"
                      type="text"
                      className={
                        "form-control" +
                        (errors.contact && touched.contact ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="contact"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Bank Name</label>
                    <Field
                      name="bank_name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.bank_name && touched.bank_name
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="bank_name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Account No.</label>
                    <Field
                      name="account_no"
                      type="text"
                      className={
                        "form-control" +
                        (errors.account_no && touched.account_no
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="account_no"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>KYC</label>
                    <Field
                      name="kyc"
                      as="select"
                      className={
                        "form-control" +
                        (errors.kyc && touched.kyc ? " is-invalid" : "")
                      }
                    >
                      <option value="Aadhar">Aadhar</option>
                      <option value="PAN">PAN</option>
                      <option value="Other">Other</option>
                    </Field>
                    <ErrorMessage
                      name="kyc"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </Paper>
            </div>
            <div className="form-group col-md-12">
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <Typography variant="h5">Loan Details</Typography>
                <br />
                <div className="d-flex justify-content-around">
                  <div className="form-group col-md-3">
                    <label>Loan Period (Months)</label>
                    <Field
                      name="period"
                      as='select'
                      min="0"
                      className={
                        "form-control" +
                        (errors.period && touched.period ? " is-invalid" : "")
                      }
                      onKeyUp={() => setAuctionClosure(values, setFieldValue)}
                    >
                      <option value=''>
                                </option>
                                <option value='3'>
                                  3
                                </option>
                                <option value='6'>
                                  6
                                </option>
                                <option value='9'>
                                  9
                                </option>
                                <option value='12'>
                                  12
                                </option>
                              </Field>
                    <ErrorMessage
                      name="period"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Purpose</label>
                    <Field
                      name="purpose"
                      type="text"
                      className={
                        "form-control" +
                        (errors.purpose && touched.purpose ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="purpose"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Branch Name</label>
                    <Field
                      name="branch_name"
                      as="select"
                      className={
                        "form-control" +
                        (errors.branch_name && touched.branch_name
                          ? " is-invalid"
                          : "")
                      }
                    >
                      {branches.map((branch) => (
                        <option
                          key={branch.branch_id}
                          value={branch.branch_name}
                        >
                          {branch.branch_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="branch_name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </Paper>
            </div>

            <br />
            <div className="form-group col-md-12">
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <Typography variant="h5">Gold Details</Typography>
                <br />
                <div style={{ border: "1px dotted black", padding: 15 }}>
                  <JobSheet setFieldValue={setFieldValue}></JobSheet>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="d-flex justify-content-around">
                  <div className="form-group col-md-3">
                    <label>RPG (Rate Per Gram)</label>
                    <Field
                      step="any"
                      name="gold.rpg"
                      type="number"
                      className={
                        "form-control" +
                        (getIn(errors, "gold.rpg") && getIn(touched, "gold.rpg")
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="gold.rpg"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Gold Packet Number</label>
                    <Field
                      name="gold_packet"
                      type="text"
                      className={
                        "form-control" +
                        (errors.gold_packet && touched.gold_packet
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="gold_packet"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </Paper>
            </div>
            <br />
            <div className="form-group col-md-12">
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <div>
                  <Typography variant="h5">
                    Disbursement Details
                    <div
                      className="d-flex justify-content-end"
                      style={{ float: "right", width: "50%" }}
                    >
                      {/* <div
                        style={{ width: 150, marginRight: "10%" }}
                        className="d-flex"
                      >
                        <label style={{ fontSize: 15 }}>Stamp Charge(%)</label>
                        <div>
                          <Field
                            name="stamp_charge"
                            type="text"
                            className={
                              "form-control" +
                              (errors.stamp_charge && touched.stamp_charge
                                ? " is-invalid"
                                : "")
                            }
                            onKeyUp={() => handleCharges(values, setFieldValue)}
                          />
                        </div>
                      </div> */}
                      {/* <div style={{ width: 150 }} className="d-flex">
                        <label style={{ fontSize: 15 }}>PF Charge(%)</label>
                        <div>
                          <Field
                            name="pf_charge"
                            type="text"
                            className={
                              "form-control" +
                              (errors.pf_charge && touched.pf_charge
                                ? " is-invalid"
                                : "")
                            }
                            onKeyUp={() => handleCharges(values, setFieldValue)}
                          />
                        </div>
                      </div> */}
                    </div>
                  </Typography>
                </div>
                <br />

                <div className="d-flex justify-content-around">
                  <div className="form-group col-md-3">
                    <label>Interest/EMI Amount (₹)</label>
                    <Field
                      name="emi"
                      type="text"
                      className={
                        "form-control" +
                        (errors.emi && touched.emi ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="emi"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Sanctioned Loan Amount (₹)</label>
                    <Field
                      name="loan_amount"
                      type="number"
                      className={
                        "form-control" +
                        (errors.loan_amount && touched.loan_amount
                          ? " is-invalid"
                          : "")
                      }
                      onKeyUp={() => setCharges(values, setFieldValue)}
                    />
                    <ErrorMessage
                      name="loan_amount"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  {/* <div className="form-group col-md-3">
                    <label>Total PF (₹)</label>
                    <Field
                      name="pf"
                      type="text"
                      className={
                        "form-control" +
                        (errors.pf && touched.pf ? " is-invalid" : "")
                      }
                      onKeyUp={() => updateDisburse(values, setFieldValue)}
                    />
                    <ErrorMessage
                      name="pf"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div> */}
                  <div className="form-group col-md-3">
                    <label>Disbursement Amount (₹)</label>
                    <Field
                      name="disburse_amount"
                      type="text"
                      className={
                        "form-control" +
                        (errors.disburse_amount && touched.disburse_amount
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="disburse_amount"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="form-group col-md-3">
                    <label>Interest Payment Frequency</label>
                    <Field
                      name="payment_frequency"
                      type="text"
                      className={
                        "form-control" +
                        (errors.payment_frequency && touched.payment_frequency
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="payment_frequency"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Total PF (₹)</label>
                    <Field
                      name="pf"
                      type="number"
                      className={
                        "form-control" +
                        (errors.pf && touched.pf
                          ? " is-invalid"
                          : "")
                      }
                      onKeyUp={() => updateDisburse(values, setFieldValue)}
                    />
                    <ErrorMessage
                      name="pf"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Rate of Interest (%)</label>
                    <Field
                      name="roi"
                      type="number"
                      className={
                        "form-control" +
                        (errors.roi && touched.roi ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="roi"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="form-group col-md-3">
                    <label>Auction Date</label>
                    <Field
                      name="auction_date"
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      className={
                        "form-control" +
                        (errors.auction_date && touched.auction_date
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="auction_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>EMI/Interest Due date</label>
                    <Field
                      name="emi_due_date"
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      className={
                        "form-control" +
                        (errors.emi_due_date && touched.emi_due_date
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="emi_due_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Closure Due Date</label>
                    <Field
                      name="closure_due_date"
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      className={
                        "form-control" +
                        (errors.closure_due_date && touched.closure_due_date
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="closure_due_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </Paper>
            </div>

            <div className="form-group col-md-12">
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <Typography variant="h5">Capture Images</Typography>
                <br />
                <div className="d-flex justify-content-around">
                  <div>
                    <Typography style={{ textAlign: "center" }} variant="h5">
                      Customer
                    </Typography>
                    <div className="d-flex justify-content-center">
                      {values.customer_pic === "" ? (
                        <Webcam
                          height={300}
                          audio={false}
                          ref={custcamRef}
                          screenshotFormat="image/jpeg"
                          width={300}
                          videoConstraints={videoConstraints}
                        />
                      ) : (
                        <img
                          src={values.customer_pic}
                          height="300"
                          width="300"
                        ></img>
                      )}
                    </div>
                    <br />
                    <div className="d-flex justify-content-around">
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={values.customer_pic}
                        type="button"
                        onClick={() =>
                          capture(
                            setFieldValue,
                            "customer_pic",
                            "cust",
                            values.loan_account
                          )
                        }
                      >
                        Capture Photo
                      </Button>
                      <Button
                        color="secondary"
                        type="button"
                        onClick={() => discard(setFieldValue, "customer_pic")}
                      >
                        Discard
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Typography style={{ textAlign: "center" }} variant="h5">
                      Ornaments
                    </Typography>
                    <div className="d-flex justify-content-center">
                      {values.gold_pic === "" ? (
                        <Webcam
                          height={300}
                          audio={false}
                          ref={goldcamRef}
                          screenshotFormat="image/jpeg"
                          width={300}
                          videoConstraints={videoConstraints}
                        />
                      ) : (
                        <img
                          src={values.gold_pic}
                          height="300"
                          width="300"
                        ></img>
                      )}
                    </div>
                    <br />
                    <div className="d-flex justify-content-around">
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={values.gold_pic}
                        type="button"
                        onClick={() =>
                          capture(
                            setFieldValue,
                            "gold_pic",
                            "gold",
                            values.loan_account
                          )
                        }
                      >
                        Capture Photo
                      </Button>
                      <Button
                        color="secondary"
                        type="button"
                        onClick={() => discard(setFieldValue, "gold_pic")}
                      >
                        Discard
                      </Button>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <div className="form-row">
              <div className="form-group col d-flex justify-content-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
