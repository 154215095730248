import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { misService } from '../../_services'
import datehelper from '../../_helpers/datehelper'
import { useReactToPrint } from 'react-to-print'
import { Button, CircularProgress } from '@material-ui/core'
import { employeeService } from '../../_services'
import { DailyReportModal } from './DailyReportModal'
import roleChecker from '../../_helpers/rolechecker'
import { history } from '../../_helpers'
import { ledgerService } from '../../_services/ledger.service'
export const BranchSummary = () => {

    const date = new Date()

    const [payment, setPayment] = useState({});
    const BranchSummaryRef = React.useRef();
    const [show, setShow] = useState(false);
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date(date.setDate(date.getDate() + 1)))
    const [employees, setEmployees] = useState([])
    const [branchList, setBranchList]=useState([])
    const [ledegerData, setLedegerData] = useState()
    const [count,setCount]=useState(0)
    const user = employeeService.userValue;
    let cas = 0;
    let onlin = 0;
    let disb = 0;
    let colend = 0;

    const handleSubmissionPrint = useReactToPrint({
        content: () => BranchSummaryRef.current,
        pageStyle: "print"
    });
    const [branch, setBranch] = useState('')
    const handleChange = async (value) => {
        setBranch(value)
        console.log({branch:value, from_date:fromDate, to_date:toDate})
        if(value==='')
        {
            return;
        }
        setShow(true)
        await misService.getBranchSummary({branch:value, from_date:fromDate, to_date:toDate}).then((response) => {
            setPayment(response)
            console.log(response)
            setShow(false)
        })
            .catch((err) => {
                console.log(err)
            })
    }
    
    console.log("test payment",payment)
    const filterEmployee = (emp_id) => {
        const matchedEmployees = employees.filter(employee => employee.emp_id === emp_id);
        const matchedEmployee = matchedEmployees[0];
        return matchedEmployee ? matchedEmployee.first_name || null : null;
        // return employees.filter(employee => employee.emp_id === emp_id)[0].first_name
        //    console.log(employees.filter(employee=>employee.emp_id===10003))
    }

    
    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }
    const fetchTodayTransaction = () => {
        ledgerService.getBranchTransaction()
          .then((res) => setLedegerData(res))
          .catch((err) => console.log(err));
      };
      const countDailyReport = async () => {
        await misService.getDailyReportCount()
            .then((res) => setCount(res))
            .catch((err) => window.alert(err))
    }
      let dailyReportId= `KFPL/GL/DAILYREPORT/${user.branch_id.slice(0,2)}/${(date.toLocaleString("default", { month: "long" }).slice(0,3))}/${count + 1}`;
      const saveData = {
        // openingBalance: openingBalance,
        payment: {
            cash_payments: payment.cash_payments,
            online_payments: payment.online_payments,
            disbursement_details: payment.disbursement_details
        },
        emp_id: user.emp_id,
        debit_ledger_data:ledegerData && ledegerData.debitLedger,
        credit_ledger_data: ledegerData && ledegerData.creditLedger,
        // topUp_transaction: topUp_transaction,
        date: date,
        branch_id: user.branch.branch_id,
        dailyReportId:dailyReportId
    }
    const handleSave = async () => {
        await misService.saveDailyReportData(saveData)
        .then((res) =>dailyReportPrint())
        .catch((err) =>alert(err))
    }
      console.log("Test today trasaction",ledegerData)
    const dailyReportPrint = () => {
        history.push("dailyreportversion2", {
            // openingBalance,
            payment,
            // topUp_transaction,
            ledegerData,
            user,
            branchDetails:user,
            dailyReportId
        })
    }
    useEffect(() => {
        async function getEmployees() {
            await employeeService.getAll()
                .then(response => setEmployees(response))
                .catch(err => console.log(err))
        }
        getEmployees()
        handleChange(branch)
        fetchTodayTransaction()
        countDailyReport()
        let tmpBranchList=[]
        let tmpBranch=''
        if(roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOGoldAccess(user.role))
        {
            tmpBranch='GL'
            tmpBranchList.push({'code':'GL','name':'All'})
            tmpBranchList.push({'code':'KFPLGLMN','name':'Malviya Nagar'})
            tmpBranchList.push({'code':'KFPLGLJH','name':'Jhotwara'})
            tmpBranchList.push({'code':'KFPLGLVN','name':'Vidhyadhar Nagar'})
            tmpBranchList.push({'code':'KFPLGLPN','name':'Pratap Nagar'})
            
        }
        else if(roleChecker.isBMLevelAccess(user.role))
        {
            if(user.branch_id==="MN001")
            { 
                tmpBranch='KFPLGLMN'
                tmpBranchList.push({'code':tmpBranch,'name':'Malviya Nagar'})
                


            }
            if(user.branch_id==="JH001")
            {
                tmpBranch='KFPLGLJH'
                tmpBranchList.push({'code':tmpBranch,'name':'Jhotwara'})
                
            }
            if(user.branch_id==="VN001")
            {
                tmpBranch='KFPLGLVN'
                tmpBranchList.push({'code':tmpBranch,'name':'Vidhyadhar Nagar'})
                
            }
            if(user.branch_id==="PN001")
            {
                tmpBranch='KFPLGLPN'
                tmpBranchList.push({'code':tmpBranch,'name':'Pratap Nagar'})
            }
        }

        setBranchList(tmpBranchList)
        setBranch(tmpBranch)
        handleChange(tmpBranch)
        
    }, [fromDate, toDate])

    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Branch Summary</button>
            </div>
            <div ref={BranchSummaryRef} style={{
                width: "290mm",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: '50px',
                backgroundColor: "white",
                padding: 40,
            }}>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>Branch Summary</h1>
                    <label style={{ display: 'flex', }} >
                        <h2>Branch Name: </h2>
                        <select style={{ borderRadius: '8px', marginLeft: '10px', fontSize: 'medium', height: '40px' }} onChange={(e) => handleChange(e.target.value)}>
                            {branchList.map((bi,idx)=> {
                                return (
                                    <option key={idx} value={bi['code']}>{bi['name']}</option>
                                )
                            })}
                        </select>
                    </label>
                    {/* {branch !== "GL" ? <DailyReportModal payment={payment} branch={branch} /> : null} */}
                    {/* {roleChecker.hasAccountsAccess(user.role)&&<DailyReportModal payment={payment} branch={branch} /> } */}
                    {roleChecker.isBMLevelAccess(user.role)&&<Button onClick={()=>handleSave()}>Daily Report</Button>}   
                </div><br/>
                <div>
                 <label>From date</label> &nbsp;
                 <input type='date' onKeyDown={(e) => e.preventDefault()} value={dateFormat(fromDate)} onChange={(e) => setFromDate(e.target.value)} defaultValue={dateFormat(fromDate)} />

                 <label style={{marginLeft:"25%"}} >To date</label> &nbsp;
                 <input type='date' onKeyDown={(e) => e.preventDefault()} value={dateFormat(toDate)} onChange={(e) => setToDate(e.target.value)} defaultValue={dateFormat(toDate)} />
                 {console.log(toDate, fromDate, "here")}
                </div>
                <hr />

                {
                    show === false ?
                        <div>
                            {payment && payment.cash_payments && payment.cash_payments.length > 0
                                ?
                                <div>
                                    <h2>Cash Payments</h2>
                                    <table style={{ marginBottom: '40px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '20%' }}>Date</th>
                                                <th style={{ width: '20%' }}>Loan Account</th>
                                                <th style={{ width: '20%' }}>Employee Name</th>
                                                <th style={{ width: '20%' }}>Amount</th>
                                                <th style={{ width: '20%' }}>Reconciled By</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payment.cash_payments.map((cash, idx) => {
                                                cas = cas + cash.payment
                                                return <tr key={idx}>
                                                    <td>{datehelper.displayDate(new Date(cash.date))}</td>
                                                    <td>{cash.loan_no}</td>
                                                    <td>{cash.emp_name}</td>
                                                    <td>{cash.payment}</td>
                                                    <td>{cash.reconcilelist && cash.reconcilelist.length > 0 && <>{filterEmployee(cash.reconcilelist[0].reconcile_by)}</>}</td>
                                                    {/* <td>{cash.reconcilelist && cash.reconcilelist.length > 0 && <>{cash.reconcilelist[0].reconcile_by}</>}</td> */}
                                                </tr>

                                            })}
                                            <tr>
                                                <td><b>TOTAL AMOUNT</b></td>
                                                <td></td>
                                                <td></td>
                                                <td><b>{cas}</b></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                null
                            }

                            {payment && payment.online_payments && payment.online_payments.length > 0
                                ?
                                <div>
                                    <h2>Online Payments</h2>
                                    <table style={{ marginBottom: '40px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '20%' }}>Date</th>
                                                <th style={{ width: '20%' }}>Loan Account</th>
                                                <th style={{ width: '20%' }}>Customer Name</th>
                                                <th style={{ width: '20%' }}>Amount</th>
                                                <th style={{ width: '20%' }}>Reconciled By</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payment.online_payments.map((online, idx) => {
                                                onlin = onlin + Number(online.txn_amt)
                                                return <tr key={idx}>
                                                    <td>{datehelper.displayDate(new Date(online.updated))}</td>
                                                    <td>{online.virtualaccount && online.virtualaccount.length > 0 && <>{online.virtualaccount[0].loan_account}</>}</td>
                                                    <td>{online.virtualaccount && online.virtualaccount.length > 0 && <>{online.virtualaccount[0].customer_name}</>}</td>
                                                    <td>{online.txn_amt}</td>
                                                    <td>{online.reconcilelist && online.reconcilelist.length > 0 && <>{filterEmployee(online.reconcilelist[0].reconcile_by)}</>}</td>
                                                </tr>

                                            })}
                                            <tr>
                                                <td><b>TOTAL AMOUNT</b></td>
                                                <td></td>
                                                <td></td>
                                                <td><b>{onlin}</b></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                null
                            }
                            {roleChecker.hasAccountsAccess(user.role) && payment && payment.colender_payments && payment.colender_payments.length > 0
                                ?
                                <div>
                                    <h2>Colender Payments</h2>
                                    <table style={{ marginBottom: '40px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>Date</th>
                                                <th style={{ width: '25%' }}>Loan Account</th>
                                                <th style={{ width: '25%' }}>Amount</th>
                                                <th style={{ width: '25%' }}>Payment Type</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payment.colender_payments.map((colender, idx) => {
                                                colend = colend + colender.payment_amount
                                                return <tr key={idx}>
                                                    <td>{datehelper.displayDate(new Date(colender.payment_date))}</td>
                                                    <td>{colender.gold_acc}</td>
                                                    <td>{colender.payment_amount}</td>
                                                    <td>{colender.payment_type}</td>
                                                </tr>

                                            })}
                                            <tr>
                                                <td><b>TOTAL AMOUNT</b></td>
                                                <td></td>
                                                <td><b>{colend}</b></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                null
                            }
                            {payment && payment.disbursement_details && payment.disbursement_details.length > 0
                                ?
                                <div>
                                    <h2>Disbursement Details</h2>
                                    <table style={{ marginBottom: '40px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>Date</th>
                                                <th style={{ width: '25%' }}>Loan Account</th>
                                                <th style={{ width: '25%' }}>Amount</th>
                                                <th style={{ width: '25%' }}>Mode</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payment.disbursement_details.map((disburse, idx) => {
                                                disb = disb + disburse.payment_amount
                                                return <tr key={idx}>
                                                    <td>{datehelper.displayDate(new Date(disburse.payment_date))}</td>
                                                    <td>{disburse.loan_account}</td>
                                                    <td>{disburse.payment_amount}</td>
                                                    <td>{disburse.payment_mode}</td>
                                                </tr>

                                            })}
                                            <tr>
                                                <td><b>TOTAL AMOUNT</b></td>
                                                <td></td>
                                                <td><b>{disb}</b></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                null
                            }

                        </div> :
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}  ><CircularProgress /></div>
                }

            </div>
            {
                console.log(employees)
            }
        </div>
    )
}