import React, { useEffect, useState } from "react";
import { Button, Paper, Typography, TextField } from "@material-ui/core";
import AddIciciEnachModal from "./AddIciciEnachModal";
import { icicienachService } from "../../../_services/icicienach.service";
import ICICINachTable from "./ICICINachTable";


// function createData(loanNo, amount, date, name, phone, bank, enachRef, action) {
//   return { loanNo, amount, date, name, phone, bank, enachRef, action };
// }

// const rows = [
//   createData(
//     "ABC123",
//     20000,
//     "30/01/2022",
//     "Anil",
//     9999999999,
//     "AU",
//     "ENACH123",
//     "Cancel"
//   ),
//   createData(
//     "ABC124",
//     30000,
//     "30/01/2022",
//     "Ramesh",
//     9999999998,
//     "AU",
//     "ENACH124",
//     "Cancel"
//   ),
// ];

export default function IciciEnach(props) {

  const data = props.location.state;

  const [enachData, setEnachData] = useState([]);
  const [filteredEnachData, setFilteredEnachData] = useState([]);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [fields, setFields] = useState({});
  const [loanNo, setLoanNo] = useState("");
  const [enachRef, setEnachRef] = useState("")

  useEffect(() => {
    setFilteredEnachData(
      enachData.filter((data) => {
        return (
          data.name.toLowerCase().includes(name.toLowerCase()) &&
          data.loan_number.toLowerCase().includes(loanNo.toLowerCase()) &&
          data.contact.toString().toLowerCase().includes(phone.toLowerCase()) &&
          data.reference_code.toString().toLowerCase().includes(enachRef.toLowerCase())
        );
      })
    );
  }, [name, phone, loanNo, enachRef, enachData]);

  useEffect(()=> {
    icicienachService.getAll()
    .then((nachList)=> {
      setEnachData(nachList)
      console.log(nachList);
    })
    .catch((err)=> {
      console.log(err);
    })
  },[])

  const handleChange = (e) =>{
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleClick = () => {
    icicienachService.getEnachBySearch(fields)
      .then((data)=>{
        setFilteredEnachData(data)
      })
      .catch((err)=>{console.logf(err)})
  }

  console.log(fields)

  return (
     data ? <AddIciciEnachModal enachData={enachData} setEnachData={setEnachData} data={data} />
   : <div className="App">
      <header className="App-header">
        <h1>ICICI Enach</h1>
        <Paper
          className="col-md-12"
          style={{
            padding: 20,
          }}
        >
          <div className="d-flex justify-content-between">
            <TextField
              name="loan_account"
              id="outlined-basic"
              label="Loan Account"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              name="contact"
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              onChange={handleChange}
            />
            {/* <TextField
              id="outlined-basic"
              label="eNach Ref"
              variant="outlined"
              onChange={(e) => setEnachRef(e.target.value)}
            /> */}
            <TextField
              name="from_date"
              id="date"
              label="From Date"
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
            <TextField
              name="to_date"
              id="date"
              label="To Date"
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </div>
          <br />
          <div className="d-flex justify-content-between">
          <Button variant="contained" color="primary" onClick={handleClick}>Search</Button>
          </div>
          {/* <AddIciciEnachModal enachData={enachData} setEnachData={setEnachData}/> */}
        </Paper>
        <br />
        <Paper>
          <ICICINachTable rows={filteredEnachData} />
        </Paper>
      </header>
    </div>
  );
}
