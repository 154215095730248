import React, { useState } from 'react'
import { Avatar, Button } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import { customerService } from '../../../../_services';
import OtpInput from 'react-otp-input';
import { useEffect } from 'react';
import { kycService } from '../../../../_services/kyc.service';



// const Timer = () =>{
//     const [counter, setCounter] = useState(60);

//     useEffect(() => {
//         if(counter>0){
//             setTimeout(() =>setCounter(counter-1),1000)
//         }
//     }, [counter])

//     return counter
    
// }



const NewCustomer = (props) => {
//   console.log("new customer",props)
    const [open, setOpen] = useState(false);
    // const [otpSent, setOtpSent] = useState(false)
    // const [response, setResponse] = useState({})
    // const [otp, setOtp] = useState('')
    const [checked, setChecked] = useState(false)

    const [show, setShow] = useState(false)

    const handleOpen = () => {
        setOpen(true)

    };
    const handleClose = () => {
        setOpen(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: "scroll",
    };

    const initialValues = {
        general_details: {
            customer_title: "",
            customer_firstname: "",
            customer_lastname: "",
            customer_dob: "",
            fathername: "",
            gender: "",
            customer_mobile_num: props.value === "Aadhar Number" ? "" : props.aadharOrNumValue,
            alternate_mobile_num: "",
            email: "",
        },
        personal_details: {
            religion: "",
            category: "",
            kyc_relation_type:"",
            kyc_relation:"",
            name_of_spouse:"",
            educational_qualification: "",
            marital_status: "",
            smoker:"",
            correspondence_address: {
                address_type: "",
                address_line_1: "",
                landmark: "",
                tehsil: "",
                district: "",
                pin_code: "",
                no_of_years: "",
                city: "",
                state: "",
            },
            permanent_address: {
                address_type: "",
                address_type: "",
                address_line_1: "",
                landmark: "",
                tehsil: "",
                district: "",
                pin_code: "",
                no_of_years: "",
                city: "",
                state: "",
            },
        },
        economic_details: {
            annual_income: "",
            occupation_type: "",
            designation: "",
            nature_of_business_or_employment: "",
            business_or_employment_address: "",
            business_or_employment_pincode: "",
            business_or_employment_city: "",
            business_or_employment_state: "",
            no_of_years: "",
            other_business:"",
            bank_details: {
                account_holder: "",
                ifsc_code: "",
                account_number: "",
                bank_name: "",
            }
        },

        property_details: {
            property_type: "",
            count_of_property: "",
            property_market_val: "",
            property_age: "",
            land_size_bigha: "",
        },

        family_data: {
            sons: "",
            daughters: "",
        },

        verification_details: {
            karza_aadhar_verified:false,
            karza_aadhar_verified_date: new Date(),
            aadhaar_no:props.value === "Aadhar Number" ? props.aadharOrNumValue :"" ,
            pan_no:"",
        }
    }


    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }

    let current_date = new Date()
    let minAge = new Date(
        current_date.getFullYear() - 18,
        current_date.getMonth(),
        current_date.getDate(),
        current_date.getHours(),
        current_date.getMinutes()
    )
    let maxAge = new Date(
        current_date.getFullYear() - 100,
        current_date.getMonth(),
        current_date.getDate(),
        current_date.getHours(),
        current_date.getMinutes()
    )

    const validationSchema = Yup.object().shape({
        general_details: Yup.object().shape({
            customer_title: Yup.string().required('This field is required'),
            customer_firstname: Yup.string().required('This field is required'),
            customer_lastname: Yup.string().required('This field is required'),
            customer_dob: Yup.date().max(dateFormat(minAge), "Age must be greater than or equal to 18 years old").min(dateFormat(maxAge), "Age can not be grater than 100 years").required("This is Required"),
            fathername: Yup.string().required('This field is required'),
            gender: Yup.string().required('This field is required'),
            customer_mobile_num: Yup.string().length(10).matches(/^[0-9]+$/, "Must be only digits").required('This field is required'),
            alternate_mobile_num: Yup.string().length(10).matches(/^[0-9]+$/, "Must be only digits"),
            email: Yup.string().email('Invalid email'),
        }),
        personal_details: Yup.object().shape({
            religion: Yup.string().required('This field is required'),
            category: Yup.string().required('This field is required'),
            kyc_relation_type:Yup.string().required('This field is required'),
            kyc_relation:Yup.string().required('This field is required'),
            // name_of_spouse:Yup.string().required('This field is required'),
            educational_qualification: Yup.string().required('This field is required'),
            marital_status: Yup.string().required('This field is required'),
            correspondence_address: Yup.object().shape({
                address_type: Yup.string().required('This field is required'),
                address_line_1: Yup.string().required('This field is required'),
                landmark: Yup.string().required('This Field is required'),
                tehsil: Yup.string().required('This field is required'),
                district: Yup.string().required("this Field is required"),
                pin_code: Yup.string().length(6).required('This field is required').matches(/^[0-9]+$/, "Must be only digits"),
                no_of_years: Yup.number().required('This field is required'),
                city: Yup.string().required('This field is required'),
                state: Yup.string().required('This field is required'),
            }),
            permanent_address: Yup.object().shape({
                address_type: Yup.string().required('This field is required'),
                address_type: Yup.string().required('This field is required'),
                address_line_1: Yup.string().required('This field is required'),
                landmark: Yup.string().required('This Field is required'),
                tehsil: Yup.string().required('This field is required'),
                district: Yup.string().required("This Field is required"),
                pin_code: Yup.string().length(6).required('This field is required').matches(/^[0-9]+$/, "Must be only digits"),
                no_of_years: Yup.number().required('This field is required'),
                city: Yup.string().required('This field is required'),
                state: Yup.string().required('This field is required'),
            }),
        }),
        economic_details: Yup.object().shape({
            annual_income: Yup.number(),
            occupation_type: Yup.string(),
            // designation: Yup.string(),
            nature_of_business_or_employment: Yup.string(),
            business_or_employment_address: Yup.string(),
            business_or_employment_pincode: Yup.number(),
            business_or_employment_city: Yup.string(),
            business_or_employment_state: Yup.string(),
            no_of_years: Yup.number(),
            bank_details: Yup.object().shape({
                account_holder: Yup.string(),
                ifsc_code: Yup.string(),
                account_number: Yup.number(),
                bank_name: Yup.string(),
            })
        }),

        property_details: Yup.object().shape({
            property_type: Yup.string(),
            count_of_property: Yup.string(),
            property_market_val: Yup.string(),
            property_age: Yup.string(),
            land_size_bigha: Yup.number(),
        }),

        family_data: Yup.object().shape({
            sons: Yup.number(),
            daughters: Yup.number(),
        }),

        verification_details: Yup.object().shape({
            aadhaar_no:Yup.string().matches(/^[0-9]{12}$/,"Valid aadhar number").required("aadhar required"),
            pan_no:Yup.string().matches(/^[a-zA-Z0-9]{10}$/,"Valid PAN number"),
        }),
    })


    const fillPermanentAddress = (values, setFieldValue, event) => {
        setChecked(event.target.checked);
        if(event.target.checked){
            setFieldValue('personal_details.permanent_address.address_type', values.personal_details.correspondence_address.address_type)
            setFieldValue('personal_details.permanent_address.address_line_1', values.personal_details.correspondence_address.address_line_1)
            setFieldValue('personal_details.permanent_address.landmark', values.personal_details.correspondence_address.landmark)
            setFieldValue('personal_details.permanent_address.tehsil', values.personal_details.correspondence_address.tehsil)
            setFieldValue('personal_details.permanent_address.district', values.personal_details.correspondence_address.district)
            setFieldValue('personal_details.permanent_address.pin_code', values.personal_details.correspondence_address.pin_code)
            setFieldValue('personal_details.permanent_address.no_of_years', values.personal_details.correspondence_address.no_of_years)
            setFieldValue('personal_details.permanent_address.city', values.personal_details.correspondence_address.city)
            setFieldValue('personal_details.permanent_address.state', values.personal_details.correspondence_address.state)
        }else{
            setFieldValue('personal_details.permanent_address.address_type', "")
            setFieldValue('personal_details.permanent_address.address_line_1', "")
            setFieldValue('personal_details.permanent_address.landmark', "")
            setFieldValue('personal_details.permanent_address.tehsil', "")
            setFieldValue('personal_details.permanent_address.district', "")
            setFieldValue('personal_details.permanent_address.pin_code', "")
            setFieldValue('personal_details.permanent_address.no_of_years', "")
            setFieldValue('personal_details.permanent_address.city', "")
            setFieldValue('personal_details.permanent_address.state', "")
        }
    }

    const handleRelation = (values, setFieldValue, value) => {
            if(value === "S/o" || value === "D/o"){
                console.log(values.general_details.fathername, "uuu")
                setFieldValue('personal_details.kyc_relation_type', value)
                setFieldValue('personal_details.kyc_relation', values.general_details.fathername)
              }else if(value === "W/o"){
                setFieldValue('personal_details.kyc_relation_type', value)
                setFieldValue('personal_details.kyc_relation', values.personal_details.name_of_spouse)
              }
              else{
                setFieldValue('personal_details.kyc_relation_type', value)
                setFieldValue('personal_details.kyc_relation', "")
              }
        
    }

    const checkSpouse = (values, setFieldValue, value) => {
        console.log(value)
        if(values.general_details.gender === 'Female' && value === "married"){
            console.log(true)
            setFieldValue('personal_details.marital_status', value)
            setShow(true)
        }else{
            setFieldValue('personal_details.marital_status', value)
            setShow(false)
        }
    }

    // const sendOtp = (values) => {

    //     console.log(values)

    //     kycService.requestAadhaarOTP({ aadhar_no: values.verification_details.aadhaar_no })
    //         .then((resp) => {
    //             setResponse(resp.response)
    //             console.log(resp.response)
    //             setOtpSent(true)
    //             alert("OTP sent")
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const verifyOtp = (values,setFieldValue) => {
    //     if (otp.length == 6) {
    //         kycService.requestAadhaarXML({ aadhar_no: `${values.aadhaar_no}`, otp: `${otp}`, request_id: response.requestId })
    //             .then((data) => {
    //                 console.log(data)
    //                 if (data.response.statusCode === 101) {
    //                     setFieldValue('verification_details.karza_aadhar_verified', true)
    //                 } else {
    //                     alert("Invalid OTP. Please try again!")
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //             })
    //     } else {
    //         alert("Enter the valid otp")
    //     }
    // }

    const onSubmit = (values) => {
        console.log({...values,application_id: props.application_id, role: props.role})
        customerService.addToLoan({ ...values, application_id: props.application_id, role: props.role })
            .then((data) => {
                console.log(data)
                alert("Added Successfully")
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
                //alert("Something went wrong")
                alert("Something Went Wrong: " + err )
            })
    }

    return (
        <div>
            <button style={{ backgroundColor: "green", color: "white" }} onClick={handleOpen}>Create New</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        // enableReinitialize={true}
                        >
                            {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                                return <Form >
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                                            <h3> General Details</h3><br /><br />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Title</h6>
                                            <Field
                                                label='Title'
                                                as='select'
                                                name='general_details.customer_title'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_title && touched && touched.general_details && touched.general_details.customer_title
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                            </Field>
                                            <ErrorMessage
                                                name='general_details.customer_title'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">First Name</h6>
                                            <Field
                                                label='First Name'
                                                type='string'
                                                name='general_details.customer_firstname'
                                                // onChange={(e) => {setFieldValue('general_details.customer_firstname', e.target.value) }}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_firstname && touched && touched.general_details && touched.general_details.customer_firstname
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.customer_firstname'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Middle Name</h6>
                                            <Field
                                                label='Middle Name'
                                                type='string'
                                                name='general_details.customer_middlename'
                                                className={
                                                    "form-control" /*+
                                                                (errors.ledger_name && touched.ledger_name
                                                                    ? " is-invalid"
                                                                    : "")*/
                                                }
                                            />
                                            {/* <ErrorMessage
                                                        name={values.name}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    /> */}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Last Name</h6>
                                            <Field
                                                label='Last Name'
                                                type='string'
                                                name='general_details.customer_lastname'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_lastname && touched && touched.general_details && touched.general_details.customer_lastname
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.customer_lastname'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">DOB</h6>
                                            <Field
                                                label='DOB'
                                                type='date'
                                                onKeyDown={(e) => e.preventDefault()}
                                                name='general_details.customer_dob'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_dob && touched && touched.general_details && touched.general_details.customer_dob
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.customer_dob'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Father Name</h6>
                                            <Field
                                                label='Father Name'
                                                type='string'
                                                name='general_details.fathername'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.fathername && touched && touched.general_details && touched.general_details.fathername
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.fathername'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Gender</h6>
                                            <Field
                                                label='Gender'
                                                as='select'
                                                name='general_details.gender'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.gender && touched && touched.general_details && touched.general_details.gender
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                            <ErrorMessage
                                                name='general_details.gender'
                                                component="div"
                                                className="invalid-feedback"
                                            />

                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Religion</h6>
                                            <Field
                                                label='Religion'
                                                as='select'
                                                name='personal_details.religion'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.religion && touched && touched.personal_details && touched.personal_details.religion
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Hindu">Hindu</option>
                                                <option value="Muslim">Muslim</option>
                                                <option value="Sikh">Sikh</option>
                                                <option value="Christian">Christian</option>
                                                <option value="Other">Other</option>
                                                <ErrorMessage
                                                    name='personal_details.religion'
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </Field>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Category</h6>
                                            <Field
                                                label='Category'
                                                as='select'
                                                name='personal_details.category'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.category && touched && touched.personal_details && touched.personal_details.category
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="General">General</option>
                                                <option value="OBC">OBC</option>
                                                <option value="SC">SC</option>
                                                <option value="ST">ST</option>
                                                <option value="DT/NT">DT/NT</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.category'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>

                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Mobile Number</h6>
                                            <Field
                                                label='Mobile Number'
                                                type='number'
                                                name='general_details.customer_mobile_num'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.customer_mobile_num && touched && touched.general_details && touched.general_details.customer_mobile_num
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.customer_mobile_num'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Alternate Mobile Number</h6>
                                            <Field
                                                label='Alternate Mobile Number'
                                                type='number'
                                                name='general_details.alternate_mobile_num'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.alternate_mobile_num && touched && touched.general_details && touched.general_details.alternate_mobile_num
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.alternate_mobile_num'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Email id</h6>
                                            <Field
                                                label='Email id'
                                                type='string'
                                                name='general_details.email'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.general_details && errors.general_details.email && touched && touched.general_details && touched.general_details.email
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='general_details.email'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>

                                    <h3>Correspondence Address</h3><br />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Current Residence Address</h6>
                                            <Field
                                                label='Current Residence Address'
                                                type='string'
                                                name='personal_details.correspondence_address.address_line_1'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.address_line_1 && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.address_line_1
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.address_line_1'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Current Residence is</h6>
                                            <Field
                                                label='Current Residence is'
                                                as='select'
                                                name='personal_details.correspondence_address.address_type'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.address_type && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.address_type
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >

                                                <option value=""></option>
                                                <option value="self_owned">Self Owned</option>
                                                <option value="family">Family</option>
                                                <option value="rented">Rented</option>
                                                <option value="company">Company</option>
                                                <option value="residential">Residential</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.address_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Landmark</h6>
                                            <Field
                                                label='Landmark'
                                                type='string'
                                                name='personal_details.correspondence_address.landmark'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.landmark && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.landmark
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.landmark'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Tehsil</h6>
                                            <Field
                                                label='Tehsil'
                                                type='string'
                                                name='personal_details.correspondence_address.tehsil'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.tehsil && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.tehsil
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.tehsil'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Pin Code</h6>
                                            <Field
                                                label='Pin code'
                                                type='number'
                                                name='personal_details.correspondence_address.pin_code'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.pin_code && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.pin_code
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.pin_code'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">City</h6>
                                            <Field
                                                label='City'
                                                type='string'
                                                name='personal_details.correspondence_address.city'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.city && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.city
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.city'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">District</h6>
                                            <Field
                                                label='District'
                                                type='string'
                                                name='personal_details.correspondence_address.district'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.district && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.district
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.district'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">State</h6>
                                            {/* <Field
                                                label='State'
                                                type='string'
                                                name='personal_details.correspondence_address.state'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.state && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.state
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            /> */}
                                               <Field
                                                label='State'
                                                 as='select'
                                                name='personal_details.correspondence_address.state'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.state && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.state
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >

                                            <option value=""></option>
                                            <option value="Andhra Pradesh">ANDHRA PRADESH</option>
                                            <option value="Arunachal Pradesh">ARUNACHAL PRADESH</option>
                                            <option value="Assam">ASSAM</option>
                                            <option value="Bihar">BIHAR</option>
                                            <option value="Chhattisgarh">CHHATTISGARH</option>
                                            <option value="Goa">GOA</option>
                                            <option value="Gujarat">GUJARAT</option>
                                            <option value="Haryana">HARYANA</option>
                                            <option value="Himachal Pradesh">HIMACHAL PRADESH</option>
                                            <option value="Jammu and Kashmir">JAMMU AND KASHMIR</option>
                                            <option value="Jharkhand">JHARKHAND</option>
                                            <option value="Karnataka">KARNATAKA</option>
                                            <option value="Kerala">KERALA</option>
                                            <option value="Madhya Pradesh">MADHYA PRADESH</option>
                                            <option value="Maharashtra">MAHARASHTRA</option>
                                            <option value="Manipur">MANIPUR</option>
                                            <option value="Meghalaya">MEGHALAYA</option>
                                            <option value="Mizoram">MIZORAM</option>
                                            <option value="Nagaland">NAGALAND</option>
                                            <option value="Odisha">ODISHA</option>
                                            <option value="Punjab">PUNJAB</option>
                                            <option value="Rajasthan">RAJASTHAN</option>
                                            <option value="Sikkim">SIKKIM</option>
                                            <option value="Tamil Nadu">TAMIL NADU</option>
                                            <option value="Telangana">TELANGANA</option>
                                            <option value="Tripura">TRIPURA</option>
                                            <option value="Uttar Pradesh">UTTAR PRADESH</option>
                                            <option value="Uttarakhand">UTTARAKHAND</option>
                                            <option value="West Bengal">WEST BENGAL</option>
                                        </Field>
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.state'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">No. of Years at Current Residence</h6>
                                            <Field
                                                label='No. of Years at Current Residence'
                                                type='number'
                                                name='personal_details.correspondence_address.no_of_years'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.correspondence_address && errors.personal_details.correspondence_address.no_of_years && touched && touched.personal_details && touched.personal_details.correspondence_address && touched.personal_details.correspondence_address.no_of_years
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.correspondence_address.no_of_years'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <h3>Permanent Address</h3><br />

                                    <Checkbox
                                    checked={checked}
                                    onChange={(event)=>fillPermanentAddress(values, setFieldValue, event)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                /> Same as above address

                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Current Residence Address</h6>
                                            <Field
                                                label='Current Residence Address'
                                                type='string'
                                                name='personal_details.permanent_address.address_line_1'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.address_line_1 && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.address_line_1
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.permanent_address.address_line_1'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Current Residence is</h6>
                                            <Field
                                                label='Current Residence is'
                                                as='select'
                                                name='personal_details.permanent_address.address_type'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.address_type && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.address_type
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >

                                                <option value=""></option>
                                                <option value="self_owned">Self Owned</option>
                                                <option value="family">Family</option>
                                                <option value="rented">Rented</option>
                                                <option value="company">Company</option>
                                                <option value="residential">Residential</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.permanent_address.address_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Landmark</h6>
                                            <Field
                                                label='Landmark'
                                                type='string'
                                                name='personal_details.permanent_address.landmark'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.landmark && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.landmark
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.permanent_address.landmark'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Tehsil</h6>
                                            <Field
                                                label='Tehsil'
                                                type='string'
                                                name='personal_details.permanent_address.tehsil'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.tehsil && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.tehsil
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.permanent_address.tehsil'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Pin Code</h6>
                                            <Field
                                                label='Pin code'
                                                type='number'
                                                name='personal_details.permanent_address.pin_code'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.pin_code && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.pin_code
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.permanent_address.pin_code'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">City</h6>
                                            <Field
                                                label='City'
                                                type='string'
                                                name='personal_details.permanent_address.city'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.city && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.city
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.permanent_address.city'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">District</h6>
                                            <Field
                                                label='District'
                                                type='string'
                                                name='personal_details.permanent_address.district'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.district && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.district
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.permanent_address.district'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">State</h6>
                                            <Field
                                                label='State'
                                                // type='string'
                                                 as='select'
                                                name='personal_details.permanent_address.state'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.state && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.state
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                             <option value=""></option>
                                            <option value="Andhra Pradesh">ANDHRA PRADESH</option>
                                            <option value="Arunachal Pradesh">ARUNACHAL PRADESH</option>
                                            <option value="Assam">ASSAM</option>
                                            <option value="Bihar">BIHAR</option>
                                            <option value="Chhattisgarh">CHHATTISGARH</option>
                                            <option value="Goa">GOA</option>
                                            <option value="Gujarat">GUJARAT</option>
                                            <option value="Haryana">HARYANA</option>
                                            <option value="Himachal Pradesh">HIMACHAL PRADESH</option>
                                            <option value="Jammu and Kashmir">JAMMU AND KASHMIR</option>
                                            <option value="Jharkhand">JHARKHAND</option>
                                            <option value="Karnataka">KARNATAKA</option>
                                            <option value="Kerala">KERALA</option>
                                            <option value="Madhya Pradesh">MADHYA PRADESH</option>
                                            <option value="Maharashtra">MAHARASHTRA</option>
                                            <option value="Manipur">MANIPUR</option>
                                            <option value="Meghalaya">MEGHALAYA</option>
                                            <option value="Mizoram">MIZORAM</option>
                                            <option value="Nagaland">NAGALAND</option>
                                            <option value="Odisha">ODISHA</option>
                                            <option value="Punjab">PUNJAB</option>
                                            <option value="Rajasthan">RAJASTHAN</option>
                                            <option value="Sikkim">SIKKIM</option>
                                            <option value="Tamil Nadu">TAMIL NADU</option>
                                            <option value="Telangana">TELANGANA</option>
                                            <option value="Tripura">TRIPURA</option>
                                            <option value="Uttar Pradesh">UTTAR PRADESH</option>
                                            <option value="Uttarakhand">UTTARAKHAND</option>
                                            <option value="West Bengal">WEST BENGAL</option>
                                        </Field>
                                            <ErrorMessage
                                                name='personal_details.permanent_address.state'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">No. of Years at Current Residence</h6>
                                            <Field
                                                label='No. of Years at Current Residence'
                                                type='number'
                                                name='personal_details.permanent_address.no_of_years'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.permanent_address && errors.personal_details.permanent_address.no_of_years && touched && touched.personal_details && touched.personal_details.permanent_address && touched.personal_details.permanent_address.no_of_years
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.permanent_address.no_of_years'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    </div>

                                    <h3 style={{ width: '100%' }}>KYC Details</h3>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Aadhaar Number</h6>
                                            <Field
                                                type='number'
                                                name='verification_details.aadhaar_no'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.verification_details && errors.verification_details.aadhaar_no && touched && touched.verification_details && touched.verification_details.aadhaar_no
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='verification_details.aadhaar_no'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >PAN Number</h6>
                                            <Field
                                                type='string'
                                                name='verification_details.pan_no'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.verification_details && errors.verification_details.pan_no && touched && touched.verification_details && touched.verification_details.pan_no
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='verification_details.pan_no'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        {/* <div className="form-group col-md-4">
                                            <Button type='button' color='primary' variant='contained' onClick={()=>sendOtp(values)} >Send OTP</Button>
                                        </div> */}
                                    </div>

                                   {/* {otpSent && (
                                     <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                     <div className="form-group col-md-4">
                                         <h6 style={{ color: "blue" }} className="required">Enter OTP</h6>
                                         <Field
                                             type='number'
                                            //  name='economic_details.annual_income'
                                             className= "form-control" 
                                             onChange = {(e)=> setOtp(e.target.value)}    
                                             
                                         /> */}
                                         {/* <ErrorMessage
                                             name='economic_details.annual_income'
                                             component="div"
                                             className="invalid-feedback"
                                         /> */}
                                     {/* </div>
                                     <div className="form-group col-md-4">
                                         <Button type='button' color='primary' variant='contained' onClick={()=> verifyOtp(values, setFieldValue)} >Verify</Button>
                                     </div>
                                 </div>
                                   )} */}


                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    </div>
                                    <h3>Income Details</h3><br />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Annual Income</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.annual_income'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.annual_income && touched && touched.economic_details && touched.economic_details.annual_income
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.annual_income'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Occupation Type</h6>
                                            <Field

                                                as='select'
                                                name='economic_details.occupation_type'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.occupation_type && touched && touched.economic_details && touched.economic_details.occupation_type
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="salaried">Salaried</option>
                                                <option value="self_employed">Self Employed</option>
                                                <option value="self_employed_professional">Self Employed Professional</option>
                                            </Field>
                                            <ErrorMessage
                                                name='economic_details.occupation_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}  >Nature of Business/Employment</h6>
                                            {/* <Field
                                                type='string'
                                                name='economic_details.nature_of_business_or_employment'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.nature_of_business_or_employment && touched && touched.economic_details && touched.economic_details.nature_of_business_or_employment
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            /> */}
                                            <Field
                                               as='select'
                                                name='economic_details.nature_of_business_or_employment'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.nature_of_business_or_employment && touched && touched.economic_details && touched.economic_details.nature_of_business_or_employment
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                onBlur={(event) => {
                                                    const selectedValue = event.target.value;
                                                    if (selectedValue !== 'Other' && selectedValue.trim() !== '') {
                                                      setFieldValue('economic_details.other_business', '');
                                                    }
                                                  }}
                                            >               
                                            <option value=""></option>
                                            <option value="Agency">Agency</option>
                                                    <option value="Auto/Rickshaw">Auto/Rickshaw</option>
                                                    <option value="Chef/Cook">Chef/Cook</option>
                                                    <option value="Clothing Shop">Clothing Shop</option>
                                                    <option value="Dairy Farm Shop">Dairy Farm Shop</option>
                                                    <option value="Driver">Driver</option>
                                                    <option value="Electronics Shop">Electronics Shop</option>
                                                    <option value="Farmer">Farmer</option>
                                                    <option value="Flower Shop">Flower Shop</option>
                                                    <option value="Fruit/vegetable Shop">Fruit/vegetable Shop</option>
                                                    <option value="Furniture Shop">Furniture Shop</option>
                                                    <option value="Grocery Shop">Grocery Shop</option>
                                                    <option value="Labour">Labour</option>
                                                    <option value="Manufacturer">Manufacturer</option>
                                                    <option value="Mason">Mason</option>
                                                    <option value="Painter">Painter</option>
                                                    <option value="Salon Shop">Salon Shop</option>
                                                    <option value="Services">Services</option>
                                                    <option value="Shoes Shop">Shoes Shop</option>
                                                    <option value="Stationery Shop">Stationery Shop</option>
                                                    <option value="Supplier">Supplier</option>
                                                    <option value="Sweet Shop">Sweet Shop</option>
                                                    <option value="Teacher">Teacher</option>
                                                    <option value="Tea/coffee Shop">Tea/coffee Shop</option>
                                                    <option value="Trader">Trader</option>
                                                    <option value="Other">Other</option>
                                                                          </Field>

                                            <ErrorMessage
                                                name='economic_details.nature_of_business_or_employment'
                                                component="div"
                                                className="invalid-feedback"
                                            />


{values?.economic_details?.nature_of_business_or_employment === 'Other' && (
            <div>
              <label>Other Business</label>
              <Field
                 type='string'
                 name='economic_details.other_business'
                 
                 className={
                     "form-control" +
                     (errors && errors.economic_details && errors.economic_details.other_business && touched && touched.economic_details && touched.economic_details.other_business
                         ? " is-invalid"
                         : "")
                 }
              />
               <ErrorMessage
                                             name='economic_details.other_business'
                                             component="div"
                                             className="invalid-feedback"
                                        />
            </div>
          )}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}  >Business/Employment Address</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.business_or_employment_address'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_address && touched && touched.economic_details && touched.economic_details.business_or_employment_address
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_address'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                            
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Pin Code</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.business_or_employment_pincode'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_pincode && touched && touched.economic_details && touched.economic_details.business_or_employment_pincode
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_pincode'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >City</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.business_or_employment_city'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_city && touched && touched.economic_details && touched.economic_details.business_or_employment_city
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_city'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>State</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.business_or_employment_state'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.business_or_employment_state && touched && touched.economic_details && touched.economic_details.business_or_employment_state
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.business_or_employment_state'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Number of years in current employment</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.no_of_years'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.no_of_years && touched && touched.economic_details && touched.economic_details.no_of_years
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.no_of_years'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Designation (if saleried)</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.designation'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.designation && touched && touched.economic_details && touched.economic_details.designation
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.designation'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <h3>Assets</h3><br />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Asset type</h6>
                                            <Field
                                                as='select'
                                                name='property_details.property_type'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_type &&
                                                        touched && touched.property_details && touched.property_details.property_type ? " is-invalid" : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="property">Property</option>
                                                <option value="land">Land</option>
                                                <option value="vehicle">Vehicle</option>
                                                <option value="farm_animal">Farm Animal</option>
                                                <option value="others" others>Others</option>
                                            </Field>
                                            <ErrorMessage
                                                name='property_details.property_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Count of asset</h6>
                                            <Field
                                                type='number'
                                                name='property_details.count_of_property'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_type &&
                                                        touched && touched.property_details && touched.property_details.property_type ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.count_of_property'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Market value of asset</h6>
                                            <Field
                                                type='number'
                                                name='property_details.property_market_val'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_market_val &&
                                                        touched && touched.property_details && touched.property_details.property_market_val ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.property_market_val'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Asset age</h6>
                                            <Field
                                                type='number'
                                                name='property_details.property_age'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.property_age &&
                                                        touched && touched.property_details && touched.property_details.property_age ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.property_age'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>If Property / Agricultural Land then ask Agricultural  Land size(in bigha)</h6>
                                            <Field
                                                type='number'
                                                name='property_details.land_size_bigha'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.property_details && errors.property_details.land_size_bigha && touched && touched.property_details && touched.property_details.land_size_bigha
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='property_details.land_size_bigha'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <h3>Bank Details</h3><br />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Bank Name</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.bank_details.bank_name'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.bank_name && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.bank_name
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.bank_name'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Account number</h6>
                                            <Field
                                                type='number'
                                                name='economic_details.bank_details.account_number'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.account_number && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.account_number
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.account_number'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >IFSC code</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.bank_details.ifsc_code'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.ifsc_code && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.ifsc_code
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.ifsc_code'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Account holder name</h6>
                                            <Field
                                                type='string'
                                                name='economic_details.bank_details.account_holder'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.economic_details && errors.economic_details.bank_details && errors.economic_details.bank_details.account_holder && touched && touched.economic_details && touched.economic_details.bank_details && touched.economic_details.bank_details.account_holder
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='economic_details.bank_details.account_holder'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <h3>Credit History</h3><br />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Cibil Score</h6>
                                            <Field
                                                type='number'
                                                name='financial_condition.cibil_score'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.financial_condition && errors.financial_condition.cibil_score && touched && touched.financial_condition && touched.financial_condition.cibil_score
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='financial_condition.cibil_score'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }}>Last date of Cibil score/ Last check date</h6>
                                            <Field
                                                type='date'
                                                onKeyDown={(e) => e.preventDefault()}
                                                name='financial_condition.cibil_score_last_check_date'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.financial_condition && errors.financial_condition.cibil_score_last_check_date && touched && touched.financial_condition && touched.financial_condition.cibil_score_last_check_date
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='financial_condition.cibil_score_last_check_date'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>

                                    <h3>Personal Details</h3><br />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Marital status</h6>
                                            <Field
                                                as='select'
                                                name='personal_details.marital_status'
                                                onChange={(e) => checkSpouse(values, setFieldValue, e.target.value)}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.marital_status && touched && touched.personal_details && touched.personal_details.marital_status
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="Single">Single</option>
                                                <option value="Married">Married</option>
                                                <option value="Divorcee">Divorcee</option>
                                                <option value="Widowed">Widowed</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.marital_status'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                       {show ?  <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Spouse Name</h6>
                                            <Field
                                                type='string'
                                                name='personal_details.name_of_spouse'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.name_of_spouse && touched && touched.personal_details && touched.personal_details.name_of_spouse
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.name_of_spouse'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>: null}

                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">KYC Relation Type</h6>
                                            <Field
                                                as='select'
                                                name='personal_details.kyc_relation_type'
                                                onChange={(e) => handleRelation(values, setFieldValue, e.target.value)}
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.kyc_relation_type && touched && touched.personal_details && touched.personal_details.kyc_relation_type
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="S/o">S/o</option>
                                                <option value="W/o">W/o</option>
                                                <option value="D/o">D/o</option>
                                                <option value="C/o">C/o</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.kyc_relation_type'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >KYC Relation</h6>
                                            <Field
                                                type='string'
                                                name='personal_details.kyc_relation'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.kyc_relation && touched && touched.personal_details && touched.personal_details.kyc_relation
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='personal_details.kyc_relation'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Son count</h6>
                                            <Field
                                                type='number'
                                                name='family_data.sons'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.family_data && errors.family_data.sons && touched && touched.family_data && touched.family_data.sons
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='family_data.sons'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} >Daughter count</h6>
                                            <Field
                                                type='number'
                                                name='family_data.daughters'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.family_data && errors.family_data.daughters && touched && touched.family_data && touched.family_data.daughters
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='family_data.daughters'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Education qualification</h6>
                                            <Field
                                                as='select'
                                                name='personal_details.educational_qualification'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.educational_qualification && touched && touched.personal_details && touched.personal_details.educational_qualification
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value="No Education">No Education</option>
                                                <option value="10th">10th</option>
                                                <option value="12th">12th</option>
                                                <option value="Graduate">Graduate</option>
                                                <option value="Post Graduate">Post Graduate</option>
                                            </Field>
                                            <ErrorMessage
                                                name='personal_details.educational_qualification'
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <h6 style={{ color: "blue" }} className="required">Smoker/Non smoker</h6>
                                            <Field
                                                as='select'
                                                name='x'
                                                className={
                                                    "form-control" +
                                                    (errors && errors.personal_details && errors.personal_details.smoker && touched && touched.personal_details && touched.personal_details.smoker
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            >
                                                <option value=""></option>
                                                <option value={true}>Smoker</option>
                                                <option value={false}>Non Smoker</option>
                                            </Field>
                                            <ErrorMessage
                                                        name='personal_details.smoker'
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
                                        <Button color='primary' type='submit' variant='contained'>Save Details</Button>
                                    </div>
                                </Form>
                            }}
                        </Formik>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default NewCustomer