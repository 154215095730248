import React from 'react'
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormElement from '../../../uielements/FormElement';
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { loanService } from "../../../../_services/loan.service";
import { Edit } from "@material-ui/icons";
import { employeeService } from '../../../../_services';
import displayhelper from '../../../../_helpers/displayhelper';


const useStyles = makeStyles((theme) => ({
    modal: {
        width: "50%",
        overflow: "scroll",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {
        minWidth: 650,
    },
}));



const DisbursementData = (props) => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [show, setShow] = useState(false)
    const formikRef = React.useRef();
    const [preEmi, setPreEmi] = useState(0)
    const [emi, setEmi] = useState(0)
    const [deduction, setDeduction] = useState(0)


    const user = employeeService.userValue;
    const customer = props.customer;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const preEmiCalculator = (value, setFieldValue) => {
        let amount, diff, pre_emi;
        setFieldValue('disbursement_date', value)
        console.log("running", new Date(value))
        if (customer && customer.loan && customer.loan.sanction_amount) {
            amount = Number(customer.loan.sanction_amount)
        } else {
            amount = 0
        }
        if (value !== "") {
            setShow(true)
            // if (new Date(value).getDate() > 20 || new Date(value).getDate() < 6) {
                let date = new Date(value)
                date.setHours(0,0,0,0)
                date.setDate(5)
                let selectedDate = new Date(value)
                selectedDate.setHours(0,0,0,0)
                if(new Date(value).getDate()<6){
                    date.setMonth(new Date(value).getMonth())
                }else{
                date.setMonth(new Date(value).getMonth() + 1)
                }

                diff = new Date(date.getTime() - selectedDate.getTime())
                // console.log('date.getTime() - selectedDate.getTime()',date.getTime() , selectedDate.getTime(),diff)
                diff = Math.ceil(diff / (1000 * 3600 * 24))

                if (customer && customer.loan && customer.loan.roi) {

                    pre_emi = Math.ceil(amount * customer.loan.roi * diff / 36000)  // 360 days * 100 (roi wala)
                    console.log(pre_emi)
                    console.log(diff)
                    // setPreEmi(pre_emi)
                } else {
                    pre_emi = 0
                    // setPreEmi(pre_emi)
                }
            // }
            // else{
            //     let date = new Date(value)
            //     date.setHours(0,0,0,0)
            //     date.setDate(5)
            //     let selectedDate = new Date(value)
            //     selectedDate.setHours(0,0,0,0)
                
            //     date.setMonth(new Date(value).getMonth() + 1)
                 

            //     diff = new Date(date.getTime() - selectedDate.getTime())
            //     diff = Math.ceil(diff / (1000 * 3600 * 24))

            //     if (customer && customer.loan && customer.loan.roi) {
            //         pre_emi = Math.ceil(amount * customer.loan.roi * diff / 36000)
            //         console.log('pre_emi',pre_emi)
            //         console.log(diff)
            //         setPreEmi(pre_emi)
            //     } else {
            //         pre_emi = 0
            //         setPreEmi(pre_emi)
            //     }
            //     // setPreEmi(0)
            // }   
        }
        getEMI() 
    }

    function getEMI() {
        let rate, numerator, denominator
        if(customer && customer.loan && customer.loan.roi){
            rate = Number(customer.loan.roi) / 1200;
        }
        if(customer && customer.loan && customer.loan.sanction_amount && customer.loan.tenure){
            numerator = Number(customer.loan.sanction_amount) * rate * Math.pow(1 + rate, Number(customer.loan.tenure));
            denominator = Math.pow(1 + rate, Number(customer.loan.tenure)) - 1;
        }
    
        setEmi (Math.ceil(numerator / denominator));
      }

    const initialValues = {
        disbursement_date: '',
    }

    const validationSchema = Yup.object().shape({
        disbursement_date: Yup.date().required("This filled is required"),
    })

    const onSubmit = (fields) => {
        let params = customer.loan
        console.log({ ...params, pre_emi_amount: preEmi, disbursement_date: fields.disbursement_date, emi: emi, disbursement_date_saved_by: user.emp_id })
        let updatedObj = { loan: { ...params, pre_emi_amount: preEmi, disbursement_date: fields.disbursement_date, emi: emi, disbursement_date_saved_by: user.emp_id} }
        console.log(updatedObj)
        loanService.update(customer._id, updatedObj)
            .then((data) => {
                console.log(data)
                setOpen(false)
                alert("Successfully Added")
                window.location.reload();
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const deductionAmount = () => {
        let deductionAmount = 0
        if(customer && customer.loan && customer.loan.processing_fees){
            deductionAmount = deductionAmount + Number(customer.loan.processing_fees)
        }
        if(customer && customer.loan && customer.loan.processing_fees){
            let gst = Number(customer.loan.processing_fees)*18/100
            deductionAmount = deductionAmount + gst
        }
        if(customer && customer.loan && customer.loan.stamp_duty){
            deductionAmount = deductionAmount + Number(customer.loan.stamp_duty)
        }
        if(customer && customer.loan && customer.loan.insurance_amount){
            deductionAmount = deductionAmount + Number(customer.loan.insurance_amount)
        }
        if(customer && customer.loan && customer.loan.imd_deduction){
            deductionAmount = deductionAmount + Number(customer.loan.imd_deduction)
        }
        // if(customer && customer.loan && customer.loan.imd_deduction){
        //     let gst = Number(customer.loan.imd_deduction)*18/100
        //     deductionAmount = deductionAmount + gst
        // }
        if(customer && customer.loan && customer.loan.other_charges){
            deductionAmount = deductionAmount + Number(customer.loan.other_charges)
        }
        if(customer && customer.loan && customer.loan.internal_bt_deduction){
            deductionAmount = deductionAmount + Number(customer.loan.internal_bt_deduction)
        }
        if(preEmi){
            deductionAmount = deductionAmount + Number(preEmi)
        }
         setDeduction(deductionAmount)
        return (deductionAmount)
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="primary">
                <Edit></Edit>
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            innerRef={formikRef}
                        >
                            {({ errors, touched, values, isSubmitting, setFieldValue }) => {
                                return (
                                    <Form>
                                        <div className={classes.paper}>
                                            <h2 style={{ marginTop: "10px" }}>Disbursement Details</h2>
                                            <div style={{ display: "flex", justifyContent: "center", marginTop:"20px" }}>

                                                <label style={{ width: "200px" }}>Disbursement Booking Date
                                                    <Field
                                                        name="disbursement_date"
                                                        type="date"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onChange={(e) => preEmiCalculator(e.target.value, setFieldValue)}
                                                        className={
                                                            "form-control" +
                                                            (errors.disbursement_date && touched.disbursement_date ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="disbursement_date"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </label>

                                            </div><br />

                                            {show ? <table style={{fontSize:"20px", width:"70%", marginLeft:"15%"}}>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Sanction Amount</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && customer.loan.sanction_amount && displayhelper.displayNumberAccounting(customer.loan.sanction_amount)}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>ROI(%)</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.roi)}%</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>PF Rate(%)</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.pf_rate)}%</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Processing Fee</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.processing_fees)}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", color:"blue", fontSize:"13px", textAlign:"left" }}>GST@18%</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && customer.loan.processing_fees && (Math.ceil(Number(customer.loan.processing_fees)*18/100))}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Stamp Duty</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.stamp_duty)}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Insurance Amount</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.insurance_amount)}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Pre EMI</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{preEmi || 0}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>IMD Charge Deduction</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.imd_deduction) || 0}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Internal BT Deduction</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.internal_bt_deduction) || 0}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Other Charges <br/> <span style={{fontSize:"18px"}}>(Comment: {customer && customer.loan && (customer.loan.other_charges_comment)})</span></th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{customer && customer.loan && (customer.loan.other_charges ) || 0}</th>
                                                </tr>
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>Deduction</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{Math.ceil(deductionAmount())}</th>
                                                </tr>
                                                {/* <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left" }}>EMI</th>
                                                    <th style={{ border: "1px solid", textAlign:"right" }}>{emi}</th>
                                                </tr> */}
                                                <tr style={{ border: "1px solid" }}>
                                                    <th style={{ border: "1px solid", textAlign:"left", color:"red" }}> Pending Disbursement Amount</th>
                                                    <th style={{ border: "1px solid", textAlign:"right", color:"red" }}>{customer && customer.loan && customer.loan.sanction_amount && (Math.ceil(Number(customer.loan.sanction_amount) - deduction))}</th>
                                                </tr>
                                            </table> : null}<br />
                                            <Fab
                                                type="submit"
                                                color="primary"
                                                aria-label="save"
                                            >
                                                <SaveIcon />
                                            </Fab>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Typography>
                </Fade>
            </Modal>
        </div>
    )
}

export default DisbursementData