import React, { useEffect } from "react";
import { Delete } from "@material-ui/icons";
import { employeeService } from "../../../../_services";
import roleChecker from "../../../../_helpers/rolechecker";
export default function AddPaymentSheetItem({
  item,
  idx,
  handleChange,
  handleRemoveSpecificRow
}) {
  const user = employeeService.userValue;
  const {
    payment_date,
    payment_type,
    payment_mode,
    payment_ref,
    payment_amount,
  } = item;


  return (
    <tr id="addr0" key={idx}>
      <td style={{ textAlign: "center" }}>{idx + 1}</td>
      <td>
        <input
          type="date"
          onKeyDown={(e) => e.preventDefault()}
          name="payment_date"
          value={payment_date}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <select
          name="payment_type"
          value={payment_type}
          onChange={handleChange(idx)}
          className="form-control"
        >
          <option value="disbursement">Disbursement</option>
          <option value="collection">Collection</option>
          <option value="pf">PF</option>
        </select>
      </td>
      <td>
        <select
          name="payment_mode"
          value={payment_mode}
          onChange={handleChange(idx)}
          className="form-control"
        >
          <option value="cash">Cash</option>
          <option value="online">Online</option>
          <option value="cheque">Cheque</option>
          <option value="bank_transfer">Bank Transfer</option>
        </select>
      </td>

      <td>
        <input
          type="text"
          name="payment_ref"
          value={payment_ref}
          onChange={handleChange(idx)}
          className="form-control"
        />
      </td>
      <td>
        <input
          type="number"
          min="1"
          name="payment_amount"
          step="1"
          value={payment_amount}
          onChange={handleChange(idx)}
          className="form-control"
          required
        />
      </td>
      <td>
      <a className="btn btn-sm" onClick={handleRemoveSpecificRow(idx)}>
          <Delete />
        </a>
      </td>
    </tr>
  );
}
