import React, {useEffect,useState} from 'react'
import LoadingOverlay from "react-loading-overlay";
import { Paper } from "@material-ui/core";
import enach_collect_payment from "../../../assets/enach_collect_payment.csv"
import { enachService } from '../../../_services/enach.service';
import { employeeService,customerService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import EnachCollectRequestModal from './EnachCollectRequestModal';
import swal from 'sweetalert';
import EnachInProgress from "./EnachInProgress"
const EnachCollectPayments = () => {

  const [spinneractive, setSpinneractive] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [state, setState] = React.useState(false)
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = useState([]); // Holds all data
  const [inProcessData, setInProcessData] = useState([]); // Holds "In Process" data
  const [selectedOption, setSelectedOption] = useState('All'); // 'All' or 'In Process'
  const user = employeeService.userValue;

  const arr = []


  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getAllData = () =>{
    enachService.getPaymentCollections()
    .then((x) => {
      console.log(x);
      setAllData(x);
      if (selectedOption === 'All') setData(x);  
    })
    .catch((err) => {
      console.log("Data Not Found", err)
    })
  }

  const getInProcessData = () => {
    enachService.getNachInProcess()
      .then((x) => {
        console.log(x, 'dddddddddddddddddddddddd');
        setInProcessData(x);
        if (selectedOption === 'In Process') setData(x);
      })
      .catch((err) => {
        console.log('Data Not Found', err);
      });
  };
  const handleFilterChange = (option) => {
    setSelectedOption(option);
    if (option === 'All') {
      setData(allData);
    } else if (option === 'In Process') {
      setData(inProcessData);
    }
  };
  useEffect(() => {
    getAllData();
    getInProcessData();
  }, [])
  

  const onClickHandler = async () => {
    setSpinneractive(true);

    const data = new FormData();
    data.append("file", selectedFile);
    console.log(data.entries())
    for (var key of data.entries()) {
      console.log(key[0] + ', ' + key[1]);
  }  

    await enachService.uploadEnachCollectionCsv(data,user.emp_id).then((response) => {
      // setSpinneractive(false);
    
      if (!response.ok) {
        alert("An Error Occurred, Please try again");
      } else {
        alert("Data Uploaded");
        window.location.reload();
      }
    });
  };


  const show = () => {
    setState(!state)
  }
  
  // const [processEnach, setProcessEnach] = useState(null);
  // const getProcess_Enach = async () => {
  //   try {
      
  //     // Assuming customerService.getProcessEnach is a function that makes a GET request
  //     const data = await customerService.getProcessEnach(); // No need to pass any parameters here
  //     setProcessEnach(data);
  //     console.log(processEnach?.status,"processEnach?.status")
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };


  const getProcess_Enach = async () => {
    try {
      // Displaying pending message
      swal({
        title: 'Please wait. Do not refresh; otherwise, your task will restart.',
        icon: 'info',
        button: false,
      });
      // Making API call to get process status
      const data = await customerService.getProcessEnach();
      // Setting process status
      // Closing pending message
      swal.close();
      // Displaying success or failure message based on status
      if (data.status === 'success') {
        swal('Success', 'Process completed successfully!', 'success');
      } else {
        swal('Error', 'Process failed!', 'error');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      swal('Error', 'Failed to fetch data!', 'error');
    }
  };

  return (
    <>
        <LoadingOverlay active={spinneractive} spinner text="Uploading Data...">

     <Paper  style={{
              padding: 20,
            }}>
              <h2 style={{textAlign: "center"}}>ICICI Enach Payment Collection List</h2><hr></hr>

<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
  <EnachCollectRequestModal />

  <button
    className="btn"
    style={{
      width: "9rem",
      backgroundColor: "green",
      color: "#ffff",
      marginTop: "1rem",
      marginLeft: "1rem",  // Added margin to space the button from others
    }}
    onClick={show}
  >
    Upload CSV File
  </button>
  <button
    style={{
      width: "9rem",
      backgroundColor: "#C65DAD",
      color: "#ffff",
      marginTop: "1rem",
      marginLeft: "1rem",  // Added margin to space the button from others
    }}
    className="btn"
    onClick={getProcess_Enach}
  >
    Process Enach
  </button>
  <EnachInProgress />
</div>

           { state === true ? <div className="container">
              <div className="offset-md-3 col-md-6">
                <form method="post" action="#" id="#">
                  <div className="form-group files">
                    <label>Upload Your File</label>
                    <input
                    style={{height: "30px"}}
                      accept=".csv"
                      type="file"
                      className="form-control"
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={() => onClickHandler()}
                  >
                    Upload
                  </button>
                </form>
                <div >
                  <a href={enach_collect_payment} download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File <b>"date format as dd/mmy/yyyy Very strict"</b></a>
                </div>
              </div>
            </div> : null}
           
     </Paper>
     </LoadingOverlay>
     <select
          value={selectedOption}
          onChange={(e) => handleFilterChange(e.target.value)}
        >
          <option value="All">All</option>
          <option value="In Process">In Process</option>
        </select>
      <table>
        <thead>
          <tr>
            <th>Enach Ref Code</th>
            <th>Amount</th>
            <th>Collection Date</th>
            <th>Collection Status</th>
            <th>Collection Comment</th>
            <th>Collected By</th>
          </tr>
        </thead>
        <tbody>
        {data && data.length > 0 ? (
  data.map((element) => ( <tr key={element._id}>
            <td>{element.reference_code}</td>
            <td>{element.amount}</td>
            <td>{datehelper.displayDate(new Date(element.collection_date))}</td>
            <td>{element.collection_status==='Failed' && (<span style={{'color':'red'}}>Failed</span>)}{element.collection_status!='Failed' && element.collection_status }</td>
            <td>{element.collection_comment}</td>
            <td>{element.collection_emp_id}</td>
          </tr>
       ))
      ) : (
        <tr>
          <td colSpan="6" style={{ textAlign: 'center' }}>No data found</td>
        </tr>
      )}
        </tbody>
      </table>

    </>
  )
}

export default EnachCollectPayments