import React ,{useRef} from 'react'
 import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, FormControlLabel, ServerStyleSheets,Checkbox } from "@material-ui/core";
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { misService } from '../../_services';
import { useEffect } from 'react';
import {reconcile} from "../../_services/reconcile.service"
import { loanService } from '../../_services';
import roleChecker from '../../_helpers/rolechecker';
import { employeeService } from '../../_services';
import { Box  ,FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import { allcloudService } from '../../_services/allcloud.service';
 

const ReconcileWithAutoCloudModal = (props) => {
// console.log("propssss",props)
  const [open, setOpen] = React.useState(false);
  const [open1,setOpen1]=React.useState(false)
  const [open2,setOpen2]=React.useState(false)
  const [cust, setCust] = React.useState({});
  const [sblCust, setSblCust] = React.useState({})
  const [customerInfo,setCustomerInfo]=React.useState()
  const [ledgers,setLedgers]=React.useState([])
  const [ledgerCode,setLedgerCode] = React.useState([]);
   const user = employeeService.userValue
  const formikRef = useRef(null);
const [paymentSchedule,setPaymentSchedule] = React.useState()
const [isForClouserPayment,setIsForClouserPayment] =React.useState(false)

const application_number = props.application_id
  const [values, setValues] = React.useState({
    loan_number: "",
    department: ""
  })
 
  
const initialValues = {
  financeId:'',
   modeOfPayment: '',
  ledger: '',
  amount: props.txn_amt,
  lpc: '',
   date: '',
   channel: '',
   instrumentNo:''
  };

const validationSchema = Yup.object().shape({
  modeOfPayment: Yup.string().required('Mode of Payment is required'),
  financeId: Yup.string().required('Finance ID is required for all payments'),
   ledger: Yup.string().required('ledger is required for all payments'),
  amount: Yup.number().min(0, 'Amount must be a non-negative number').required('Amount is required'),
  date: Yup.date().required('Date is required'),
  channel: Yup.string().when('modeOfPayment', {
    is: 'Bank',
    then: Yup.string().required('Channel is required for Bank payment'),
    otherwise: Yup.string().notRequired(),
  }),
  lpc: Yup.number()
    .min(0, 'LPC must be a non-negative number') // Min value of 0
    .nullable()
});


const handleOpen = () => setOpen(true);
const handleClose1 = () => {setOpen1(false); setOpen(false)};
 const handleClose2 = () => {setOpen2(false); setOpen(false) ;setCustomerInfo([]);setIsForClouserPayment(false)};
   const handleChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value})
    // console.log(values)
   }

   const handleSubmit = async () => {
    await misService.getLoanByAccount(values.loan_number)
     .then((data) => {
       setCust(data[0])
       console.log(data[0])
     })
     .catch((err)=> {
       console.log(err, "Error")
     })
   }

     

   const reconcile_date = props.txn_date; 
   const reconcile_amt = parseInt(props.txn_amt);
   const reconcile_mode = props.payment_mode;
   const payment_ref = props.payment_ref;
   const reference_id = props.reference_id;
   const loan_type =  values.department;
   const payment_type = "collection";
   const loan_account = values.loan_number;
   const [emi, setEmi]= React.useState(0);
   const[other_charges, setOther_charges]=React.useState(0);
   const[principle_amt, setPrinciple_amt]=React.useState(0);

   const addData = async (e) => {
    e.preventDefault();
    let total= Number(emi)+Number(other_charges)+Number(principle_amt)
     
      const temp = {reconcile_date, reconcile_amt, reconcile_mode, reference_id, payment_ref, loan_type, payment_type, loan_account}
      console.log(temp);
      await reconcile.reconcileData(temp)
      .then((data) => {
       alert("Data Added Successfully")
      //  handleClose()
       window.location.reload()
      })
      .catch((err)=> {
        alert("Something went Wrong", err)
      })
  
   }

   const handleSbl = async () =>{
    await loanService.getBySearch({...values, application_id: values.loan_number})
    .then((x)=>{
      setSblCust(x[0])
      console.log(x[0])
    })
    .catch((err)=>{
      console.log(err)
    })
   }

   const addSbl = async (e) => {
    e.preventDefault();
 
      const temp = {reconcile_date, reconcile_amt, reconcile_mode, reference_id, payment_ref, loan_type, payment_type, loan_account}
      console.log(temp);
      if(values.loan_number === ""){
        alert("Please fill all the fields")
      }else{
        await reconcile.reconcileSblData(temp)
        .then((data) => {
         alert("Data Added Successfully")
        //  handleClose()
         window.location.reload()
        })
        .catch((err)=> {
          alert("Something went Wrong")
          console.log(err)
        })
      }

   }

   const reconcileSblWithAllCloud = async (  ) => {
    const reconcile_date = props.txn_date; 
    const reconcile_amt = parseInt(props.txn_amt);
    const reconcile_mode = props.payment_mode;
    const payment_ref = props.payment_ref;
    const reference_id = props.reference_id;
    const loan_type =  'SBL';
    const payment_type = "collection";
    const loan_account = application_number;
      const temp = {reconcile_date, reconcile_amt, reconcile_mode, reference_id, payment_ref, loan_type, payment_type, loan_account}
      console.log(temp);
       
        await reconcile.reconcileSblData(temp)
        .then((data) => {
         alert("Data Added Successfully")
        //  handleClose()
         window.location.reload()
        })
        .catch((err)=> {
          alert("Something went Wrong")
          console.log(err)
        })
       
 
   }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    //paddingBottom: '30px',
  };


  useEffect(() => {
    if (!open || !application_number) {
      return;
    }
  
    loanService.getBySearch({ application_id: application_number })
      .then((res) => {
        console.log("response is", res);
        setCustomerInfo(res);
        if (res.length > 0 && res[0].stage && (res[0].stage === "Collection" || res[0].stage ==="Unreviewed")) {
          setOpen2(true);
        } else {
          setOpen1(true);
        }
      })
      .catch(err => console.log("err", err));

      allcloudService.getAllCloudLedgers()
      .then((res)=>{console.log("res res",res);setLedgerCode(res)})
      .catch(err=>console.log('err',err))

    allcloudService.getAllCloudFinanceId({application_id:application_number})
    .then(res=>{console.log("response of finance id is",res);  formikRef.current.setFieldValue("financeId",res.allcloud_file_num) ;setPaymentSchedule(res) })
    .catch(err=>console.log('err',err))


  }, [open, application_number]);


const handleSubmitForm=(values,{setSubmitting})=>{
  console.log('values of form ',values)
  let finalSubmitValue={};
  console.log("values.modOfPayment",values.modeOfPayment,values.modeOfPayment=="Bank");
  if(values.modeOfPayment=="Bank"){

    finalSubmitValue['FinanceId']=values.financeId;
    finalSubmitValue['LedgerCode']=values.ledger;
    finalSubmitValue['ReceivedDate']=values.date;
    finalSubmitValue['Amount']=values.amount;
    finalSubmitValue['LPC']=values.lpc;
    finalSubmitValue['ChequeNo']=values.instrumentNo;
    finalSubmitValue['LoanReceiptMode']=values.modeOfPayment;
    finalSubmitValue['BankChannelType']=values.channel;
    finalSubmitValue['PolicyPaymentTypeId']="EMIPayment";
    finalSubmitValue['DiscountAmount']=0;
  }
  else if(values.modeOfPayment=="Cash"){

                        finalSubmitValue['FinanceId']=values.financeId;
                        finalSubmitValue['LedgerCode']=values.ledger;
                        finalSubmitValue['ReceivedDate']=values.date;
                        finalSubmitValue['Amount']=values.amount;
                        finalSubmitValue['LPC']=values.lpc;
                        finalSubmitValue['ChequeNo']=values.instrumentNo;
                        finalSubmitValue['LoanReceiptMode']=values.modeOfPayment;
                        finalSubmitValue['PolicyPaymentTypeId']="EMIPayment";
                        finalSubmitValue['DiscountAmount']=0;
                        finalSubmitValue['BankChannelType']="Select";
   } 
  console.log('finalSubmitValue',finalSubmitValue)
  
allcloudService.saveRepaymentInAllCloud(finalSubmitValue)
.then(res=>{
  console.log('allcloud res is',res)
  if(res && res.status==="success"){
    reconcileSblWithAllCloud()
      
  } 
    setSubmitting(false)
 
})
.catch(err=>{console.log('allcloud err is',err);  setSubmitting(false)
})

}

 
const handleCheckboxChange = async(e) => {
  setIsForClouserPayment(e.target.checked);
  console.log('e.target.checked', e.target.checked);
  console.log('formikRef.current',formikRef.current)
  // Check if formikRef has been assigned
  if (formikRef.current) {
    // Access setFieldValue from formikRef
    formikRef.current.setFieldValue("financeId", paymentSchedule.allcloud_file_num);
  }
};
  // console.log("setCustomerInfo",customerInfo)
  // console.log("initial values",initialValues)
  // console.log('ledgerCode',ledgerCode)
  // console.log('setPaymentSchedule',paymentSchedule)
  return (
    <> 
      <Button style={{ color: "white", backgroundColor: "#A020F0", borderRadius: "5px", padding: "5px", textStyle: "none" }} onClick={handleOpen}>Reconciliation</Button>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h2">

            <h2>Reconcile Payment</h2><br />

            <form >
            <div className='my-3' >
            <select className="form-select form-select-sm" style={{width: "700px"}}  name='department' aria-label=".form-select-sm example"  onChange={handleChange} required>
              <option selected>Select the Department</option>
              <option >Gold</option>
              <option >SBL</option>
            </select> 
            </div>
              <div className='my-3'>
              <input type="text" className="form-control"  placeholder='Enter the Loan Account Number'  name='loan_number'  onChange={handleChange} required/>
              </div>
              {values.department === "Gold" ?  <div>
                <button className='btn btn-primary' type='button'  onClick={handleSubmit} >Search</button><br/><br/>
              </div> : <div> 
              <button className='btn btn-primary' type='button'  onClick={handleSbl} >Search</button><br/><br/></div>}
      
            </form>
           {values.department === "SBL" && (
            sblCust && sblCust.applicant && sblCust.applicant.general_information ?
              <div>
                Amount Received : {reconcile_amt} <br/>
                <p>{sblCust.applicant.general_information.name}</p>
               <form style={{display:'flex', flexWrap:'wrap'}} onSubmit={(e)=>addSbl(e)} >
               {/* <label>Interest Amount: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={emi} type="number" name='emi' onChange={(e)=>setEmi(e.target.value)} required/> </label>
               <label>Principal Amount: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={principle_amt}  name="principle_amt" type="number"  onChange={(e)=>setPrinciple_amt(e.target.value)} required/> </label>
               <label>Other Charges: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={other_charges}  name='other_charges' type="number" onChange={(e)=>setOther_charges(e.target.value)} required/> </label> */}
             <button className='btn btn-danger' >Reconcile</button>
             </form> 
             </div> : <span style={{color: "red"}}>***No Data Found***</span>
            )}
            {values.department === "Gold" &&(
              cust&&cust.name ? <div>
                Amount Received : {reconcile_amt} <br />
                <p>{cust.name}</p>
                <form style={{display:'flex', flexWrap:'wrap'}} onSubmit={(e)=>addData(e)} >
                 
                  {((cust.loan_status === "Locked"&&!roleChecker.hasAccountsAccess(user.role)) || ((cust.loan_status === "Closed" || cust.loan_status === "closed")&&!roleChecker.hasAccountsAccess(user.role))) ? <span>***Loan is Closed***</span> : <button className='btn btn-danger' >Reconcile</button> }
                </form>           
              </div>: <span style={{color: "red"}}>***No Data Found***</span>
            )}

          </Typography>

        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          <Button style={{float:'right',margin:'-30px'}} onClick={()=>handleClose2()}><CloseIcon/>  </Button>
          {/* {console.log("paymentSchedule.emi_schedule[0].emi_amount",paymentSchedule?.emi_schedule[0].emi_amount
 )} */}
          {
            paymentSchedule?.emi_schedule[0]?.emi_amount && <p> EMI Amount  : {paymentSchedule.emi_schedule[0].emi_amount} </p>
          }
          
    <div>
              <div>
              <input type='checkbox' onChange={(e)=> handleCheckboxChange(e)}/> Check if you   want to reconcile only
              </div>
             
               {isForClouserPayment && 
               <>                <h5>Application ID : {application_number}</h5>
              <h5>Amount Received : {props.txn_amt}</h5>
               <Button color='primary' variant='contained' onClick={()=>{if(window.confirm("are you sure you want to reconcile only")){reconcileSblWithAllCloud()}}}>Reconcile</Button></>
}
            </div>
          { !isForClouserPayment && <Formik
            innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            >

              {({ errors, touched, isSubmitting, setFieldValue, values}) => (
                <Form>
                  <div style={{padding:'10px'}}>
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',fontSize:'medium'}}>

                     
                      <label className="required" >Mode of Payment</label>
                      <Field   style={{    width:'80%',
    height: '40px',
    border: '1px solid darkgray',
    borderRadius: '5px'}} as='select' name="modeOfPayment"    variant="outlined"  onChange={(e) => {
              setFieldValue('modeOfPayment', e.target.value); 
              if (e.target.value !== values.modeOfPayment) {
                setFieldValue('ledger', '');  
                setFieldValue('amount', props.txn_amt);
                setFieldValue('lpc', '');  
                 setFieldValue('date', '');
                setFieldValue('channel', '');  
               
              }
              const filteredLedgerCode = ledgerCode.filter((val) => val.mode === e.target.value);
              setLedgers(filteredLedgerCode);

          
            }} >
             <option value="" disabled>Select Mode</option>
                        <option value="Bank">Bank</option>
                        <option value="Cash">Cash</option>
                       </Field>
                      <ErrorMessage name="modeOfPayment" component="div" className="invalid-feedback"/>
                     
                  
</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label>Finance Id</label>
                <div style={{ width: '80%' }}>
                  <Field
                    as={TextField}
                    type="text"
                    name="financeId"
                    label="Finance Id"
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.financeId && touched.financeId ? " is-invalid" : "")}
                  />
                  <ErrorMessage name="financeId" component="div" className="invalid-feedback"/>
                </div>
              </div>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',fontSize:'medium'}}>

                     
<label className="required" >Ledger Code</label>
<Field   style={{    width:'80%',
height: '40px',
border: '1px solid darkgray',
borderRadius: '5px'}} as='select' name="ledger"    variant="outlined"   >
<option value="" disabled>Select Ledger</option>
{ledgers.map((e)=>{

 return <option value={e.ledger_code}>{e.bank_name}-{e.ledger_code}</option>
})}
  </Field>
<ErrorMessage name="ledger" component="div" className="invalid-feedback"/>


</div>
                  
                   {/* {(values.modeOfPayment==="Bank" || values.modeOfPayment==="Cash")  &&  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label className="required">Ledger</label>
                <div style={{ width: '80%' }}>
                  <Field
                   as={TextField}
                     type="text"
                    name="ledger"
                    label="Ledger"
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.ledger && touched.ledger ? " is-invalid" : "")}
                  />
                  <ErrorMessage name="ledger" component="div" className="invalid-feedback"/>
                </div>
              </div>} */}


              
                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label className="required">Amount</label>
                <div style={{ width: '80%' }}>
                  <Field
                   as={TextField}
                     type="number"
                    name="amount"
                    label="Amount"
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.amount && touched.amount ? " is-invalid" : "")}
                     
                  />
                  <ErrorMessage name="amount" component="div" className="invalid-feedback"/>
                </div>
              </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label>LPC</label>
                <div style={{ width: '80%' }}>
                  <Field
                   as={TextField}
                     type="text"
                    name="lpc"
                    label="LPC"
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.lpc && touched.lpc ? " is-invalid" : "")}
                    onChange={(e)=>{setFieldValue('lpc',e.target.value);setFieldValue('amount', props.txn_amt-e.target.value)}}
                  />
                  <ErrorMessage name="lpc" component="div" className="invalid-feedback"/>
                </div>
              </div>

{/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label>Collection Charges</label>
                <div style={{ width: '80%' }}>
                  <Field
                   as={TextField}
                     type="text"
                    name="collectionCharges"
                    label="Collection Charges"
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.collectionCharges && touched.collectionCharges ? " is-invalid" : "")}
                  />
                  <ErrorMessage name="collectionCharges" component="div" className="invalid-feedback"/>
                </div>
              </div> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label className="required">Date</label>
                <div style={{ width: '80%' }}>
                <Field
      as={TextField}
      type="date"
      name="date"
      label="Date"
      variant="outlined"
      onKeyDown={(e) => e.preventDefault()}
      size="small"
      InputLabelProps={{ shrink: true }}
      inputProps={{ max: (new Date()).toISOString().slice(0, 10) }} 
       className={"form-control" + (errors.date && touched.date ? " is-invalid" : "")}
    />
                  <ErrorMessage name="date" component="div" className="invalid-feedback"/>
                </div>
              </div>

                   {(values.modeOfPayment==="Bank" || values.modeOfPayment==="Cash") &&  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label className="required">Instrument No.</label>
                <div style={{ width: '80%' }}>
                  <Field
                   as={TextField}
                     type="text"
                    name="instrumentNo"
                    label="Instrument No."
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.instrumentNo && touched.instrumentNo ? " is-invalid" : "")}
                  />
                  <ErrorMessage name="instrumentNo" component="div" className="invalid-feedback"/>
                </div>
              </div>}
                   {values.modeOfPayment==="Bank" && <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',fontSize:'medium'}}>
                    <label className="required">Channel</label>
 
                    <Field   style={{    width:'80%',
    height: '40px',
    border: '1px solid darkgray',
    borderRadius: '5px'}} as='select' name="channel"    variant="outlined"   
          
              >
                                          <option value="" disabled>Select Channel</option>
                                          <option value="NEFT">NEFT</option>
                                          <option value="RTGS">RTGS</option>
                                          <option value="IMPS">IMPS</option>
                                          <option value="Others">Others</option>
                                          <option value="Deposit">Deposit</option>
                                          <option value="Cheque">Cheque</option>
                                          <option value="IFT">IFT</option>
                                          <option value="DD">DD</option>
                                          <option value="NACH">NACH</option>
                                          <option value="Prepayment">Prepayment</option>
                                          <option value="UPI">UPI</option>
                                          <option value="Wallet">Wallet</option>
                                        

                                           


                                        </Field>
                    <ErrorMessage name="channel" component="div" className="invalid-feedback" />
                     </div>
}
                    
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label>Voucher No.</label>
                <div style={{ width: '80%' }}>
                  <Field
                    as={TextField}
                    type="text"
                    name="voucherNo"
                    label="Voucher No."
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.voucherNo && touched.voucherNo ? " is-invalid" : "")}
                  />
                  <ErrorMessage name="voucherNo" component="div" className="invalid-feedback" />
                </div>
              </div> */}

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: 'medium' }}>
                <label>Comments</label>
                <div style={{ width: '80%' }}>
                  <Field
                   as={TextField}
                     type="text"
                    name="comments"
                    label="Comments"
                    variant="outlined"
                    size="small" // Apply size here
                    className={"form-control" + (errors.comments && touched.comments ? " is-invalid" : "")}
                  />
                  <ErrorMessage name="comments" component="div" className="invalid-feedback"/>
                </div>
              </div> */}
                  
</div>
                  <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                </Form>
              )}
            </Formik>}

            
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default ReconcileWithAutoCloudModal
