// import React from "react";
// import { Button, Paper, Typography } from "@material-ui/core";

// export default function DocumentVerification() {
//   const [id, setId] = React.useState("pan");
//   const [inp, setInp] = React.useState({});
//   const [verificationSuccessMessage, setVerificationSuccessMessage] = React.useState("");
//   const [verificationFailedMessage, setVerificationFailedMessage] = React.useState("");

//   function changeId(idValue)
//   {
//      setVerificationFailedMessage("");
//      setVerificationSuccessMessage("");
//      setId(idValue);
//   }

//   function printDocument()
//   {
//      window.print();
//   }
//   function verifyid(itemid, token) {
//     var info;

//     if (id === "pan")
//       info = {
//         name: inp.name,
//         number: inp.number,
//         fuzzy: "true",
//       };
//     else if( id ==="dl")
//       info = {
//         number: inp.number,
//         dob: inp.dob.toString().split("-").reverse().join("/"),
//         issueDate: inp.issueDate.toString().split("-").reverse().join("/"),
//       };
//     else if ( id =="vid")
//         info={
//           epicNumber: inp.number,
//           name: inp.name,
//           state: inp.state,
//         };

//     fetch("https://signzy.tech/api/v2/snoops", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         service: "Identity",
//         itemId: itemid,
//         accessToken: token,
//         task: "verification",
//         essentials: info,
//       }),
//     })
//       .then(async (response) => {
//         const data = await response.json();
//         console.log(response);
//         let verificationMessage="Request id:"+data.response.instance.id+" . "+data.response.result.message+".  ";
//         setVerificationSuccessMessage(verificationMessage);
//         if((id==='vid' && !data.response.result.verification) || ((id ==='pan' || id ==='dl') && !data.response.result.verified))
//            setVerificationFailedMessage(" Negative result. Please check carefully");
//         else
//            setVerificationFailedMessage("");
//       })
//       .catch((err) => {
//         console.log(err);
//         setVerificationSuccessMessage("");
//         setVerificationFailedMessage("Verification Failed");
//       });
//   }

//   const fetchIdentity = async () => {
//     // console.log('process.env.REACT_APP_SIGNZY_API_KEY',process.env.REACT_APP_SIGNZY_API_KEY)
//     var idname = "";
//     var imgarr = [];

//     if (id === "pan") {
//       idname = "individualPan";
//     } else if ( id ==="dl")  {
//       idname = "drivingLicence";
//     }
//     else if ( id ==="vid")  {
//       idname = "voterid";
//     }

//     fetch(
//       "https://signzy.tech/api/v2/patrons/5f432a53a748d43a72a10fed/identities",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           authorization:
//             process.env.REACT_APP_SIGNZY_API_KEY,
//         },
//         body: JSON.stringify({
//           type: idname,
//           callbackUrl: "https://samruddhi.kaabilfinance.com",
//           email: "dewanshu@kaabilfinance.com",
//           images: imgarr,
//         }),
//       }
//     )
//       .then(async (response) => {
//         const data = await response.json();
//         verifyid(data.id, data.accessToken);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   return (
//     <div >
  
//       <Paper
//         style={{
//           padding: 20,
//         }}
        
//       >
       
//        <div  className="row justify-content-center" style={{'margin-bottom':'20px'}}>
//           <Typography variant="h5">Verify ID</Typography>
//           </div>
//           <div  className="row d-flex justify-content-center">
//              <input
//               style={{ marginRight: 5, marginLeft:20 }}
//               type="radio"
//               id="pan"
//               name="id"
//               value="pan"
//               checked={id === "pan"}
//               onChange={() => changeId("pan")}
//             />
//             <label style={{ marginRight: 10 }} htmlFor="pan">
//               PAN
//             </label>
//             <input
//               style={{ marginRight: 5 }}
//               type="radio"
//               id="dl"
//               name="id"
//               value="dl"
//               checked={id === "dl"}
//               onChange={() => changeId("dl")}
//             />
//             <label htmlFor="dl">DL</label>
//             <input
//               style={{ marginRight: 5, marginLeft:5 }}
//               type="radio"
//               id="vid"
//               name="id"
//               value="vid"
//               checked={id === "vid"}
//               onChange={() => changeId("vid")}
//             />
//             <label htmlFor="vid">Voter Id</label>
//           </div>
//         <div className="row d-flex justify-content-around">
//           {id === "pan" && (
//             <div>
//               <input
//                 type="text"
//                 name="Name"
//                 placeholder="Customer Name"
//                 onChange={(e) => setInp({ ...inp, name: e.target.value })}
//               ></input>
//               <br />
//               <br />
//               <input
//                 type="text"
//                 name="PAN"
//                 placeholder="PAN Number"
//                 onChange={(e) => setInp({ ...inp, number: e.target.value })}
//               ></input>
//             </div>
//           )}
//           {id === "dl" && (
//             <div>
//               <input
//                 type="text"
//                 name="DL"
//                 placeholder="DL Number"
//                 onChange={(e) => setInp({ ...inp, number: e.target.value })}
//               ></input>
//               <br />
//               <br />
//               <label htmlFor="DOB">Date of Birth</label>
//               <br />
//               <input
//                 label="Date"
//                 type="date"
//                 name="DOB"
//                 placeholder="Date of Birth"
//                 onChange={(e) => setInp({ ...inp, dob: e.target.value })}
//               ></input>
//               <br />
//               <br />
//               <label htmlFor="Issue Date">Date of Issue</label>
//               <br />
//               <input
//                 type="date"
//                 name="Issue Date"
//                 placeholder="Issue Date"
//                 onChange={(e) => setInp({ ...inp, issueDate: e.target.value })}
//               ></input>
//             </div>
//           )}

//           {id === "vid" && (
//             <div>
//               <input
//                 type="text"
//                 name="VoterId"
//                 placeholder="EPIC Number"
//                 onChange={(e) => setInp({ ...inp, number: e.target.value })}
//               ></input>  <a href="https://electoralsearch.in/">Check on EC site</a>
//               <br />
//               <br />
//               <input
//                 type="text"
//                 name="Name"
//                 placeholder="Customer Name"
//                 onChange={(e) => setInp({ ...inp, name: e.target.value })}
//               ></input>
//               <br />
//               <br />
//               <input
//                 type="text"
//                 name="StateName"
//                 placeholder="State name"
//                 onChange={(e) => setInp({ ...inp, state: e.target.value })}
//               ></input>
//               <br />
//               <br/>
              
//             </div>
//           )}
//           {id === "aid" && (
//             <div>
//               <input
//                 type="text"
//                 name="AadharId"
//                 placeholder="Aadhar Id"
//                 onChange={(e) => setInp({ ...inp, number: e.target.value })}
//               ></input>  <a href="https://myaadhaar.uidai.gov.in/verifyAadhaar">Check on UIDAI site</a>
//               <br />
//               <br />
//               <input
//                 type="text"
//                 name="Name"
//                 placeholder="Customer Name"
//                 onChange={(e) => setInp({ ...inp, name: e.target.value })}
//               ></input>
    
              
//             </div>
//           )}
//         </div>
//         <br />
//         <div className="d-flex row justify-content-center">
//             <Button variant="contained" style={{'backgroundColor':'orange','color':'black'}} onClick={() => fetchIdentity()}>
//               Submit Details
//             </Button>
//         </div>
       
//           <br/>
//         <div className="row justify-content-center" style={{'margin-top':'20px'}}>
//           <span style={{color:'green'}}><b>{verificationSuccessMessage}</b></span>
//           <span style={{color:'red'}}><b>{verificationFailedMessage}</b></span>
//         </div>

//         <div className="row justify-content-center" style={{'margin-top':'20px'}}>
//         <Button variant="contained" style={{'backgroundColor':'gray','color':'white'}} onClick={() => printDocument()}>
//               Print
//             </Button>
//         </div>

        
//       </Paper>
//     </div>
//   );
// }


import React from "react";
import { Button, Paper, Typography } from "@material-ui/core";

export default function DocumentVerification() {
  const [id, setId] = React.useState("pan");
  const [inp, setInp] = React.useState({});
  const [verificationSuccessMessage, setVerificationSuccessMessage] = React.useState("");
  const [verificationFailedMessage, setVerificationFailedMessage] = React.useState("");

  function changeId(idValue)
  {
     setVerificationFailedMessage("");
     setVerificationSuccessMessage("");
     setId(idValue);
  }

  function printDocument()
  {
     window.print();
  }
  function verifyid(itemid, token) {
    var info;

    if (id === "pan")
      info = {
        name: inp.name,
        number: inp.number,
        fuzzy: "true",
      };
    else if( id ==="dl")
      info = {
        number: inp.number,
        dob: inp.dob.toString().split("-").reverse().join("/"),
        issueDate: inp.issueDate.toString().split("-").reverse().join("/"),
      };
    else if ( id =="vid")
        info={
          epicNumber: inp.number,
          name: inp.name,
          state: inp.state,
        };

    fetch("https://signzy.tech/api/v2/snoops", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        service: "Identity",
        itemId: itemid,
        accessToken: token,
        task: "verification",
        essentials: info,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        console.log(response);
        let verificationMessage="Request id:"+data.response.instance.id+" . "+data.response.result.message+".  ";
        setVerificationSuccessMessage(verificationMessage);
        if((id==='vid' && !data.response.result.verification) || ((id ==='pan' || id ==='dl') && !data.response.result.verified))
           setVerificationFailedMessage(" Negative result. Please check carefully");
        else
           setVerificationFailedMessage("");
      })
      .catch((err) => {
        console.log(err);
        setVerificationSuccessMessage("");
        setVerificationFailedMessage("Verification Failed");
      });
  }

  const fetchIdentity = async () => {
    // console.log('process.env.REACT_APP_SIGNZY_API_KEY',process.env.REACT_APP_SIGNZY_API_KEY)
    var idname = "";
    var imgarr = [];

    if (id === "pan") {
      idname = "individualPan";
    } else if ( id ==="dl")  {
      idname = "drivingLicence";
    }
    else if ( id ==="vid")  {
      idname = "voterid";
    }

    fetch(
      "https://signzy.tech/api/v2/patrons/5f432a53a748d43a72a10fed/identities",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization:
            process.env.REACT_APP_SIGNZY_API_KEY,
        },
        body: JSON.stringify({
          type: idname,
          callbackUrl: "https://samruddhi.kaabilfinance.com",
          email: "dewanshu@kaabilfinance.com",
          images: imgarr,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();
        verifyid(data.id, data.accessToken);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div >
  
      <Paper
        style={{
          padding: 20,
        }}
        
      >
       
       <div  className="row justify-content-center" style={{'margin-bottom':'20px'}}>
          <Typography variant="h5">Driving Licence Verify</Typography>
          </div>
        <div className="row d-flex justify-content-around">
      
            <div>
              <input
                type="text"
                name="DL"
                placeholder="DL Number"
                onChange={(e) => setInp({ ...inp, number: e.target.value })}
              ></input>
              <br />
              <br />
              <label htmlFor="DOB">Date of Birth</label>
              <br />
              <input
                label="Date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                name="DOB"
                placeholder="Date of Birth"
                onChange={(e) => setInp({ ...inp, dob: e.target.value })}
                style={{width:"11.5rem"}}
              ></input>
              <br />
              <br />
              <label htmlFor="Issue Date">Date of Issue</label>
              <br />
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                name="Issue Date"
                placeholder="Issue Date"
                onChange={(e) => setInp({ ...inp, issueDate: e.target.value })}
                style={{width:"11.5rem"}}
              ></input>
            </div>
        

         
     
        </div>
        <br />
        <div className="d-flex row justify-content-center">
            <Button variant="contained" style={{'backgroundColor':'orange','color':'black'}} onClick={() => fetchIdentity()}>
              Submit Details
            </Button>
        </div>
       
          <br/>
        <div className="row justify-content-center" style={{'margin-top':'20px'}}>
          <span style={{color:'green'}}><b>{verificationSuccessMessage}</b></span>
          <span style={{color:'red'}}><b>{verificationFailedMessage}</b></span>
        </div>

        <div className="row justify-content-center" style={{'margin-top':'20px'}}>
        <Button variant="contained" style={{'backgroundColor':'gray','color':'white'}} onClick={() => printDocument()}>
              Print
            </Button>
        </div>
      </Paper>
    </div>
  );
}
