import React, { useState ,useEffect} from "react";
import { Paper } from "@material-ui/core";
import urlhelper from '../../../../../_helpers/urlhelper'
import datehelper from '../../../../../_helpers/datehelper';
import roleChecker from '../../../../../_helpers/rolechecker';
import PreviewModal from "./PreviewModal"
import { employeeService } from '../../../../../_services';

function AlreadyUploadedDocuments({ cust }) {

  const [allDocuments, setAllDocuments] = useState([]);
  const [employeeList, setEmployeeList] = useState({});

  const styles = {
    paper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      paddingTop: "30px",
      paddingBottom: "30px",
      marginBottom: "30px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tableHeader: {
      border: "1px solid",
      padding: "10px",
      backgroundColor: "#f4f4f4",
      textAlign: "left",
    },
    tableRow: {
      border: "1px solid",
      padding: "10px",
    },
    tableData: {
      border: "1px solid",
      padding: "10px",
    },
    image: {
      width: "250px",
      height: "250px",
    },
    heading: {
      marginBottom: "20px",
    },
  };
  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      setEmployeeList(list);
    });
  }

  const renderKYCImages = (kycImages) => (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.tableHeader}>Category</th>
          <th style={styles.tableHeader}>Tag</th>
          <th style={styles.tableHeader}>Photo</th>
        </tr>
      </thead>
      <tbody>
        {kycImages.map((img, idx) => (
          <tr key={idx} style={styles.tableRow}>
            <td style={styles.tableData}>{img.category}</td>
            <td style={styles.tableData}>{img.tag}</td>
            <td style={styles.tableData}>
              {/* <img src={img.uri} alt="KYC Document" style={styles.image} /> */}
              {img.uri && img.uri!="" && <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(img.uri) + "&download=1"} target='_blank'>View Document</a>}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const gatherDocuments = () => {
    const docs = [];

    // Applicant's KYC
    if (cust?.applicant?.kyc_images?.length > 0) {
      cust.applicant.kyc_images.forEach((doc) => {
        if (doc.uri) {
          docs.push({
            ...doc,
            category: `Applicant ${cust.applicant?.general_information?.name} KYC`,
          });
        }
      });
    }

    // Co-applicants' KYC
    cust?.co_applicants?.forEach((coApplicant, index) => {
      if (coApplicant?.kyc_images?.length > 0) {
        coApplicant.kyc_images.forEach((doc) => {
          if (doc.uri) {
            docs.push({
              ...doc,
              category: `Co-Applicant ${coApplicant?.general_information?.name} KYC`,
            });
          }
        });
      }
    });

    // Guarantors' KYC
    cust?.guarantors?.forEach((guarantor, index) => {
      if (guarantor?.kyc_images?.length > 0) {
        guarantor.kyc_images.forEach((doc) => {
          if (doc.uri) {
            docs.push({
              ...doc,
              category: `Guarantor ${guarantor?.general_information?.name} KYC`,
            });
          }
        });
      }
    });

    // CIBIL requests
    if (cust?.cibil_requests?.length > 0) {
      cust.cibil_requests.forEach((doc) => {
        if (doc.report) {
          docs.push({
            uri: doc.report,
            name: doc.name || `${doc.first_name} ${doc.middle_name} ${doc.last_name}`,
            category: `CIBIL ${doc.name || `${doc.first_name} ${doc.middle_name} ${doc.last_name}`}`,
          });
        }
      });
    }


    // Collaterals
    if (cust?.property_details?.document_list?.length > 0) {
      cust.property_details.document_list.forEach((doc) => {
        if (doc.document_url) {
          docs.push({
            uri: doc.document_url,
            category: `Collateral ${doc.document_type}`,
          });
        }
      });
    }

    setAllDocuments(docs);
  };

 useEffect(() => {
    gatherDocuments(); // Gather documents once the component mounts
  }, [cust]);

  useEffect(()=>{
    getAllEmployees()
  },[])


  return (
    <div>

      <PreviewModal
        documents={allDocuments}
      />

      {/* Applicant's KYC */}
      {cust?.applicant?.kyc_images?.length > 0 && (
        <Paper style={styles.paper}>
          <div>
            <h4 style={styles.heading}>Applicant: {cust.applicant?.general_information?.name}'s KYC</h4>
            {renderKYCImages(cust.applicant.kyc_images)}
          </div>
        </Paper>
      )}

      {/* Co-Applicant's KYC */}
      {cust?.co_applicants?.length > 0 &&
        cust.co_applicants.map((val, ind) =>
          val.kyc_images?.length > 0 ? (
            <Paper key={`co-applicant-${ind}`} style={styles.paper}>
              <div>
                <h4 style={styles.heading}>Co-Applicant :   {val?.general_information?.name}'s KYC</h4>
                {renderKYCImages(val.kyc_images)}
              </div>
            </Paper>
          ) : null
        )}

      {/* Guarantor's KYC */}
      {cust?.guarantors?.length > 0 &&
        cust.guarantors.map((val, ind) =>
          val.kyc_images?.length > 0 ? (
            <Paper key={`guarantor-${ind}`} style={styles.paper}>
              <div>
                <h4 style={styles.heading}>Guarantor : {val?.general_information?.name}'s KYC</h4>
                {renderKYCImages(val.kyc_images)}
              </div>
            </Paper>
          ) : null
        )}

      {
        <div>
          <Paper style={styles.paper}>
            <h4>Cibil Data</h4>
            <table style={styles.table}>
              <thead style={{ borderBottom: "2px solid black" }}>
                <tr>
                  <th style={styles.tableHeader}>Date</th>
                  <th style={styles.tableHeader}>Name</th>
                  <th style={styles.tableHeader}>Role</th>
                  <th style={styles.tableHeader}>AADHAR</th>
                  <th  style={styles.tableHeader}>PAN/Voter</th>
                  <th style={styles.tableHeader}>Action</th>
                </tr>
              </thead>
              <tbody>
                {cust.cibil_requests && cust.cibil_requests.length > 0 && cust.cibil_requests.map((e, idx) => {
                  return <tr key={idx}>
                    <td  style={styles.tableData}>{datehelper.displayDate(new Date(e.date))}</td>
                    <td  style={styles.tableData}>{e.name || `${e.first_name} ${e.middle_name} ${e.last_name}`}</td>
                    <td  style={styles.tableData}>{e.role}</td>
                    <td  style={styles.tableData}>{e.aadhar}</td>
                    <td  style={styles.tableData}>{e.pan || e.verification_id}</td>
                    <td  style={styles.tableData}>
                      {e.report && e.report != "" && (
                        <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(e.report) + "&download=1"} target="_blank">View Report</a>
                      )}

                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </Paper>

        </div>
      }

      {
        <div>
          <Paper style={styles.paper}>
            <h4>Collateral Documents</h4>

            {cust?.property_details?.document_list?.length > 0 &&

              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.tableHeader}>Document Type</th>
                    <th style={styles.tableHeader}>LOD</th>
                    <th style={styles.tableHeader}>Document Link</th>
                  </tr>
                </thead>
                <tbody>
                  {cust.property_details.document_list.map((val, idx) => (
                    <tr key={idx} style={styles.tableRow}>
                      <td style={styles.tableData}>{val.document_type}</td>
                      <td style={styles.tableData}>{val.is_lod}</td>
                      <td style={styles.tableData}>
                        {/* <img src={img.uri} alt="KYC Document" style={styles.image} /> */}
                        {val.document_url && val.document_url!="" && <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(val.document_url) + "&download=1"} target='_blank'>View Document</a>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>}
          </Paper>
        </div>


      }

      
    </div>
  );
}

export default AlreadyUploadedDocuments;
