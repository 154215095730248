import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";
import { employeeService } from "../../../../_services";
import { allcloudService } from '../../../../_services/allcloud.service'
import AllCloudAppCoAppDiscription from './AllCloudAppCoAppDiscription'
import AllCloudAdditionalInfo from "./AllCloudAdditionalInfo";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from "@material-ui/core/CircularProgress";
import roleChecker from '../../../../_helpers/rolechecker';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
const useStyles = makeStyles((theme) => ({
  modal: {
    width: "75%",
    // overflow: "scroll",
    
   
    textAlign: "center",
    margin:"auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY:"scroll",
    height:"100vh"
  },
  table: {
    minWidth: 650,
  },
}));

export default function AllCloudPpreviewModal({ cust }) {
  console.log("application_id,cust", cust)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();
  const [applicant, setApplicant] = React.useState([]);
  const [coApplicants, setCoApplicants] = React.useState([]);
  const [guarantor, setGuarantor] = React.useState([]);
  const [addtionalInfo, setAdditionalInfo] = React.useState([]);
  const user = employeeService.userValue

  const [spinner, setSpinner] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [dynamicApplicants, setDynamicApplicants] = useState("")
  const [dynamicguarantor, setDynamicguarantor] = useState("")
  const handleOpen = async () => {
    setOpen(true);
  };

  const jsonLength = coApplicants.length;
  const options = [];
  for (let i = 1; i <= jsonLength; i++) {
    options.push(<option key={i} value={i}>Co-applicant {i}</option>);
  }

  const guarantorLength = guarantor.length;
  const guarantoroption = [];
  for (let j = 1; j <= guarantorLength; j++) {
    guarantoroption.push(<option key={j} value={j}>Guarantor {j}</option>);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const fetchAllData = (res) => {
    const coApplicantValues = [];
    const applicantValues = [];
    const guarantorValues = [];
    for (const key in res) {
      const arr = key.split(" ");
      console.log('arr', arr);
      if (arr[0] === "applicant") {
        applicantValues.push(res[key]);
      }
      if (arr[0] === "co-applicant") {
        coApplicantValues.push(res[key]);
      }
      if (arr[0] === "guarantor") {
        guarantorValues.push(res[key]);
      }
      if (arr[0] === 'LeadFinanceDTO') {
        setAdditionalInfo(res[key]);
      }

    }

    setApplicant(applicantValues);
    setCoApplicants(coApplicantValues);
    setGuarantor(guarantorValues);
  }

  const requestAllCloud = (application_id) => {
    console.log('application_id', application_id)
    allcloudService.requestAllCloud(application_id)
      .then(res => {
        console.log("requestAllCloud", res);
        window.location.reload()
      })
      .catch(err => console.log("err", err))
  }

  const verifyAllCloudloan = (application_id) => {
    console.log('application_id', application_id)
    allcloudService.verifyAllCloudloan(application_id)
      .then(res => {
        console.log("verifyAllCloud", res);
        window.location.reload()
      })
      .catch(err => console.log("err", err))
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setSpinner(true);
        const res = await allcloudService.allCloudPreview({
          loan_number: cust.application_id,
        });

        console.log("allcloud response", res);
        if (res.status === "success") {

          fetchAllData(res.preview);
        }
        setData(res.preview);
        setSpinner(false)
      } catch (err) {
        console.log("err", err);
        setSpinner(false)
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const changeCount = (index) => {
    setCount(index);
  }

  console.log(coApplicants[dynamicApplicants - 1], "Ssssssssssss")
  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="primary"
      >
        <VisibilityIcon />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} style={{marginTop:"4rem"}}>
          <div className={classes.paper}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
              <Button color='primary' variant="outlined" onClick={() => changeCount(0)}>Additional Info</Button>
              <Button color='primary' variant="outlined" onClick={() => changeCount(1)}>Applicant</Button>
              <select color='primary' variant="outlined" style={{ padding: "0.5rem", color: "#3F51B5", border: "1px solid #3F51B5", borderRadius: "5px" }} value={dynamicApplicants} onChange={(e) => setDynamicApplicants(e.target.value)} onClick={() => changeCount(2)} >
                {options}
              </select>
              <select color='primary' variant="outlined" style={{ padding: "0.5rem", color: "#3F51B5", border: "1px solid #3F51B5", borderRadius: "5px" }} value={dynamicguarantor} onChange={(e) => setDynamicguarantor(e.target.value)} onClick={() => changeCount(3)}>
                {guarantoroption}
              </select >
            </div>
            <div>

              {spinner && <CircularProgress style={{ color: "#ff00ff", width: '2rem' }} />}
            </div>
            {data ?
              <div>
                {count === 0 && <div>
                  {
                    addtionalInfo && <AllCloudAdditionalInfo value={addtionalInfo} />
                  }
                </div>}
                {count === 1 && <div>
                  {
                    applicant.map((value, idx) => {
                      { console.log(value) }
                      return <div>
                        <AllCloudAppCoAppDiscription value={value} text={"Applicant"} />
                      </div>
                    })
                  }
                </div>}

                {count === 2 &&
                  <AllCloudAppCoAppDiscription value={coApplicants[dynamicApplicants - 1] === undefined ? coApplicants[1 - 1] : coApplicants[dynamicApplicants - 1]} dynamicApplicants={dynamicApplicants} text={"Co-applicant"} />
                }
                {count === 3 &&
                  <AllCloudAppCoAppDiscription value={guarantor[dynamicguarantor - 1] === undefined ? guarantor[1 - 1] : guarantor[dynamicguarantor - 1]} dynamicguarantor={dynamicguarantor} text={"Guarantor"} />
                }
                <div style={{display:"flex",position:"fixed",bottom:'20px',left:"45%",gap:'2rem',padding:'10px 0'}}>
                <div>
                { ["Operation","Disbursal"].includes(cust.stage) && (  cust && (!cust.allcloud_datas || cust.allcloud_datas.length === 0 || !cust.allcloud_datas[0].application_id ) && (roleChecker.isHOOpsTeamAccess(user.role) && <Button onClick={() => {
                  if (window.confirm("have you  check all nesessary conditions ?")) {
                    requestAllCloud(cust.application_id);
                  }
                }} color='primary' variant='outlined'>Request</Button>) )}
                </div>
                <div>
                {( cust.allcloud_datas && cust.allcloud_datas.length !== 0 && cust.allcloud_datas[0].request_details) && (!cust.allcloud_datas[0].verify_details ? <>{roleChecker.isHOOpsApproverAccess(user.role) && <Button onClick={() => {
                if (window.confirm("have you  check all nesessary conditions ?")) {
                  verifyAllCloudloan(cust.application_id);
                }
              }} color='primary' variant='outlined'>verify</Button>} </>: <span style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}><VerifiedUserIcon />Verified</span>
              )}
                </div>
                </div>
                
              </div> : <div>
                <h1 style={{ marginTop: '4rem' }}> Preview Not Found</h1>
              </div>
            }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}