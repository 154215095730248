import React, {useEffect, useState} from 'react'
import { Modal, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { receiptService } from "../../_services/receipt.service";
import { ApprovalRequestFilter } from '../MakerCheckerFramework/ApprovalRequestFilter';
import { employeeService } from '../../_services';
import { loanService } from '../../_services';

const DiscardedModel = (props) => {
    console.log('discard props is',props)
    const [open, setOpen] = useState(false)
    const [reason, setReason] = useState("")
    const [stage,setStage] = useState()
    const user = employeeService.userValue
    const {payment} = props;
    const application_id=payment.loan_no
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };

    const discardItem = (e) =>{
        e.preventDefault();
        let approvalAccess="isAccountChecker"
        if(stage && stage=="Collection"){
           approvalAccess="isCFOAccess"
        }

        ApprovalRequestFilter.RequestFilter({
            service: 'receiptService',
            api: 'updateReceipt',
            id: payment._id,
            task: 'Discard Payment',
            updated_fields: ['loan_no', 'mode', 'payment', 'discarding_reason','remark'],
            sensitive_fields: [],
            new_values: {...payment, is_discarded: true, discarding_reason: reason, discarded_by:user.emp_id,remark:reason},
            old_values: {},
            identification_key: 'Receipt Number',
            identification_value: payment.receipt_no,
            emp_id: user.emp_id,
            approval_needed: true,
            approved:false,
            approval_access:approvalAccess,
            product:'accounting',
            remarks:reason
          })
            .then((res) => {
                window.alert('Request sent to concerned authority');
                window.location.reload();
            })
            .catch((err) => {
              window.alert(err)
              console.log(err)
            })
        // console.log(e)
        // receiptService.update({...payment, is_discarded: true, discarding_reason: reason})
        //  .then((data)=>{
        //   console.log(data)
        //   alert("Payment Discarded")
        //   window.location.reload();
        //  })
        //  .catch((err)=>{
        //   console.log(err)
        //  })
      }

      useEffect(()=>{
         
        if(open && application_id){
               loanService.getBySearch({"application_id":application_id})
               .then(res=>{
                console.log('res of the discard loan is',res);
                if(res && res.length>0){
                    setStage(res[0].stage)
                }
            })
               .catch(err=>console.log('err of discard loan is',err))
           
        }

      },[open,application_id])
    // console.log('stage of discard loan is',stage)
  return (
   <>
   <button style={{ width: "100%", backgroundColor:"#FC2E20", border:"none" }} onClick={handleOpen} ><HighlightOffIcon style={{color:"wheat"}}/></button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={(e) => discardItem(e)} > 
                        <h5>Why are You Discarding the Payment ?</h5><br/>
                        <TextField type={"text"} style={{width:"100%"}} name='discarding_reason' placeholder='Enter the reason' onChange={(e)=>setReason(e.target.value)} required/><br/><br/>                           
                        <button style={{ color: "white", backgroundColor: 'green', borderRadius: "5px", padding: "5px", width: '125px', paddingTop: '8px', paddingBottom: '8px', border: 'none' }} >Submit</button>
                        </form>
                    </Typography>
                    {/* {
                        console.log(data)
                    } */}
                </Box>
            </Modal>
   </>
  )
}

export default DiscardedModel