import React from 'react';
import { useState } from 'react';
import { Typography, Paper, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { Delete } from '@material-ui/icons';
import datehelper from '../../../_helpers/datehelper';
import Save from '@material-ui/icons/Save';
import CourtCaseServices from '../../../_services/courtcase.service';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import { ArbitrationMarkDoneModal } from './ArbitrationMarkDoneModal';

export const NextStageForm = (props) => {
    let CourtCase = props.case;
    let stage = CourtCase.next_stage
    let ndate = new Date()


    const formik = useFormik({
        initialValues: {
            next_stage: [...stage]
        },
        onSubmit 
    })

    const addArray = () => {
        let arr = [...formik.values.next_stage, { date: new Date(), stage: 'LRN Notice' }]
        formik.setFieldValue('next_stage', arr)
    }

    const removeArray = (e, idx) => {
        let arr = [...formik.values.next_stage]
        console.log(idx, arr)
        arr.splice(idx, 1)
        formik.setFieldValue('next_stage', arr)
    }

    async function onSubmit (){
        await CourtCaseServices.UpdateCase(CourtCase._id, { next_stage: formik.values.next_stage })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }


    return (
        <Paper style={{ marginTop: '20px', padding: '20px' }}>
            <form onSubmit={(e) => { e.stopPropagation(); formik.handleSubmit(e) }} id='NextTaskForm'>
                <Table style={{ fontSize: 'large' }}>
                    <Thead>
                        <Tr>
                            <Th style={{ width: '20%' }} >Date</Th>
                            <Th style={{ width: '50%' }}>Stage</Th>
                            <Th style={{ width: '20%' }}></Th>
                            <Th style={{ width: '10%' }}></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            formik.values && formik.values.next_stage && formik.values.next_stage.length > 0 ?
                                formik.values.next_stage.map((m, idx) => {
                                    return <Tr style={{ marginTop: '20px' }} key={m._id ? m._id : m.date}>
                                        <Td>
                                            <input type="date" onKeyDown={(e) => e.preventDefault()} name={`next_stage[${idx}][date]`} style={{ border: '.25px solid grey', width: '100%' }} onChange={(e) => formik.handleChange(e, idx)} defaultValue={datehelper.getDateInputString(new Date(m.date))} required />
                                        </Td>
                                        <Td >
                                            <select name={`next_stage[${idx}][stage]`} style={{ border: '.25px solid grey', width: '100%', height: '25px' }} onChange={(e) => formik.handleChange(e, idx)} defaultValue={m.stage} >
                                                <option value="LRN Notice">LRN Notice</option>
                                                <option value="Arbitrator Appoint">Arbitrator Appoint</option>
                                                <option value="Claim & Interim Application">Claim & Interim Application</option>
                                                <option value="1st Notice">1st Notice</option>
                                                <option value="2nd Notice">2nd Notice</option>
                                                <option value="Evidance">Evidance</option>
                                                <option value="Argument">Argument</option>
                                                <option value="Award">Award</option>
                                                <option value="Execution">Execution</option>
                                            </select>
                                        </Td>
                                        <Td >
                                            {
                                                m && m._id && (new Date(m.date) <= ndate) && <ArbitrationMarkDoneModal stageDetails={{ date: m.date, stage: m.stage, name: '', claim: null, comment: '' }} next_stage_id={m._id} court_case={CourtCase} />
                                            }
                                        </Td>
                                        <Td>
                                            <Button type='button' onClick={(e) => removeArray(e, idx)}><Delete /></Button>
                                        </Td>
                                    </Tr>
                                }) :
                                null
                        }
                    </Tbody>
                </Table>

                <Paper style={{ width: '63px', margin: 'auto', marginTop: '20px' }}>
                    {/* <Button type='button' onClick={() => { addArray() }}>
                        Add
                    </Button> */}
                </Paper>
                <hr style={{ width: '100%' }} />
                <Paper style={{ width: '63px', height: '63px', margin: 'auto', marginTop: '10px', paddingTop: '12.5px', borderRadius: '70px' }}>
                    <Button type='submit' style={{ color: 'blue' }}>
                        <Save />
                    </Button>
                </Paper>
            </form>
            {
                console.log(formik.values)
            }
        </Paper>
    )

}