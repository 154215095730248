import React, {useState} from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import * as Yup from "yup";
// import urlhelper from '../../../../_helpers/urlhelper';
// import { history } from '../../../../_helpers/history'
import { Button } from "@material-ui/core";
import { Box } from '@material-ui/core';
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { loanService, employeeService } from '../../_services';
import { ApprovalRequestFilter } from '../MakerCheckerFramework/ApprovalRequestFilter';
import workflowHelper from "../../_helpers/workflowhelper";


const ColenderReceivedModal = (props) => {

    const coLender = props.coLender;
    const application_id = props.application_id

    console.log(coLender,"dhgf")

    const formikRef = React.useRef();
    const user = employeeService.userValue

    
    const [open, setOpen] = useState(false);
    
    const style = {
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        width: 1100,
        height: 500,
        marginLeft: "auto",
        marginRight: "auto",
        overflow: 'scroll',
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dateFormat = (value) => {
        let max_date = new Date(value)
        let dd = ("00" + max_date.getDate()).slice(-2)
        let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
        let yy = max_date.getFullYear();
        max_date = yy + "-" + mm + "-" + dd;
        return max_date
    }

    const disablePastDate = () => {
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };


    let initialValues = {
        co_lender_name: coLender.co_lender_name,
        co_lender_account_num: coLender.co_lender_account_num,
        co_lender_status: "AmountReceivedFromColender",
        co_lender_status_date: dateFormat(new Date()),
        co_lender_sanction_amount: coLender.co_lender_sanction_amount,
        co_lender_sanction_date: coLender.co_lender_sanction_date,
        co_lender_interest_rate: coLender.co_lender_interest_rate,
        co_lender_tenure: coLender.co_lender_tenure,
        co_lender_emi_day: coLender.co_lender_emi_day,
        co_lender_received_amount: coLender.co_lender_received_amount,
        co_lender_received_date: dateFormat(coLender.co_lender_received_date,),
        co_lender_stage_emp_id: coLender.co_lender_stage_emp_id,
        co_lender_insurance_amount: coLender.co_lender_insurance_amount,
        co_lender_rps_report: "",
        co_lender_pf_amount: coLender.co_lender_pf_amount,
    }

    const validationSchema = Yup.object().shape({
        payment_bank_name: Yup.string().required("This is Required"),
        payment_bank_utr_number: Yup.string().required("This is Required"),
        co_lender_received_amount: Yup.number().required("This Field is required"),
        // co_lender_received_date: Yup.date().min(disablePastDate(), "Date equal or greater than today").required("This filled is required")
        co_lender_received_date: Yup.date().max(disablePastDate(), "Date equal or less than today").required("This filled is required"),
        co_lender_emi_day: Yup.number().required("This Field is required"),
    })


    const onSubmit = (values) => {
        const obj = {...values, co_lender_payment_status_update:{payment_bank_name: values.payment_bank_name, requested_by:user.emp_id, requested_date: new Date(), payment_bank_utr_number:values.payment_bank_utr_number}, _id:coLender._id,  application_id:application_id}
        console.log(obj)
        ApprovalRequestFilter.RequestFilter({
            service: 'loanService',
            api: 'colenderPaymentStatusUpdate',
            id: application_id,
            task: 'Update CoLender Payment Status',
            updated_fields:["co_lender_name",'co_lender_status',"co_lender_tenure","co_lender_interest_rate","co_lender_account_num","co_lender_sanction_amount","co_lender_emi_day" ,'co_lender_received_amount', 'co_lender_received_date','payment_bank_name', 'payment_bank_utr_number',  "co_lender_stage_emp_id","co_lender_insurance_amount","co_lender_pf_amount"],
            sensitive_fields: [],
            new_values: obj,
            old_values: coLender,
            identification_key: 'Loan Number',
            identification_value: application_id,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'sbl'
        }).then(async(x) => {
            console.log('resp is',x)
            const acUserData = await loanService.getEmpIdRoleWise({ role: 'AC' }); 
            console.log("AC User Data:", acUserData);
            if (Array.isArray(acUserData) && acUserData.length > 0) { // Ensure acUserData is an array and not empty
              console.log("Calling Redis Helper for sending Notifications");
              await Promise.all(
                  acUserData.map((employee) => {
                      const emp_id = employee.emp_id; // Extract emp_id for each user
          
                      return workflowHelper.isRedisNotifications({
                        emp_id: user.emp_id,
                        message: `Loan Application ID: ${application_id} - You have an approval request from Co-Lender Team ${user.emp_id} ,Please review and take necessary action.`,
                        action_link: `https://samruddhi.kaabilfinance.com/home/colending/dashboard`,
                        action_taken_by: emp_id
                    });
                  })
                );
              }
        
            console.log("test x", x)
            alert("Request Sent to concerned Authority")
            window.location.reload()
        })
        .catch((err) => {
            window.alert(err)
            console.log(err)
        })
    }


    return (
        <>

            <Button style={{ color: "blue" }} onClick={handleOpen} >Amount Received Status</Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">

                        <h3 style={{textAlign:"center"}} >Co-Lender Payment Details</h3>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            innerRef={formikRef}
                        >
                            {({ errors, touched, isSubmitting, setFieldValue }) => {
                                return (
                                    <Form>
                                        <div style={{ marginLeft: "50px", marginTop: "10px" }}>
                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <label style={{ color: "rgb(41,73,185)" }}>Co-Lender Name</label>
                                                    <Field disabled style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)", color: "rgb(41,73,185)" }} name="co_lender_name" value={coLender.co_lender_name} className={
                                                        "form-control" +
                                                        (errors.co_lender_name && touched.co_lender_name ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="co_lender_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label style={{ color: "rgb(41,73,185)" }}>EMI Day</label>
                                                    <Field  style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="co_lender_emi_day" value={coLender.co_lender_emi_day} className={
                                                        "form-control" +
                                                        (errors.co_lender_emi_day && touched.co_lender_emi_day ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="co_lender_emi_day"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label style={{ color: "rgb(41,73,185)" }}>Received Amount</label>
                                                    <Field  style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="co_lender_received_amount" value={coLender.co_lender_received_amount} className={
                                                        "form-control" +
                                                        (errors.co_lender_received_amount && touched.co_lender_received_amount ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="co_lender_received_amount"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">

                                                <div className="form-group col">
                                                    <label style={{ color: "rgb(41,73,185)" }}>Amount Received Date</label>
                                                    <Field  style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="co_lender_received_date" type="date" onKeyDown={(e) => e.preventDefault()} className={
                                                        "form-control" +
                                                        (errors.co_lender_received_date && touched.co_lender_received_date ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="co_lender_received_date"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                
                                                <div className="form-group col">
                                                    <label style={{ color: "rgb(41,73,185)" }}>Insurance Amount</label>
                                                    <Field  style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="co_lender_insurance_amount" value={coLender.co_lender_insurance_amount} className={
                                                        "form-control" +
                                                        (errors.co_lender_insurance_amount && touched.co_lender_insurance_amount ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="co_lender_insurance_amount"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group col">
                                                    <label style={{ color: "rgb(41,73,185)" }}>PF</label>
                                                    <Field  style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="co_lender_pf_amount" value={coLender.co_lender_pf_amount} className={
                                                        "form-control" +
                                                        (errors.co_lender_pf_amount && touched.co_lender_pf_amount ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="co_lender_pf_amount"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>                                 
                                            </div>

                                            <div className='form-row'>
                                                <div className='form-group col'>
                                                <label style={{ color: "rgb(41,73,185)" }}>Payment Bank Name</label>
                                                    <Field  style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="payment_bank_name" value={coLender.payment_bank_name} className={
                                                        "form-control" +
                                                        (errors.payment_bank_name && touched.payment_bank_name ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="payment_bank_name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className='form-group col'>
                                                <label style={{ color: "rgb(41,73,185)" }}>Payment Bank UTR Number</label>
                                                    <Field  style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="payment_bank_utr_number" value={coLender.payment_bank_utr_number} className={
                                                        "form-control" +
                                                        (errors.payment_bank_utr_number && touched.payment_bank_utr_number ? " is-invalid" : "")
                                                    } />
                                                    <ErrorMessage
                                                        name="payment_bank_utr_number"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className='form-group col'></div>
                                            </div> <br/>
                                            <div className="row justify-content-around">
                                                <Fab
                                                    type="submit"
                                                    color="primary"
                                                    aria-label="save"
                                                >
                                                    <SaveIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Typography>
                </Box>

            </Modal>
        </>
    )
}

export default ColenderReceivedModal