import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";

const LetterOfContinuity = (props) => {


    const data = props.data
    const branches = props.branches


    // const LetterOfContinuityRef = React.useRef();



    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => LetterOfContinuityRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }


    return (
        <>

            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                <span style={{ marginLeft: "40%" }}><b>LETTER OF CONTINUITY</b></span><br /><br />
                <div style={{ textAlign: "justify" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Kaabil Finance Private Limited<br />
                        Plot No. 1, Scheme No. 1, Income Tax Colony,<br/>
                        Model Town, Jagatpura, Jaipur Rajasthan 302017<br /></span>

                        <span>
                            Place: {branches.branch_city}<br />
                            Date:  <b>{datehelper.displayDate(new Date(data.created))} </b> 
                        </span>
                    </div><br /><br />

                    <span>Dear Sir/Madam,</span><br /><br />

                    <span>
                        I/We,<br />
                        <div>
                            <span>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span>
                            {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                                return <div>
                                    <span>{e.general_information.name}</span>
                                </div>
                            })}
                        </div>
                        the Borrowers, hereby enclose Demand Promissory Note dated<b> {datehelper.displayDate(new Date(data.created))} </b> , for<b> Rs.{data && data.loan && data.loan.sanction_amount}/-
                        (Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)} only) </b>
                        with Interest thereon as specified in such Demand Promissory Note, which is payable on demand and
                        given by me/us, as security for payment / repayment of the Outstanding Amounts, in relation to the Loan,
                        in terms of the loan agreement executed between Kaabil Finance Private Limited, Vistaar Financial Services Pvt Ltd (Collectively “Lenders”) and the Borrowers (“Loan Agreement”).<br /><br />

                        Notwithstanding the fact that the Outstanding Amounts may be reduced from time to time or extinguished,
                        I/we hereby acknowledge and confirm that the aforesaid security shall be a continuing security until the
                        payment/repayment of all the Outstanding Amounts, in full.<br /><br />

                        The capitalized terms used herein shall have the same meaning assigned to them under the Loan
                        Agreement and other transaction documents.
                    </span><br /><br />
                    <span>
                        Name of the Borrower/s
                    </span><br /><br />
                    <div>
                        <span>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br /><br />
                        <span>________________________</span><br /><br />
                        {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                            return <div>
                                <span>{e.general_information.name}</span><br /><br />
                                <span>_________________________</span><br /><br />
                            </div>
                        })}
                    </div>
                </div>
            </div>

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                <span style={{ marginLeft: "40%" }}>LETTER OF CONTINUITY</span><br /><br />
                <div style={{ textAlign: "justify" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vistaar Financial Services Pvt Ltd<br />
                        Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout,<br />
                        2nd  Stage NA Bengaluru Bangalore KA 560076</span>
                   
                        <span>
                            Place: {branches.branch_city}<br />
                            Date:  <b>{datehelper.displayDate(new Date(data.created))} </b> 
                        </span>
                    </div><br /><br />

                    <span>Dear Sir/Madam,</span><br /><br />

                    <span>
                        I/We,<br />
                        <div>
                            <span>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span>
                            {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                                return <div>
                                    <span>{e.general_information.name}</span>
                                </div>
                            })}
                        </div>
                        the Borrowers, hereby enclose Demand Promissory Note dated  <b>{datehelper.displayDate(new Date(data.created))} </b> , for <b>Rs.{data && data.loan && data.loan.sanction_amount}/-</b>
                        &nbsp;(Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)} only)
                        with Interest thereon as specified in such Demand Promissory Note, which is payable on demand and
                        given by me/us, as security for payment / repayment of the Outstanding Amounts, in relation to the Loan,
                        in terms of the loan agreement executed between Kaabil Finance Private Limited, Vistaar Financial Services Pvt Ltd (Collectively “Lenders”) and the Borrowers (“Loan Agreement”).<br /><br />

                        Notwithstanding the fact that the Outstanding Amounts may be reduced from time to time or extinguished,
                        I/we hereby acknowledge and confirm that the aforesaid security shall be a continuing security until the
                        payment/repayment of all the Outstanding Amounts, in full.<br /><br />

                        The capitalized terms used herein shall have the same meaning assigned to them under the Loan
                        Agreement and other transaction documents.
                    </span><br /><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px" }}>________________________</span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px" }}>________________________</span><br /><br />
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}

export default LetterOfContinuity