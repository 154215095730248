import React from 'react';
import { useState } from 'react';
import { Modal, Box, Button, Paper, Typography } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import datehelper from '../../../_helpers/datehelper';
import CourtCaseServices from '../../../_services/courtcase.service';


export const MarkDoneModal = (props) => {
    const dataFill = props
    console.log(dataFill)
    const court_case = props.court_case
    let ndate= new Date()

    const [open, setOpen] = useState(false)
    const [hearingDetails, setHearingDetails] = useState({...dataFill.hearing_details})

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (e) => {
        setHearingDetails({ ...hearingDetails, [e.target.name]: e.target.value })
    }

    const HearingDetailsUpdation = async (e) => {
        e.preventDefault();
        let next_hearing = court_case.next_hearing.filter(h => { return h._id !== dataFill.next_hearing_id })
        let hearings = [...court_case.hearings, hearingDetails]
        await CourtCaseServices.UpdateCase(dataFill.court_case._id, { hearings: hearings, next_hearing: next_hearing })
            .then(res => {
                window.alert('Details saved successfully')
                window.location.reload()
            })
            .catch(err => {
                window.alert('Some error occurred')
                console.log(err)
            })
    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "65vw",
        height: "55vh",
        bgcolor: "background.paper",
        border: "2px solid #000",
        overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} style={{ color: 'blue' }} >Mark Done</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box id="modal-modal-title" variant="h6" component="h2" style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
                        <Paper>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h4 style={{ margin: 'auto', marginTop: '10px', marginBottom: '10px' }}>Details</h4>
                            </Box>
                        </Paper>
                        <form onSubmit={(e) => { e.stopPropagation(); HearingDetailsUpdation(e) }} id='HearingForm'>
                            <Paper style={{ marginTop: '20px' }}>
                                <Typography style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Hearing Date
                                        <input type="date" onKeyDown={(e) => e.preventDefault()} name='hearing_date' style={{ border: '.25px solid grey' }} max={datehelper.getDateInputString(ndate)} onChange={(e) => handleChange(e)} defaultValue={datehelper.getDateInputString(new Date(hearingDetails.hearing_date))} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Lawyer
                                        <input type="text" name='lawyer_name' style={{ border: '.25px solid grey' }} onChange={(e) => handleChange(e)} defaultValue={hearingDetails.lawyer_name} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Judge
                                        <input type="text" name='judge_name' style={{ border: '.25px solid grey' }} defaultValue={hearingDetails.judge_name} onChange={(e) => handleChange(e)} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Party Lawyer
                                        <input type="text" name='party_lawyer' style={{ border: '.25px solid grey' }} defaultValue={hearingDetails.party_lawyer} onChange={(e) => handleChange(e)} />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Case Manager
                                        <input type="text" name='case_manager_name' style={{ border: '.25px solid grey' }} defaultValue={hearingDetails.case_manager_name} onChange={(e) => handleChange(e)} required />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Penal codes
                                        <input type="text" name='penal_codes' style={{ border: '.25px solid grey' }} defaultValue={hearingDetails.penal_codes} onChange={(e) => handleChange(e)} />
                                    </label>

                                    <label style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
                                        Judgement
                                        <input type="text" name='judgement' style={{ border: '.25px solid grey' }} defaultValue={hearingDetails.judgement} onChange={(e) => handleChange(e)} required />
                                    </label>
                                </Typography>
                            </Paper>
                            <hr style={{ border: '1px solid grey' }} />
                            <Paper style={{ width: '63px', height: '63px', margin: 'auto', marginTop: '10px', paddingTop: '12.5px', borderRadius: '70px' }}>
                                <Button type='submit' style={{ color: 'blue' }}>
                                    <Save />
                                </Button>
                            </Paper>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}