import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, DirectionsRenderer, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import { employeeService } from "../../_services/employee.service";
import { fieldVisitService } from "../../_services/fieldvisit.service";
import { useParams } from 'react-router-dom';
const CollectionTracking = () => {
    const { emp_id, date } = useParams();
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [employees, setEmployees] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [trackingData, setTrackingData] = useState([]);
    const [directionsResponses, setDirectionsResponses] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null); // State to store selected marker

    useEffect(() => {
        employeeService.getAll()
            .then((res) => setEmployees(res))
            .catch((err) => console.error(err));
    }, []);

    const handleSearch = async () => {
        try {
            const result = await fieldVisitService.getCollectiontracking({
                emp_id: emp_id || selectedOption, // Use emp_id from URL or selectedOption
                date: date || selectedDate, 
            });
            if(result.message==="No records found for the provided emp_id and date."){
            setTrackingData([])
            await fetchRoadRoute([]);
            alert("No data found for this emp_id")
            return
            }
            setTrackingData(result);
            await fetchRoadRoute(result); // Fetch Directions for selected tracking data
        } catch (error) {
            console.error("Error fetching records:", error);
        }
    };
    useEffect(() => {
        if (emp_id && date) {
            // If both emp_id and date exist in URL, call handleSearch automatically
            setSelectedOption(emp_id); // Set selectedOption from URL params
            setSelectedDate(date); // Set selectedDate from URL params
            handleSearch();
        }
    }, [emp_id, date])
    const fetchRoadRoute = async (trackingData) => {
        const directionsService = new window.google.maps.DirectionsService();
        setDirectionsResponses([]); // Clear previous directions before fetching new ones

        const newDirectionsResponses = [];

        for (let i = 0; i < trackingData.length; i++) {
            const fromLocation = {
                lat: parseFloat(trackingData[i]?.tracking?.from_location?.lat),
                lng: parseFloat(trackingData[i]?.tracking?.from_location?.long),
            };
            const toLocation = {
                lat: parseFloat(trackingData[i]?.tracking?.to_location?.lat),
                lng: parseFloat(trackingData[i]?.tracking?.to_location?.long),
            };

            // Fetch the route from from_location to to_location
            await new Promise((resolve, reject) => {
                directionsService.route(
                    {
                        origin: fromLocation,
                        destination: toLocation,
                        travelMode: "DRIVING",
                    },
                    (result, status) => {
                        if (status === "OK") {
                            newDirectionsResponses.push(result);
                            resolve();
                        } else {
                            console.error(`Directions request failed for segment ${i} due to ${status}`);
                            reject(status);
                        }
                    }
                );
            });

            // If there's a next point, fetch the route from to_location to next from_location
            if (i < trackingData.length - 1) {
                const nextFromLocation = {
                    lat: parseFloat(trackingData[i + 1]?.tracking?.from_location?.lat),
                    lng: parseFloat(trackingData[i + 1]?.tracking?.from_location?.long),
                };

                await new Promise((resolve, reject) => {
                    directionsService.route(
                        {
                            origin: toLocation,
                            destination: nextFromLocation,
                            travelMode: "DRIVING",
                        },
                        (result, status) => {
                            if (status === "OK") {
                                newDirectionsResponses.push(result);
                                resolve();
                            } else {
                                console.error(`Directions request failed for segment ${i + 1} due to ${status}`);
                                reject(status);
                            }
                        }
                    );
                });
            }
        }

        setDirectionsResponses(newDirectionsResponses);
    };

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDy5H5qcdwYLcJeT2am4iXzIqdSDB_lmcw", // Replace with your API Key
    });

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const center = trackingData.length > 0
        ? {
            lat: parseFloat(trackingData[0]?.tracking?.from_location?.lat) || 0,
            lng: parseFloat(trackingData[0]?.tracking?.from_location?.long) || 0,
        }
        : { lat: 26.8402713, lng: 75.8324717 }; // Default coordinates (Jaipur, India)

    const filteredEmployees = employees.filter(employee =>
        employee.emp_id.toString().includes(searchTerm)
    );

    const handleMarkerClick = (data, index) => {
        setSelectedMarker({
            ...data.tracking,
            index: index,
        });
    };

    return (
        <div>
            <div style={styles.container}>
                <input
                    type="text"
                    placeholder="Search by Employee ID"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={styles.searchInput}
                />

                <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} style={styles.select}>
                    <option value="">Select an Employee ID</option>
                    {filteredEmployees.map((e) => (
                        <option key={e?.emp_id} value={e?.emp_id}>
                            {e?.emp_id} --- {e?.first_name} {e?.middle_name} {e?.last_name} 
                        </option>
                    ))}
                </select>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    style={styles.dateInput}
                />
                <button onClick={handleSearch} style={styles.button}>
                    Search
                </button>
            </div>

            <GoogleMap
                mapContainerStyle={{ width: "100%", height: "500px" }}
                center={center}
                zoom={15}
            >
                {directionsResponses.map((directions, index) => (
                    <DirectionsRenderer key={index} directions={directions} />
                ))}

                {trackingData.map((data, index) => {
                    const fromLocation = {
                        lat: parseFloat(data.tracking?.from_location.lat),
                        lng: parseFloat(data.tracking?.from_location.long),
                    };
                    const toLocation = {
                        lat: parseFloat(data.tracking?.to_location.lat),
                        lng: parseFloat(data.tracking?.to_location.long),
                    };

                    const isFirstMarker = index === 0; // Check if it's the first marker

                    return (
                        <React.Fragment key={index}>
                            <Marker
                                position={fromLocation}
                                // label={`From ${index + 1}`}
                                onClick={() => handleMarkerClick(data, index)}
                                icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    fillColor: isFirstMarker ? 'green' : 'red', // Set first marker color to green
                                    fillOpacity: 1,
                                    scale: 7,
                                    strokeColor: 'white',
                                    strokeWeight: 2,
                                }} 
                            />
                            <Marker
                                position={toLocation}
                                // label={`To ${index + 1}`}
                                onClick={() => handleMarkerClick(data, index)}
                            />
                        </React.Fragment>
                    );
                })}

                {/* Show InfoWindow if a marker is selected */}
                {selectedMarker && (
                    <InfoWindow
                        position={{
                            lat: parseFloat(selectedMarker?.from_location.lat),
                            lng: parseFloat(selectedMarker?.from_location.long),
                        }}
                        onCloseClick={() => setSelectedMarker(null)} // Close the InfoWindow
                    >
                        <div>
                            <h3>Loan No: {selectedMarker?.loan_no}</h3>
                            <p>Start Date: {new Date(selectedMarker?.start_date_time).toLocaleString()}</p>
                            <p>Total Distance: {selectedMarker?.total_km} km</p>
                            <p>Expense Price: ₹{selectedMarker?.expense_price}</p>
                            <p>End Date: {new Date(selectedMarker?.end_date_time).toLocaleString()}</p>

                            {/* Show next visit's start time if available */}
                            {trackingData[selectedMarker?.index + 1] && (
                                <p>Next Visit Start Time: {new Date(trackingData[selectedMarker?.index + 1]?.tracking?.start_date_time).toLocaleString()}</p>
                            )}
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        margin: "20px auto",
        maxWidth: "700px",
    },
    select: {
        padding: "10px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        width: "100%",
    },
    dateInput: {
        padding: "10px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        width: "100%",
    },
    button: {
        padding: "10px 20px",
        fontSize: "16px",
        color: "white",
        backgroundColor: "#007bff",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
    },
    searchInput: {
        padding: "10px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        width: "100%",
    },
};

export default CollectionTracking;


