import React, { useEffect, useState } from 'react'
import { Box, Button, Modal } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Edit, Save } from "@material-ui/icons";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { legalServices } from '../../../_services/legal_cases.services';
const AddStageModal = (props) => {
    let id = props.caseInfo._id
    let data = props.caseInfo
    console.log('Terst props', props)
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "1100px",
        height: "300px",
        bgcolor: "#F3F3F3",
        border: "2px solid #000",
        // overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const initialValues = {
        stage: "",
        issued_against: data.party_name,
        issued_date: "",
        issued_by: "",
        other_stage:''
    };

    const validationSchema = Yup.object().shape({
        stage: Yup.string().required("This is Required"),
        issued_against: Yup.string().required("This is Required"),
        issued_date: Yup.date().required("This is Required"),
        issued_by: Yup.string().required("This is Required"),
        // other_stage: Yup.string().required("This is Required"),
    });
    const addStage = (values) => {
        legalServices.addStage(id, values)
            .then((res) => {
                alert("Stage Added !!")
                window.location.reload()
            })
            .catch((err) => alert(err))
    }
    return (
        <div>
            <Button onClick={handleOpen} style={{ color: '#0C2294', height: '25px', background: '9990FF', float: 'right', textTransform: 'capitalize', }}><Add style={{ border: "2px solid #0C2294", marginBottom: '5px', height: "20px" }} />&nbsp; Add</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={addStage}
                    >
                        {({ errors, touched, isSubmitting, values }) => {
                            return (
                                <Form>
                                    <h5 style={{ textAlign: 'center', marginBottom: "40px" }}>Add {data.case_type == "ni138" && "NI-138 Stage" || data.case_type == "arbitration" && "Arbitration Stage" || data.case_type == "execution" && "Execution Stage"}</h5>
                                    <div style={{ display: "flex", justifyContent: "space-around", }}>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Stage</label>
                                            <Field
                                                as="select"
                                                name="stage"
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px", }}
                                                className={
                                                    "form-control" +
                                                    (errors.stage && touched.stage ? " is-invalid" : "")
                                                } >
                                                {data.case_type==="ni138"&&<>    
                                                <option value="">Choose Stage</option>
                                                <option value="EMI Notice">EMI Notice</option>
                                                <option value="LRN Notice">LRN Notice</option>
                                                <option value="Cheque Bounce">Cheque Bounce</option>
                                                <option value="Advocate Legal Notice">Advocate Legal Notice</option>
                                                <option value="NI-138-Court Submission">NI-138-Court Submission</option>
                                                <option value="Court Hearing">Court Hearing</option>
                                                <option value="Case Closed">Case Closed</option>
                                                </>}
                                                {data.case_type==="arbitration" &&<>
                                                <option value="">Choose Stage</option>
                                                <option value="Pre-Arbitration Intention cum Loan recall Notice">Pre-Arbitration Intention cum Loan recall Notice</option>
                                                <option value="Arbitrator Decleration & Procedure">Arbitrator Decleration & Procedure</option>
                                                <option value="Claim & Interim Application file">Claim & Interim Application file</option>
                                                <option value="Order on Interim Application File">Order on Interim Application File</option>
                                                <option value="1st Notice to Respondent">1st Notice to Respondent</option>
                                                <option value="Appearance / 2nd Notice">Appearance / 2nd Notice</option>
                                                <option value="Evidence in support of claim">Evidence in support of claim</option>
                                                <option value="Argument">Argument</option>
                                                <option value="Award">Award</option>
                                                <option value="Award Dispatched to all Parties">Award Dispatched to all Parties</option>
                                                </>}
                                                {data.case_type==="execution" &&<>
                                                 <option value="">Choose Satge</option>
                                                <option value="Award">Award</option>
                                                <option value="Report">Report</option>
                                                <option value="Notice">Notice</option>
                                                <option value="other">Other</option>
                                                </>}
                                            </Field>
                                            <ErrorMessage
                                                name="stage"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                       {values.stage=="other" &&<div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Other Stage</label>
                                            <Field
                                                type="text"
                                                name="other_stage"
                                                required
                                                className={
                                                    "form-control" +
                                                    (errors.other_stage && touched.other_stage ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                        </div>}
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Issued Against</label>
                                            <Field
                                                name="issued_against"
                                                className={
                                                    "form-control" +
                                                    (errors.issued_against && touched.issued_against ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="issued_against"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Issued Date</label>
                                            <Field
                                                type="date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                name="issued_date"
                                                className={
                                                    "form-control" +
                                                    (errors.issued_date && touched.issued_date ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="issued_date"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Issued By</label>
                                            <Field
                                                name="issued_by"
                                                className={
                                                    "form-control" +
                                                    (errors.issued_by && touched.issued_by ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="issued_by"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'blue', margin: "0 auto", color: 'white', borderRadius: '4px'
                                        }}><Save />Save</button>
                                </Form>)
                        }}
                    </Formik>
                </Box>
            </Modal>

        </div>
    )
}
export default AddStageModal
