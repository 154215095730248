import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/ms/fieldvisits`;

export const fieldVisitService = {
  getAll,
  search,
  getCollectiontracking
};


async function getAll()
{
  return fetchWrapper.get(baseUrl);
}

async function search(params)
{
  return fetchWrapper.post(`${baseUrl}/search`,params);
}
function getCollectiontracking(params) {
  return fetchWrapper.post(`${baseUrl}/collectiontracking`,params);
}

