import React from "react";
import { misService } from "../../_services";
import { ThumbUp } from "@material-ui/icons";
// import { Edit } from "@material-ui/icons";
import { Button, Modal ,Box} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "antd";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { employeeService } from "../../_services/employee.service";
import LoanModal from "./LoanModal";
import BankModal from "./BankModal";
import LoanStatusModal from "./MIS/LoanStatusModal";
import LoanCloseModal from "./LoanCloseModal"
import LetterListModal from "./LetterListModal";

import "./loanlist.css";
import "antd/dist/antd.css";
import PaymentDetailsModal from "./MIS/PaymentDetailsModal";
import { Role } from "../../_helpers";
import RepaymentSchedule from "./RepaymentSchedule";
import { DisbursementDetailsModal } from "./MIS/DisbursementDetailsModal";
 
import ColenderPaymentModal from "./MIS/ColenderPaymentModel";

 
import {history} from "../../_helpers/history"
import { useLocation } from "react-router-dom";
import { AuctionModal } from "./GoldLoanLetters/AuctionModal";
import LoanCancelModal from "./LoanCancelModel";
import roleChecker from "../../_helpers/rolechecker";
import { ApprovalRequestFilter } from "../MakerCheckerFramework/ApprovalRequestFilter";
import displayhelper from "../../_helpers/displayhelper";
const { Search } = Input;

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 1000,
  },
});

const useSortableData = (searchVal, items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  const [origData, setOrigData] = React.useState([]);
  const [searchIndex, setSearchIndex] = React.useState([]);

  React.useEffect(() => {
    const crawl = (user, allValues) => {
      if (!allValues) allValues = [];
      for (var key in user) {
        if (typeof user[key] === "object") crawl(user[key], allValues);
        else allValues.push(user[key] + " ");
      }
      return allValues;
    };
    const fetchData = async () => {
      setOrigData(items);
      const searchInd = items.map((user) => {
        const allValues = crawl(user);
        return { allValues: allValues.toString() };
      });
      setSearchIndex(searchInd);
    };
    fetchData();
  }, [items]);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...origData];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    if (searchVal) {
      const newItems = searchIndex.map((user, index) => {
        if (user.allValues.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0)
          return sortableItems[index];
        return null;
      });
      sortableItems = newItems.filter((user) => {
        if (user) return true;
        return false;
      });
    }

    return sortableItems;
  }, [sortConfig, searchVal, origData, searchIndex]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default function LoanList(props) {
  const classes = useStyles();
  const { path,params,url } = props.match;
  const location = useLocation();
  const [loans, setLoans] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [filterFields, setFilterFields] = React.useState({});
  const user = employeeService.userValue;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [searchVal, setSearchVal] = React.useState(null);
  const [originalData,setOriginalData]=React.useState()
  var { items, requestSort, sortConfig } = useSortableData(searchVal, loans);
  const search = props.location.search;
  console.log("location search params "+search);
  console.log("location 2 search params "+location.search);
  console.log("Path is "+path);
  console.log("Params is "+params);
  console.log(params);
  console.log("Url is "+url);

 
  const searchParams = new URLSearchParams(search);
  const intitialLoanAccount=searchParams.get("loan_account");
  const updateFields=(fieldKey,fieldVal)=> {
      filterFields[fieldKey]=fieldVal;
      setFilterFields(filterFields)
      console.log({filterFields})
 
  }
  console.log(searchParams);
  

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  React.useEffect(() => {
    if(searchParams.get("loan_account"))
    {
      updateFields("loan_number",searchParams.get("loan_account").toUpperCase());
      loadLoans();
    }
    else {
      getloans();
    }
    
  }, []);

 
  async function misdownload(filterTarget) {
    const dlink = await misService.getExcel({ 'filter_target': filterTarget });
    window.open(dlink);
  }

 
  function filterLoans(l)
  {
    if (roleChecker.isHOGoldAccess(user.role) || roleChecker.hasAccountsAccess(user.role))
      return l;
    else {
      return l.filter((loan) => loan.branch_name.toLowerCase().trim() === user.branch.branch_name.toLowerCase().trim());
    }
  }

  async function getloans() {
    await misService.getAll().then((x) => {
      setIsLoading(false);
      setLoans(filterLoans(x))
      setOriginalData(filterLoans(x))
    })
      .catch((err) => {
        setIsLoading(false);
        console.log("error getting loans");
        console.log(err);
      });

  }

  function approveLoan(id) {
    misService.approveloan(id).then((res) => {
      getloans();
      alert(res.message);
    });
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (e) => (
    e.target.value.length <= 10 ? updateFields('mobile_number', e.target.value) : e.target.value = e.target.value.slice(0, 10), updateFields('mobile_number', e.target.value)
  )

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

 
  async function applyFilter()
  {
    //loadLoans();
    
     if(filterFields['loan_number'])
     {
        history.push('/home/goldloans?loan_account='+filterFields['loan_number']);
        window.location.reload(); 
     }
     else
     {
       loadLoans();
     }
     
  }
  async function loadLoans(){
 
    console.log("loading loans");
    console.log(filterFields);
    setIsLoading(true);
    setLoans([]);
    await misService.getAllFilter(filterFields).then((x) => {
      setIsLoading(false);
      setLoans(filterLoans(x))
      setOriginalData(filterLoans(x))
    })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        window.alert("error getting loans")
      })
  }

  // async function unlockLoan(loan_id){
  //   console.log("Unlocking the loan id "+loan_id)
  //   if(window.confirm("Are you sure to unlock the loan?"))
  //   {
  //     misService.unlockLoan(loan_id).then((x)=> {
  //             window.alert("Loan unlocked");
  //             window.location.reload();
  //     })
  //     .catch((err)=> {
  //        console.log(err);
  //     })
  //   }
  // KFPLGLMN00598
  // }

  const filterLoanList = () => {
    let selectedValue = document.querySelector('select').value
    if(selectedValue === "All")
    {
      setLoans(originalData)
    }else{
      setLoans(originalData.filter(x => x.loan_status == selectedValue))
    }
  }  
  const [open, setOpen] = React.useState(false);
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const searchByloanOrMobileNo=()=>{
    setIsLoading(true);
    setLoans([]);
    misService.searchByloanOrMobileNo(filterFields)
    .then((res)=>{
      setIsLoading(false);
      setLoans(filterLoans(res))
      setOriginalData(filterLoans(res))
    })
    .catch((err)=>{
      console.log(err)
      setIsLoading(false);})
  }
  const style = {
    position: 'absolute',
    top: '28%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    background:'#F8FAFF',
    borderRadius:'7px'
  };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h1 style={{float:'left'}}>Gold Loans </h1>
        {/* Create Loan Button */}
        {/* <div>
         <Button href={`${path}/add`} variant="contained" style={{'background-color':'orange','color':'black'}}>Create Loan</Button> 
        </div> */}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <input
            style={{ border: '1px solid #3760F1', borderRadius: "5px", height: '40px', width: "16rem" }}
            placeholder="Enter phone number/Mobile number"
            label="Loan Number or mobile no"
            name="loan_or_mobile_no"
            type="text"
            // defaultValue={intitialLoanAccount}
            onChange={(e) => updateFields('loan_or_mobile_no', e.target.value)}
          />
          <Button style={{ background: '#3760F1', color: 'white', marginLeft: '7px' ,height:"40px"}}
            onClick={searchByloanOrMobileNo}
          >Search</Button>
          <Button style={{ color: "blue", width: "39px", height: "40px", border: '1px solid #3760F1', justifyContent: 'flex-end', marginRight: '40px',marginLeft: '7px' }} onClick={openModal}>Filter</Button>
          <Modal open={open} onClose={closeModal}>
            <Box sx={style}>
              <h3 style={{ textAlign: 'center', color: '#3760F1' }}>Filter</h3>
              <h4 style={{ color: '#3760F1' }}>Date Range</h4>
              <div style={{ display: "flex", flexDirection: 'row', fontSize: "20px", float: 'left' }}>
                <span>From date: </span>
                <input
                  label="From date"
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  name="loan_from_date"
                  style={{ border: '1px solid #3760F1', borderRadius: '5px' }}
                  onChange={(e) => updateFields('loan_from_date', e.target.value)}
                />
              </div>
              <div style={{ display: "flex", flexDirection: 'row', fontSize: "20px", float: 'right' }}>
                <span>To date: </span>
                <input
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  label="To date"
                  name="loan_to_date"
                  style={{ border: '1px solid #3760F1', borderRadius: '5px' }}
                  onChange={(e) => updateFields('loan_to_date', e.target.value)}
                />
              </div>
              <h4 style={{ color: '#3760F1', marginTop: '60px', marginBottom: "20px" }}>Status Wise</h4>
              <div style={{ display: 'flex', justifyContent: 'center', float: 'left' }}>
                <h5>View By:</h5>
                <select onChange={filterLoanList} style={{ height: "30px", border: '2px solid blue', borderRadius: '4px', width: "10rem" }}>
                  <option value="All" defaultValue style={{ fontSize: '19px' }}>All</option>
                  <option value="Disbursed" style={{ fontSize: '19px' }}> Disbursed</option>
                  <option value="Closed" style={{ fontSize: '19px' }}> Closed</option>
                  <option value="Locked" style={{ fontSize: '19px' }}> Locked</option>
                  <option value="Cancelled" style={{ fontSize: '19px' }}> Cancelled</option>
                  <option value="Auctioned" style={{ fontSize: '19px' }}> Auctioned</option>
                </select>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
                <button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ height: "35px", background: '#2949B9', borderRadius: '5px', color: 'white', width: '7rem' }}
                  onClick={applyFilter}
                >
                  Apply
                </button>
                <button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ height: "35px", marginLeft: '40px', background: '#EF2222', color: 'white', borderRadius: '5px', width: '7rem' }}
                  onClick={closeModal}
                >
                  cancel
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      <br /> <br />
      <TableContainer className={classes.container}>
        <Table>
          <Thead>
            <Tr style={{ background: '#3760F1', color: 'white', height: '60px', borderRadius: "5px",fontWeight:"bold",fontSize:"18px" }}>
              <Th style={{ width: "15%" }}>&nbsp;&nbsp;Customer Name</Th>
              <Th style={{ width: "15%" }}>Loan Account Number</Th>
              <Th style={{ width: "15%" }}>
                <button
                  style={{ background: "#3760F1", textDecoration: "underLine" }}
                  onClick={() => requestSort("date")}
                  className={getClassNamesFor("date")}
                >
                  Loan Creation Date
                </button>
              </Th>
              <Th style={{ width: "10%" }}>
                <button
                  style={{ background: "#3760F1", textDecoration: "underLine" }}
                  onClick={() => requestSort("loan_amount")}
                  className={getClassNamesFor("loan_amount")}
                >
                  Loan Amount
                </button>
              </Th>
              <Th style={{ width: "10%" }}>Branch</Th>
              <Th style={{ width: "10%" }}>Loan Status</Th>
              <Th style={{ width: "10%" }}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items &&
              items
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((loan) => (
                  <>
                    <Tr key={loan._id} style={{ background: '#D9DDF5', height: "50px", fontWeight: 'bold', fontSize: "18px", borderRadius: "5px" }}>
                      <Td>{loan.name.toUpperCase()}</Td>
                      <Td>
                        {loan.loan_account}
                      </Td>
                      <Td>{loan.date.split("T")[0]}</Td>
                      <Td>
                        {/* {loan.loan_amount && loan.loan_amount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })} */}
                        ₹{displayhelper.displayNumberAccounting(loan.loan_amount)}
                      </Td>
                      <Td>
                        {loan.branch_name}
                      </Td>
                      <Td>
                       <p style={{color:loan.loan_status==="Closed"?"red":"green"}}>{loan.loan_status}</p>
                       {(loan.closure_date && loan.closure_date !== "" && (
                        <span>Closure Date:<br/>{loan.closure_date.split("T")[0]}</span>
                      ))}
                      <br/>
                        {(loan.closed_by_emp_id && (
                        <span>Closed by: {loan.closed_by_emp_id}</span>
                      ))}
                        {(loan.renew_of && (
                        <p>Renew Of: {loan.renew_of}</p>
                      ))}
                      <br />
                      {(loan.renew_by && (
                        <p>Renewed By: {loan.renew_by}</p>
                      ))}
                        </Td>
                      <Td>
                        <Button onClick={() => props.history.push(`${path}/viewloandetails`, loan)}
                          style={{
                            width: '83%',
                            color: "#3760F1",
                            border: '2px solid #3760F1',
                            marginBottom:'8px'
                          }}>View&nbsp;Details</Button>
                      {/* {(roleChecker.isAccountChecker(user.role))  && (loan.loan_status !== 'Closed') && (
                          <LoanCloseModal
                            user={user}
                            loan={loan}
                            history={props.history}
                          />
                        )}
                        {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role)|| roleChecker.hasAccountsAccess(user.role))  && (loan.loan_status === 'Locked') && (
                          <Button
                          onClick={() => unlockLoan(loan._id,loan.loan_account,loan.loan_status)}
                          style={{color:'red', border: '2px solid #3760F1',width: '83%',marginBottom:'8px'}}
                          compact="true"
                        >Unlock&nbsp;Loan</Button>
                        )}
                        {(roleChecker.isAdminAccess(user.role)|| roleChecker.isGMLevelAccess(user.role)) && (loan.loan_status !== 'Closed') && (loan.loan_status !== "Cancelled") && (loan.loan_status !== "Auctioned")&&( 
                          <LoanCancelModal
                            user={user}
                            loan={loan}
                            history={props.history}
                          />
                        )} */}
                      {/* {(roleChecker.isBranchLevelAccess(user.role)) && (loan.loan_status==='Disbursed'||loan.loan_status==='Suspense')&&(<Button   
                      style={{ width: '83%', color: "#3760F1",border: '2px solid #3760F1',marginBottom:'8px'}}
                      color="primary" compact="true"   onClick={()=>props.history.push(`/home/pledge?renew_of=${loan.loan_account}`)} >Renew Loan</Button>)} */}
                        <LetterListModal
                          {...props}
                          user={user}
                          loan={loan}
                          history={props.history}
                        />
                      </Td>
                    </Tr>
                    <br />
                  </>
                ))}
            {isLoading && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="spinner-border spinner-border-lg align-center"></span>
                </Td>
              </Tr>
            )}
            {!isLoading && loans.length === 0 && (
              <Tr>
                <Td colSpan="9" className="text-center">
                  <span className="align-center">No Loans founds</span>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {loans ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={loans.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

// import React from "react";
// import { misService } from "../../_services";
// import { ThumbUp } from "@material-ui/icons";
// // import { Edit } from "@material-ui/icons";
// import { Button } from "@material-ui/core";
// import TableContainer from "@material-ui/core/TableContainer";
// import TablePagination from "@material-ui/core/TablePagination";
// import { makeStyles } from "@material-ui/core/styles";
// import { Input } from "antd";

// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// import { employeeService } from "../../_services/employee.service";
// import LoanModal from "./LoanModal";
// import BankModal from "./BankModal";
// import LoanStatusModal from "./MIS/LoanStatusModal";
// import LoanCloseModal from "./LoanCloseModal"
// import LetterListModal from "./LetterListModal";

// import "./loanlist.css";
// import "antd/dist/antd.css";
// import PaymentDetailsModal from "./MIS/PaymentDetailsModal";
// import { Role } from "../../_helpers";
// import RepaymentSchedule from "./RepaymentSchedule";
// import { DisbursementDetailsModal } from "./MIS/DisbursementDetailsModal";
 
// import ColenderPaymentModal from "./MIS/ColenderPaymentModel";

 
// import {history} from "../../_helpers/history"
// import { useLocation } from "react-router-dom";
// import { AuctionModal } from "./GoldLoanLetters/AuctionModal";
// import LoanCancelModal from "./LoanCancelModel";
// import roleChecker from "../../_helpers/rolechecker";
// import { ApprovalRequestFilter } from "../MakerCheckerFramework/ApprovalRequestFilter";
// const { Search } = Input;

// const useStyles = makeStyles({
//   root: {
//     width: "100%",
//   },
//   container: {
//     maxHeight: 1000,
//   },
// });

// const useSortableData = (searchVal, items, config = null) => {
//   const [sortConfig, setSortConfig] = React.useState(config);
//   const [origData, setOrigData] = React.useState([]);
//   const [searchIndex, setSearchIndex] = React.useState([]);

//   React.useEffect(() => {
//     const crawl = (user, allValues) => {
//       if (!allValues) allValues = [];
//       for (var key in user) {
//         if (typeof user[key] === "object") crawl(user[key], allValues);
//         else allValues.push(user[key] + " ");
//       }
//       return allValues;
//     };
//     const fetchData = async () => {
//       setOrigData(items);
//       const searchInd = items.map((user) => {
//         const allValues = crawl(user);
//         return { allValues: allValues.toString() };
//       });
//       setSearchIndex(searchInd);
//     };
//     fetchData();
//   }, [items]);

//   const sortedItems = React.useMemo(() => {
//     let sortableItems = [...origData];

//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? 1 : -1;
//         }
//         return 0;
//       });
//     }

//     if (searchVal) {
//       const newItems = searchIndex.map((user, index) => {
//         if (user.allValues.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0)
//           return sortableItems[index];
//         return null;
//       });
//       sortableItems = newItems.filter((user) => {
//         if (user) return true;
//         return false;
//       });
//     }

//     return sortableItems;
//   }, [sortConfig, searchVal, origData, searchIndex]);

//   const requestSort = (key) => {
//     let direction = "ascending";
//     if (
//       sortConfig &&
//       sortConfig.key === key &&
//       sortConfig.direction === "ascending"
//     ) {
//       direction = "descending";
//     }
//     setSortConfig({ key, direction });
//   };

//   return { items: sortedItems, requestSort, sortConfig };
// };

// export default function LoanList(props) {
//   const classes = useStyles();
//   const { path,params,url } = props.match;
//   const location = useLocation();
//   const [loans, setLoans] = React.useState([]);
//   const [isLoading, setIsLoading] = React.useState(true);
//   const [filterFields, setFilterFields] = React.useState({});
//   const user = employeeService.userValue;
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(25);

//   const [searchVal, setSearchVal] = React.useState(null);
//   const [originalData,setOriginalData]=React.useState()
//   var { items, requestSort, sortConfig } = useSortableData(searchVal, loans);
//   const search = props.location.search;
//   console.log("location search params "+search);
//   console.log("location 2 search params "+location.search);
//   console.log("Path is "+path);
//   console.log("Params is "+params);
//   console.log(params);
//   console.log("Url is "+url);

 
//   const searchParams = new URLSearchParams(search);
//   const intitialLoanAccount=searchParams.get("loan_account");
//   const updateFields=(fieldKey,fieldVal)=> {
//       filterFields[fieldKey]=fieldVal;
//       setFilterFields(filterFields)
//       console.log({filterFields})
 
//   }
//   console.log(searchParams);
  

//   const getClassNamesFor = (name) => {
//     if (!sortConfig) {
//       return;
//     }
//     return sortConfig.key === name ? sortConfig.direction : undefined;
//   };

//   React.useEffect(() => {
//     if(searchParams.get("loan_account"))
//     {
//       updateFields("loan_number",searchParams.get("loan_account").toUpperCase());
//       loadLoans();
//     }
//     else {
//       getloans();
//     }
    
//   }, []);

 
//   async function misdownload(filterTarget) {
//     const dlink = await misService.getExcel({ 'filter_target': filterTarget });
//     window.open(dlink);
//   }

 
//   function filterLoans(l)
//   {
//     if (roleChecker.isHOGoldAccess(user.role) || roleChecker.hasAccountsAccess(user.role))
//       return l;
//     else {
//       return l.filter((loan) => loan.branch_name.toLowerCase().trim() === user.branch.branch_name.toLowerCase().trim());
//     }
//   }

//   async function getloans() {
//     await misService.getAll().then((x) => {
//       setIsLoading(false);
//       setLoans(filterLoans(x))
//       setOriginalData(filterLoans(x))
//     })
//       .catch((err) => {
//         setIsLoading(false);
//         console.log("error getting loans");
//         console.log(err);
//       });

//   }

//   function approveLoan(id) {
//     misService.approveloan(id).then((res) => {
//       getloans();
//       alert(res.message);
//     });
//   }


//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChange = (e) => (
//     e.target.value.length <= 10 ? updateFields('mobile_number', e.target.value) : e.target.value = e.target.value.slice(0, 10), updateFields('mobile_number', e.target.value)
//   )

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

 
//   async function applyFilter()
//   {
//     //loadLoans();
    
//      if(filterFields['loan_number'])
//      {
//         history.push('/home/goldloans?loan_account='+filterFields['loan_number']);
//         window.location.reload(); 
//      }
//      else
//      {
//        loadLoans();
//      }
     
//   }
//   async function loadLoans(){
 
//     console.log("loading loans");
//     console.log(filterFields);
//     setIsLoading(true);
//     setLoans([]);
//     await misService.getAllFilter(filterFields).then((x) => {
//       setIsLoading(false);
//       setLoans(filterLoans(x))
//       setOriginalData(filterLoans(x))
//     })
//       .catch((err) => {
//         setIsLoading(false);
//         console.log(err);
//         window.alert("error getting loans")
//       })
//   }

//   // async function unlockLoan(loan_id){
//   //   console.log("Unlocking the loan id "+loan_id)
//   //   if(window.confirm("Are you sure to unlock the loan?"))
//   //   {
//   //     misService.unlockLoan(loan_id).then((x)=> {
//   //             window.alert("Loan unlocked");
//   //             window.location.reload();
//   //     })
//   //     .catch((err)=> {
//   //        console.log(err);
//   //     })
//   //   }
//   // KFPLGLMN00598
//   // }
//   async function unlockLoan(loan_id,loan_account,loan_status){
//     await ApprovalRequestFilter.RequestFilter({
//       service: 'misService',
//       api: 'unlockLoan',
//       id:loan_id,
//       task: 'unlock Loan',
//       updated_fields:['loan_status'],
//       sensitive_fields: [],
//       new_values:{loan_status:"Disbursed"},
//       old_values: {loan_status},
//       identification_key: 'Loan Number',
//       identification_value:loan_account,
//       emp_id: user.emp_id,
//       approval_needed: true,
//       approved: false,
//       approval_access: 'isAccountChecker',
//       product: 'goldloans'
//   })
//       .then((x) => {
//           console.log(x)
//           alert("Unlock Request Sent to Concerned Authority")
//           window.location.reload()
//       })
//       .catch((err) => {
//           window.alert(err)
//           console.log(err)
//       })
//   }
//   const filterLoanList = () => {
//     let selectedValue = document.querySelector('select').value
//     if(selectedValue === "All")
//     {
//       setLoans(originalData)
//     }else{
//       setLoans(originalData.filter(x => x.loan_status == selectedValue))
//     }
//   }  
//   return (
//     <div>
//       <div className="d-flex justify-content-between">
//         <h1>Gold Loans </h1>

//         {/* Create Loan Button */}
//         {/* <div>
//          <Button href={`${path}/add`} variant="contained" style={{'background-color':'orange','color':'black'}}>Create Loan</Button> 
//         </div> */}
//         <div>
//         </div>
//       </div>
//       <div className="d-flex justify-content-between">
//         <div>
 
//         <span>From date: </span>
//         <input
//         label="From date"
//         type="date"
//         name="loan_from_date"
//         onChange={(e)=>updateFields('loan_from_date',e.target.value)}
//         />
//         <span>To date: </span> 
//         <input
//         type="date"
//         label="To date"
//         name="loan_to_date"
//         onChange={(e)=>updateFields('loan_to_date',e.target.value)}
//         />
//         <br/>
//         <div style={{marginTop:"5px"}}>
//         Loan Number: 
//         <input 
//         label="Loan Number"
//         name="loan_number"
//         type="text"
//         defaultValue={intitialLoanAccount}
//         onChange={(e)=>updateFields('loan_number',e.target.value)}
//         />
//         Mobile Number: 
//         <input 
//         label="Mobile Number"
//         name="mobile_number"
//         type="number"
//         max="10" 
//         onChange={ handleChange }
//         />
//         </div>
//         </div>
//         <Button 
//          variant="contained"
//          color="primary"
//          size="small"
//          style={{height:"25px"}}
//          onClick={()=>applyFilter()}>Load Loans</Button>
//          {/* <Search
//         onChange={(e) => setSearchVal(e.target.value)}
//         placeholder="Search"
//         enterButton
//         style={{
//           position: "sticky",
//           top: "0",
//           left: "0",
//           width: "200px",
//           marginTop: "2vh",
//         }}
//       /> */}
//        <div style={{marginRight:'30px',}}>
//         <select onChange={filterLoanList}
//         style={{height:"30px",border:'2px solid blue',borderRadius:'4px',width:"15rem"}}>
//         <option value="All" defaultValue style={{fontSize:'19px'}}>All</option>
//          <option value="Disbursed" style={{fontSize:'19px'}}>See Disbursed Loan List</option>
//          <option value="Closed" style={{fontSize:'19px'}}>See Closed Loan List</option>
//          <option value="Locked" style={{fontSize:'19px'}}>See Locked Loan List</option> 
//          <option value="Cancelled" style={{fontSize:'19px'}}>See Cancelled Loan List</option>
//          <option value="Auctioned" style={{fontSize:'19px'}}>See Auctioned Loan List</option>
//         </select>
//        </div>
//       </div>
//       <br /> <br />
//       <TableContainer className={classes.container}>
//         <Table>
//           <Thead>
//             <Tr>
//               <Th style={{ width: "10%" }}>
//                 <button
//                   onClick={() => requestSort("date")}
//                   className={getClassNamesFor("date")}
//                 >
//                   Date
//                 </button>
//               </Th>
//               <Th style={{ width: "15%" }}>Customer</Th>
//               <Th style={{ width: "15%" }}>Loan No</Th>
//               <Th style={{ width: "10%" }}>Loan Status</Th>
//               <Th style={{ width: "10%" }}>
//                 <button
//                   onClick={() => requestSort("loan_amount")}
//                   className={getClassNamesFor("loan_amount")}
//                 >
//                   Loan Amount
//                 </button>
//               </Th>
//               <Th style={{ width: "10%" }}>Branch</Th>
//               <Th style={{ width: "10%" }}></Th>
//               <Th style={{ width: "10%" }}></Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {items &&
//               items
//                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((loan) => (
//                   <Tr key={loan._id}>
//                     <Td>{loan.date.split("T")[0]}</Td>
//                     <Td>
//                       {loan.name.toUpperCase()}
//                       <br />
//                       {(roleChecker.hasAccountsAccess(user.role) || (roleChecker.isHOGoldAccess(user.role)&&!loan.loan_status==='Locked')) &&
//                         loan.details && (
//                           <LoanModal loan={loan} history={props.history} />
//                         )} <br />
//                       {(roleChecker.hasAccountsAccess(user.role)||loan.loan_status!=='Locked' || loan.loan_status==='Locked')&&<DisbursementDetailsModal loan={loan} />}
//                       {/* )}  */}
//                       <br />
//                       {(roleChecker.hasAccountsAccess(user.role) || ((roleChecker.isHOGoldAccess(user.role) || roleChecker.isBranchLevelAccess(user.role))&&loan.loan_status!=='Locked' || loan.loan_status==='Locked')) && (
//                           <PaymentDetailsModal loan={loan} history={props.history} user={user} />
//                         )}
//                     </Td>
//                   <Td>
//                       {loan.loan_account}
//                       <br />
//                       <br />
//                       {(roleChecker.isBranchLevelAccess(user.role)) && (loan.loan_status==='Disbursed'||loan.loan_status==='Suspense')&&(<Button   color="primary" compact="true"   onClick={()=>props.history.push(`/home/pledge?renew_of=${loan.loan_account}`)} >Renew Loan</Button>)}
                      
//                       {(loan.renew_of && (
//                         <p>Renew Of: {loan.renew_of}</p>
//                       ))}
//                       <br />
//                       {(loan.renew_by && (
//                         <p>Renewed By: {loan.renew_by}</p>
//                       ))}
//                       <br />
//                       {
//                         loan.details && (
//                           <RepaymentSchedule loan={loan} />
//                         )} <br />
//                     </Td>
//                     <Td>
//                       {loan.loan_status==='Auctioned'?
//                       <React.Fragment><b style={{color:'red'}}>{loan.loan_status}</b></React.Fragment>:
//                       <React.Fragment>{loan.loan_status}</React.Fragment>
//                       }
//                       <br />
//                       {(roleChecker.isAccountChecker(user.role))  && (loan.loan_status !== 'Closed') && (
//                           <LoanCloseModal
//                             user={user}
//                             loan={loan}
//                             history={props.history}
//                           />
//                         )}
//                         {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOLevelAccess(user.role)|| roleChecker.hasAccountsAccess(user.role))  && (loan.loan_status === 'Locked') && (
//                           <Button
//                           onClick={() => unlockLoan(loan._id,loan.loan_account,loan.loan_status)}
//                           style={{color:'red'}}
//                           compact="true"
//                         >Unlock Loan</Button>
//                         )}
                      
//                       {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isAccountChecker(user.role) ) && (loan.loan_status !== 'Closed') &&(
//                           <AuctionModal
//                             user={user}
//                             loan={loan}
//                             history={props.history}
//                           />
//                         )}
//                         {(roleChecker.isAdminAccess(user.role)|| roleChecker.isGMLevelAccess(user.role)) && (loan.loan_status !== 'Closed') && (loan.loan_status !== "Cancelled") && (loan.loan_status !== "Auctioned")&&( 
//                           <LoanCancelModal
//                             user={user}
//                             loan={loan}
//                             history={props.history}
//                           />
//                         )}
//                       <br/>
//                           {(
//                             loan&&loan.cancelled_by_emp_id && (
//                            <span>Cancelled by: {user.emp_id}</span>)
//                        )}
//                       <br />
//                       {(loan.closure_date && loan.closure_date !== "" && (
//                         <span>Closure Date: {loan.closure_date.split("T")[0]}</span>
//                       ))}
//                       <br/>
//                         {(loan.closed_by_emp_id && (
//                         <span>Closed by: {loan.closed_by_emp_id}</span>
//                       ))}
//                     </Td>
//                     <Td>₹{loan.loan_amount}
//                       {(
//                         ((loan.loan_status!=='Cancelled' && roleChecker.hasAccountsAccess(user.role))
//                         || (loan.loan_status!=='Cancelled' && roleChecker.isBMLevelAccess(user.role))
//                         ||(loan.loan_status!=='Cancelled' && roleChecker.isHOGoldAccess(user.role)))&&
//                         <Button
//                           onClick={() =>
//                             props.history.push({ 'pathname': `${path}/interestcalculatorv2`, 'loan': loan })
//                           }
//                           color="primary"
//                           compact="true"
//                         >Interest Calculator</Button>
//                       )}
//                     </Td>
//                     <Td>{loan.branch_name}</Td>
//                     <Td>
//                       {((roleChecker.isHOGoldAccess(user.role)&&loan.loan_status!=='Locked') || roleChecker.hasAccountsAccess(user.role)) && (
//                           <BankModal
//                             user={user}
//                             loan={loan}
//                             history={props.history}
//                           />
//                         )}
//                       {((roleChecker.isHOGoldAccess(user.role)&&loan.loan_status!=='Locked') || roleChecker.hasAccountsAccess(user.role) ) && loan.bank_details && (
//                           <ColenderPaymentModal loan={loan} history={props.history} user={user} />
//                         )}
//                       <LetterListModal
//                         {...props}
//                         user={user}
//                         loan={loan}
//                         history={props.history}
//                       />
//                     </Td>
//                     <Td style={{ whiteSpace: "nowrap" }}>
//                       <div className="d-flex flex-column">
//                         <div className="d-flex flex-row justify-content-between">

//                           {((roleChecker.isHOGoldChecker(user.role)&&loan.loan_status!=='Locked')||roleChecker.hasAccountsAccess(user.role)) && (
//                               <button
//                                 style={{
//                                   marginTop: "10%",
//                                   width: "100%",
//                                 }}
//                                 onClick={() => approveLoan(loan._id)}
//                                 disabled={loan.approved ? true : false}
//                                 className={`btn btn-sm ${loan.approved ? "btn-success" : "btn-secondary"
//                                   }`}
//                                 data-toggle="tooltip"
//                                 data-placement="bottom"
//                                 title={`${loan.approved ? "Loan Approved" : "Approve Loan"
//                                   }`}
//                               >
//                                 <span>
//                                   <ThumbUp />
//                                 </span>
//                               </button>
//                             )}
//                         </div>
//                       </div>
//                     </Td>
//                   </Tr> 
//                 ))}
//             {isLoading && (
//               <Tr>
//                 <Td colSpan="9" className="text-center">
//                   <span className="spinner-border spinner-border-lg align-center"></span>
//                 </Td>
//               </Tr>
//             )}
//             {!isLoading && loans.length === 0 && (
//               <Tr>
//                 <Td colSpan="9" className="text-center">
//                   <span className="align-center">No Loans founds</span>
//                 </Td>
//               </Tr>
//             )}
//           </Tbody>
//         </Table>
//       </TableContainer>
//       {loans ? (
//         <TablePagination
//           rowsPerPageOptions={[10, 25, 100]}
//           component="div"
//           count={loans.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onChangePage={handleChangePage}
//           onChangeRowsPerPage={handleChangeRowsPerPage}
//         />
//       ) : (
//         <div />
//       )}
//     </div>
//   );
// }
