import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import {
  ExpandMore,
  ExpandLess,
  AccountBalanceWallet,
} from "@material-ui/icons";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService } from "../../_services";
import Product from "../../_helpers/product"

export default function PaymentMenuList(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user = props.user;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AccountBalanceWallet />
        </ListItemIcon>
        <ListItemText primary="Collections" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "50px" }}>
          <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="valist"
            component="a"
            href={`${path}/virtualaccounts`}
          >
            <ListItemText primary="Virtual Accounts" />
          </ListItem>

          <ListItem
            hidden={!(roleChecker.isGMLevelAccess(user.role) || roleChecker.isCollectionManager(user.role) || roleChecker.isHOCollectionTeamAccess(user.role) || user.role ==="KTR" ||user.role ==="ZCCM" ||user.role ==="CCCM"||user.role ==="RCCM"||user.role ==="ACCM"||user.role ==="BCCM") }
            button
            key="fvlist"
            component="a"
            href={`${path}/fieldvisits`}
          >
            <ListItemText primary="Field Visits" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isGMLevelAccess(user.role) || roleChecker.isCollectionManager(user.role) || roleChecker.isHOCollectionTeamAccess(user.role)) }
            button
            key="ctlist"
            component="a"
            href={`${path}/collectiontracking`}
          >
            <ListItemText primary="Collection Tracking" />
          </ListItem>
          <ListItem
            button 
            hidden={!(roleChecker.isCollectionManager(user.role) ||roleChecker.isAdminAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || [20858].includes(user.emp_id))} 
            key="setalert"
            component="a"
            href={`${path}/setalert`}>
            <ListItemText primary="Set Alert" />
          </ListItem>
          {employeeService.productValue == Product.SBL &&(
                      <ListItem
                      hidden={
                        !(
                          roleChecker.hasAccountsAccess(user.role) ||
                          roleChecker.isCollectionManager(user.role) ||
                          roleChecker.isBMLevelAccess(user.role) ||
                          roleChecker.isHOCollectionTeamAccess(user.role)
                        )
                      }
                      button
                      key="branchsummary"
                      component="a"
                      href={`${path}/sblloans/sblsummary`}
                    >
                      <ListItemText primary="Branch Payments" />
                    </ListItem>
          )}

          {employeeService.productValue == Product.SBL &&(
                      <ListItem
                      hidden={
                        !(
                          roleChecker.hasAccountsAccess(user.role) ||
                          roleChecker.isCollectionManager(user.role) ||
                          roleChecker.isBMLevelAccess(user.role) ||
                          roleChecker.isHOCollectionTeamAccess(user.role)
                        )
                      }
                      button
                      key="branchsummary"
                      component="a"
                      href={`${path}/sblloans/sblcollectionsummary`}
                    >
                      <ListItemText primary="Branch Collections" />
                    </ListItem>
          )}



          <ListItem
            hidden={
              !(
                roleChecker.hasReceiptViewAccess(user.role) || roleChecker.isITAccess(user.role)|| user.role ==="KTR"||user.role ==="ZCCM" ||user.role ==="CCCM"||user.role ==="RCCM"||user.role ==="ACCM"||user.role ==="BCCM"
              )
            }
            button
            key="vap"
            component="a"
            href={`${path}/virtualaccounts/payments`}
          >
            <ListItemText primary="QR Payments" />
          </ListItem>


          <ListItem
            hidden={
              !(
                roleChecker.hasReceiptViewAccess(user.role) || user.role ==="KTR"||user.role ==="ZCCM" ||user.role ==="CCCM"||user.role ==="RCCM"||user.role ==="ACCM"||user.role ==="BCCM"
              )
            }
            button
            key="vap"
            component="a"
            href={`${path}/payments`}
          >
            <ListItemText primary="CASH Payments" />
          </ListItem>

          <ListItem
            hidden={
              !(
                roleChecker.hasReceiptViewAccess(user.role) || roleChecker.isITAccess(user.role)||user.role ==="ZCCM" ||user.role ==="CCCM"||user.role ==="RCCM"||user.role ==="ACCM"||user.role ==="BCCM"
              )
            }
            button
            key="vap"
            component="a"
            href={`${path}/paymentreceipts`}
          >
            <ListItemText primary="Print Receipts" />
          </ListItem>
         
          

          <ListItem
            hidden={
              !(
                roleChecker.isAdminAccess(user.role) 
              )
            }
            button
            key="vap"
            component="a"
            href={`${path}/nachpayments`}
          >
            <ListItemText primary="Nach Payments" />
          </ListItem>

          <ListItem
            hidden={
              !(
                roleChecker.isHOCreditTeamAccess(user.role) ||
                roleChecker.isHOOpsTeamAccess(user.role) ||
                roleChecker.isBMLevelAccess(user.role)
              )
            }
            button
            key="vap"
            component="a"
            href={`${path}/icicienach`}
          >
            <ListItemText primary="ICICI ENach" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isHOCollectionTeamAccess(user.role) || roleChecker.isCollectionManager(user.role) )}
            button
            key="import"
            component="a"
            href={`${path}/collectionloans`}
          >
            <ListItemText primary="Assign Collection" />
          </ListItem>

          <ListItem
            hidden={!(roleChecker.isAdminAccess(user.role) || [20113].includes(user.emp_id))}
            button
            key="vap"
            component="a"
            href={`${path}/auenach`}
          >
            <ListItemText primary="AU ENach" />
          </ListItem>
          <ListItem
            hidden={
              !(
                roleChecker.hasAccountsAccess(user.role) ||
                roleChecker.isHOCollectionTeamAccess(user.role)
              )
            }
            button
            key="collectenach"
            component="a"
            href={`${path}/collectenach`}
          >
            <ListItemText primary="Collect E-Nach" />
          </ListItem>
          <ListItem
            hidden={!roleChecker.isAdminAccess(user.role)}
            button
            key="collectemailnach"
            component="a"
            href={`${path}/collectmailnach`}
          >
            <ListItemText primary="Collect Mail Nach" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.isHOCollectionTeamAccess(user.role) || roleChecker.isAdminAccess(user.role))}
            button
            key="bulkuploadcollection"
            component="a"
            href={`${path}/bulkuploadcollection`}
          >
            <ListItemText primary="Bulk Upload" />
          </ListItem>
        </List>
      </Collapse>
    </React.Fragment>
  );
}
