import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from "@material-ui/core";
import { uploadFileToBucket } from "../../../../_helpers/upload";
import {loanService } from "../../../../_services";
const ImageUpload = (props) => {

    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);
    const handleUrl=props.handleUrl;
    const application_id=props.loan_number;
    const selectedDocumentType = props.selectedDocumentType
    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
      };

    const data = new FormData();
    data.append("file", selectedFile);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        // overflow: 'scroll',
        // paddingBottom: '30px',
    };

    const bucket = process.env.REACT_APP_s3docsBucket

    const onClickHandler = async () => {
        console.log("application id is " + application_id);
        console.log("selected file is " + selectedFile.name);
        
        let fileName = selectedFile.name.replace(/^.*[\\\/]/, ''); // Extract file name
        const report = await uploadFileToBucket(bucket, "image/jpeg", `${application_id}/kyc_images/${fileName}`, selectedFile);
        console.log(report, "dddddddd");
    
        // Check document type and perform corresponding recognition
        if (selectedDocumentType === "aadhar_front" || selectedDocumentType === "aadhar_back" || selectedDocumentType === "pan" || selectedDocumentType === "voter_id") {
            const res = await loanService.checkImageRecognization({ "s3_url": report });
            console.log('res is', res);
            
            if (res && res.status === "success" && (res.id_label === "aadhar_images" || res.id_label === "pan_card" || res.id_label === "voter_id")) {
                console.log("upload success");
                handleUrl(report);
            } else if (res && res.status !== "success") {
                alert(`${res.message}`);
            }
        }
         else if (selectedDocumentType === "customer_photo") { // Remove extra space here
            const res = await loanService.checkPersonRecognization({ "s3_url": report });
            console.log('res is', res);
            
            if (res && res.status === "success") {
                console.log("upload success");
                handleUrl(report);
            } else if (res && res.status !== "success") {
                alert(`${res.message}`);
            }
        } 
        else {
            // Handle non-recognition document types
            handleUrl(report);
        }
    
        handleClose(); // Close the dialog or modal
    };
    
    return (

        <div>
            <Button color='primary' onClick={handleOpen}>Upload Folder Scan</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography style={{ margin: "auto", }}>
                        <div className="offset-md-3 col-md-6">
                            <form method="post" action="#" id="#">
                                <div className="form-group files">
                                    <label>Upload Your File (JPG)</label>
                                    <input
                                        accept="image/jpeg"
                                        type="file"
                                        className="form-control"
                                         onChange={(e) => onChangeHandler(e)}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-success btn-block"
                                    onClick={() => onClickHandler()}
                                >
                                    Upload
                                </button>
                            </form>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default ImageUpload