import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";

const PersonalGuarantee = (props) => {

    const data = props.data
    const branches = props.branches

    // const PersonalGuaranteeRef = React.useRef();



    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => PersonalGuaranteeRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }



    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                <p style={{ marginLeft: "40%" }}><b>Schedule</b></p>
                <span style={{ marginLeft: "32%" }}><b>(PERSONAL GUARANTEE)</b></span><br/><br/>

                <table className="table table-bordered" style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
    <tbody>
        <tr>
            <td className="w-25">Place of the Execution</td>
            <td>{branches.branch_city}</td>
        </tr>
        <tr>
            <td>Date of the Execution</td>
            <td><b>{datehelper.displayDate(new Date(data.created))}</b></td>
        </tr>
        <tr>
            <td className="d-flex flex-column align-items-start">Guarantor/s:</td>
            <td>
                {data && data.guarantors && data.guarantors.length > 0 && data.guarantors.map((e, idx) => (
                    <div key={idx}>
                        <p>Name: {e.general_information.name}</p>
                        <p>Address: {e.general_information.address}</p>
                    </div>
                ))}
            </td>
        </tr>
        <tr>
            <td className="d-flex flex-column align-items-start">Borrower/s</td>
            <td>
                <p>Name: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</p>
                <p>Address: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.address}</p>
                {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => (
                    <div key={idx}>
                        <p>Name: {e.general_information.name}</p>
                        <p>Address: {e.general_information.address}</p>
                    </div>
                ))}
            </td>
        </tr>
        <tr>
            <td>Loan details</td>
            <td>Loan of <b>Rs. {data && data.loan && data.loan.sanction_amount}/- (Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)} only)</b> , as per the terms and conditions set out in the Loan Agreement.</td>
        </tr>
        <tr>
            <td>Loan Agreement</td>
            <td>Loan agreement dated <b>{datehelper.displayDate(new Date(data.created))}</b> , executed between the Borrower/s and the Lenders, in respect of the Loan.</td>
        </tr>
    </tbody>
</table>
<br />
            </div>
            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                <span><b>IN WITNESS WHEREOF this Guarantee is executed on the day and year hereinabove written by;</b></span><br /><br />

                <span>Within named “Guarantor/s”</span><br /><br />

                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <span style={{width:"300px"}}>Name of the Guarantor/s</span>
                    <span style={{width:"300px"}}>Signature/ Thumb Impression</span>
                </div><br/>
                {data && data.co_applicants && data.guarantors.length > 0 && data.guarantors.map((e, idx) => {
                        return <div style={{display:"flex", justifyContent:"space-between"}}>
                            <span span style={{width:"300px"}}>{e.general_information.name}</span><br />
                            <span span style={{width:"200px"}}>________________________</span><br /><br/>
                        </div>
                    })}
                <span style={{ marginTop: "20px" }}>Confirmed by the within named “Borrower/s”</span><br /><br />
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <span style={{width:"300px"}}>Name of the Borrower/s</span>
                    <span style={{width:"300px"}}>Signature/ Thumb Impression</span>
                </div><br/>
                <div style={{display:"flex", justifyContent:"space-between"}}>    
                    <span span style={{width:"300px"}}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                    <span span style={{width:"200px"}}>________________________</span><br /><br/>
                 </div>   
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{display:"flex", justifyContent:"space-between"}}>
                            <span span style={{width:"300px"}}>{e.general_information.name}</span><br />
                            <span span style={{width:"200px"}}>________________________</span><br /><br/>
                        </div>
                    })}
            </div>
        </>
    )
}

export default PersonalGuarantee