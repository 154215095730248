import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import ImageUpload from "./ImageUpload";
import { loanService } from "../../../../_services";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function KycImageAddModal(props) {
  const customer = props.customer;
  const applicant_type = props.applicant_type;
  const applicant_id = props.applicant_id;
  console.log("applicant id is "+applicant_id);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [documentUrl,setDocumentUrl]= React.useState("");
  const [selectedDocumentType, setSelectedDocumentType] = React.useState("");
  const documentTypes = {'customer_photo':{'title':'Customer Photo','tag':'Customer Photo'},
                      'aadhar_front': {'title':'Aadhar','tag':'Aadhar Front Side'},
                      'aadhar_back': {'title':'Aadhar','tag':'Aadhar Back Side'},
                      'pan':{ 'title':'PAN Card','tag':'PAN Card'},
                      'voter_id':{ 'title':'Voter Id','tag':'Voter Id'},
                      'driving_licence':{'title':'Driving Licence','tag':'Driving License'},
                      'other':{'title':'Other','tag':'Other Id'}
                    };
  
  const initialValues = {
         document_type : "",
        }

  const validationSchema = Yup.object().shape({
      document_type: Yup.string().required("Document Type is required")
  });

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
   
  const handleUrl=(url)=> {
     console.log("url.."+url);
     setDocumentUrl(url);
     
  }

  function handleChange(fieldName,value,setFieldValue)
  {
      setFieldValue(fieldName,value);
      
  }

  
  async function onKycSubmit(fields, { setSubmitting }) {
    console.log("new colender submit called");
    let params={};
    console.log(fields);
    if(fields['document_type']==="")
    {
       alert("Document type not selected");
       return;
    }
    if(documentUrl==="")
    {
       alert("Image not uploaded");
       return;
    }

    let document_type=fields['document_type'];
     let kyc_images;
     if(applicant_type==="applicant") {
       kyc_images = customer.applicant.kyc_images;
     }
     else if(applicant_type ==="co_applicant")
     {
       customer.co_applicants.map((ca)=> {
           if(ca.general_information.aadhar===applicant_id)
           {
             kyc_images= ca.kyc_images;
           }
       })
     }
     else if(applicant_type ==="guarantor")
     {
       customer.guarantors.map((g)=> {
           if(g.general_information.aadhar===applicant_id)
           {
             kyc_images= g.kyc_images;
           }
       })
     }
     let new_kyc_images=[];
     if( !kyc_images || kyc_images.length==0)
     {
        
        new_kyc_images.push({'ref_id':document_type,'category':documentTypes[document_type]['title'],
            'tag':documentTypes[document_type]['tag'],'uri':documentUrl,
          });
          
     }
     else
     {
         new_kyc_images=kyc_images.filter((x)=> {
             if(x.ref_id===document_type)
             {
               return false;
             } 
             return true;
         });
         new_kyc_images.push({'ref_id':document_type,'category':documentTypes[document_type]['title'],
              'tag':documentTypes[document_type]['tag'],'uri':documentUrl,
         });
     }
     
     if(applicant_type==="applicant")
     {
      customer.applicant.kyc_images=new_kyc_images;
     }
     else if (applicant_type ==="co_applicant")
     {
      console.log("applicant id is "+applicant_id);
      let new_co_applicants= [];
      customer.co_applicants.map((ca)=> {
        if(ca.general_information.aadhar===applicant_id)
        {
            console.log("matched .. ");
            console.log(new_kyc_images); 
            ca.kyc_images = new_kyc_images;
            
        }
        new_co_applicants.push(ca);
      })
      console.log(new_co_applicants);
      customer.co_applicants=new_co_applicants;

     }
     else if (applicant_type ==="guarantor")
     {
      console.log("applicant id is for guarantor "+applicant_id);
      let new_guarantors= [];
      customer.guarantors.map((g)=> {
        if(g.general_information.aadhar===applicant_id)
        {
            console.log("guarantor matched .. ");
            console.log(new_kyc_images); 
            g.kyc_images = new_kyc_images;
            
        }
        new_guarantors.push(g);
      })
      console.log(new_guarantors);
      customer.guarantors=new_guarantors;

     }
     console.log(customer);
     loanService.update(customer._id,customer).then((x)=> {
        alert("Kyc Image added");
        window.location.reload(false);

     })
     .catch((err)=> {
        console.log("error adding image "+err);
        alert("Error uploading KYC Image");
     })
    
    setSubmitting(false);
    
  }

  return (
    <div>
     
     <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
      >
        Add Kyc Image
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">KYC Image for {applicant_type}</h2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onKycSubmit}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue,values }) => {
                  return (
                    <Form>
                        <div className="form-row">
                        <div className="form-group col">
                        
                        <label>Document Type:</label><br/>
                        <Field
                          as="select"
                          style={{'marginTop':'10px'}}
                          name="document_type"
                          label="Document Type"
                          value={values.document_type}
                          errors={errors}
                          touched={touched}
                          onChange={e => {
                            // Update Formik's value
                            setFieldValue('document_type', e.target.value);
              
                            // Step 3: Update the selectedDocumentType state
                            setSelectedDocumentType(e.target.value);
                          }}
                        >
                          <option value="" disabled>Select Document Type</option>
                          {
                            Object.keys(documentTypes).map((g)=> {
                              return <option value={g}>{documentTypes[g]['tag']}</option>
                            })
                          }
                          </Field>
                          <ErrorMessage
                            name="document_type"
                            component="div"
                            className="invalid-feedback"
                          />

                        </div>
                        <div className="form-group col">
                           <ImageUpload loan_number={customer.application_id} handleUrl={handleUrl} selectedDocumentType={selectedDocumentType}/>
                           {documentUrl !=="" && (
                              <img src={documentUrl} width="200px" hieght="200px"/>
                           )}
                        </div>
                      </div>                        
                  <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>         
          </div>
        </Fade>
      </Modal>
    </div>
  );
}