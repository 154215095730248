import React, { useState, useEffect } from "react";
import { employeeService } from "../../_services/employee.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReportRequestModal from "./ReportRequestModal";
import { reportsService } from "../../_services/reports.service";
import { Button } from '@material-ui/core'

function ReportsList({ match }) {
  const { path } = match;
  const [reports, setReports] = useState(null);
  const user = employeeService.userValue;

  const fetchReports = () => {
    reportsService.getAll().then((x) => {
      x = x.filter((y) => y.emp_id === user.emp_id);
      setReports(x);
    });
  };

  const handleRefresh = () => {
    setReports([]);
    reportsService.getAll().then((x) => {
      x = x.filter((y) => y.emp_id === user.emp_id);
      setReports(x);
    });
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <ReportRequestModal />
      <h2>Reports List</h2>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <Button variant="contained" color="primary"
          onClick={handleRefresh}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "20px",

          }}
        >
            Refresh
        
        </Button>
      </div>

      <Table className="table table-striped">
        <Thead>
          <Tr>
            <Th style={{ width: "10%" }}>Report Date</Th>
            <Th style={{ width: "20%" }}>Business Division</Th>
            <Th style={{ width: "20%" }}>Report Type</Th>
            <Th style={{ width: "20%" }}>Creator</Th>
            <Th style={{ width: "20%" }}>Status</Th>
            <Th style={{ width: "10%" }}>Report Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reports &&
            reports.map((report) => (
              <Tr key={report._id}>
                <Td>{report.updated.split("T")[0]}</Td>
                <Td>{report.business_div}</Td>
                <Td>{report.report_type}</Td>
                <Td>
                  {report.employee && report.employee.length > 0 && (
                    <>
                      {report.employee[0].first_name}{" "}
                      {report.employee[0].last_name}
                    </>
                  )}
                </Td>
                <Td>{report.report_status}</Td>
                <Td style={{ whiteSpace: "nowrap" }}>
                  {report.report_status === "Ready" && (
                    <a href={report.report_link}>Download</a>
                  )}
                </Td>
              </Tr>
            ))}
          {!reports && (
            <Tr>
              <Td colSpan="6" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export { ReportsList };
