import React from "react";
import { useState } from "react";
import { Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { misService } from "../../../_services";
import datehelper from "../../../_helpers/datehelper";
import { useEffect } from "react";
import { ledgerService } from "../../../_services/ledger.service";

export const DisbursementDetailsModal = (props) => {
    const [open, setOpen] = useState(false)
    const data = props.loan
    console.log("Test loan",data)
    const payments = [];
    const pf = [];
    const [value, setValue] = useState({
        payment_amount:data.disburse_amount,
        payment_date:datehelper.getPaymentDateString(new Date(data.date))
    })
    let maxDate=datehelper.getDateInputString(new Date())
    let maxDis=data.loan_amount

    const [pfValue, setPfValue] = useState({
        payment_amount:data.pf,
        payment_date:datehelper.getPaymentDateString(new Date(data.date))
    })

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const [ledegerCurrentBalance,setLedgerCurrentBalance]=useState()
    const getLedgerCurrectBalance=async()=>{
        await ledgerService.getLedgerCurrectBalance()
        .then((res)=>setLedgerCurrentBalance(res))
        .catch((err)=>console.log(err))
      }
    useEffect(()=>{
        getLedgerCurrectBalance()
    },[])
    
    // useEffect(() => {
    //     async function getPayments() {
    //     await props.loan.payment_details && props.loan.payment_details.length > 0 && props.loan.payment_details.map(payment => <>{payment.payment_type.toLowerCase() === 'disbursement' && payments.push(payment)}{payment.payment_type.toLowerCase() === 'pf' && pf.push(payment)}</>)
    //     }
    //     getPayments();
    // }
    //     , [])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        //paddingBottom: '30px',
    };
    const handleChange = (fieldKey, fieldValue) => {
        value[fieldKey] = fieldValue;
        setValue(value);
    }
    const changePf = (fieldKey, fieldValue) => {
        pfValue[fieldKey] = fieldValue;
        setPfValue(pfValue);
    }
    // const handleDisbursement = (e) => {
    //     e.preventDefault()
    //     misService.addDisbursementDetails({ ...value, id: data._id, payment_type: 'disbursement' })
    //         .then(
    //             response =>
    //                 {window.alert('Disbursement Details Added');
    //                 window.location.reload()}
    //         )
    //         .catch(err => console.log(err))
    // }

    const handleDisbursement = (e) => {
        if (value.payment_amount>199999 && value.payment_mode==="cash") {
            e.preventDefault()
            alert("You can't disburse more than ₹1,99,999 in cash.")
        } else if (pf.length < 1) {
            e.preventDefault()
            alert("Please add pf details")
        } else if ((ledegerCurrentBalance && ledegerCurrentBalance.current_balance < 0)&&(value.payment_mode==="cash") ) {
            e.preventDefault()
            alert(`Disbursement is not possible due to negative ledger balance:${ledegerCurrentBalance && ledegerCurrentBalance.current_balance.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}`)
        } else if ((value.payment_amount>(ledegerCurrentBalance && ledegerCurrentBalance.current_balance))&&value.payment_mode==="cash") {
            e.preventDefault()
            alert(`Disbursement amount must be less than your current ledger balance:${ledegerCurrentBalance && ledegerCurrentBalance.current_balance.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}`)
        }else{
        e.preventDefault()
        misService.addDisbursementDetails({ ...value, id: data._id, payment_type: 'disbursement' })
            .then(
                response =>
                    {window.alert('Disbursement Details Added');
                    window.location.reload()
                }
            )
            .catch(err => console.log(err))
        }
    }

    const handlePf = (e) => {
        e.preventDefault()
        misService.addDisbursementDetails({ ...pfValue, id: data._id, payment_type: 'pf' })
            .then(
                response =>
                    {window.alert('PF Details Added');
                    window.location.reload()}
            )
            .catch(err => console.log(err))
    }
//   let  cashDisburseAmount=data.payment_details.filter(x=>x.payment_type==='disbursement' && x.payment_mode ==="cash").reduce((acc,curr)=>acc+curr.payment_amount,0)

    const getRemainingAmount =()=>{
        let dis_amt = payments.reduce((acc,curr)=>{
          return acc+curr.payment_amount
        },0)
        return (dis_amt-(data.insurance_amount??0)-(data.pf??0))
    }

    return (
        <React.Fragment>
            <Button style={{ width: "100%", color: 'primary',border: '2px solid #3760F1' }} onClick={handleOpen} color="primary"> Disbursement Details </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>
                        <b style={{ fontSize: '30px' }}>Disbursement Details </b> <br />  Loan Account:{data.loan_account} <br />
                        {data && data.payment_details.length > 0 && data.payment_details.map(payment => <>{payment.payment_type  === 'disbursement' && console.log(payments.push(payment))}{payment.payment_type  === 'pf' && console.log(pf.push(payment))}</>)}

                        {
                            payments.length > 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Mode</th>
                                            <th>Reference</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {payments.map(payment =>
                                            <tr>
                                                <td style={{ width: '20%' }}>{datehelper.displayDate(new Date(payment.payment_date))}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_type}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_mode}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_ref}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_amount}</td>
                                            </tr>)}

                                    </tbody>
                                </table>
                                : null
                        }

                        <div style={{ marginTop: '10px' }}>
                            {/* {payments.length === 0 && <span style={{ marginLeft: '28%' }}>No data available <button style={{ backgroundColor: '#0066cc', color: 'white', borderRadius: '5px', border: 'none' }} onClick={() => <DisbursementDetails />}>Add Disbursement Details</button></span>} */}
                            {/* {payments.length === 0 && */}
                                <form style={{marginTop:'25px'}} onSubmit={(e) => handleDisbursement(e)} >
                                    <label>Amount: <input style={{ width: '120px', marginRight: '10px', borderRadius: '5px' }} type="number" defaultValue={value.payment_amount} max={maxDis-getRemainingAmount()} min={1} name="payment_amount" placeholder="Amount" onChange={(e) => handleChange([e.target.name], e.target.value)} required /></label>
                                    <label>Date: <input style={{ width: '120px', marginRight: '10px', borderRadius: '5px' }} onKeyDown={(e) => e.preventDefault()} type="date" max={maxDate} defaultValue={value.payment_date} name="payment_date" onChange={(e) => handleChange([e.target.name], e.target.value)}  required /></label>
                                    <label>Reference: <input style={{ width: '150px', marginRight: '10px', borderRadius: '5px' }} type="text" placeholder="Reference" name="payment_ref" onChange={(e) => handleChange([e.target.name], e.target.value)} required /></label>
                                    <label>Mode: <select style={{ width: '120px', height: '28px', borderRadius: '5px' }} name="payment_mode" onChange={(e) => handleChange([e.target.name], e.target.value)} required>
                                        <option value=""></option>
                                        <option value="cash">Cash</option>
                                        <option value="online">Online</option>
                                        {/* <option value="adjustment">Adjustment</option> */}
                                    </select></label>
                                    <button style={{ backgroundColor: '#0066cc', color: 'white', borderRadius: '5px', border: 'none', marginLeft: '38%', marginTop: '25px' }} >Add Disbursement Details</button>
                                </form>
                                {/* } */}
                        </div>
                        <br />
                        <br />
                        {/* <br /> */}
                        {data.insurance_amount && <h5>Insurance Amount:₹{data.insurance_amount}</h5>}
                        <b style={{ fontSize: '30px' }}>PF Details</b><br /><br />
                        {
                            pf.length > 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Mode</th>
                                            <th>Reference</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {pf.map(payment =>
                                            <tr>
                                                <td style={{ width: '20%' }}>{datehelper.displayDate(new Date(payment.payment_date))}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_type}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_mode}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_ref}</td>
                                                <td style={{ width: '20%' }}>{payment.payment_amount}</td>
                                            </tr>)}

                                    </tbody>
                                </table>
                                : null
                        }
                        <div style={{ marginTop: '10px' }}>
                            {pf.length === 0 &&
                                <form onSubmit={(e) => handlePf(e)} >
                                    <label>PF Amount: <input style={{ width: '120px', marginRight: '10px', borderRadius: '5px' }} type="number" defaultValue={pfValue.payment_amount} name="payment_amount" placeholder="PF Amount" onChange={(e) => changePf([e.target.name], e.target.value)} disabled required /></label>
                                    <label>Date: <input style={{ width: '120px', marginRight: '10px', borderRadius: '5px' }} onKeyDown={(e) => e.preventDefault()} max={maxDate} type="date" defaultValue={pfValue.payment_date} name="payment_date" onChange={(e) => changePf([e.target.name], e.target.value)} required /></label>
                                    <label>Reference: <input style={{ width: '150px', marginRight: '10px', borderRadius: '5px' }} type="text" placeholder="Reference" name="payment_ref" onChange={(e) => changePf([e.target.name], e.target.value)} required /></label>
                                    <label>Mode: <select style={{ width: '120px', height: '28px', borderRadius: '5px' }} name="payment_mode" onChange={(e) => changePf([e.target.name], e.target.value)} required>
                                        <option value=""></option>
                                        <option value="cash">Cash</option>
                                        <option value="online">Online</option>
                                        <option value="adjustment">Adjustment</option>
                                    </select></label>
                                    <button style={{ backgroundColor: '#0066cc', color: 'white', borderRadius: '5px', border: 'none', marginLeft: '38%', marginTop: '25px' }} >Add PF Details</button>
                                </form>}
                        </div>

                        {
                            console.log(data)
                        }
                    </Typography>
                </Box>
            </Modal>
        </React.Fragment>
    )
}