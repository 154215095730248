// import React, { useEffect, useState } from 'react';
// import { customerService, employeeService } from "../../_services";
// import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
// import './Collection.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import swal from 'sweetalert';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, CircularProgress } from '@material-ui/core';
// import { Modal } from 'react-bootstrap';
// import { Button } from "@material-ui/core";
// import { Add, Edit } from "@material-ui/icons";
// import { Switch } from '@material-ui/core';
// import { ApprovalRequestFilter } from '../MakerCheckerFramework/ApprovalRequestFilter';
// const validateFrequency = (value) => {
//     let error;
//     if (!value) {
//         error = 'Frequency is required';
//     }
//     return error;
// };

// const validateAlertType = (value) => {
//     let error;
//     if (!value) {
//         error = 'Alert Type is required';
//     }
//     return error;
// };

// const validateMessageType = (value) => {
//     let error;
//     if (!value) {
//         error = 'Message Type is required';
//     }
//     return error;
// };

// const validateCustomerType = (value) => {
//     let error;
//     if (!value) {
//         error = 'Customer Type is required';
//     }
//     return error;
// };

// const validateFreqDate = (value) => {
//     let error;
//     if (!value) {
//         error = 'Date is required';
//     }
//     return error;
// };

// const validateFreqTime = (value) => {
//     let error;
//     if (!value) {
//         error = 'Time is required';
//     }
//     return error;
// };

// const DebugFormik = () => {
//     const { values } = useFormikContext();
//     return <pre>{JSON.stringify(values, null, 2)}</pre>;
// };



// const SetAlert = () => {
//     const [messageTypeOptions, setMessageTypeOptions] = useState([]);
//     const user = employeeService.userValue;
//     const [alerts, setAlerts] = useState([]);
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(5);
//     const [loading, setLoading] = useState(true);
//     const [showModal, setShowModal] = useState(false);
//     const [showMainModal, setShowMainModal] = useState(false);
//     const [selectedAlert, setSelectedAlert] = useState(null);
//     const searchTarget = (values, action) => {
//         const requestData = {
//           ...(action !== "create" && { _id: values._id, action: "edit", active: values.active }),
//           action,
//           freq_type: values.frequency,
//           freq_date: values.frequency === "once" ? values.freq_date : parseInt(values.freq_date),
//           freq_time: parseInt(values.freq_time),
//           alert_type: values.alert_type,
//           message_type: values.message_type,
//           customer: values.customer_type,
//           created_by: user.emp_id, // Ensure user and user.emp_id are defined
//         };
// console.log(requestData,"requestData")
//         ApprovalRequestFilter.RequestFilter({
//           service: 'customerService',
//           api: 'getSetAlert',
//           id: "",
//           task: 'Set Alert',
//           updated_fields: ['freq_type', 'freq_date', 'freq_time', 'alert_type', 'message_type',"active", 'customer', 'created_by'],
//           sensitive_fields: [],
//           new_values: { ...requestData },
//           old_values: requestData , // Changed to match the new_values structure
//           identification_key: 'Set Alert',
//           identification_value: '',
//           emp_id: user.emp_id,
//           approval_needed: true,
//           approved: false,
//           approval_access: 'isCFOAccess',
//           product: 'sbl'
//         })
//         .then((x) => {
//           console.log("test x", x)
//           swal({
//             title: "Success",
//             text: "Request Sent to concerned Authority",
//             icon: "success",
//             button: "Okay"
//           }).then(() => {
//             window.location.reload();
//           });
//         })
//         .catch((err) => {
//           window.alert(err)
//           console.log(err)
//         });
//       }


//     const handleAlertTypeChange = (setFieldValue, value) => {
//         setFieldValue('alert_type', value);

//         let options = [];
//         switch (value) {
//             case 'sms':
//                 options = [
//                     { value: 'sbl_emi_reminder', label: 'EMI Reminder For All Due Loans' },
//                 ];
//                 break;
//             case 'ivr':
//                 options = [
//                     { value: 'emi_reminder_5th_month', label: 'EMI Reminder For All Due Loans' },
//                 ];
//                 break;
//             case 'whatsapp':
//                 options = [
//                     { value: 'emi_reminder', label: 'EMI Reminder For All Due Loans' },
//                 ];
//                 break;
//             default:
//                 options = [];
//                 break;
//         }
//         setMessageTypeOptions(options);
//         setFieldValue('message_type', '');
//     };

//     async function searchSetAlert() {
//         try {
//             const res = await customerService.searchSetAlert();
//             let parsedResponses = typeof res === 'string' ? JSON.parse(res) : res;
//             if (Array.isArray(parsedResponses)) {
//                 setAlerts(parsedResponses.reverse());
//             } else {
//                 console.error("Response is not an array:", parsedResponses);
//             }
//         } catch (err) {
//             console.log('Error fetching alerts', err);
//         } finally {
//             setLoading(false);
//         }
//     }

//     useEffect(() => {
//         searchSetAlert();
//     }, []);

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

//     const handleEditClick = (alert) => {
//         setSelectedAlert(alert);
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//         setSelectedAlert(null);
//     };

//     const displayedAlerts = alerts.slice(
//         page * rowsPerPage,
//         Math.min((page + 1) * rowsPerPage, alerts.length)
//     );

//     const handleOpenMainModal = () => {
//         setShowMainModal(true)
//     }
//     const handleCloseMainModal = () => {
//         setShowMainModal(false);
//     };
//     const [selectedFrequency, setSelectedFrequency] = useState('');

//     const handleFrequencyChange = (setFieldValue, value) => {
//       setSelectedFrequency(value);
//       setFieldValue('frequency', value);
//     };
//     console.log(selectedFrequency,"selectedFrequencyselectedFrequencyselectedFrequency")
//     const [conflictMessage, setConflictMessage] = useState('');
//     const handleFreqTimeChange = (setFieldValue, freqTime) => {
//         setFieldValue("freq_time", freqTime);

//         // Check for conflicts with existing alerts
//         const conflictingAlert = alerts.find(alert => 
//             alert.freq_time === parseInt(freqTime) && alert.active
//         );

//         if (conflictingAlert) {
//             setConflictMessage('Please choose a different time range; As an alerts is already active on this time.');
//         } else {
//             setConflictMessage('');
//         }
//     };
//     return (
//         <div >
//             <Button variant="outlined" aria-label="increase" onClick={() => handleOpenMainModal()} >
//                 <Add fontSize="small" /> Create Alert
//             </Button>
//             {loading ? (
//                 <div className="text-center" style={{ marginTop: "2rem" }}>
//                     <CircularProgress />
//                 </div>
//             ) : (
//                 <Paper style={{ marginTop: "2rem" }}>
//                     <TableContainer>
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>S NO.</TableCell>
//                                     <TableCell>Frequency</TableCell>
//                                     <TableCell>Alert Type</TableCell>
//                                     <TableCell>Message Type</TableCell>
//                                     <TableCell>Customer Type</TableCell>
//                                     <TableCell>Frequency Date</TableCell>
//                                     <TableCell>Frequency Time</TableCell>
//                                     <TableCell>Active</TableCell>
//                                     <TableCell>Action</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {displayedAlerts.map((alert,test) => (
//                                     <TableRow key={alert.id}>
//                                           <TableCell>{test+1}</TableCell>
//                                         <TableCell>{alert.freq_type}</TableCell>
//                                         <TableCell>{alert.alert_type}</TableCell>
//                                         <TableCell>{alert.message_type}</TableCell>
//                                         <TableCell>{alert.customer}</TableCell>
//                                         <TableCell>{alert.freq_date}</TableCell>
//                                         <TableCell>{alert.freq_time}</TableCell>
//                                         <TableCell>{alert.active ? 'Yes' : 'No'}</TableCell>
//                                         <TableCell>
//                                             <Edit style={{ color: "blue" }} onClick={() => handleEditClick(alert)} />
//                                         </TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                     <TablePagination
//                         component="div"
//                         count={alerts.length}
//                         page={page}
//                         onPageChange={handleChangePage}
//                         rowsPerPage={rowsPerPage}
//                         onRowsPerPageChange={handleChangeRowsPerPage}
//                     />
//                 </Paper>
//             )}
//             {/* Create Alert Modal  */}

//             <Modal show={showMainModal} onHide={handleCloseMainModal} style={{ height: "100%", marginTop: "3rem" }}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Create Alert</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
//                     <Formik
//                         initialValues={{
//                             frequency: '',
//                             alert_type: '',
//                             message_type: '',
//                             customer_type: '',
//                             created_by: "",
//                             freq_date: "",
//                             freq_time: ""
//                         }}

//                         onSubmit={(values) => {
//                             searchTarget(values, "create");
//                         }}
//                     >
//                         {({ errors, touched, setFieldValue }) => (
//                             <>

//                                 <Form>
//                                 {conflictMessage && <div className="alert alert-danger">{conflictMessage}</div>}
//                                 {selectedFrequency==="weekly" && <div className="alert alert-success">Note:- Select Date assuming the weeks are grouped by date as follows: 1-7, 8-14, 15-21, 22-28, and 28+. Ex- If selected date is 6, the alert will be sent on 6, 13, 20 and 27th date every month.</div>}

//                                     <div className="row">
//                                         <div className="form-group col-md-6">
//                                             <label>Frequency</label>
//                                             <Field
//                                                 name='frequency'
//                                                 style={{ height: "2.5rem" }}
//                                                 as='select'
//                                                 validate={validateFrequency}
//                                                 onChange={e => handleFrequencyChange(setFieldValue, e.target.value)}
//                                                 className={`form-control ${errors.frequency && touched.frequency ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value=''>Choose Frequency</option>
//                                                 <option value='once'>Once</option>
//                                                 <option value='daily'>Daily</option>
//                                                 <option value='weekly'>Weekly</option>
//                                                 <option value='monthly'>Monthly</option>
//                                             </Field>
//                                             <ErrorMessage name="frequency" component="div" className="invalid-feedback" />
//                                         </div>
//                                         <div className="form-group col-md-6">
//                                             <label>Alert Type</label>
//                                             <Field
//                                                 name='alert_type'
//                                                 as='select'
//                                                 style={{ height: "2.5rem" }}
//                                                 validate={validateAlertType}
//                                                 className={`form-control ${errors.alert_type && touched.alert_type ? "is-invalid" : ""}`}
//                                                 onChange={e => handleAlertTypeChange(setFieldValue, e.target.value)}
//                                             >
//                                                 <option value=''>Choose Alert Type</option>
//                                                 <option value='sms'>SMS</option>
//                                                 <option value='ivr'>IVR</option>
//                                                 <option value='whatsapp'>Whatsapp</option>
//                                             </Field>
//                                             <ErrorMessage name="alert_type" component="div" className="invalid-feedback" />
//                                         </div>
//                                         <div className="form-group col-md-6">
//                                             <label>Message Type</label>
//                                             <Field
//                                                 name='message_type'
//                                                 style={{ height: "2.5rem" }}
//                                                 validate={validateMessageType}
//                                                 as='select'
//                                                 className={`form-control ${errors.message_type && touched.message_type ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value=''>Choose Message Type</option>
//                                                 {messageTypeOptions.map(option => (
//                                                     <option key={option.value} value={option.value}>{option.label}</option>
//                                                 ))}
//                                             </Field>
//                                             <ErrorMessage name="message_type" component="div" className="invalid-feedback" />
//                                         </div>
//                                         <div className="form-group col-md-6">
//                                             <label>Customer Type</label>
//                                             <Field
//                                                 name='customer_type'
//                                                 style={{ height: "2.5rem" }}
//                                                 as='select'
//                                                 validate={validateCustomerType}
//                                                 className={`form-control ${errors.customer_type && touched.customer_type ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value=''>Choose Customer Type</option>
//                                                 <option value='applicant'>Applicant</option>
//                                                 <option value='co_applicants'>Co-applicant</option>
//                                                 <option value='guarantors'>Guarantor</option>
//                                             </Field>
//                                             <ErrorMessage name="customer_type" component="div" className="invalid-feedback" />
//                                         </div>

//                                             {selectedFrequency==="once"?
//                                              <div className="form-group col-md-6">
//                                             <label>Select Date</label>
//                                              <Field
//                                                 name='freq_date'
//                                                 type="date"
//                                                 validate={validateFreqDate}
//                                                 style={{ height: "2.5rem" }}
//                                                 className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
//                                             />
//                                             <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
//                                             </div>:selectedFrequency==="daily"?"":selectedFrequency==="weekly"? <div className="form-group col-md-6">
//                                              <label>Select Date</label>
//                                             <Field
//                                                 name='freq_date'
//                                                 as='select'
//                                                 validate={validateFreqDate}
//                                                 style={{ height: "2.5rem" }}
//                                                 className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value="">Select Date</option>
//                                                 {[...Array(7).keys()].map(num => (
//                                                     <option key={num} value={num + 1}>
//                                                         {num + 1}
//                                                     </option>
//                                                 ))}

//                                             </Field>
//                                             <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
//                                         </div>:
//                                              <div className="form-group col-md-6">
//                                              <label>Select Date</label>
//                                             <Field
//                                                 name='freq_date'
//                                                 as='select'
//                                                 validate={validateFreqDate}
//                                                 style={{ height: "2.5rem" }}
//                                                 className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value="">Select Date</option>
//                                                 {[...Array(30).keys()].map(num => (
//                                                     <option key={num} value={num + 1}>
//                                                         {num + 1}
//                                                     </option>
//                                                 ))}

//                                             </Field>
//                                             <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
//                                         </div>}

//                                         <div className="form-group col-md-6">
//                                             <label>Select Time</label>
//                                             <Field
//                                                 name='freq_time'
//                                                 as='select'
//                                                 validate={validateFreqTime}
//                                                 style={{ height: "2.5rem" }}
//                                                 onChange={e => handleFreqTimeChange(setFieldValue, e.target.value)} 
//                                                 className={`form-control ${errors.freq_time && touched.freq_time ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value=''>Choose Time</option>
//                                                 {[...Array(23).keys()].map(num => (
//                                                     <option key={num} value={num + 1}>
//                                                         {num + 1}
//                                                     </option>
//                                                 ))}
//                                             </Field>

//                                             <ErrorMessage name="freq_time" component="div" className="invalid-feedback" />
//                                         </div>
//                                     </div>
//                                     <div className="d-flex justify-content-center mt-3 ">
//                                         <button type="submit" className="btn btn-primary w-50">Set Alert</button>
//                                     </div>

//                                 </Form>
//                                 {/* <DebugFormik /> */}
//                             </>
//                         )}
//                     </Formik>
//                 </Modal.Body>
//             </Modal>
//             {/* Edit Alert Modal  */}

//             <Modal show={showModal} onHide={handleCloseModal} style={{ height: "100%", marginTop: "3rem" }}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Edit Alert</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
//                     {selectedAlert && (
//                         <Formik
//                             initialValues={{
//                                 frequency: selectedAlert.freq_type,
//                                 alert_type: selectedAlert.alert_type,
//                                 message_type: selectedAlert.message_type,
//                                 customer_type: selectedAlert.customer,
//                                 freq_date: selectedAlert.freq_date,
//                                 freq_time: selectedAlert.freq_time,
//                                 created_by: user.emp_id,
//                                 _id: selectedAlert._id,
//                                 // active: selectedAlert.active
//                                 active: selectedAlert ? selectedAlert.active : false,
//                             }}
//                             onSubmit={(values) => {
//                                 searchTarget(values, "edit");
//                                 handleCloseModal();
//                             }}
//                         >
//                             {({ errors, touched, setFieldValue }) => (
//                                 <Form>
//                                     {conflictMessage && <div className="alert alert-danger">{conflictMessage}</div>}
//                                 {(selectedAlert.freq_type==="weekly" ||selectedFrequency ==="weekly") && <div className="alert alert-success">Note:- Select Date assuming the weeks are grouped by date as follows: 1-7, 8-14, 15-21, 22-28, and 28+. Ex- If selected date is 6, the alert will be sent on 6, 13, 20 and 27th date every month.</div>}

//                                     <div className="row">

//                                         <div className="form-group col-md-6">
//                                             <label>Frequency</label>
//                                             <Field
//                                                 style={{ height: "2.5rem" }}
//                                                 name="frequency"
//                                                 as="select"
//                                                 onChange={e => handleFrequencyChange(setFieldValue, e.target.value)}
//                                                 className={`form-control ${errors.frequency && touched.frequency ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value="">Choose Frequency</option>
//                                                 <option value="once">Once</option>
//                                                 <option value="daily">Daily</option>
//                                                 <option value="weekly">Weekly</option>
//                                                 <option value="monthly">Monthly</option>
//                                             </Field>
//                                             <ErrorMessage name="frequency" component="div" className="invalid-feedback" />
//                                         </div>
//                                         <div className="form-group col-md-6">
//                                             <label>Alert Type</label>
//                                             <Field
//                                                 name="alert_type"
//                                                 as="select"
//                                                 style={{ height: "2.5rem" }}
//                                                 className={`form-control ${errors.alert_type && touched.alert_type ? "is-invalid" : ""}`}
//                                                 onChange={(e) => handleAlertTypeChange(setFieldValue, e.target.value)}
//                                             >
//                                                 <option value="">Choose Alert Type</option>
//                                                 <option value="sms">SMS</option>
//                                                 <option value="ivr">IVR</option>
//                                                 <option value="whatsapp">Whatsapp</option>
//                                             </Field>
//                                             <ErrorMessage name="alert_type" component="div" className="invalid-feedback" />
//                                         </div>
//                                         <div className="form-group col-md-6">
//                                             <label>Message Type</label>
//                                             <Field
//                                                 name="message_type"
//                                                 style={{ height: "2.5rem" }}
//                                                 as="select"
//                                                 className={`form-control ${errors.message_type && touched.message_type ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value="">Choose Message Type</option>
//                                                 {messageTypeOptions.map((option) => (
//                                                     <option key={option.value} value={option.value}>
//                                                         {option.label}
//                                                     </option>
//                                                 ))}
//                                             </Field>
//                                             <ErrorMessage name="message_type" component="div" className="invalid-feedback" />
//                                         </div>
//                                         <div className="form-group col-md-6">
//                                             <label>Customer Type</label>
//                                             <Field
//                                                 name="customer_type"
//                                                 style={{ height: "2.5rem" }}
//                                                 as="select"
//                                                 className={`form-control ${errors.customer_type && touched.customer_type ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value="">Choose Customer Type</option>
//                                                 <option value="applicant">Applicant</option>
//                                                 <option value="co_applicants">Co-applicant</option>
//                                                 <option value="guarantors">Guarantor</option>
//                                             </Field>
//                                             <ErrorMessage name="customer_type" component="div" className="invalid-feedback" />
//                                         </div>

//                                             {selectedAlert.freq_type==="once"||selectedFrequency ==="once"?
//                                              <div className="form-group col-md-6">
//                                             <label>Select Date</label>
//                                             <Field
//                                                 name="freq_date"
//                                                 type="date"
//                                                 style={{ height: "2.5rem" }}
//                                                 className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
//                                             />
//                                             <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
//                                             </div>:selectedAlert.freq_type==="daily" ||selectedFrequency ==="daily"?"":
//                                              <div className="form-group col-md-6">
//                                              <label>Select Date</label>
//                                             <Field
//                                                 name="freq_date"
//                                                 as='select'
//                                                 style={{ height: "2.5rem" }}
//                                                 className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value=''>Choose Date</option>
//                                                 {[...Array(30).keys()].map(num => (
//                                                     <option key={num} value={num + 1}>
//                                                         {num + 1}
//                                                     </option>
//                                                 ))}
//                                             </Field>
//                                             <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
//                                         </div>}

//                                         <div className="form-group col-md-6">
//                                             <label>Select Time</label>
//                                             <Field
//                                                 name="freq_time"
//                                                 as='select'
//                                                 style={{ height: "2.5rem" }}
//                                                 onChange={e => handleFreqTimeChange(setFieldValue, e.target.value)} 
//                                                 className={`form-control ${errors.freq_time && touched.freq_time ? "is-invalid" : ""}`}
//                                             >
//                                                 <option value=''>Choose Time</option>
//                                                 {[...Array(23).keys()].map(num => (
//                                                     <option key={num} value={num + 1}>
//                                                         {num + 1}
//                                                     </option>
//                                                 ))}
//                                             </Field>
//                                             <ErrorMessage name="freq_time" component="div" className="invalid-feedback" />
//                                         </div>
//                                         <div  className="form-group col-md-12">
//                                         <label>Active</label>
//         <Field name="active">
//             {({ field }) => (
//                 <Switch
//                     checked={field.value === true}
//                     onChange={(event) => setFieldValue('active', event.target.checked ? true : false)}
//                     color="primary"
//                 />
//             )}
//         </Field>
//         <ErrorMessage name="active" component="div" className="invalid-feedback" />
//     </div>

//                                     </div>
//                                     <button type="submit" className="btn btn-primary w-50">
//                                         Update Alert
//                                     </button>
//                                     {/* <DebugFormik /> */}
//                                 </Form>
//                             )}
//                         </Formik>
//                     )}
//                 </Modal.Body>
//             </Modal>
//         </div>
//     );
// };

// export default SetAlert;


import React, { useEffect, useState } from 'react';
import { customerService, employeeService } from "../../_services";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import './Collection.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, CircularProgress } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import { Button } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { Switch } from '@material-ui/core';
import { uploadFileToBucket } from "../../_helpers/upload";
import { v4 as uuidv4 } from 'uuid';
import { ApprovalRequestFilter } from '../MakerCheckerFramework/ApprovalRequestFilter';
const validateFrequency = (value) => {
    let error;
    if (!value) {
        error = 'Frequency is required';
    }
    return error;
};

const validateAlertType = (value) => {
    let error;
    if (!value) {
        error = 'Alert Type is required';
    }
    return error;
};

const validateMessageType = (value) => {
    let error;
    if (!value) {
        error = 'Message Type is required';
    }
    return error;
};

const validateCustomerType = (value) => {
    let error;
    if (!value) {
        error = 'Customer Type is required';
    }
    return error;
};

const validateFreqDate = (value) => {
    let error;
    if (!value) {
        error = 'Date is required';
    }
    return error;
};

const validateFreqTime = (value) => {
    let error;
    if (!value) {
        error = 'Time is required';
    }
    return error;
};

const DebugFormik = () => {
    const { values } = useFormikContext();
    return <pre>{JSON.stringify(values, null, 2)}</pre>;
};



const SetAlert = () => {
    const bucket = process.env.REACT_APP_s3sblBucket;
    const [messageTypeOptions, setMessageTypeOptions] = useState([]);
    const user = employeeService.userValue;
    const [alerts, setAlerts] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showMainModal, setShowMainModal] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [showFileInput, setShowFileInput] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showError,setShowError] = useState(false);
    const handleCheckboxChange = (event) => {
        setShowFileInput(event.target.checked);
    };
    const random_uid= uuidv4();
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };
      
    
    console.log(selectedFile,"selectedFileselectedFile")
    const searchTargets = async (values, action) => {
        try {
            
          const val = selectedFile?.name?.split('.').pop().toLowerCase();
          console.log("random_uid", random_uid);
          let link;
          if (val === 'csv') {
              console.log(val,"wwvalvalval")
            try {
              link = await uploadFileToBucket(
                bucket,
                'application/csv',
               ` ${bucket}/bulkuploads/${random_uid}.csv`,
                selectedFile
              );
              console.log("File uploaded successfully:", link);
            } catch (uploadError) {
              console.error("File upload failed:", uploadError);
              throw new Error("Failed to upload file. Please try again.");
            }
          } else {
            setShowError(true);
            setTimeout(() => setShowError(false), 3000);
            return;
          }
      
          const requestData = {
            ...(action !== "create" && { _id: values._id, action: "edit", active: values.active }),
            action,
            freq_type: values.frequency,
            freq_date: values.frequency === "once" ? values.freq_date : parseInt(values.freq_date),
            freq_time: parseInt(values.freq_time),
            alert_type: values.alert_type,
            message_type: values.message_type,
            customer: values.customer_type,
            created_by: user?.emp_id, // Ensure user is defined
            s3_url:link,
          };
      console.log(requestData,"requestDatarequestDatarequestData")
          const approvalResponse = await ApprovalRequestFilter.RequestFilter({
            service: 'customerService',
            api: 'getSetAlert',
            id: "",
            task: 'Set Alert',
            updated_fields: [
              'freq_type',
              'freq_date',
              'freq_time',
              'alert_type',
              'message_type',
              'active',
              'customer',
              'created_by',
            ],
            sensitive_fields: [],
            new_values: { ...requestData },
            old_values: requestData,
            identification_key: 'Set Alert',
            identification_value: '',
            emp_id: user?.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isCFOAccess',
            product: 'sbl',
          });
      
          console.log("Approval response:", approvalResponse);
      
          swal({
            title: "Success",
            text: "Request Sent to concerned Authority",
            icon: "success",
            button: "Okay",
          }).then(() => {
            // Update UI state instead of reloading
            console.log("Page update logic can go here.");
          });
        } catch (err) {
          console.error("An error occurred:", err);
          window.alert("Error: " + err.message);
        }
      };
    
    const searchTarget = async (values, action) => {

        
        let val = selectedFile?.name?.split('.').pop().toLowerCase();
        if ( val === 'xlsx') {
            swal({
                title: "Error",
                text: "Please upload a CSV file. Your file format is currently XLSX. Kindly correct the format",
                icon: "error",
                button: "Okay"
            })
            return
        }
        let link;
        if ((action === "bulk_create" && val === 'csv')|| (action === "edit" && val === 'csv')) {
            console.log("link hai kya")
    
            try {
                link = await uploadFileToBucket(
                  bucket,
                  'application/csv',
                  `${bucket}/bulkuploads/${random_uid}.csv`,
                  selectedFile
                );
                console.log("File uploaded successfully:", link);
            } catch (uploadError) {
                console.error("File upload failed:", uploadError);
                throw new Error("Failed to upload file. Please try again.");
              }
        }
    console.log(link,"s3Links3Link")
        // Prepare the requestData object
        const requestData = {
            ...((action === "edit" && val=== 'csv') && { _id: values._id, active: values.active, s3_url: link }),
            ...(action === "edit"  && { _id: values._id, active: values.active, s3_url: values.s3_url }),
            ...(action === "bulk_create" && { s3_url: link }), // Pass S3 link for bulk_create
            action,
            freq_type: values.frequency,
            freq_date: values.frequency === "once" ? values.freq_date : parseInt(values.freq_date),
            freq_time: parseInt(values.freq_time),
            alert_type: values.alert_type,
            message_type: values.message_type,
            customer: values.customer_type,
            created_by: user.emp_id, // Ensure user and user.emp_id are defined
        };
    
        console.log(requestData, "requestData");
    
        // Call the ApprovalRequestFilter API
        ApprovalRequestFilter.RequestFilter({
            service: 'customerService',
            api: 'getSetAlert',
            id: "",
            task: 'Set Alert',
            updated_fields: ['freq_type', 'freq_date', 'freq_time', 'alert_type', 'message_type', 'active', 'customer','s3_url', 'created_by'],
            sensitive_fields: [],
            new_values: { ...requestData },
            old_values: requestData, // Changed to match the new_values structure
            identification_key: 'Set Alert',
            identification_value: '',
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isCFOAccess',
            product: 'sbl',
        })
        .then((x) => {
            console.log("Response from RequestFilter:", x);
            swal({
                title: "Success",
                text: "Request Sent to concerned Authority",
                icon: "success",
                button: "Okay"
            }).then(() => {
                window.location.reload();
            });
        })
        .catch((err) => {
            window.alert(err);
            console.error(err);
        });
    };
    
    // const searchTarget = async (values, action) => {
    //     try {
    //         // Handle file upload for bulk_create action and get the S3 link
    //         let s3Link = null;
    //         if (action === "bulk_create") {
    //             if (!selectedFile) {
    //                 throw new Error("No file selected for bulk upload.");
    //             }
    
    //             const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    //             if (fileExtension !== "csv") {
    //                 throw new Error("Only CSV files are supported for bulk upload.");
    //             }
    
    //             try {
    //                 s3Link = await uploadFileToBucket(
    //                     bucket,
    //                     "application/csv",
    //                     `${bucket}/bulkuploads/${random_uid}.csv`,
    //                     selectedFile
    //                 );
    //                 console.log("File uploaded successfully:", s3Link);
    //             } catch (error) {
    //                 console.error("File upload failed:", error);
    //                 throw new Error("Failed to upload file. Please try again.");
    //             }
    //         }
    
    //         // Prepare request data
    //         const requestData = {
    //             action, // Common field for all actions
    //             freq_type: values.frequency,
    //             freq_date: values.frequency === "once" ? values.freq_date : parseInt(values.freq_date),
    //             freq_time: parseInt(values.freq_time),
    //             alert_type: values.alert_type,
    //             message_type: values.message_type,
    //             customer: values.customer_type,
    //             created_by: 21125, // Ensure `user` is defined
    //             ...(action === "edit" && { _id: values._id, active: values.active }),
    //             ...(action === "bulk_create" && { s3_url: s3Link }), // Pass S3 link for bulk_create
    //         };
    
    //         console.log("Request Data:", requestData);
    
    //         // Call the appropriate API
    //         ApprovalRequestFilter.RequestFilter({
    //                       service: 'customerService',
    //                       api: 'getSetAlert',
    //                       id: "",
    //                       task: 'Set Alert',
    //                       updated_fields: ['freq_type', 'freq_date', 'freq_time', 'alert_type', 'message_type',"active", 'customer', 'created_by'],
    //                       sensitive_fields: [],
    //                       new_values: { ...requestData },
    //                       old_values: requestData , // Changed to match the new_values structure
    //                       identification_key: 'Set Alert',
    //                       identification_value: '',
    //                       emp_id: user.emp_id,
    //                       approval_needed: true,
    //                       approved: false,
    //                       approval_access: 'isCFOAccess',
    //                       product: 'sbl'
    //                     })
    
    //         console.log("Approval Response:", approvalResponse);
    
    //         // Show success message
    //         swal({
    //             title: "Success",
    //             text: "Request Sent to concerned Authority",
    //             icon: "success",
    //             button: "Okay",
    //         }).then(() => {
    //             console.log("Page update logic can go here.");
    //         });
    //     } catch (err) {
    //         console.error("An error occurred:", err);
    //         window.alert(`Error: ${err.message}`);
    //     }
    // };
    
    const handleAlertTypeChange = (setFieldValue, value) => {
        setFieldValue('alert_type', value);

        let options = [];
        switch (value) {
            case 'sms':
                options = [
                    // { value: 'sbl_emi_reminder', label: 'EMI Reminder For All Due Loans' },
                    { value: 'CurrentEMIPaymentReminder', label: 'Current EMI Payment Reminder' },
                    { value: '0DPD ENACH PASS', label: '0-DPD Enach Success' },
                    { value: '0 DPD eNACH Failed', label: '0-DPD Enach Failure' },
                    { value: '1-30DPD', label: '1-30 DPD Enach Dues' },
                ];
                break;
            case 'ivr':
                options = [
                    { value: 'emi_reminder_5th_month', label: 'EMI Reminder For All Due Loans' },
                    { value: 'emi_failure_reminder', label: 'EMI Failure Reminder' },
                    { value: '0_dpd_enach_successful', label: '0-DPD Enach Success' },
                    { value: '0_dpd_enach_failed', label: '0-DPD Enach Failure' },
                    { value: '1_30_dpd_enach', label: '1-30 DPD Enach Dues' },
                ];
                break;
            case 'whatsapp':
                options = [
                    { value: 'emi_reminder', label: 'EMI Reminder For All Due Loans' },
                ];
                break;
            default:
                options = [];
                break;
        }
        setMessageTypeOptions(options);
        setFieldValue('message_type', '');
    };

    async function searchSetAlert() {
        try {
            const res = await customerService.searchSetAlert();
            let parsedResponses = typeof res === 'string' ? JSON.parse(res) : res;
            if (Array.isArray(parsedResponses)) {
                setAlerts(parsedResponses.reverse());
            } else {
                console.error("Response is not an array:", parsedResponses);
            }
        } catch (err) {
            console.log('Error fetching alerts', err);
        } finally {
            setLoading(false);
        }
    }
    const openUrl = (url) => {
        window.open(url, '_blank');
      };
    useEffect(() => {
        searchSetAlert();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditClick = (alert) => {
        setSelectedAlert(alert);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedAlert(null);
    };

    const displayedAlerts = alerts.slice(
        page * rowsPerPage,
        Math.min((page + 1) * rowsPerPage, alerts.length)
    );

    const handleOpenMainModal = () => {
        setShowMainModal(true)
    }
    const handleCloseMainModal = () => {
        setShowMainModal(false);
    };
    const [selectedFrequency, setSelectedFrequency] = useState('');

    const handleFrequencyChange = (setFieldValue, value) => {
        setSelectedFrequency(value);
        setFieldValue('frequency', value);
    };
    console.log(selectedFrequency, "selectedFrequencyselectedFrequencyselectedFrequency")
    const [conflictMessage, setConflictMessage] = useState('');
    const handleFreqTimeChange = (setFieldValue, freqTime) => {
        setFieldValue("freq_time", freqTime);

        // Check for conflicts with existing alerts
        const conflictingAlert = alerts.find(alert =>
            alert.freq_time === parseInt(freqTime) && alert.active
        );

        if (conflictingAlert) {
            setConflictMessage('Please choose a different time range; As an alerts is already active on this time.');
        } else {
            setConflictMessage('');
        }
    };
    return (
        <div >
            <Button variant="outlined" aria-label="increase" onClick={() => handleOpenMainModal()} >
                <Add fontSize="small" /> Create Alert
            </Button>
            {loading ? (
                <div className="text-center" style={{ marginTop: "2rem" }}>
                    <CircularProgress />
                </div>
            ) : (
                <Paper style={{ marginTop: "2rem" }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>S NO.</TableCell>
                                    <TableCell>Frequency</TableCell>
                                    <TableCell>Alert Type</TableCell>
                                    <TableCell>Message Type</TableCell>
                                    <TableCell>Customer Type</TableCell>
                                    <TableCell>Frequency Date</TableCell>
                                    <TableCell>Frequency Time</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayedAlerts.map((alert, test) => (
                                    <TableRow key={alert.id}>
                                        <TableCell>{test + 1}</TableCell>
                                        <TableCell>{alert.freq_type}</TableCell>
                                        <TableCell>{alert.alert_type}</TableCell>
                                        <TableCell>{alert.message_type}</TableCell>
                                        <TableCell>{alert.customer}</TableCell>
                                        <TableCell>{alert.freq_date}</TableCell>
                                        <TableCell>{alert.freq_time}</TableCell>
                                        <TableCell>{alert.active ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>
                                            <Edit style={{ color: "blue" }} onClick={() => handleEditClick(alert)} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={alerts.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}
            {/* Create Alert Modal  */}

            <Modal show={showMainModal} onHide={handleCloseMainModal} style={{ height: "100%", marginTop: "3rem" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Formik
                        initialValues={{
                            frequency: '',
                            alert_type: '',
                            message_type: '',
                            customer_type: '',
                            created_by: "",
                            freq_date: "",
                            freq_time: ""
                        }}

                        onSubmit={(values) => {
                            const action = showFileInput && selectedFile ? "bulk_create" : "create";
                            searchTarget(values, action);
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <>

                                <Form>
                                    {conflictMessage && <div className="alert alert-danger">{conflictMessage}</div>}
                                    {selectedFrequency === "weekly" && <div className="alert alert-success">Note:- Select Date assuming the weeks are grouped by date as follows: 1-7, 8-14, 15-21, 22-28, and 28+. Ex- If selected date is 6, the alert will be sent on 6, 13, 20 and 27th date every month.</div>}

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>Frequency</label>
                                            <Field
                                                name='frequency'
                                                style={{ height: "2.5rem" }}
                                                as='select'
                                                validate={validateFrequency}
                                                onChange={e => handleFrequencyChange(setFieldValue, e.target.value)}
                                                className={`form-control ${errors.frequency && touched.frequency ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Choose Frequency</option>
                                                <option value='once'>Once</option>
                                                <option value='daily'>Daily</option>
                                                <option value='weekly'>Weekly</option>
                                                <option value='monthly'>Monthly</option>
                                            </Field>
                                            <ErrorMessage name="frequency" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Alert Type</label>
                                            <Field
                                                name='alert_type'
                                                as='select'
                                                style={{ height: "2.5rem" }}
                                                validate={validateAlertType}
                                                className={`form-control ${errors.alert_type && touched.alert_type ? "is-invalid" : ""}`}
                                                onChange={e => handleAlertTypeChange(setFieldValue, e.target.value)}
                                            >
                                                <option value=''>Choose Alert Type</option>
                                                <option value='sms'>SMS</option>
                                                <option value='ivr'>IVR</option>
                                                <option value='whatsapp'>Whatsapp</option>
                                            </Field>
                                            <ErrorMessage name="alert_type" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Message Type</label>
                                            <Field
                                                name='message_type'
                                                style={{ height: "2.5rem" }}
                                                validate={validateMessageType}
                                                as='select'
                                                className={`form-control ${errors.message_type && touched.message_type ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Choose Message Type</option>
                                                {messageTypeOptions.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="message_type" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Customer Type</label>
                                            <Field
                                                name='customer_type'
                                                style={{ height: "2.5rem" }}
                                                as='select'
                                                validate={validateCustomerType}
                                                className={`form-control ${errors.customer_type && touched.customer_type ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Choose Customer Type</option>
                                                <option value='applicant'>Applicant</option>
                                                <option value='co_applicants'>Co-applicant</option>
                                                <option value='guarantors'>Guarantor</option>
                                            </Field>
                                            <ErrorMessage name="customer_type" component="div" className="invalid-feedback" />
                                        </div>

                                        {selectedFrequency === "once" ?
                                            <div className="form-group col-md-6">
                                                <label>Select Date</label>
                                                <Field
                                                    name='freq_date'
                                                    type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    validate={validateFreqDate}
                                                    style={{ height: "2.5rem" }}
                                                    className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
                                                />
                                                <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
                                            </div> : selectedFrequency === "daily" ? "" : selectedFrequency === "weekly" ? <div className="form-group col-md-6">
                                                <label>Select Date</label>
                                                <Field
                                                    name='freq_date'
                                                    as='select'
                                                    validate={validateFreqDate}
                                                    style={{ height: "2.5rem" }}
                                                    className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
                                                >
                                                    <option value="">Select Date</option>
                                                    {[...Array(7).keys()].map(num => (
                                                        <option key={num} value={num + 1}>
                                                            {num + 1}
                                                        </option>
                                                    ))}

                                                </Field>
                                                <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
                                            </div> :
                                                <div className="form-group col-md-6">
                                                    <label>Select Date</label>
                                                    <Field
                                                        name='freq_date'
                                                        as='select'
                                                        validate={validateFreqDate}
                                                        style={{ height: "2.5rem" }}
                                                        className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
                                                    >
                                                        <option value="">Select Date</option>
                                                        {[...Array(30).keys()].map(num => (
                                                            <option key={num} value={num + 1}>
                                                                {num + 1}
                                                            </option>
                                                        ))}

                                                    </Field>
                                                    <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
                                                </div>}

                                        <div className="form-group col-md-6">
                                            <label>Select Time</label>
                                            <Field
                                                name='freq_time'
                                                as='select'
                                                validate={validateFreqTime}
                                                style={{ height: "2.5rem" }}
                                                onChange={e => handleFreqTimeChange(setFieldValue, e.target.value)}
                                                className={`form-control ${errors.freq_time && touched.freq_time ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Choose Time</option>
                                                {[...Array(23).keys()].map(num => (
                                                    <option key={num} value={num + 1}>
                                                        {num + 1}
                                                    </option>
                                                ))}
                                            </Field>

                                            <ErrorMessage name="freq_time" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={showFileInput}
                                                    onChange={handleCheckboxChange}
                                                />
                                                Enable Bulk Upload
                                            </label>
                                        </div>
                                        <div className="form-group col-md-6">
                                        {showFileInput && (
                                            <>
                                                <div style={{ marginTop: "10px" }}>
                                                <label>Bulk Upload</label>
                                                  <input name="s3_url" type="file" accept=".csv"  className="form-control" style={{width:'80%',padding:'4px'}} onChange={handleFileChange} />
                                                </div>
                                                  <a href="https://sbl-credit.s3.ap-south-1.amazonaws.com/sbl-credit/rpsbulkupload/22717a69-221c-464b-badf-3e9c88c39b4d.csv" download style={{color: "red" }} >Download Sample File </a>
                                                  </> )}
                                           
                                            </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3 ">
                                        <button type="submit" className="btn btn-primary w-50">Set Alert</button>
                                    </div>

                                </Form>
                               
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            {/* Edit Alert Modal  */}

            <Modal show={showModal} onHide={handleCloseModal} style={{ height: "100%", marginTop: "3rem" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {selectedAlert && (
                        <Formik
                            initialValues={{
                                frequency: selectedAlert.freq_type,
                                alert_type: selectedAlert.alert_type,
                                message_type: selectedAlert.message_type,
                                customer_type: selectedAlert.customer,
                                freq_date: selectedAlert.freq_date,
                                freq_time: selectedAlert.freq_time,
                                created_by: user.emp_id,
                                _id: selectedAlert._id,
                                s3_url:selectedAlert.s3_url,
                                // active: selectedAlert.active
                                active: selectedAlert ? selectedAlert.active : false,
                            }}
                            onSubmit={(values) => {
                                searchTarget(values, "edit");
                                handleCloseModal();
                            }}
                        >
                            {({ errors, touched, setFieldValue }) => (
                                <Form>
                                    {conflictMessage && <div className="alert alert-danger">{conflictMessage}</div>}
                                    {(selectedAlert.freq_type === "weekly" || selectedFrequency === "weekly") && <div className="alert alert-success">Note:- Select Date assuming the weeks are grouped by date as follows: 1-7, 8-14, 15-21, 22-28, and 28+. Ex- If selected date is 6, the alert will be sent on 6, 13, 20 and 27th date every month.</div>}

                                    <div className="row">

                                        <div className="form-group col-md-6">
                                            <label>Frequency</label>
                                            <Field
                                                style={{ height: "2.5rem" }}
                                                name="frequency"
                                                as="select"
                                                onChange={e => handleFrequencyChange(setFieldValue, e.target.value)}
                                                className={`form-control ${errors.frequency && touched.frequency ? "is-invalid" : ""}`}
                                            >
                                                <option value="">Choose Frequency</option>
                                                <option value="once">Once</option>
                                                <option value="daily">Daily</option>
                                                <option value="weekly">Weekly</option>
                                                <option value="monthly">Monthly</option>
                                            </Field>
                                            <ErrorMessage name="frequency" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Alert Type</label>
                                            <Field
                                                name="alert_type"
                                                as="select"
                                                style={{ height: "2.5rem" }}
                                                className={`form-control ${errors.alert_type && touched.alert_type ? "is-invalid" : ""}`}
                                                onChange={(e) => handleAlertTypeChange(setFieldValue, e.target.value)}
                                            >
                                                <option value="">Choose Alert Type</option>
                                                <option value="sms">SMS</option>
                                                <option value="ivr">IVR</option>
                                                <option value="whatsapp">Whatsapp</option>
                                            </Field>
                                            <ErrorMessage name="alert_type" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Message Type</label>
                                            <Field
                                                name="message_type"
                                                style={{ height: "2.5rem" }}
                                                as="select"
                                                className={`form-control ${errors.message_type && touched.message_type ? "is-invalid" : ""}`}
                                            >
                                                <option value="">Choose Message Type</option>
                                                {messageTypeOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="message_type" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Customer Type</label>
                                            <Field
                                                name="customer_type"
                                                style={{ height: "2.5rem" }}
                                                as="select"
                                                className={`form-control ${errors.customer_type && touched.customer_type ? "is-invalid" : ""}`}
                                            >
                                                <option value="">Choose Customer Type</option>
                                                <option value="applicant">Applicant</option>
                                                <option value="co_applicants">Co-applicant</option>
                                                <option value="guarantors">Guarantor</option>
                                            </Field>
                                            <ErrorMessage name="customer_type" component="div" className="invalid-feedback" />
                                        </div>

                                        {selectedAlert.freq_type === "once" || selectedFrequency === "once" ?
                                            <div className="form-group col-md-6">
                                                <label>Select Date</label>
                                                <Field
                                                    name="freq_date"
                                                    type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    style={{ height: "2.5rem" }}
                                                    className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
                                                />
                                                <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
                                            </div> : selectedAlert.freq_type === "daily" || selectedFrequency === "daily" ? "" :
                                                <div className="form-group col-md-6">
                                                    <label>Select Date</label>
                                                    <Field
                                                        name="freq_date"
                                                        as='select'
                                                        style={{ height: "2.5rem" }}
                                                        className={`form-control ${errors.freq_date && touched.freq_date ? "is-invalid" : ""}`}
                                                    >
                                                        <option value=''>Choose Date</option>
                                                        {[...Array(30).keys()].map(num => (
                                                            <option key={num} value={num + 1}>
                                                                {num + 1}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="freq_date" component="div" className="invalid-feedback" />
                                                </div>}

                                        <div className="form-group col-md-6">
                                            <label>Select Time</label>
                                            <Field
                                                name="freq_time"
                                                as='select'
                                                style={{ height: "2.5rem" }}
                                                onChange={e => handleFreqTimeChange(setFieldValue, e.target.value)}
                                                className={`form-control ${errors.freq_time && touched.freq_time ? "is-invalid" : ""}`}
                                            >
                                                <option value=''>Choose Time</option>
                                                {[...Array(23).keys()].map(num => (
                                                    <option key={num} value={num + 1}>
                                                        {num + 1}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="freq_time" component="div" className="invalid-feedback" />
                                        </div>
                                        
                                        {selectedAlert.s3_url && (
                                <div className="form-group col-md-6">
                                    <label>S3 File URL</label>
                                    <p><a href={selectedAlert.s3_url} target="_blank" rel="noopener noreferrer">{selectedAlert.s3_url}</a></p>
                                </div>
                            )} {!selectedAlert.s3_url && (
                                <div className="form-group col-md-6">
                                    <label>Bulk Upload</label>
                                    <div style={{ marginTop: "10px" }}>
                                        <input
                                            name="s3_url"
                                            type="file"
                                            accept=".csv"
                                            className="form-control"
                                            style={{ width: '80%', padding: '4px' }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                            )}
                                        <div className="form-group col-md-12">
                                            <label>Active</label>
                                            <Field name="active">
                                                {({ field }) => (
                                                    <Switch
                                                     name="active"
                                                        checked={field.value === true}
                                                        onChange={(event) => setFieldValue('active', event.target.checked ? true : false)}
                                                        color="primary"
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="active" component="div" className="invalid-feedback" />
                                        </div>

                                    </div>
                                    <button type="submit" className="btn btn-primary w-50">
                                        Update Alert
                                    </button>
                                    {/* <DebugFormik /> */}
                                </Form>
                            )}
                        </Formik>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SetAlert;
