import React, { useState, useEffect } from 'react';
import {
  Modal,   Button, TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, IconButton, List, ListItem, ListItemText,Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { adminservice } from "../../../_services/admin.services";
import { uploadFileToBucket } from '../../../_helpers/upload';
import  {branchExpenseService} from "../../../_services/branchExpense.service"
import { employeeService } from "../../../_services/employee.service";
import {notifyInfo,notifyFailed}  from "../../../_helpers/notify"
import { ToastContainer } from "react-toastify";
import { Add, Edit } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 800,
    maxHeight:700,
    overflow:'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fileList: {
   marginTop: theme.spacing(2),
    maxHeight:100, // Adjust the height as needed
    overflowY: 'auto', // Makes the list scrollable
  },
}));

const BranchOwnersList = (expense_id) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [updateExpense,setUpdateExpense]=useState()
  console.log('branch petty cash is',expense_id)
  const expenseId = expense_id.id
  const [formData, setFormData] = useState({
    category: '',
    branch_id:'',
    status:'Refer To Accounts',
    subCategory: '',
    startDate: '',
    endDate: '',
    owner_reference: '',
    amount: '',
    invoiceDate: '',
    invoices: [],
    prevUnit: '',
    currentUnit: '',
     isSubmittering: false,
    comment: '',
  });
  const [branchOwners, setBranchOwners] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [spinner,setSpinner] = useState(false)
  const bucket = process.env.REACT_APP_s3docsBucket;
  const user = employeeService.userValue;

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFormData  = () => {
    setFormData({
      category: '',
      branch_id:'',
      status:'Refer To Accounts',
      subCategory: '',
      startDate: '',
      endDate: '',
      owner_reference: '',
      amount: '',
      invoiceDate: '',
      invoices: [],
      prevUnit: '',
      currentUnit: '',
       isSubmittering: false,
      comment: '',
    });
   
  };

  const handleClose =()=>{
    resetFormData();
    setOpen(false);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('name',name,value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      category: value,
    }));
    resetFormData(); // Reset form data when category changes
    setFormData((prevData) => ({
      ...prevData,
      category: value,
    }));
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async () => {
    const errors = {};
    if(!formData.category ){
      errors.category='Category is required'
    }
    if(!formData.branch_id ){
      errors.branch_id='branch id  is required'
    }
    if(expenseId && !formData.comment ){
  errors.comment='Add a Comment'
    }
    //  Validate Rent category
    if (formData.category === 'Rent') {
        if (!formData.startDate) errors.startDate = 'Start Date is required';
        if (!formData.endDate) errors.endDate = 'End Date is required';
        if (!formData.owner_reference) errors.owner_reference = 'Owner Reference is required';
    }

    // Validate Petty Cash category
    if (formData.category === 'Petty Cash') {
        if (!formData.subCategory) errors.subCategory = 'SubCategory is required';
        if (!formData.amount) errors.amount = 'Amount is required';
        if (!formData.invoiceDate) errors.invoiceDate = 'Invoice Date is required';
        if (formData.invoices.length === 0) errors.invoices = 'At least one invoice must be uploaded';
    }

    // Validate Electricity Bills category
    if (formData.category === 'Electricity Bills') {
        if (!formData.startDate) errors.startDate = 'Start Date is required';
        if (!formData.endDate) errors.endDate = 'End Date is required';
        if (!formData.prevUnit) errors.prevUnit = 'Previous Unit is required';
        if (!formData.currentUnit) errors.currentUnit = 'Current Unit is required';
        if (!formData.owner_reference) errors.owner_reference = 'Owner Reference is required';
    }

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
        // Handle errors (e.g., show error messages)
        console.log('Validation Errors:', Object.values(errors));
        notifyFailed(`Please fill in all required fields : ${Object.values(errors)}`);
        return;
    }

    setSpinner(true);

    const payload = {
        category: formData.category,
        comment: formData.comment,
        branch_id: formData.branch_id,
        ...(formData.category === 'Rent' && {
            startDate: formData.startDate,
            endDate: formData.endDate,
            owner_reference: formData.owner_reference,
            amount: formData.amount,
            status: formData.status,
        }),
        ...(formData.category === 'Petty Cash' && {
            subCategory: formData.subCategory,
            amount: formData.amount,
            invoiceDate: formData.invoiceDate,
            invoices: formData.invoices,
            status: formData.status,
        }),
        ...(formData.category === 'Electricity Bills' && {
            startDate: formData.startDate,
            endDate: formData.endDate,
            prevUnit: formData.prevUnit,
            currentUnit: formData.currentUnit,
            isSubmittering: formData.isSubmittering,
            owner_reference: formData.owner_reference,
            amount: formData.amount,
            status: formData.status,
        }),
    };
    
    
    console.log(payload);
if(expenseId){
  payload.comments = updateExpense.comments
  branchExpenseService.update({expenseId,payload})
  .then((res) => {
      if (res && res.status === "success") {
          console.log('Branch expense updated successfully', res);
          notifyInfo(`${formData.category} of ${formData.branch_id} has been updated successfully`);
       } else if (res && res.status === "failed") {
          console.log('Failed to update branch expense', res.message);
          notifyFailed(`Failed: ${res.message}`);
          setSpinner(false);
      }
  })
  .catch((err) => {
      console.log(err);
      notifyFailed('An error occurred while updating the branch expense');
      setSpinner(false);
  });
}else{
  
  branchExpenseService.create(payload)
      .then((res) => {
          if (res && res.status === "success") {
              console.log('Branch expense created successfully', res);
              notifyInfo(`${formData.category} of ${formData.branch_id} has been added successfully`);
           } else if (res && res.status === "failed") {
              console.log('Failed to create branch expense', res.message);
              notifyFailed(`Failed: ${res.message}`);
              setSpinner(false);
          }
      })
      .catch((err) => {
          console.log(err);
          notifyFailed('An error occurred while creating the branch expense');
          setSpinner(false);
      });
}
};


  const handleInvoices = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedFiles = [];

    for (const file of files) {
      let name = file.name.split('.')[0];
      let num1 = Math.floor(Math.random() * 10000);
      let num2 = Math.floor(Math.random() * 10000);
      let fileName = `${name}_${num1}${num2}.pdf`;
      try {
        const link = await uploadFileToBucket(bucket, 'application/pdf', `expense/invoices/${fileName}`, file);
        uploadedFiles.push({ url: link, name: fileName });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      invoices: [...prevData.invoices, ...uploadedFiles],
    }));
  };


  const handleDeleteFile = (fileName) => {
    setFormData((prevData) => ({
      ...prevData,
      invoices: prevData.invoices.filter((file) => file.name !== fileName),
    }));
  };

  useEffect(() => {
    adminservice.getBranchOwnerByBranchId()
      .then(response => {
        console.log('Response of branch owner:', response);
        setBranchOwners(response);
      })
      .catch(error => console.error('Error fetching branch owners:', error));

    adminservice.getPairByKey({ key_type: 'Petty_Cash' })
      .then(res => {
        console.log('Response of petty cash:', res);
        setSubCategories(res);
      })
      .catch(err => console.error('Error fetching petty cash:', err));
  }, []);

  useEffect(() => {
    if (expenseId && open) {
      branchExpenseService
        .getById({ id: expenseId })
        .then((res) => {
          console.log('edit branch expense data', res);
          setUpdateExpense(res);
          // Format the dates correctly
          const formattedData = {
            ...res,
            startDate: res.startDate ? new Date(res.startDate).toISOString().split('T')[0] : '',
            endDate: res.endDate ? new Date(res.endDate).toISOString().split('T')[0] : '',
            invoiceDate: res.invoiceDate ? new Date(res.invoiceDate).toISOString().split('T')[0] : '',
          };
  
          setFormData(formattedData);
        })
        .catch((err) => console.log('err of edit expense', err));
    }
  }, [expenseId, open]);
  
  
console.log('user is',user)


  return (
    <div>
    {expenseId  ? <div style={{marginBottom:'1rem'}} >
     
      <Button variant="outlined" color="primary" onClick={handleOpen}>
    <Edit/>
      </Button>
      </div> :<div style={{marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    gap: '1rem',
}} >
    <label style={{fontSize: 'xx-large'}}>Add Branch Expense</label>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
    <Add/>
      </Button>
      </div>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2 id="simple-modal-title">Add Expense</h2>
          <ToastContainer/>
          <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel className='required'>Category</InputLabel>
            <Select
              name="category"
              value={formData.category}
              onChange={handleCategoryChange}
              disabled={expenseId ? true:false}
            >
              <MenuItem value="Rent">Rent</MenuItem>
              <MenuItem value="Petty Cash">Petty Cash</MenuItem>
              <MenuItem value="Electricity Bills">Electricity Bills</MenuItem>
            </Select>
          </FormControl>
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel className='required'>Branch Name</InputLabel>
            <Select
              name="branch_id"
              value={formData.branch_id}
              onChange={handleChange}
            >
             {user && user.operation_branch && user.operation_branch.map((val,ind)=>{
              return <MenuItem value={val}>{val}</MenuItem>
             })  }
               
            </Select>
          </FormControl>
          {formData.category === 'Rent' && (
            <>
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="startDate"
                label="Start Date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                value={formData.startDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="endDate"
                label="End Date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                value={formData.endDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="amount"
                label="Amount"
                type="number"
                value={formData.amount}
                onChange={handleChange}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Owner Reference</InputLabel>
                <Select
                  name="owner_reference"
                  value={formData.owner_reference}
                  onChange={handleChange}
                >
                  {branchOwners.map((owner) => (
                    <MenuItem key={owner._id} value={owner._id}>
                      {owner.name} - {owner.account_no} - {owner.ifsc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {formData.category === 'Petty Cash' && (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel className='required'>SubCategory</InputLabel>
                <Select
                  name="subCategory"
                  value={formData.subCategory}
                  onChange={handleChange}
                >
                  {subCategories.map((subCat) => (
                    <MenuItem key={subCat} value={subCat}>
                      {subCat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="amount"
                label="Amount"
                type="number"
                value={formData.amount}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                margin="normal"
                className='required'
                name="invoiceDate"
                label="Invoice Date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                value={formData.invoiceDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
              <input
              className='required'
                accept=".pdf"
                multiple
                type="file"
                onChange={handleInvoices}
              />
              <List className={classes.fileList}>
                {formData.invoices.map((file) => (
                  <ListItem key={file.name}>
                    <ListItemText primary={
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.name}
                        </a>
                      } />
                    <IconButton onClick={() => handleDeleteFile(file.name)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {formData.category === 'Electricity Bills' && (
            <>
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="startDate"
                label="Start Date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                value={formData.startDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="endDate"
                label="End Date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                value={formData.endDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="prevUnit"
                label="Previous Unit"
                type="number"
                value={formData.prevUnit}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="currentUnit"
                label="Current Unit"
                type="number"
                value={formData.currentUnit}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                className='required'
                margin="normal"
                name="amount"
                label="Amount"
                type="number"
                value={formData.amount}
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isSubmittering"
                    checked={formData.isSubmittering}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Submitting"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel className='required'>Owner Reference</InputLabel>
                {
                  formData.isSubmittering ? 
                <Select
                  name="owner_reference"
                  value={formData.owner_reference}
                  onChange={handleChange}
                >
                  {branchOwners.map((owner) => (
                    <MenuItem key={owner._id} value={owner._id}>
                      {owner.name} - {owner.account_no} - {owner.ifsc}
                    </MenuItem>
                  ))}
                </Select> :  <Select
                  name="owner_reference"
                  value={formData.owner_reference}
                  onChange={handleChange}
                >
                  {branchOwners.map((owner) => (
                    <MenuItem key={owner._id} value={owner._id}>
                      {owner.kNo} - {owner.Electricity_Distribution_Company} 
                    </MenuItem>
                  ))}
                </Select>
                }
              </FormControl>
            </>
          )}

          <TextField
            fullWidth
            margin="normal"
            name="comment"
            label="Comment"
            value={formData.comment}
            onChange={handleChange}
          />
           <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={spinner}
          >
            Submit{spinner  && <span className="spinner-border spinner-border-sm"></span>}
          </Button>
        </div>
      </Modal>


    </div>
  );
};

export default BranchOwnersList;
