import React, { useState ,useEffect} from "react";
import { Paper } from "@material-ui/core";
import urlhelper from '../../../../../_helpers/urlhelper'
import datehelper from '../../../../../_helpers/datehelper';
import roleChecker from '../../../../../_helpers/rolechecker';
import PreviewModal from "./PreviewModal"
import { employeeService } from '../../../../../_services';

function UploadDocuments({ cust }) {

  const [allDocuments, setAllDocuments] = useState([]);
  const [employeeList, setEmployeeList] = useState({});

  const styles = {
    paper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      paddingTop: "30px",
      paddingBottom: "30px",
      marginBottom: "30px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tableHeader: {
      border: "1px solid",
      padding: "10px",
      backgroundColor: "#f4f4f4",
      textAlign: "left",
    },
    tableRow: {
      border: "1px solid",
      padding: "10px",
    },
    tableData: {
      border: "1px solid",
      padding: "10px",
    },
    image: {
      width: "250px",
      height: "250px",
    },
    heading: {
      marginBottom: "20px",
    },
  };
  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      setEmployeeList(list);
    });
  }
 
  const gatherDocuments = () => {
    const docs = [];




    // Collaterals
    if (cust?.documents_preview?.length > 0) {
      cust.documents_preview.forEach((doc) => {
        if (doc.url) {
          docs.push({
            uri: doc.url,
            category: doc.category,
            comment:doc.comment
          });
        }
      });
    }

    setAllDocuments(docs);
  };

 useEffect(() => {
    gatherDocuments(); // Gather documents once the component mounts
  }, [cust]);

  useEffect(()=>{
    getAllEmployees()
  },[])


  return (
    <div>

     

      {
        <div>
          <Paper style={styles.paper}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h4>Uploaded Documents</h4>
            <PreviewModal
        documents={allDocuments}
      />
      </div>
            {cust?.documents_preview?.length > 0 &&

              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.tableHeader}>Category</th>
                    <th style={styles.tableHeader}>Added By </th>
                    <th style={styles.tableHeader}>Document Link</th>
                    <th style={styles.tableHeader}>Comment</th>

                  </tr>
                </thead>
                <tbody>
                  {cust.documents_preview.map((val, idx) => (
                    <tr key={idx} style={styles.tableRow}>
                      <td style={styles.tableData}>{val.category}</td>
                      <td style={styles.tableData}>{employeeList[val.created_by] }</td>
                      <td style={styles.tableData}>
                        {/* <img src={img.uri} alt="KYC Document" style={styles.image} /> */}
                        {val.url && val.url!="" && <a href={`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(val.url) + "&download=1"} target='_blank'>View Document</a>}
                      </td>
                      <td style={styles.tableData}>{ val.comment}</td>

                    </tr>
                  ))}
                </tbody>
              </table>}
          </Paper>
        </div>


      }
    </div>
  );
}

export default UploadDocuments;
