import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import FormElement from "../../uielements/FormElement";
import { emailNachService } from "../../../_services/emailnach.service";
import { employeeService } from "../../../_services";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    overflow: "scroll",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));

export default function EmailNachCollectRequestModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [enachData,setEnachData]= React.useState({});
  const formikRef = React.useRef();
  const user = employeeService.userValue;
  const initialValues = {
    loan_number: "",
    umrn_number:"",
    collect_amount:"",
    collection_date:new Date().toISOString().substring(0, 10),
  };

  const disablePastDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); 
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

  const validationSchema = Yup.object().shape({
    loan_number: Yup.string().required("This field is required")
                  .min(1,"loan number can't be blank"),
    collect_amount: Yup.number().required("Amount required").min(1,"Minimum 1"),              
    collection_date: Yup.date().min(disablePastDate(),"Date equal or greater than today").required("collection date required")
  });

  React.useEffect(() => {
    emailNachService.getAllActive().then((x) => {
      console.log(x);
      let enachMap={}
      x.map((y)=> {
          enachMap[y.loan_number]=y;
      })
      setEnachData(enachMap);
    });
  }, []);
  
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  async function onSubmit(fields, { setSubmitting }) {
    console.log("on submit called");
    setSubmitting(true);
    let collect_amount=fields['collect_amount'];
    let emi_limit=enachData[fields['loan_number']]['emi_amount'];
    const inputValues={
        ...fields,
        collection_emp_id:user.emp_id,
        umrn_number:enachData[fields['loan_number']]['umrn_number']
    }
    console.log(inputValues);
    emailNachService.addCollectEmailnach(inputValues).then((x)=> {
          window.alert("Collection request added");
          handleClose();
          window.location.reload();
    })
    .catch((err)=> {
       window.alert("Error adding collect request");
    });
    setSubmitting(false);
  }

  async function onLoanNumChange(value,setFieldValue)
  {
    //
    setFieldValue('loan_number',value);
  }

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined" color="primary">
        Request ENach Collection
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">New Email NACH Collect Request</h2>
           
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                id="transition-modal-description"
              >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="form-row">
                        <div className="form-group col">
                          <label>Loan Number</label>
                          <Field
                            name="loan_number"
                            as="select"
                            onChange={(e)=> onLoanNumChange(e.target.value,setFieldValue)}
                            className={
                              "form-control" +
                              (errors.em && touched.em ? " is-invalid" : "")
                            }
                          >
                            <option value="" disabled></option>
                            {Object.keys(enachData).map((x)=> {
                              return (<option key={x} value={x}>{x}</option>)
                            })}
                          </Field>
                          <ErrorMessage
                            name="loan_number"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        </div>
                        <div className="form-row">
                        <div className="form-group col">
                          <FormElement
                            label="Amount"
                            name="collect_amount"
                            type="number"
                            errors={errors}
                            touched={touched}
                          >
                          </FormElement>
      
                        </div>
                        </div>

                        <div className="form-row">
                        <div className="form-group col">
                          <FormElement
                            label="Collection Date"
                            name="collection_date"
                            type="date"
                            onKeyDown={(e) => e.preventDefault()}
                            errors={errors}
                            touched={touched}
                          >
                          </FormElement>
                        </div>
                        </div>
                        <hr/>
                        <div className="row justify-content-around">
                        <Fab
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          aria-label="save"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          <SaveIcon />
                        </Fab>
                      </div>
                      </Form>
                  );
                 }}
              </Formik>
          
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
