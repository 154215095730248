import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { branchService } from '../_services/branch.service';
import { customerService, employeeService } from '../_services';

function AssignTarget() {
  const user = employeeService.userValue;
  const [loginTargets, setLoginTargets] = useState({});
  const [disbursementTargets, setDisbursementTargets] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [allBranch, setAllBranch] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  const date = new Date();
  const currentMonth = date.getMonth() + 1; // Months are zero-based, so we add 1
  const currentYear = date.getFullYear();
  const currentMonthYear = `${currentMonth}-${currentYear}`;

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const branchResponse = await branchService.getAll();
        setAllBranch(branchResponse);

        const targetDashboardResponse = await customerService.getTargetDashboard({ emp_id: user.emp_id });
        if (targetDashboardResponse.status === 'success') {
          console.log(targetDashboardResponse.target,"targetDashboardResponse.target")
          console.log(targetDashboardResponse,"targetDashboardResponse")
          setDashboardData(targetDashboardResponse.target);
          const initialTargets = Object.keys(targetDashboardResponse.target).reduce((acc, key) => {
            acc[key] = 0; // Default value of 0
            return acc;
          }, {});
          setLoginTargets(initialTargets);
          setDisbursementTargets(initialTargets);
        } else {
          swal('Error', 'API failed', 'error');
        }

        const searchTargetResponse = await customerService.searchTarget({ zone: user.branch_id });
        const parsedResponses = typeof searchTargetResponse === 'string' ? JSON.parse(searchTargetResponse) : searchTargetResponse;

        if (Array.isArray(parsedResponses)) {
          setApiData(parsedResponses);
          
          // Check the conditions for the submit button visibility
          const shouldHideSubmitButton = parsedResponses.some(item => 
            item.branch_key === `zone-${user.branch_id}` &&
            item.key === 'login_target' &&
            item.month_key === currentMonthYear &&
            item.approval_status === 'approved'
          );
          
          setShowSubmitButton(!shouldHideSubmitButton);

          // Set initial values from apiData
          const initialLoginTargets = {};
          const initialDisbursementTargets = {};
          parsedResponses.forEach((item) => {
            if (item.key === 'login_target' && item.month_key === currentMonthYear) {
              initialLoginTargets[item.branch_key.replace('branch-', '')] = item.monthly_target;
            }
            if (item.key === 'disbursement_target' && item.month_key === currentMonthYear) {
              initialDisbursementTargets[item.branch_key.replace('branch-', '')] = item.monthly_target;
            }
          });
          setLoginTargets(initialLoginTargets);
          setDisbursementTargets(initialDisbursementTargets);
        } else {
          console.error('Data fetched is not an array:', parsedResponses);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchInitialData();
  }, [user.emp_id, user.branch_id, currentMonthYear]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginTargets((prev) => ({ ...prev, [name]: value ? parseFloat(value) : 0 }));
  };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setDisbursementTargets((prev) => ({ ...prev, [name]: value ? parseFloat(value) : 0 }));
  };

  const preparePayload = (targets) => {
    return Object.keys(dashboardData).reduce((acc, key) => {
      acc[key] = targets[key] || 0;
      return acc;
    }, {});
  };

  const getTarget = async (e) => {
    e.preventDefault();
    const today = new Date();
  const dayOfMonth = today.getDate();
  if (dayOfMonth < 1 || dayOfMonth > 13) {
    swal('Error', 'ZSM will not be able to set targets. Targets can only be set from the 1st to the 3rd of the month.', 'error');
    return;
  }
    try {
      const loginData = await customerService.getTarget({
        zone: user.branch_id,
        target: preparePayload(loginTargets),
        purpose: 'login_target'
      });
      console.log(loginData, 'login target data');

      const disbursementData = await customerService.getTarget({
        zone: user.branch_id,
        target: preparePayload(disbursementTargets),
        purpose: 'disbursement_target'
      });
      swal(disbursementData.status, disbursementData.message, disbursementData.status)
        .then(() => {
          // getTargetDashboard();
        });
      console.log(disbursementData, 'disbursement target data');
    } catch (error) {
      console.error('Error getting targets:', error);
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Target Dashboard</h3>
      <form onSubmit={getTarget}>
        <table className="table-secondary">
          <thead>
            <tr>
              <th style={{ padding: '1rem' }}>S No.</th>
              <th style={{ padding: '1rem' }}>Branch</th>
              <th style={{ padding: '1rem' }}>Branch Id</th>
              <th style={{ padding: '1rem' }}>Region</th>
              <th style={{ padding: '1rem' }}>Area</th>
              <th style={{ padding: '1rem' }}>Total Login Target</th>
              <th style={{ padding: '1rem' }}>Total Disbursement Target (in Lakhs)</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(dashboardData).map((key, index) => {
              const branch = allBranch.find(branch => branch.branch_id === key);
              const defaultValue = loginTargets[key] || '';
              return (
                <tr key={key}>
                  <td>{index + 1}</td>
                  <td>{branch ? branch.branch_name : key}</td>
                  <td>{key}</td>
                  <td>{branch && branch.branch_region}</td>
                  <td>{branch && branch.branch_area}</td>
                  <td>
                    <input
                      style={{ width: '50%', height: '35px', color: 'rgb(41,73,185)', borderColor: 'rgb(41,73,185)' }}
                      type="number"
                      name={key}
                      placeholder={key}
                      value={defaultValue}
                      onChange={handleInputChange}
                      required
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: '50%', height: '35px', color: 'rgb(41,73,185)', borderColor: 'rgb(41,73,185)' }}
                      type="number"
                      name={key}
                      placeholder={key}
                      value={disbursementTargets[key] || ''}
                      onChange={handleInputChanges}
                      required
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {showSubmitButton && (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '1rem' }}>
            <button className="btn btn-primary" style={{ width: '35rem' }} type="submit">
              Submit
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default AssignTarget;
