import React, { useEffect, useState } from 'react'
import { Box, Button, Modal } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Edit, Save } from "@material-ui/icons";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { legalServices } from '../../../_services/legal_cases.services';

const AddHearingModal = (props) => {
    console.log("Test pros", props)
    let id = props.caseInfo._id
    console.log("test id", id)
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "1100px",
        height: "450px",
        bgcolor: "#F3F3F3",
        border: "2px solid #000",
        // overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const initialValues = {
        hearing_date: '',
        lawyer_name: '',
        judge_name: '',
        party_lawyer: '',
        case_manager_name: '',
        penal_codes: '',
        judgement: '',
    };
    const validationSchema = Yup.object({
        hearing_date: Yup.date().required('Required'),
        lawyer_name: Yup.string().required('Required'),
        judge_name: Yup.string().required('Required'),
        party_lawyer: Yup.string().required('Required'),
        case_manager_name: Yup.string().required('Required'),
        penal_codes: Yup.string().required('Required'),
        judgement: Yup.string().required('Required'),
    });
    const addHearing = async (values) => {
        await legalServices.addHearing(id, values)
            .then((res) => {
                alert("Hearing Added !!")
                window.location.reload()
            })
            .catch((err) => alert(err))
    }
    return (
        <div>
            <Button onClick={handleOpen} style={{ color: '#0C2294', height: '25px', background: '9990FF', float: 'right', textTransform: 'capitalize', }}><Add style={{ border: "2px solid #0C2294", marginBottom: '5px', height: "20px" }} />&nbsp; Add</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={addHearing}
                    >
                        {({ errors, touched, isSubmitting, values }) => {
                            return (
                                <Form>
                                    <h5 style={{ textAlign: 'center', marginBottom: "40px" }}>Add Hearing</h5>
                                    <div style={{ display: "flex", justifyContent: "space-around", }}>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Hearing Date</label>
                                            <Field
                                                type="date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                name="hearing_date"
                                                className={
                                                    "form-control" +
                                                    (errors.hearing_date && touched.hearing_date ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="hearing_date"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Lawyer Name</label>
                                            <Field
                                                name="lawyer_name"
                                                className={
                                                    "form-control" +
                                                    (errors.lawyer_name && touched.lawyer_name ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="lawyer_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Judge Name</label>
                                            <Field
                                                name="judge_name"
                                                className={
                                                    "form-control" +
                                                    (errors.judge_name && touched.judge_name ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="judge_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Party Lawyer</label>
                                            <Field
                                                name="party_lawyer"
                                                className={
                                                    "form-control" +
                                                    (errors.party_lawyer && touched.party_lawyer ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="party_lawyer"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-around", marginTop: "50px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Case Manager</label>
                                            <Field
                                                name="case_manager_name"
                                                className={
                                                    "form-control" +
                                                    (errors.case_manager_name && touched.case_manager_name ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="case_manager_name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Penal Code</label>
                                            <Field
                                                name="penal_codes"
                                                className={
                                                    "form-control" +
                                                    (errors.penal_codes && touched.penal_codes ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="penal_codes"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <label>Judgement</label>
                                            <Field
                                                name="judgement"
                                                className={
                                                    "form-control" +
                                                    (errors.judgement && touched.judgement ? " is-invalid" : "")
                                                }
                                                style={{ width: "200px", height: "40px", border: '2px solid #3760F1', borderRadius: "4px" }}
                                            />
                                            <ErrorMessage
                                                name="judgement"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'blue', margin: "0 auto", color: 'white', borderRadius: '4px'
                                        }}><Save />Save</button>
                                </Form>)
                        }}
                    </Formik>
                </Box>
            </Modal>
        </div>
    )
}
export default AddHearingModal
