import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/ms/payments/enach`;

export const enachService = {
  addEnach,
  getAll,
  resend,
  getPaymentCollections,
  getNachRequestSent,
  getNachInProcess,
  uploadEnachCollectionCsv,
  addCollectEnach,
};

function addEnach(params) {
  return fetchWrapper.post(baseUrl, params);
}

function getAll(){
  return fetchWrapper.get(baseUrl);
}
function resend(refCode){
  let params=
  {'reference_code': refCode};
  return fetchWrapper.post(`${baseUrl}/resend`, params);
}

function getPaymentCollections() {
  return fetchWrapper.post(`${baseUrl}/getpaymentcollections`, {});
}
function getNachRequestSent() {
  return fetchWrapper.post(`${baseUrl}/getnachrequestsent`, {});
}
function getNachInProcess() {
  return fetchWrapper.post(`${baseUrl}/getNachInProcess`, {});
}
function uploadEnachCollectionCsv(data,emp_id) {
  for (var key of data.entries()) {
    console.log(key[0] + ', ' + key[1]);
  }  
  return fetch(`${baseUrl}/collectenachcsv/${emp_id}`,{
    method: "POST",
    body: data,
    headers:fetchWrapper.authHeader(`${baseUrl}/collectenachcsv/${emp_id}`),
  })
}



function addCollectEnach(params) {
  return fetchWrapper.post(`${baseUrl}/collectenachrequest`, params);
}


