import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from "@material-ui/core";
import { customerService, loanService } from '../../_services';
import { cibil } from "../../_services/cibil.service"
import { history } from "../../_helpers/history"
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import * as Yup from "yup";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));


const Cibil = (props) => {
  console.log("props data is", props)
  const classes = useStyles();
  const isEdit = props.isEdit
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true)
    getData();
    if (props.isEdit) {
      retryRequest(props.request_id)
    }
  };

  const formikRef = React.useRef();


  const [data, setData] = useState([])
  const [list, setList] = useState({})
  const [name, setName] = useState('')
  const [verificationIdType, setVerificationIdType] = useState('')
  const [role, setRole] = useState('')
  const [value, setValue] = useState({})
  const [spinner, setSpinner] = useState(false)
  const [searchPanOrVoter, setSearchPanOrVoterId] = useState(false);
  const [isPanOrVoterVerified, setIsPanOrVoterVerified] = useState(false)
  const [isCibil, setIsCibil] = useState(false)
  const [isHighMark, setIsHighMark] = useState(false)
  const [message, setMessage] = useState()
  const id = props.id;
  const handleClose = () => {
    setOpen(false);
    setSearchPanOrVoterId(false)
    setIsCibil(false)
    setIsHighMark(false)
    setMessage()
  };
  const verificationId = ['PAN Card', 'Voter_Id']
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 460,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    overflow: 'scroll',
    paddingBottom: '8px',
  };

  const dateFormat = (value) => {
    let max_date = new Date(value)
    let dd = ("00" + max_date.getDate()).slice(-2)
    let mm = ("00" + (max_date.getMonth() + 1)).slice(-2);
    let yy = max_date.getFullYear();
    max_date = yy + "-" + mm + "-" + dd;
    return max_date
  }

  let current_date = new Date()
  let minAge = new Date(
    current_date.getFullYear() - 18,
    current_date.getMonth(),
    current_date.getDate(),
    current_date.getHours(),
    current_date.getMinutes()
  )
  let maxAge = new Date(
    current_date.getFullYear() - 100,
    current_date.getMonth(),
    current_date.getDate(),
    current_date.getHours(),
    current_date.getMinutes()
  )



  let initialValues = {
    role: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: "",
    gender: "",
    mobile: "",
    aadhar: "",
    verification_id_type: "",
    verification_id: "",
    address: "",
    city: "",
    pincode: "",
    state: "",
    customer_id: ""
  }


  const retryRequest = async(id) => {
    try{
      console.log("id is", id)
      const x = await cibil.getById({ id: id })
      console.log('x x x', x)
      // if (formikRef.current) {
      //   setName({ first_name: x.first_name, middle_name: x.middle_name, last_name: x.last_name })
      //   if (x.gender == "Male") {
      //     setIsCibil(true)
      //     setIsHighMark(false)
      //   } else if (x.gender == "Female") {
      //     setIsCibil(false)
      //     setIsHighMark(true)
      //   }
      //   formikRef.current.setFieldValue("first_name", x.first_name);
      //   formikRef.current.setFieldValue("middle_name", x.middle_name);
      //   formikRef.current.setFieldValue("last_name", x.last_name);

      //   formikRef.current.setFieldValue("customer_id", x.customer_id);
      //   formikRef.current.setFieldValue("role", x.role);
      //   formikRef.current.setFieldValue("mobile", x.mobile);
      //   formikRef.current.setFieldValue("dob", dateFormat(new Date(x.dob)));
      //   formikRef.current.setFieldValue("gender", x.gender);
      //   formikRef.current.setFieldValue("address", x.address);
      //   formikRef.current.setFieldValue("aadhar", x.aadhar);
      //   formikRef.current.setFieldValue("city", x.city);
      //   // formikRef.current.setFieldValue("state", x.state);
      //   // formikRef.current.setFieldValue("state", "RAJASTHAN");
      //   formikRef.current.setFieldValue("state", x.state.toUpperCase());

      //   formikRef.current.setFieldValue("pincode", x.pincode);
      //   formikRef.current.setFieldValue("verification_id", x.verification_id);
      //   setVerificationIdType(x.verification_id_type)
      //   console.log("x", x)
      // }

       const customer = await customerService.searchCustomer({ customer_id: x.customer_id })
      if (!customer.length > 0) return alert("No customer found")
      setValue(customer[0])
      const previousCibil = await cibil.checkByAadharAndId({ customer_id: x.customer_id, _id: x.reference_id });
      console.log("previousCibil", previousCibil)
  
      if (previousCibil && customer[0].general_details.gender == "Female" && (previousCibil.crif_cibil_score)) {
        setIsCibil(true);
        setIsHighMark(false)
      } else if (previousCibil && customer[0].general_details.gender == "Male" && previousCibil.tu_cibil_score < 1) {
        setIsCibil(false);
        setIsHighMark(true)
      }
      else if (previousCibil && !previousCibil.crif_cibil_score && !previousCibil.tu_cibil_score) {
        setIsCibil(false);
        setIsHighMark(true)
      }
      else if (customer[0].general_details.gender == "Male") {
        setIsCibil(true)
        setIsHighMark(false)
      } else if (customer[0].general_details.gender == "Female") {
        setIsCibil(false)
        setIsHighMark(true)
      }
  
      console.log("customer", customer)
      setName({ first_name: customer[0].general_details.customer_firstname, middle_name: customer[0].general_details.customer_middlename, last_name: customer[0].general_details.customer_lastname })
      if (formikRef.current) {
        // formikRef.current.setFieldValue("first_name", customer[0].general_details.customer_firstname);
        // formikRef.current.setFieldValue("middle_name", customer[0].general_details.customer_lastname);
        if (!customer[0].verification_details.pan_no && !customer[0].verification_details.voter_id) {
          setSearchPanOrVoterId(true);
        } else {
          setSearchPanOrVoterId(false);
  
        }
  
        if (!customer[0].verification_details.karza_pan_verified && !customer[0].verification_details.karza_voter_verified) {
          setIsPanOrVoterVerified(true)
        } else {
          setIsPanOrVoterVerified(false)
        }
        formikRef.current.setFieldValue("customer_id", customer[0].customer_id);
        formikRef.current.setFieldValue("role", x.role);
        formikRef.current.setFieldValue("mobile", customer[0].general_details.customer_mobile_num);
        if (customer[0].verification_details.pan_no) {
          setVerificationIdType(verificationId[0]);
          formikRef.current.setFieldValue("verification_id", customer[0].verification_details.pan_no);
        }
        else if (customer[0].verification_details.voter_id) {
          setVerificationIdType(verificationId[1])
          formikRef.current.setFieldValue("verification_id", customer[0].verification_details.voter_id);
        } else {
          setVerificationIdType('')
          formikRef.current.setFieldValue("verification_id", '');
        }
        formikRef.current.setFieldValue("dob", dateFormat(new Date(customer[0].general_details.customer_dob)));
        formikRef.current.setFieldValue("gender", customer[0].general_details.gender.trim());
        formikRef.current.setFieldValue("address", customer[0].personal_details.permanent_address.address_line_1.trim());
        formikRef.current.setFieldValue("aadhar", customer[0].verification_details.aadhaar_mask_no);
        formikRef.current.setFieldValue("city", customer[0].personal_details.permanent_address.city.trim());
        formikRef.current.setFieldValue("state", customer[0].personal_details.permanent_address.state.trim().toUpperCase());
        // formikRef.current.setFieldValue("state", "RAJASTHAN");
        formikRef.current.setFieldValue("pincode", customer[0].personal_details.permanent_address.pin_code);
      }
    }
    catch(error){
console.log('error is ',error)
    }
   
  }

  let validationSchema = {};

  if (verificationIdType === 'PAN Card') {
    validationSchema = Yup.object().shape({
      // name: Yup.string().required("This is Required"),
      dob: Yup.date().max(dateFormat(minAge), "Age must be greater than or equal to 18 years old").min(dateFormat(maxAge), "Age can not be grater than 100 years").required("This is Required"),
      mobile: Yup.string().length(10).required('This field is required').matches(/^[0-9]+$/, "Must be only 10 digits"),
      aadhar: Yup.string().required("This is Required"),
      verification_id: Yup.string().required("This is Required").matches(/^[a-zA-Z0-9]{10}$/, "Please enter valid PAN number"),
      address: Yup.string().required("This Field is required"),
      city: Yup.string().required("This Field is required"),
      pincode: Yup.string().length(6).required('This field is required').matches(/^[0-9]+$/, "Must be only 6 digits"),
      state: Yup.string().required("This Field is required"),
    })
  } else {
    validationSchema = Yup.object().shape({
      // name: Yup.string().required("This is Required"),
      dob: Yup.date().max(dateFormat(minAge), "Age must be greater than or equal to 18 years old").min(dateFormat(maxAge), "Age can not be grater than 100 years").required("This is Required"),
      mobile: Yup.string().length(10).required('This field is required').matches(/^[0-9]+$/, "Must be only 10 digits"),
      aadhar: Yup.string().required("This is Required"),
      verification_id: Yup.string().required("This is Required"),
      address: Yup.string().required("This Field is required"),
      city: Yup.string().required("This Field is required"),
      pincode: Yup.string().length(6).required('This field is required').matches(/^[0-9]+$/, "Must be only 6 digits"),
      state: Yup.string().required("This Field is required"),
    })
  }


  const onSubmit = (values) => {
    setSpinner(true)
    console.log({ ...values, ...name, verification_id_type: verificationIdType }, 'uuu')
    if (isCibil) {

      cibil.create({ ...values, ...name, verification_id_type: verificationIdType, reference_id: id,"isEdit":props.isEdit ,id:props.request_id})
        .then((data) => {
          console.log("data of report is", data)
          setSpinner(false)
          if (data && data.status != "success") {
            console.log("data.message", data.message)
            setMessage(data.message)
            

          } else if (data && data.status == "success") {
            alert("cibil Data added successfully")
            window.location.reload()
          }
        })
        .catch((err) => {
          setSpinner(false)
          alert("Try Again")
          // window.location.reload()
          console.log(err)
        })
    } else if (isHighMark) {
      cibil.highMarkCreate({ ...values, ...name, verification_id_type: verificationIdType, reference_id: id,"isEdit":props.isEdit,id:props.request_id })
        .then((data) => {
          setSpinner(false)
          console.log("data of report is", data)
          console.log("data astdtcd", data)
          if (data && data.status != "success") {
            console.log("data.message", data.message)
            setMessage(data.message)
        
          } else if (data && data.status == "success") {
            alert("highMark Data added successfully")
            window.location.reload()
          }
        })
        .catch((err) => {
          setSpinner(false)
          alert("Try Again")
          // window.location.reload()
          console.log(err)
        })
    }

  }

  const fetchData = async (e) => {
    console.log(e, 'jere')
    const customer = await customerService.searchCustomer({ customer_id: e.customer_id })
    if (!customer.length > 0) return alert("No customer found")
    setValue(customer[0])
    const previousCibil = await cibil.checkByAadharAndId({ customer_id: e.customer_id, _id: e.loan_id });
    console.log("previousCibil", previousCibil)

    if (previousCibil && customer[0].general_details.gender == "Female" && (previousCibil.crif_cibil_score)) {
      setIsCibil(true);
      setIsHighMark(false)
    } else if (previousCibil && customer[0].general_details.gender == "Male" && previousCibil.tu_cibil_score < 1) {
      setIsCibil(false);
      setIsHighMark(true)
    }
    else if (previousCibil && !previousCibil.crif_cibil_score && !previousCibil.tu_cibil_score) {
      setIsCibil(false);
      setIsHighMark(true)
    }
    else if (customer[0].general_details.gender == "Male") {
      setIsCibil(true)
      setIsHighMark(false)
    } else if (customer[0].general_details.gender == "Female") {
      setIsCibil(false)
      setIsHighMark(true)
    }

    console.log("customer", customer)
    setName({ first_name: customer[0].general_details.customer_firstname, middle_name: customer[0].general_details.customer_middlename, last_name: customer[0].general_details.customer_lastname })
    if (formikRef.current) {
      // formikRef.current.setFieldValue("first_name", customer[0].general_details.customer_firstname);
      // formikRef.current.setFieldValue("middle_name", customer[0].general_details.customer_lastname);
      if (!customer[0].verification_details.pan_no && !customer[0].verification_details.voter_id) {
        setSearchPanOrVoterId(true);
      } else {
        setSearchPanOrVoterId(false);

      }

      if (!customer[0].verification_details.karza_pan_verified && !customer[0].verification_details.karza_voter_verified) {
        setIsPanOrVoterVerified(true)
      } else {
        setIsPanOrVoterVerified(false)
      }
      formikRef.current.setFieldValue("customer_id", customer[0].customer_id);
      formikRef.current.setFieldValue("role", e.role);
      formikRef.current.setFieldValue("mobile", customer[0].general_details.customer_mobile_num);
      if (customer[0].verification_details.pan_no) {
        setVerificationIdType(verificationId[0]);
        formikRef.current.setFieldValue("verification_id", customer[0].verification_details.pan_no);
      }
      else if (customer[0].verification_details.voter_id) {
        setVerificationIdType(verificationId[1])
        formikRef.current.setFieldValue("verification_id", customer[0].verification_details.voter_id);
      } else {
        setVerificationIdType('')
        formikRef.current.setFieldValue("verification_id", '');
      }
      formikRef.current.setFieldValue("dob", dateFormat(new Date(customer[0].general_details.customer_dob)));
      formikRef.current.setFieldValue("gender", customer[0].general_details.gender.trim());
      formikRef.current.setFieldValue("address", customer[0].personal_details.permanent_address.address_line_1.trim());
      formikRef.current.setFieldValue("aadhar", customer[0].verification_details.aadhaar_mask_no);
      formikRef.current.setFieldValue("city", customer[0].personal_details.permanent_address.city.trim());
      formikRef.current.setFieldValue("state", customer[0].personal_details.permanent_address.state.trim().toUpperCase());
      // formikRef.current.setFieldValue("state", "RAJASTHAN");
      formikRef.current.setFieldValue("pincode", customer[0].personal_details.permanent_address.pin_code);
    }
  }

  const getData = () => {
    loanService.getById(id)
      .then((x) => {
        console.log("applicant data", x)
        setList(x)
        // setData( x.applicant.general_information.name)
        let tempArr = [];
        if (x && x.applicant.customer_id) {
          tempArr.push({ name: x.applicant.general_information.name, customer_id: x.applicant.customer_id, role: "Applicant", data: x.applicant, loan_id: x._id })
          if (x.co_applicants && x.co_applicants.length) {
            x.co_applicants.forEach(co_app => {
              if (co_app.customer_id && co_app.customer_id !== '') {
                tempArr.push({ name: co_app.general_information.name, customer_id: co_app.customer_id, role: "Co-Applicant", data: co_app, loan_id: x._id })
              }
            })

          }

          if (x.guarantors && x.guarantors.length) {
            x.guarantors.forEach(guar => {
              if (guar.customer_id && guar.customer_id !== "") {
                tempArr.push({ name: guar.general_information.name, customer_id: guar.customer_id, role: "Guarantor", data: guar, loan_id: x._id })
              }
            })

          }
        }
        console.log(tempArr);
        setData(tempArr)
      })
      .catch((err) => {
        console.log("Something went Wrong", err)
      })
  }

  const validate = () => {
    if (value.name.toLowerCase() === list.applicant.general_information.name.toLowerCase()) {
      //  setAadhar(list.applicant.general_information.masked_aadhar)
      //  setPan(list.applicant.general_information.pan_no)
      setRole("Applicant")
    }

    if (list && list.co_applicants && list.co_applicants.length) {
      list.co_applicants.map((e) => {
        if (value.name.toLowerCase() === e.general_information.name.toLowerCase()) {
          // setAadhar(e.general_information.masked_aadhar)
          // setPan(e.general_information.pan_no)
          setRole("Co-Applicant")
        }
      })
    }

    if (list && list.guarantors && list.guarantors.length) {
      list.guarantors.map((e) => {
        if (value.name.toLowerCase() === e.general_information.name.toLowerCase()) {
          // setAadhar(e.general_information.masked_aadhar)
          // setPan(e.general_information.pan_no)
          setRole("Guarantor")
        }
      })
    }

  }
  console.log("message is", message)

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {
          props.isEdit ? <Button style={{ color: "red", alignItems: "center" }} onClick={handleOpen}>Retry</Button> : <Button style={{ color: "white", backgroundColor: "blue", border: "1px solid" }} onClick={handleOpen}>CIBIL Request</Button>

        }
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography style={{ margin: "auto", }}>
            {spinner ? <div className={classes.root} style={{ marginLeft: "45%", marginTop: "20%" }} >
              <CircularProgress />
            </div> : <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              innerRef={formikRef}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <Form>
                    <div>
                   <h3 style={{ display: "flex", justifyContent: 'center', color: "red" }}>In case data is incorrect , fix it at customer level</h3> 
                      {message && <h3 style={{ display: "flex", justifyContent: 'center', color: "red" }}>Error is : {message}</h3>}

                      <Button color='primary' variant='contained' type='button' disabled={!isCibil} style={{ marginRight: '1rem' }}>Cibil</Button>
                      <Button color='primary' variant='contained' type='button' disabled={!isHighMark}>High Mark</Button>
                    </div>
                    {searchPanOrVoter && list.stage !== "Collection" && <h5 style={{ display: "flex", justifyContent: 'center', color: "red" }}>Please Fill The Detail of Either PAN Card Or Voter ID In Application form*</h5>}
                    {isPanOrVoterVerified && list.stage !== "Collection" && <h5 style={{ display: "flex", justifyContent: 'center', color: "red" }}>Either pan number or voter id should be verified</h5>}
                    <div style={{ marginLeft: "50px", marginTop: "10px" }}>
                      <div className="form-row p-0 m-0">
                        <div className="form-group col p-0 m-0">
                          <label className="required" style={{ color: "rgb(41,73,185)" }}>Customers Name</label>
                          {!isEdit ? 
                          <Select
                            style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }}
                            name="name"
                            // required
                            onChange={(e) => fetchData(e.target.value)}
                            className={
                              "form-control"
                              // (errors.insured_person_name && touched.insured_person_name ? " is-invalid" : "")
                            } >

                            <MenuItem selected disabled  >Choose Name</MenuItem>
                            {data.map((x) => {
                              return <MenuItem value={x}>{x.name}</MenuItem>
                            })}

                          </Select>
                          : <Field style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} value={`${name.first_name || ''} ${name.middle_name || ''} ${name.last_name || ''}`} disabled className={
                              "form-control" 
                            } />

                          }
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group col p-0 m-0">
                          <label style={{ color: "rgb(41,73,185)" }}>Role</label>
                          <Field style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="role"
                          disabled
                            className={
                              "form-control" +
                              (errors.role && touched.role ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="role"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group col p-0 m-0">
                          <label style={{ color: "rgb(41,73,185)" }}>AAdhar Number</label>
                          <Field style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="aadhar"
                            disabled
                            className={
                              "form-control" +
                              (errors.aadhar && touched.aadhar ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="aadhar"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group col p-0 m-0">
                          <label className="required" style={{ color: "rgb(41,73,185)" }}>Verification Id Type</label>
                          <Select
                            style={{ width: "200px", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }}
                            name="verification_id_type"
                            onChange={(e) => setVerificationIdType(e.target.value)}
                            required
                            value={verificationIdType}
                            className={
                              "form-control"
                              // (errors.insured_person_name && touched.insured_person_name ? " is-invalid" : "")
                            } >

                            <MenuItem defaultChecked disabled  >Choose Verification Id</MenuItem>
                            {/* {verificationId && verificationId.map((x) => {
                              return <MenuItem value={`${x}`}>{x}</MenuItem>
                            })} */}
                            {verificationId && verificationId
                              .filter((id) => verificationIdType === 'PAN Card' ? id === 'PAN Card' : id === 'Voter_Id')
                              .map((x) => (
                                <MenuItem key={x} value={x}>
                                  {x}
                                </MenuItem>
                              ))}
                          </Select>
                          <ErrorMessage
                            name="verification_id_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                      </div>

                      <div className="form-row p-0 m-0">

                       

                        <div className="form-group col p-0 m-0">
                          <label style={{ color: "rgb(41,73,185)" }}>Verification Id Number</label>
                          <Field style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="verification_id"
                            disabled
                            className={
                              "form-control" +
                              (errors && errors.verification_id && touched && touched.verification_id ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="verification_id"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group col p-0 m-0">
                          <label style={{ color: "rgb(41,73,185)" }}>Address</label>
                          <Field style={{ width: "200px", backgroundColor: "white", height: "35px", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="address"
                          disabled
                            className={
                              "form-control" +
                              (errors && errors.address && touched && touched.address ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className='form-group col p-0 m-0'>
                          <label style={{ color: "rgb(41,73,185)" }}>Date Of Birth</label>
                          <Field type='date' onKeyDown={(e) => e.preventDefault()} style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="dob"
                          disabled
                            className={
                              "form-control" +
                              (errors.dob && touched.dob ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="dob"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className='form-group col p-0 m-0'>
                          <label style={{ color: "rgb(41,73,185)" }}>Gender</label>
                          <Field style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="gender"
                          disabled
                            className={
                              "form-control" +
                              (errors.gender && touched.gender ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                      </div>

                      <div className='form-row p-0 m-0'>
                       
                        <div className='form-group col p-0 m-0'>
                          <label style={{ color: "rgb(41,73,185)" }}>Mobile</label>
                          <Field style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="mobile"
                          disabled
                            className={
                              "form-control" +
                              (errors.mobile && touched.mobile ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className='form-group col p-0 m-0'>
                          <label style={{ color: "rgb(41,73,185)" }}>Pincode</label>
                          <Field style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="pincode"
                          disabled
                            className={
                              "form-control" +
                              (errors && errors.pincode && touched && touched.pincode ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="pincode"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className='form-group col p-0 m-0'>
                          <label style={{ color: "rgb(41,73,185)" }}>City</label>
                          <Field style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="city"
                          disabled
                            className={
                              "form-control" +
                              (errors.city && touched.city ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className='form-group col p-0 m-0'>
                          <label style={{ color: "rgb(41,73,185)" }}>State</label>
                          <Field style={{ width: "200px", height: "35px", backgroundColor: "white", color: "rgb(41,73,185)", borderColor: "rgb(41,73,185)" }} name="state"
                          disabled
                            className={
                              "form-control" +
                              (errors.state && touched.state ? " is-invalid" : "")
                            } />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                  <br />

                      {<div className="row justify-content-around">
                        <Fab
                          type="submit"
                          color="primary"
                          aria-label="save"
                        >
                          <SaveIcon />
                        </Fab>
                      </div>}
                    </div>
                  </Form>
                )
              }}
            </Formik>}

          </Typography>
        </Box>
      </Modal><br />
    </div>
  )
}

export default Cibil