import React, { useState, useEffect } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Add, Delete, Edit } from '@material-ui/icons';
import { adminservice } from "../../../_services/admin.services";
import { uploadFileToBucket } from '../../../_helpers/upload';
import { employeeService } from "../../../_services/employee.service";
import EmployeeSelectModal from "./EmployeeSelectModal"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { employeeExpenseService } from "../../../_services/employeeExpense.service"
import { ToastContainer } from "react-toastify";
import { notifyInfo, notifyFailed } from "../../../_helpers/notify"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F2F0EF',
    padding: theme.spacing(4),
    borderRadius: '10px',
    maxWidth: '80%',
    // maxWidth: '1000px',
    maxHeight: "600px",
    overflow: 'auto',
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    gap: '1rem',
  },
  entityWrapper: {
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  deleteButton: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}));



const EmployeeRequestModal = (id) => {
  const classes = useStyles();
  const user = employeeService.userValue
  const [open, setOpen] = useState(false);
  const [expenseData, setExpenseData] = useState({});
  const [availableSubOptions, setAvailableSubOptions] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const bucket = process.env.REACT_APP_s3docsBucket;
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [allCategories, setAllCategories] = useState([])
  const [spinner, setSpinner] = useState(false)

  const travelExp = {
    travelby: '',
    traveltype: '',
    startDate: '',
    tollInvoice:'',
    invoice: '',
    ticket: '',
    amount: '',
    purpose: ''
  }

  const mealExp =  {
    mealtype: '',
    date: '',
    invoice: '',
    amount: '',
    purpose: ''

  }

const stayExp =  {
  citytype: '',
  cityname: '',
  startDate: '',
  duration: '',
  invoice: '',
  amount: '',
  purpose: ''

}

  const [initialValues, setInitialValues] = useState({
    travel: [
      
    ],
    meal: [
       
    ],
    stay: [
       
    ],
    AddedEmployees: [],
    comment: '',
    totalApprovedAmount: '',
    totalRequestedAmount: ''
  })
  const expenseId = id.id;

  const disablePastDate = () => {
    let minDate = new Date();
    let maxDate = new Date();
  
    // Set minDate to 15 days ago
    minDate.setDate(minDate.getDate() - 15);
  
    // Format both dates to "YYYY-MM-DD"
    const formattedMinDate = minDate.toISOString().split("T")[0];
    const formattedMaxDate = maxDate.toISOString().split("T")[0];
  
    return { minDate: formattedMinDate, maxDate: formattedMaxDate };
  };
  

  const validationSchema = Yup.object().shape({
    travel: Yup.array().of(
      Yup.object().shape({
        travelby: Yup.string().nullable(),
        traveltype: Yup.string().when('travelby', {
          is: (value) => value && value.length > 0,
          then: Yup.string().required('Travel Type is required when Travel By is selected'),
          otherwise: Yup.string().nullable(),
        }),
        startDate: Yup.date().when('travelby', {
          is: (value) => value && value.length > 0,
          then: Yup.date()
          .min(disablePastDate().minDate, "Select a date within the last 15 days")
          .max(disablePastDate().maxDate, "Future dates are not allowed")
          .required("This field is required"),
          otherwise: Yup.date().nullable(),
        }),
        invoice: Yup.mixed().when('travelby', {
          is: (value) => value && value.length > 0,
          then: Yup.mixed().required('Invoice is required when Travel By is selected'),
          otherwise: Yup.mixed().nullable(),
        }),
        amount: Yup.number().when('travelby', {
          is: (value) => value && value.length > 0,
          then: Yup.number().required('Amount is required when Travel By is selected').positive('Amount must be positive'),
          otherwise: Yup.number().nullable(),
        }),
      })
    ),

    meal: Yup.array().of(
      Yup.object().shape({
        mealtype: Yup.string().nullable(),
        date: Yup.date().when('mealtype', {
          is: (value) => value && value.length > 0,
          then: Yup.date()
          .min(disablePastDate().minDate, "Select a date within the last 15 days")
          .max(disablePastDate().maxDate, "Future dates are not allowed")
          .required("This field is required"),
          otherwise: Yup.date().nullable(),
        }),
        invoice: Yup.mixed().when('mealtype', {
          is: (value) => value && value.length > 0,
          then: Yup.mixed().required('Invoice is required when Meal Type is selected'),
          otherwise: Yup.mixed().nullable(),
        }),
        amount: Yup.number().when('mealtype', {
          is: (value) => value && value.length > 0,
          then: Yup.number().required('Amount is required when Meal Type is selected').positive('Amount must be positive'),
          otherwise: Yup.number().nullable(),
        }),
      })
    ),

    stay: Yup.array().of(
      Yup.object().shape({
        citytype: Yup.string().nullable(),
        cityname: Yup.string().when('citytype', {
          is: (value) => value && value.length > 0,
          then: Yup.string().required('City Name is required when City Type is selected'),
          otherwise: Yup.string().nullable(),
        }),
        startDate: Yup.date().when('citytype', {
          is: (value) => value && value.length > 0,
          then: Yup.date()
          .min(disablePastDate().minDate, "Select a date within the last 15 days")
          .max(disablePastDate().maxDate, "Future dates are not allowed")
          .required("This field is required"),
          otherwise: Yup.date().nullable(),
        }),
        duration: Yup.number().when('citytype', {
          is: (value) => value && value.length > 0,
          then: Yup.number().required('Duration is required when City Type is selected').positive('Duration must be positive'),
          otherwise: Yup.number().nullable(),
        }),
        invoice: Yup.mixed().when('citytype', {
          is: (value) => value && value.length > 0,
          then: Yup.mixed().required('Invoice is required when City Type is selected'),
          otherwise: Yup.mixed().nullable(),
        }),
        amount: Yup.number().when('citytype', {
          is: (value) => value && value.length > 0,
          then: Yup.number().required('Amount is required when City Type is selected').positive('Amount must be positive'),
          otherwise: Yup.number().nullable(),
        }),
      })
    ),
  });
  const handleTravelOptionChange = (event, index, setFieldValue) => {
    const selectedOption = event.target.value;
    setFieldValue(`travel.${index}.travelby`, selectedOption);

    const selectedTravel = expenseData?.travelOptions?.find(
      (option) => option.travelOptionName === selectedOption
    );

    const updatedSubOptions = [...availableSubOptions];
    if (selectedTravel) {
      updatedSubOptions[index] = selectedTravel.subOptions;
    } else {
      updatedSubOptions[index] = [];
    }
    setAvailableSubOptions(updatedSubOptions); // Update the sub-options for the specific travel entry
  };

  const handleMealTypeChange = (event, index, setFieldValue) => {
    const selectedMeal = event.target.value;
    setFieldValue(`meal.${index}.mealtype`, selectedMeal);

    const selectedMealDetails = expenseData?.mealEligibility?.find(
      (meal) => meal.mealType === selectedMeal
    );


  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (values) => {
    setSpinner(true);

    if(values && values.travel && values.travel.length==0 && values.meal && values.meal.length==0 && values.stay && values.stay.length==0 ){
      notifyFailed(`At least one expense is required in either travel, meal, or stay allowance`);
      setSpinner(false);

      return;
    }

    values.AddedEmployees = []
    for (let i = 0; i < selectedEmployee.length; i++) {
      values.AddedEmployees.push({
        "emp_id": selectedEmployee[i].emp_id, "category": (selectedEmployee[i].category_reference || selectedEmployee[i].category)
      })
    }

    console.log("values at the time of submit", values)


    let totalApprovedAmount = 0;
    let totalRequestedAmount = 0;
    const updateOverallStatus = (array) => {
      console.log('array is', array)

      if (array && array.length > 0) {
        for (let item of array) {
          totalRequestedAmount = Number(totalRequestedAmount) + Number(item.amount);
          console.log('item is', item)
          if (item.status == "Verified") {
            totalApprovedAmount = Number(totalApprovedAmount) + Number(item.approvedAmount);

          }
        }

      }
    };

    // Check statuses for travel, meal, and stay
    updateOverallStatus(values.travel);
    updateOverallStatus(values.meal);
    updateOverallStatus(values.stay);

    values.totalApprovedAmount = Number(totalApprovedAmount);
    values.totalRequestedAmount = Number(totalRequestedAmount);


    if (expenseId) {
      values.status = "Requested"
      employeeExpenseService.update(expenseId, values)
        .then((res) => {
          if (res && res.status === "success") {
            console.log('Employee expense updated successfully', res);
            notifyInfo(`Employee expense   has been updated successfully`);
          } else if (res && res.status === "failed") {
            console.log('Failed to update employee expense', res.message);
            notifyFailed(`Failed: ${res.message}`);
            setSpinner(false);
          }
        })
        .catch((err) => {
          console.log(err);
          notifyFailed('An error occurred while updating the employee expense');
          setSpinner(false);
        });
    }
    else {

      const meal = values.meal;
      const travel = values.travel;
      const stay = values.stay;

      console.log('meal travel stay', meal, travel, stay, expenseData);

      // Adding maxamount to the travel array based on travelby
      // const updatedTravel = travel.map(travelItem => {
      //    const travelOption = expenseData.travelOptions.find(option => option.travelOptionName === travelItem.travelby);
      //   console.log('travelOption travelOption', travelOption)
      //    const maxamount = travelOption ? 1000 : 0;  
      //   return {
      //     ...travelItem,
      //     maxamount,
      //   };
      // });

      // console.log('updatedTravel', updatedTravel);

      // Adding maxamount to the meal array based on mealtype
      const updatedMeal = meal.map(mealItem => {
        // Find the meal eligibility that matches the mealtype
        const mealOption = expenseData.mealEligibility.find(meal => meal.mealType === mealItem.mealtype);
        console.log('mealOption mealOption', mealOption)

        // Get the maxamount from the matched meal eligibility
        const maxamount = mealOption ? mealOption.amount : 0;

        return {
          ...mealItem,
          maxamount,
        };
      });

      console.log('updatedMeal', updatedMeal);

      // Adding maxamount to the stay array based on citytype
      const updatedStay = stay.map(stayItem => {
        // Find the stay allowance that matches the citytype
        const stayOption = expenseData.stayAllowance.find(stay => stay.cityType === stayItem.citytype);
        console.log('stayOption stayOption', stayOption)

        // Get the maxamount from the matched stay allowance
        const maxamount = stayOption ? (Number(stayOption.amount))*(Number(stayItem.duration)) : 0;

        return {
          ...stayItem,
          maxamount,
        };
      });

      console.log('updatedStay', updatedStay);


      values.travel = travel;
      values.meal = updatedMeal;
      values.stay = updatedStay;

      employeeExpenseService.create(values)
        .then((res) => {
          if (res && res.status === "success") {
            console.log('Employee expense created successfully', res);
            notifyInfo(`$Employee Expense   has been added successfully`);
          } else if (res && res.status === "failed") {
            console.log('Failed to create employee expense', res.message);
            notifyFailed(`Failed: ${res.message}`);
            setSpinner(false);
          }
        })
        .catch((err) => {
          console.log(err);
          notifyFailed('An error occurred while creating the branch expense');
          setSpinner(false);
        });
    }

  };

  useEffect(() => {
    if (open) {
      adminservice.getCategoryById()
        .then(res => {
          console.log('response of get by category is', res);
          setExpenseData(res);
        })
        .catch(err => console.log('error of category is', err));

      adminservice.getAllCategory()
        .then(res => { console.log('all getegories are ', res); setAllCategories(res) })
        .catch(err => { console.log('error of get all categor is', err) })


      adminservice.getAllCities()
        .then(res => {
          console.log('Response of Tier 1:', res);
          const citiesArray = [
            ...res['Tier 1'].map(city => ({ cityType: 'Tier 1', cityName: city })),
            ...res['Tier 2'].map(city => ({ cityType: 'Tier 2', cityName: city })),
            ...res['Tier 3'].map(city => ({ cityType: 'Tier 3', cityName: city })),
          ];
          setAllCities(citiesArray);
        })
        .catch(err => console.error('Error fetching tier 1:', err));

      employeeService.getAll()
        .then(res => { setAllEmployees(res); console.log('all employees are', res) })
        .catch(err => console.log('eer of epmloyee res is ', err));
    }
  }, [open]);

  const handleCityChange = (event, index, setFieldValue) => {
    const selectedCity = event.target.value;
    setFieldValue(`stay.${index}.cityname`, selectedCity);

    const selectedCityDetails = allCities.find(
      (city) => city.cityName === selectedCity
    );

    if (selectedCityDetails) {
      setFieldValue(`stay.${index}.citytype`, selectedCityDetails.cityType);
    }
  };



  useEffect(() => {
    if (expenseId && open) {
      employeeExpenseService.getById({ id: expenseId })
        .then((res) => {
          console.log('edit employee  expense data', res);

          setInitialValues(res)
          setSelectedEmployee(res.AddedEmployees)

        })
        .catch((err) => console.log('err of edit expense', err));
    }
  }, [expenseId, open]);

  console.log('gsf rwfewref0,', initialValues)


  const handleInvoices = async (e, val, setFieldValue) => {
    const files = Array.from(e.target.files);  // Convert FileList to Array

    // To store uploaded file URLs
    const uploadedFiles = [];

    for (const file of files) {
      let name = file.name.split('.')[0];
      let num1 = Math.floor(Math.random() * 10000);
      let num2 = Math.floor(Math.random() * 10000);
      let fileName = `${name}_${num1}${num2}.pdf`;

      try {
        // Upload the file to the S3 bucket
        const link = await uploadFileToBucket(bucket, 'application/pdf', `employeeexpense/invoices/${fileName}`, file);
        uploadedFiles.push(link);  // Store the URL

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    // Update the Formik field with the first uploaded file's URL, if available
    if (uploadedFiles.length > 0) {
      setFieldValue(val, uploadedFiles[0]);  // Assuming you're handling only one file per field
    }
  };

  // const handleSelectEmployee = (employee) => {
  //   const isEmployeeAlreadySelected = selectedEmployee.some(
  //     (selected) => selected.id === employee.id
  //   );

  //   if (isEmployeeAlreadySelected) {
  //     console.log('Employee already added');
  //     return;
  //   }
  //   setSelectedEmployee((prevSelected) => [...prevSelected, employee]);
  // };

  // const handleRemoveEmp = (employee) => {
  //   setSelectedEmployee((prevSelected) =>
  //     prevSelected.filter((selected) => selected.id !== employee.id)
  //   );
  // };

  const handleSelectEmployee = (employee) => {
    const isEmployeeAlreadySelected = selectedEmployee.some(
      (selected) => selected.id === employee.id
    );

    if (isEmployeeAlreadySelected) {
      console.log('Employee already added');
      return;
    }

    setSelectedEmployee((prevSelected) => [...prevSelected, employee]);

    const filterCategory = allCategories.find((val) => val.categoryName === employee.category_reference);

    console.log('filterCategory is', filterCategory);

    if (filterCategory && expenseData) {
      let breakfastAmount = 0;
      let lunchAmount = 0;
      let dinnerAmount = 0;
      let tier1StayAmount = 0;
      let tier2StayAmount = 0;
      let tier3StayAmount = 0;

      // Calculate amounts for meals
      if (filterCategory.mealEligibility) {
        filterCategory.mealEligibility.forEach((meal) => {
          switch (meal.mealType) {
            case "BreakFast":
              breakfastAmount += meal.amount;
              break;
            case "Lunch":
              lunchAmount += meal.amount;
              break;
            case "dinner":
              dinnerAmount += meal.amount;
              break;
            default:
              break;
          }
        });
      }

      // Calculate amounts for stays
      if (filterCategory.stayAllowance) {
        filterCategory.stayAllowance.forEach((stay) => {
          switch (stay.cityType) {
            case "Tier 1":
              tier1StayAmount += stay.amount;
              break;
            case "Tier 2":
              tier2StayAmount += stay.amount;
              break;
            case "Tier 3":
              tier3StayAmount += stay.amount;
              break;
            default:
              break;
          }
        });
      }
      console.log('breakfastAmount,lunchAmount,dinnerAmount,tier1StayAmount,tier2StayAmount,tier3StayAmount', breakfastAmount, lunchAmount, dinnerAmount, tier1StayAmount, tier2StayAmount, tier3StayAmount)
      // Update the expenseData object with separate amounts
      setExpenseData((prevData) => ({
        ...prevData,
        mealEligibility: prevData.mealEligibility.map(meal => ({
          ...meal,
          amount: meal.mealType === "BreakFast" ? meal.amount + breakfastAmount :
            meal.mealType === "Lunch" ? meal.amount + lunchAmount :
              meal.mealType === "dinner" ? meal.amount + dinnerAmount :
                meal.amount
        })),
        stayAllowance: prevData.stayAllowance.map(stay => ({
          ...stay,
          amount: stay.cityType === "Tier 1" ? stay.amount + tier1StayAmount :
            stay.cityType === "Tier 2" ? stay.amount + tier2StayAmount :
              stay.cityType === "Tier 3" ? stay.amount + tier3StayAmount :
                stay.amount
        }))
      }));

    }
  };

  const handleRemoveEmp = (employee) => {
    // Remove the employee from the selectedEmployee list
    setSelectedEmployee((prevSelected) =>
      prevSelected.filter((selected) => selected.id !== employee.id) // Adjust this line if your employee has a different unique identifier
    );

    // Filter the category based on the employee's category_reference
    const filterCategory = allCategories.find(
      (val) => val.categoryName === employee.category_reference
    );

    console.log('filterCategory is', filterCategory);

    if (filterCategory && expenseData) {
      // Initialize amounts to subtract
      let breakfastAmount = 0;
      let lunchAmount = 0;
      let dinnerAmount = 0;
      let tier1StayAmount = 0;
      let tier2StayAmount = 0;
      let tier3StayAmount = 0;

      // Calculate amounts for meals
      if (filterCategory.mealEligibility) {
        filterCategory.mealEligibility.forEach((meal) => {
          switch (meal.mealType) {
            case 'BreakFast':
              breakfastAmount += meal.amount;
              break;
            case 'Lunch':
              lunchAmount += meal.amount;
              break;
            case 'dinner':
              dinnerAmount += meal.amount;
              break;
            default:
              break;
          }
        });
      }

      // Calculate amounts for stays
      if (filterCategory.stayAllowance) {
        filterCategory.stayAllowance.forEach((stay) => {
          switch (stay.cityType) {
            case 'Tier 1':
              tier1StayAmount += stay.amount;
              break;
            case 'Tier 2':
              tier2StayAmount += stay.amount;
              break;
            case 'Tier 3':
              tier3StayAmount += stay.amount;
              break;
            default:
              break;
          }
        });
      }

      console.log(
        'breakfastAmount,lunchAmount,dinnerAmount,tier1StayAmount,tier2StayAmount,tier3StayAmount',
        breakfastAmount,
        lunchAmount,
        dinnerAmount,
        tier1StayAmount,
        tier2StayAmount,
        tier3StayAmount
      );

      // Update the expenseData object by subtracting the amounts
      setExpenseData((prevData) => ({
        ...prevData,
        mealEligibility: prevData.mealEligibility.map((meal) => ({
          ...meal,
          amount:
            meal.mealType === 'BreakFast'
              ? meal.amount - breakfastAmount
              : meal.mealType === 'Lunch'
                ? meal.amount - lunchAmount
                : meal.mealType === 'dinner'
                  ? meal.amount - dinnerAmount
                  : meal.amount,
        })),
        stayAllowance: prevData.stayAllowance.map((stay) => ({
          ...stay,
          amount:
            stay.cityType === 'Tier 1'
              ? stay.amount - tier1StayAmount
              : stay.cityType === 'Tier 2'
                ? stay.amount - tier2StayAmount
                : stay.cityType === 'Tier 3'
                  ? stay.amount - tier3StayAmount
                  : stay.amount,
        })),
      }));
    }
  };
  console.log('Updated expenseData:', expenseData);
  console.log('selectedEmployee is', selectedEmployee)
  return (
    <div>
      {expenseId ? <div style={{ marginBottom: '1rem' }} >

        <Button variant="outlined" color="primary" onClick={handleOpen}>
          <Edit />
        </Button>
      </div>
        :
        <div><Button variant="outlined" color="primary" onClick={handleOpen}>
          Initiate Expense <Add />
        </Button>
        </div>}
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h6">Expense Details

              {!expenseId && <EmployeeSelectModal

                allEmployees={allEmployees}
                onSelectEmployee={handleSelectEmployee}
              />}

              <div className="form-group row p-1"  >
                {selectedEmployee.map((emp, idx) => (
                  <div key={idx}>

                    <button
                      type="button"
                      onClick={() => handleRemoveEmp(emp)}
                      className="btn btn-danger btn-sm"
                      style={{
                        marginLeft: '5px', position: 'relative', height: '50px', marginTop: '10px'

                      }}
                      disabled={expenseId ? true : false}
                    >
                      {emp.emp_id}
                      <HighlightOffIcon

                        style={{
                          position: 'absolute',
                          top: '-4px',
                          right: '-5px',
                          borderRadius: '50%',
                          cursor: 'pointer'
                        }}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </Typography>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, setFieldValue, errors, touched }) => {
                console.log('values are ', values)
                return <Form>
                  <ToastContainer />
                <div>
                  <FieldArray name="travel">
                    {({ push, remove }) => (
                      <>
                        {values.travel.map((_, index) => (
                          <div key={index} className={classes.entityWrapper}>
                            <div className={classes.formRow}>
                              <Typography variant="subtitle1">Travel {index + 1}</Typography>
                              <IconButton
                                disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
                                className={classes.deleteButton}
                                onClick={() => remove(index)}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                            <div className={classes.formRow}>
                              <TextField
                                className={classes.formField}
                                select
                                label="Travel By"
                                name={`travel.${index}.travelby`}
                                value={values.travel[index].travelby}
                                onChange={(e) => handleTravelOptionChange(e, index, setFieldValue)}
                                fullWidth
                                disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
                                error={touched.travel?.[index]?.travelby && Boolean(errors.travel?.[index]?.travelby)}
                                helperText={touched.travel?.[index]?.travelby && errors.travel?.[index]?.travelby}
                              >
                                {expenseData?.travelOptions?.map((option) => (
                                  <MenuItem key={option.travelOptionName} value={option.travelOptionName}>
                                    {option.travelOptionName}
                                  </MenuItem>
                                ))}
                              </TextField>
                              {availableSubOptions[index]?.length > 0 && (
                                <TextField
                                  className={classes.formField}
                                  select
                                  label="Travel Type"
                                  name={`travel.${index}.traveltype`}
                                  value={values.travel[index].traveltype}
                                  onChange={handleChange}
                                  fullWidth
                                  disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
                                  error={touched.travel?.[index]?.traveltype && Boolean(errors.travel?.[index]?.traveltype)}
                                  helperText={touched.travel?.[index]?.traveltype && errors.travel?.[index]?.traveltype}
                                >
                                  {availableSubOptions[index]?.map((subOption) => (
                                    <MenuItem key={subOption} value={subOption}>
                                      {subOption}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                              <TextField
                                label="Start Date"
                                type="date"
                                onKeyDown={(e) => e.preventDefault()}
                                name={`travel.${index}.startDate`}
                                value={values.travel[index].startDate?new Date(values.travel[index].startDate).toISOString().split('T')[0] : values.travel[index].startDate}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
                                InputLabelProps={{ shrink: true }}
                                error={touched.travel?.[index]?.startDate && Boolean(errors.travel?.[index]?.startDate)}
                                helperText={touched.travel?.[index]?.startDate && errors.travel?.[index]?.startDate}
                              />

                              <TextField
                                label="Amount"
                                name={`travel.${index}.amount`}
                                value={values.travel[index].amount}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
                                error={touched.travel?.[index]?.amount && Boolean(errors.travel?.[index]?.amount)}
                                helperText={touched.travel?.[index]?.amount && errors.travel?.[index]?.amount}
                              />
                              <TextField
                                label="Upload Invoice"
                                name={`travel.${index}.invoice`}
                                type="file"
                                onChange={(e) => handleInvoices(e, `travel.${index}.invoice`, setFieldValue)}
                                fullWidth
                                disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
                                error={touched.travel?.[index]?.invoice && Boolean(errors.travel?.[index]?.invoice)}
                                helperText={touched.travel?.[index]?.invoice && errors.travel?.[index]?.invoice}
                              />
                            {values?.travel?.[index]?.travelby == "Car" &&   (
  <TextField
    label="Upload Invoice"
    name={`travel.${index}.tollInvoice`}
    type="file"
    onChange={(e) => handleInvoices(e, `travel.${index}.tollInvoice`, setFieldValue)}
    fullWidth
    disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
    error={touched.travel?.[index]?.tollInvoice && Boolean(errors.travel?.[index]?.tollInvoice)}
    helperText={touched.travel?.[index]?.tollInvoice && errors.travel?.[index]?.tollInvoice}
  />
)}

                              <TextField
                                label="Purpose"
                                name={`travel.${index}.purpose`}
                                value={values.travel[index].purpose}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.travel[index].status === "Verified" || values.travel[index].status === "Rejected"}
                                error={touched.travel?.[index]?.purpose && Boolean(errors.travel?.[index]?.purpose)}
                                helperText={touched.travel?.[index]?.purpose && errors.travel?.[index]?.purpose}
                              />
                            </div>
                          </div>
                        ))}
                        <Button
                          type="button"
                          variant="contained"
                           style={{marginRight:'2rem'}}
                          color="secondary"
                          startIcon={<Add />}
                          onClick={() => push(travelExp)}
                        >
                          Add Travel
                        </Button>
                      </>
                    )}
                  </FieldArray>

                  <FieldArray name="meal">
                    {({ push, remove }) => (
                      <>
                        {values.meal.map((_, index) => (
                          <div key={index} className={classes.entityWrapper}>
                            <div className={classes.formRow}>
                              <Typography variant="subtitle1">Meal {index + 1}</Typography>
                              <IconButton
                                className={classes.deleteButton}
                                disabled={values.meal[index].status === "Verified" || values.meal[index].status === "Rejected"}
                                onClick={() => remove(index)}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                            <div className={classes.formRow}>
                              <TextField
                                label="Meal Type"
                                name={`meal.${index}.mealtype`}
                                select
                                value={values.meal[index].mealtype}
                                onChange={(e) => handleMealTypeChange(e, index, setFieldValue)}
                                fullWidth
                                disabled={values.meal[index].status === "Verified" || values.meal[index].status === "Rejected"}
                                error={touched.meal?.[index]?.mealtype && Boolean(errors.meal?.[index]?.mealtype)}
                                helperText={touched.meal?.[index]?.mealtype && errors.meal?.[index]?.mealtype}
                              >
                                {expenseData?.mealEligibility?.map((meal) => (
                                  <MenuItem key={meal.mealType} value={meal.mealType}>
                                    {meal.mealType}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                label="Date"
                                type="date"
                                onKeyDown={(e) => e.preventDefault()}
                                name={`meal.${index}.date`}
                                value={values.meal[index].date ? new Date(values.meal[index].date).toISOString().split('T')[0]:values.meal[index].date}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.meal[index].status === "Verified" || values.meal[index].status === "Rejected"}
                                InputLabelProps={{ shrink: true }}
                                error={touched.meal?.[index]?.date && Boolean(errors.meal?.[index]?.date)}
                                helperText={touched.meal?.[index]?.date && errors.meal?.[index]?.date}
                              />
                              <TextField
                                label="Amount"
                                name={`meal.${index}.amount`}
                                value={values.meal[index].amount}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.meal[index].status === "Verified" || values.meal[index].status === "Rejected"}
                                error={touched.meal?.[index]?.amount && Boolean(errors.meal?.[index]?.amount)}
                                helperText={touched.meal?.[index]?.amount && errors.meal?.[index]?.amount}
                              />
                              <TextField
                                label="Upload Invoice"
                                name={`meal.${index}.invoice`}
                                type="file"
                                onChange={(e) => handleInvoices(e, `meal.${index}.invoice`, setFieldValue)}
                                fullWidth
                                disabled={values.meal[index].status === "Verified" || values.meal[index].status === "Rejected"}
                                error={touched.meal?.[index]?.invoice && Boolean(errors.meal?.[index]?.invoice)}
                                helperText={touched.meal?.[index]?.invoice && errors.meal?.[index]?.invoice}
                              />
                              <TextField
                                label="Purpose"
                                name={`meal.${index}.purpose`}
                                value={values.meal[index].purpose}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.meal[index].status === "Verified" || values.meal[index].status === "Rejected"}
                                error={touched.meal?.[index]?.purpose && Boolean(errors.meal?.[index]?.purpose)}
                                helperText={touched.meal?.[index]?.purpose && errors.meal?.[index]?.purpose}
                              />
                            </div>
                          </div>
                        ))}
                        <Button
                          type="button"
                          variant="contained"
                          style={{marginRight:'2rem'}}
                          color="secondary"
                          startIcon={<Add />}
                          onClick={() => push(mealExp)}
                        >
                          Add Meal
                        </Button>
                      </>
                    )}
                  </FieldArray>

                  <FieldArray name="stay">
                    {({ push, remove }) => (
                      <>
                        {values.stay.map((_, index) => (
                          <div key={index} className={classes.entityWrapper}>
                            <div className={classes.formRow}>
                              <Typography variant="subtitle1">Stay {index + 1}</Typography>
                              <IconButton
                                className={classes.deleteButton}
                                disabled={values.stay[index].status === "Verified" || values.stay[index].status === "Rejected"}
                                onClick={() => remove(index)}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                            <div className={classes.formRow}>

                              <TextField
                                label="City Name"
                                name={`stay.${index}.cityname`}
                                select
                                value={values.stay[index].cityname}
                                onChange={(e) => handleCityChange(e, index, setFieldValue)}
                                fullWidth
                                disabled={values.stay[index].status === "Verified" || values.stay[index].status === "Rejected"}
                                error={touched.stay?.[index]?.cityname && Boolean(errors.stay?.[index]?.cityname)}
                                helperText={touched.stay?.[index]?.cityname && errors.stay?.[index]?.cityname}
                              >
                                {allCities?.map((stay) => (
                                  <MenuItem key={stay.cityName} value={stay.cityName}>
                                    {stay.cityType}-{stay.cityName}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                label="Start Date"
                                type="date"
                                onKeyDown={(e) => e.preventDefault()}
                                name={`stay.${index}.startDate`}
                                value={values.stay[index].startDate ? new Date(values.stay[index].startDate).toISOString().split('T')[0]:values.stay[index].startDate}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.stay[index].status === "Verified" || values.stay[index].status === "Rejected"}
                                InputLabelProps={{ shrink: true }}
                                error={touched.stay?.[index]?.startDate && Boolean(errors.stay?.[index]?.startDate)}
                                helperText={touched.stay?.[index]?.startDate && errors.stay?.[index]?.startDate}
                              />
                              <TextField
                                label="Duration"
                                select
                                name={`stay.${index}.duration`}
                                value={values.stay[index].duration}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.stay[index].status === "Verified" || values.stay[index].status === "Rejected"}
                                error={touched.stay?.[index]?.duration && Boolean(errors.stay?.[index]?.duration)}
                                helperText={touched.stay?.[index]?.duration && errors.stay?.[index]?.duration}
                              >
                                {/* Default selection */}
                                <MenuItem key="" value="">
                                  Select Duration
                                </MenuItem>

                                {/* Duration options */}
                                <MenuItem key="freshness" value={0.5}>
                                  Freshen up
                                </MenuItem>
                                <MenuItem key="oneNight" value={1}>
                                  One Night
                                </MenuItem>
                                <MenuItem key="twoNights" value={2}>
                                  Two Nights
                                </MenuItem>
                                <MenuItem key="threeNights" value={3}>
                                  Three Nights
                                </MenuItem>
                                <MenuItem key="fourNights" value={4}>
                                  Four Nights
                                </MenuItem>
                                <MenuItem key="fiveNights" value={5}>
                                  Five Nights
                                </MenuItem>
                              </TextField>

                              <TextField
                                label="Amount"
                                name={`stay.${index}.amount`}
                                value={values.stay[index].amount}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.stay[index].status === "Verified" || values.stay[index].status === "Rejected"}
                                error={touched.stay?.[index]?.amount && Boolean(errors.stay?.[index]?.amount)}
                                helperText={touched.stay?.[index]?.amount && errors.stay?.[index]?.amount}
                              />

                              <TextField
                                label="Upload Invoice"
                                name={`stay.${index}.invoice`}
                                type="file"
                                onChange={(e) => handleInvoices(e, `stay.${index}.invoice`, setFieldValue)}
                                fullWidth
                                disabled={values.stay[index].status === "Verified" || values.stay[index].status === "Rejected"}
                                error={touched.stay?.[index]?.invoice && Boolean(errors.stay?.[index]?.invoice)}
                                helperText={touched.stay?.[index]?.invoice && errors.stay?.[index]?.invoice}
                              />
                              <TextField
                                label="Purpose"
                                name={`stay.${index}.purpose`}
                                value={values.stay[index].purpose}
                                onChange={handleChange}
                                fullWidth
                                disabled={values.stay[index].status === "Verified" || values.stay[index].status === "Rejected"}
                                error={touched.stay?.[index]?.purpose && Boolean(errors.stay?.[index]?.purpose)}
                                helperText={touched.stay?.[index]?.purpose && errors.stay?.[index]?.purpose}
                              />
                            </div>
                          </div>
                        ))}
                        <Button
                          type="button"
                          variant="contained"
                          style={{marginRight:'2rem'}}
                          color="secondary"
                          startIcon={<Add />}
                          onClick={() => push(stayExp)}
                        >
                          Add Stay
                        </Button>
                      </>
                    )}
                  </FieldArray>
                  </div>
                  <div>
                    <TextField
                      label="Comments"
                      type="text"
                      name={`comment`}

                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div style={{
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                    <Button variant="contained" color="primary" type="submit" disabled={spinner}>
                      Submit{spinner && <span className="spinner-border spinner-border-sm"></span>}
                    </Button></div>
                </Form>
              }}
            </Formik>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default EmployeeRequestModal;
