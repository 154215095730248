import React from "react";
import {
  useFormikContext,
  Formik,
  Field,
  Form,
  ErrorMessage,
  getIn,
  useField,
} from "formik";
import * as Yup from "yup";
import { Button, Paper, Typography } from "@material-ui/core";
import { misService } from "../../_services";
import JobSheet from "./JobSheetCSB/JobSheet";
import Webcam from "react-webcam";
import { uploadFile } from "../../_helpers/upload";
import { branchService } from "../../_services/branch.service";
import { employeeService } from "../../_services";
import FormElement from "../uielements/FormElement";
import { State, City } from "country-state-city";
import { kaabilService } from "../../_services/kaabil.service";

const Form60Field = (props) => {
  const {
    values: { pan_no },
    setFieldValue,
  } = useFormikContext();
  const [field] = useField(props);

  React.useEffect(() => {
    // set the value of textC, based on textA and textB
    if (pan_no.trim() === "") {
      setFieldValue(props.name, "Yes");
    } else {
      setFieldValue(props.name, "No");
    }
  }, [pan_no, setFieldValue, props.name]);

  return <FormElement {...props} {...field}></FormElement>;
};

export default function LoanApplication(props) {
  const user = employeeService.userValue;
  const [totalOrnaments, setTotalOrnaments] = React.useState([]);
  const [stage, setStage] = React.useState(1);
  const [idpic, setIdpic] = React.useState(["", ""]);
  const [id, setId] = React.useState("aadhaar");
  const [verificationMessage, setVerificationMessage] = React.useState("");
  const itemsRef = React.useRef([]);
  const [loanno, setLoanno] = React.useState("");
  const [sameAddresses, setSameAddresses] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [loanDetails, setLoanDetails] = React.useState(null);

  const formikRef = React.useRef();
  const disabledInput = true;
  const query = new URLSearchParams(props.location.search);
  const renewOf = (query.get("renew_of") ? query.get("renew_of") : "");

  React.useEffect(() => {
    misService.getLoanNo(user.branch_id).then((result) => {
      setLoanno(result.loan_no);
    });
    branchService.getAll().then((branches) => {
      setBranches(branches);
    });
  }, [user]);

  React.useEffect(() => {
    if (renewOf) {
      kaabilService.getByLoanAccount(renewOf).then((tmpLDList) => {
        console.log(tmpLDList);
        let tmpLD = tmpLDList[0];
        setLoanDetails(tmpLD);
        if (formikRef.current) {
          tmpLD.sonof && formikRef.current.setFieldValue("father", tmpLD.sonof);
          tmpLD.father && formikRef.current.setFieldValue("father", tmpLD.father);
          tmpLD.mother && formikRef.current.setFieldValue("mother", tmpLD.mother);
          tmpLD.details && tmpLD.details.name && tmpLD.details.name.title && formikRef.current.setFieldValue("name.title", tmpLD.details.name.title);
          tmpLD.details && tmpLD.details.name && tmpLD.details.name.first_name && formikRef.current.setFieldValue("name.first_name", tmpLD.details.name.first_name);
          tmpLD.details && tmpLD.details.name && tmpLD.details.name.middle_name && formikRef.current.setFieldValue("name.middle_name", tmpLD.details.name.middle_name);
          tmpLD.details && tmpLD.details.name && tmpLD.details.name.last_name && formikRef.current.setFieldValue("name.last_name", tmpLD.details.name.last_name);
          tmpLD.dob && formikRef.current.setFieldValue("dob", tmpLD.dob);
          tmpLD.birth_place && formikRef.current.setFieldValue("birth_place", tmpLD.birth_place);
          tmpLD.gender && formikRef.current.setFieldValue("gender", tmpLD.gender);
          tmpLD.citizen && formikRef.current.setFieldValue("citizen", tmpLD.citizen);
          tmpLD.mother_tounge && formikRef.current.setFieldValue("mother_tounge", tmpLD.mother_tounge);
          tmpLD.communication_lang && formikRef.current.setFieldValue("communication_lang", tmpLD.communication_lang);
          tmpLD.category && formikRef.current.setFieldValue("category", tmpLD.category);
          tmpLD.education && formikRef.current.setFieldValue("education", tmpLD.education);
          tmpLD.physically_challenged && formikRef.current.setFieldValue("physically_challenged", tmpLD.physically_challenged);
          tmpLD.mentally_challenged && formikRef.current.setFieldValue("mentally_challenged", tmpLD.mentally_challenged);
          tmpLD.religion && formikRef.current.setFieldValue("religion", tmpLD.religion);
          tmpLD.marital_status && formikRef.current.setFieldValue("marital_status", tmpLD.marital_status);
          tmpLD.spouse && formikRef.current.setFieldValue("spouse", tmpLD.spouse);
          tmpLD.wedding_date && formikRef.current.setFieldValue("wedding_date", tmpLD.wedding_date);
          tmpLD.pan_no && formikRef.current.setFieldValue("pan_no", tmpLD.pan_no);
          tmpLD.adhaar_no && formikRef.current.setFieldValue("adhaar_no", tmpLD.adhaar_no);
          tmpLD.kyc && formikRef.current.setFieldValue("kyc", tmpLD.kyc);
          tmpLD.form60 && formikRef.current.setFieldValue("form60", tmpLD.form60);
          tmpLD.other_pid && formikRef.current.setFieldValue("other_pid", tmpLD.other_pid);
          tmpLD.permanent_address && tmpLD.permanent_address.address_type && formikRef.current.setFieldValue("permanent_address.address_type", tmpLD.permanent_address.address_type);
          tmpLD.permanent_address && tmpLD.permanent_address.address && formikRef.current.setFieldValue("permanent_address.address", tmpLD.permanent_address.address);
          tmpLD.permanent_address && tmpLD.permanent_address.state && formikRef.current.setFieldValue("permanent_address.state", tmpLD.permanent_address.state);
          tmpLD.permanent_address && tmpLD.permanent_address.district && formikRef.current.setFieldValue("permanent_address.district", tmpLD.permanent_address.district);
          tmpLD.permanent_address && tmpLD.permanent_address.city && formikRef.current.setFieldValue("permanent_address.city", tmpLD.permanent_address.city);
          tmpLD.permanent_address && tmpLD.permanent_address.pin_code && formikRef.current.setFieldValue("permanent_address.pin_code", tmpLD.permanent_address.pin_code);
          tmpLD.gold_packet && formikRef.current.setFieldValue("gold_packet", tmpLD.gold_packet);
        }
      })
        .catch((err) => {
          console.log("error getting loan details");
        })
    }
  }, [renewOf]);

  React.useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "loan_account",
        "KFPLGL" +
        user.branch_id.slice(0, -3) +
        loanno.toString().padStart(5, 0)
      );

    }
  }, [loanno, user]);

  const SetValuationDependent = () => {
    const { values, setFieldValue } = useFormikContext();
    React.useEffect(() => {
      setFieldValue("loan_amount", values.gold.valuation);
      setFieldValue("loan_request", values.gold.valuation);
      setCharges(values, setFieldValue);
    }, [values.gold.valuation]);
    return null;
  };

  const SetSanctionDependent = () => {
    const { values, setFieldValue } = useFormikContext();
    React.useEffect(() => {
      updateDisburse(values, setFieldValue);
    }, [values, setFieldValue]);
    return null;
  };

  React.useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, totalOrnaments.length);
  }, [totalOrnaments]);

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };

  const IDvideoConstraints = {
    width: 350,
    height: 230,
    facingMode: "user",
  };

  let initialValues = {
    date: new Date(),
    name: {
      title: "Mr",
      first_name: "",
      middle_name: "",
      last_name: "",
    },
    gender: "Male",
    father: "",
    mother: "",
    dob: "",
    citizenship: "Indian",
    birth_place: "",
    adhaar_no: "",
    pan_no: "",
    form60: "Yes",
    other_pid: "",
    kyc: "",
    marital_status: "Married",
    spouse: "",
    wedding_date: "",
    mother_tounge: "Hindi",
    communication_lang: "Hindi",
    permanent_address: {
      address_type: "Residential",
      address: "",
      city: "",
      district: "Jaipur",
      state: "RJ",
      pin_code: "",
    },
    correspondence_address: {
      address_type: "Residential",
      address: "",
      city: "",
      district: "Jaipur",
      state: "RJ",
      pin_code: "",
    },
    contact: {
      mobile: "",
      phone: "",
      email: "",
    },
    religion: "Hindu",
    category: "General",
    education: "Below SSC",
    physically_challenged: "No",
    mentally_challenged: "No",
    annual_income: "<1 Lakh",
    occupation_type: "Private Sector",
    income_source: "None",
    organisation: "None",
    designation: "",
    net_worth: "",
    assets: {
      land: "",
      machinery: "",
      investment: "",
      stock: "",
      gold: "",
      income: "",
      term_deposits: "",
      other_assets: "",
      total_assets: "",
    },
    liabilities: {
      csb_borrowings: "",
      other_borrowings: "",
      other_liabilities: "",
      total_liabilities: "",
      total_net_worth: "",
    },
    branch_name: user.branch.branch_name,
    branch_code: user.branch_id,
    loan_account: "",
    client_id: "",
    product: "Gold Loan",
    period: "",
    roi: "",
    penal_roi: "",
    disbursement_mode: "Cash",
    purpose: "",
    agri_purpose: "",
    bank_account: {
      bank_name: "",
      account_no: "",
      ifsc_code: "",
      acc_holder: "",
    },
    gold: {
      ornaments: [],
      gross_weight: "",
      net_weight: "",
      total_items: "",
      deduction: "",
      valuation: "",
      eligible_advance: "",
      rpg: "",
    },
    gold_pics: [],
    customer_pic: "",
    gold_pic: "",
    loan_request: "",
    loan_amount: "",
    external_loans: "",
    external_appraisal: "",
    gold_packet: "",
    source_code: "",
    approved: false,

    // Extras
    pf_charge: 0.5,
    pf: 0,
    stamp_duty: 0,
    auction_date: "",
    closure_due_date: "",
    emi: "",
    emi_due_date: new Date().addDays(29).toISOString().substring(0, 10),
    payment_frequency: "EVERY_30_DAYS",
    disburse_amount: "",
    renew_of: renewOf,
    bank: "CSB",
  };

  const validationSchema = () => {
    if (stage === 1) {
      return Yup.object().shape({
        loan_account: Yup.string().required("Required"),
        name: Yup.object().shape({
          title: Yup.string().required("Required"),
          first_name: Yup.string().required("Required"),
          middle_name: Yup.string(),
          last_name: Yup.string().required("Required"),
        }),
        gender: Yup.string().required("Required"),
        father: Yup.string().required("Required"),
        mother: Yup.string().required("Required"),
        dob: Yup.date().required("Required"),
        citizenship: Yup.string().required("Required"),
        birth_place: Yup.string().required("Required"),
        adhaar_no: Yup.string().length(12, 'Must be 12 digit and only numbers').matches(/^[0-9]{12}/).required("Required"),
        pan_no: Yup.string().length(10, 'Must be in proper format').matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Must be in proper format' ),
        form60: Yup.string(),
        other_pid: Yup.string(),
        kyc: Yup.string().required("Required"),
        marital_status: Yup.string().required("Required"),
        spouse: Yup.string().when("marital_status", {
          is: "Married", //just an e.g. you can return a function
          then: Yup.string().required("Field is required"),
          otherwise: Yup.string(),
        }),
        wedding_date: Yup.string().when("marital_status", {
          is: "Married", //just an e.g. you can return a function
          then: Yup.string(),
          otherwise: Yup.string(),
        }),
        mother_tounge: Yup.string().required("Required"),
        communication_lang: Yup.string().required("Required"),
        religion: Yup.string().required("Required"),
        category: Yup.string().required("Required"),
        education: Yup.string().required("Required"),
        physically_challenged: Yup.string().required("Required"),
        mentally_challenged: Yup.string().required("Required"),
      });
    } else if (stage === 2) {
      return Yup.object().shape({
        permanent_address: Yup.object().shape({
          address_type: Yup.string().required("Required"),
          address: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
          district: Yup.string().required("Required"),
          state: Yup.string().required("Required"),
          pin_code: Yup.string().length(6, 'Must be 6 digit and only numbers').matches(/^[0-9]{6}/).required("Required"),
        }),
        correspondence_address: Yup.object().shape({
          address_type: Yup.string().required("Required"),
          address: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
          district: Yup.string().required("Required"),
          state: Yup.string().required("Required"),
          pin_code: Yup.string().length(6, 'Must be 6 digit and only numbers').matches(/^[0-9]{6}/).required("Required"),
        }),
        contact: Yup.object().shape({
          mobile: Yup.string().length(10, 'Must be 10 digit and only numbers').matches(/^[0-9]{10}/).required("Required"),
          phone: Yup.string().length(10, 'Must be 10 digit and only numbers').matches(/^[0-9]{10}/),
          email: Yup.string().email('Must be a valid email'),
        }),
        annual_income: Yup.string().required("Required"),
        occupation_type: Yup.string().required("Required"),
        income_source: Yup.string().required("Required"),
        organisation: Yup.string().required("Required"),
        net_worth: Yup.number().required("Required"),
      });
    } else if (stage === 3) {
      return Yup.object().shape({
        assets: Yup.object().shape({
          land: Yup.string(),
          machinery: Yup.string(),
          investment: Yup.string(),
          stock: Yup.string(),
          gold: Yup.string(),
          income: Yup.string(),
          term_deposits: Yup.string(),
          other_assets: Yup.string(),
          total_assets: Yup.number(),
        }),
        liabilities: Yup.object().shape({
          csb_borrowings: Yup.string(),
          other_borrowings: Yup.string(),
          other_liabilities: Yup.string(),
          total_liabilities: Yup.number(),
          total_net_worth: Yup.number(),
        }),
      });
    } else {
      return Yup.object().shape({
        branch_name: Yup.string().required("Required"),
        branch_code: Yup.string().required("Required"),
        disbursement_mode: Yup.string().required("Required"),
        bank_account: Yup.object().shape({
          bank_name: Yup.string(),
          account_no: Yup.string(),
          ifsc_code: Yup.string(),
          acc_holder: Yup.string(),
        }),
        client_id: Yup.string(),
        product: Yup.string(),
        period: Yup.number().required("Required"),
        roi: Yup.number().required("Required"),
        gold: Yup.object().shape({
          rpg: Yup.number().required("Required"),
        }),
        emi: Yup.number().required("Required"),
        penal_roi: Yup.number().required("Required"),
        purpose: Yup.string().required("Required"),
        loan_request: Yup.number().required("Required"),
        loan_amount: Yup.number().required("Required"),
        external_loans: Yup.string(),
        gold_packet: Yup.string().required("Required")
          .min(8, "minimum 8 digit needed")
          .max(9, "maximum 9 digit allowed"),
        emi_due_date: Yup.date().required("This field is required"),
        closure_due_date: Yup.date().required("This field is required"),
        auction_date: Yup.date().required("This field is required"),
        pf: Yup.number().required("This field is required"),
        payment_frequency: Yup.string().required("This field is required"),
        disburse_amount: Yup.number().required("This field is required"),
      });
    }
  };

  const frontidcamRef = React.useRef(null);
  const backidcamRef = React.useRef(null);
  const custcamRef = React.useRef(null);
  const goldcamRef = React.useRef(null);

  const capture = React.useCallback(
    async (setFieldValue, target, targetcam, loan_no) => {
      var imageSrc;

      if (!loan_no) {
        alert("Please Enter Loan Number");
        return;
      }

      if (targetcam === "cust") imageSrc = custcamRef.current.getScreenshot();
      else imageSrc = goldcamRef.current.getScreenshot();

      const link = await uploadFile(`${loan_no}/${target}.jpg`, imageSrc);

      setFieldValue(target, link);
    },
    [custcamRef, goldcamRef]
  );

  const captureGold = async (setFieldValue, idx, loan_no) => {
    if (!loan_no) {
      alert("Please Enter Loan Number");
      return;
    }

    var imageSrc = itemsRef.current[idx].getScreenshot();

    const link = await uploadFile(`${loan_no}/ornament${idx}.jpg`, imageSrc);

    var newtotal = [...totalOrnaments];

    newtotal[idx].image = link;

    setTotalOrnaments(newtotal);
    setFieldValue("gold_pics", newtotal);
  };

  const captureFrontId = async (loan_no) => {
    if (!loan_no) {
      alert("Please Enter Loan Number");
      return;
    }

    var imageSrc = frontidcamRef.current.getScreenshot();

    const link = await uploadFile(`${loan_no}/frontID.jpg`, imageSrc);

    const tempArr = [...idpic];
    tempArr[0] = link;

    setIdpic(tempArr);
  };

  const captureBackId = async (loan_no) => {
    if (!loan_no) {
      alert("Please Enter Loan Number");
      return;
    }

    var imageSrc = backidcamRef.current.getScreenshot();

    const link = await uploadFile(`${loan_no}/backID.jpg`, imageSrc);

    const tempArr = [...idpic];
    tempArr[1] = link;

    setIdpic(tempArr);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function setAuctionClosure(vals, setFieldValue) {
    setFieldValue(
      "auction_date",
      new Date(new Date().setMonth(new Date().getMonth() + vals.period))
        .toISOString()
        .substring(0, 10)
    );
    setFieldValue(
      "closure_due_date",
      new Date(new Date().setMonth(new Date().getMonth() + vals.period))
        .toISOString()
        .substring(0, 10)
    );
  }

  function verifyid(itemid, token, inp) {
    var info;

    if (id === "pan")
      info = {
        name: inp.name,
        number: inp.number,
        fuzzy: "true",
      };
    else
      info = {
        number: inp.number,
        dob: inp.dob,
        issueDate: inp.issueDate,
      };

    fetch("https://signzy.tech/api/v2/snoops", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        service: "Identity",
        itemId: itemid,
        accessToken: token,
        task: "verification",
        essentials: info,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        setVerificationMessage(data.response.result.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const autofiller = (data, setFieldValue) => {
    if (id === "pan") {
      const name = data.name.split(" ");
      setFieldValue("name.first_name", name[0]);
      if (name.length >= 3) {
        setFieldValue("name.middle_name", name[1]);
        setFieldValue("name.last_name", name[2]);
      } else setFieldValue("name.last_name", name[1]);

      setFieldValue("father", data.fatherName);
      setFieldValue("pan_no", data.number);
      setFieldValue("dob", data.dob.split("/").reverse().join("-"));
    } else if (id === "aadhaar") {
      const name = data.name.split(" ");
      setFieldValue("name.first_name", name[0]);
      if (name.length >= 3) {
        setFieldValue("name.middle_name", name[1]);
        setFieldValue("name.last_name", name[2]);
      } else setFieldValue("name.last_name", name[1]);
      if (data.gender === "female" || data.gender === "male") {
        setFieldValue("gender", capitalizeFirstLetter(data.gender));
      }
      setFieldValue("father", data.summary.guardianName);
      setFieldValue("adhaar_no", data.uid);
      setFieldValue("permanent_address.address", data.splitAddress.addressLine);
      setFieldValue("permanent_address.city", data.splitAddress.city[0]);
      setFieldValue(
        "permanent_address.district",
        data.splitAddress.district[0]
      );
      setFieldValue("permanent_address.state", data.splitAddress.state[0][0]);
      setFieldValue("permanent_address.pin_code", data.splitAddress.pincode);
      setFieldValue("dob", data.dob.split("/").reverse().join("-"));
      setFieldValue("birth_place", data.splitAddress.district[0]);
    } else {
      const name = data.name.split(" ");
      setFieldValue("name.first_name", name[0]);
      if (name.length >= 3) {
        setFieldValue("name.middle_name", name[1]);
        setFieldValue("name.last_name", name[2]);
      } else setFieldValue("name.last_name", name[1]);
      setFieldValue("dob", data.dob.split("/").reverse().join("-"));
      setFieldValue("father", data.guardianName);
      setFieldValue("permanent_address.address", data.splitAddress.addressLine);
      setFieldValue("permanent_address.city", data.splitAddress.city[0]);
      setFieldValue(
        "permanent_address.district",
        data.splitAddress.district[0]
      );
      setFieldValue("permanent_address.state", data.splitAddress.state[0][0]);
      setFieldValue("permanent_address.pin_code", data.splitAddress.pincode);
      setFieldValue("birth_place", data.splitAddress.district[0]);
    }
  };

  const fetchDetails = async (itemid, token, setFieldValue) => {
    fetch("https://signzy.tech/api/v2/snoops", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        service: "Identity",
        itemId: itemid,
        accessToken: token,
        task: "autoRecognition",
        essentials: {},
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        autofiller(data.response.result, setFieldValue);
        if (id === "pan" || id === "dl")
          verifyid(itemid, token, data.response.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchIdentity = async (setFieldValue) => {
    var idname = "";
    var imgarr = [];

    if (id === "pan") {
      idname = "individualPan";
      imgarr = [""];
      imgarr[0] = idpic[0];
    } else if (id === "aadhaar") {
      idname = "aadhaar";
      imgarr = [...idpic];
    } else {
      idname = "drivingLicence";
      imgarr = [...idpic];
    }

    fetch(
      "https://signzy.tech/api/v2/patrons/5f432a53a748d43a72a10fed/identities",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization:
            process.env.REACT_APP_SIGNZY_API_KEY,
        },
        body: JSON.stringify({
          type: idname,
          callbackUrl: "https://samruddhi.kaabilfinance.com",
          email: "dewanshu@kaabilfinance.com",
          images: imgarr,
        }),
      }
    )
      .then(async (response) => {
        const data = await response.json();
        fetchDetails(data.id, data.accessToken, setFieldValue);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const discardGold = (setFieldValue, idx) => {
    var newtotal = [...totalOrnaments];
    newtotal[idx].image = "";
    setTotalOrnaments(newtotal);
    setFieldValue("gold_pics", newtotal);
  };

  const discard = React.useCallback((setFieldValue, target) => {
    setFieldValue(target, "");
  }, []);

  const discardId = (side) => {
    var i = 0;

    if (side === "backside") i = 1;

    const tempArr = [...idpic];
    tempArr[i] = "";

    setIdpic(tempArr);
  };

  const setCorrespondence = (e, setFieldValue, values) => {
    if (e.target.checked) {
      setSameAddresses(true);
      setFieldValue(
        "correspondence_address.address_type",
        values.permanent_address.address_type
      );
      setFieldValue(
        "correspondence_address.address",
        values.permanent_address.address
      );
      setFieldValue(
        "correspondence_address.city",
        values.permanent_address.city
      );
      setFieldValue(
        "correspondence_address.state",
        values.permanent_address.state
      );
      setFieldValue(
        "correspondence_address.district",
        values.permanent_address.district
      );
      setFieldValue(
        "correspondence_address.pin_code",
        values.permanent_address.pin_code
      );
    } else {
      setSameAddresses(false);
      setFieldValue("correspondence_address.address_type", "");
      setFieldValue("correspondence_address.address", "");
      setFieldValue("correspondence_address.city", "");
      setFieldValue("correspondence_address.state", "RJ");
      setFieldValue("correspondence_address.district", "Jaipur");
      setFieldValue("correspondence_address.pin_code", "");
    }
  };

  function updateDisburse(vals, setFieldValue) {
    let pf
    {
      renewOf ? pf = Math.round(vals.loan_amount*.0035) : pf = Math.ceil(vals.loan_amount*.006)
    }
    setFieldValue('pf',pf )
    setFieldValue("disburse_amount", Math.round(vals.loan_amount - vals.pf));
    let emi = Math.round(vals.loan_amount*vals.roi/100);
    setFieldValue('emi',emi);
  }

  function setCharges(vals, setFieldValue) {
    const pf = Math.ceil(vals.gold.valuation / 50000) * 250;
    setFieldValue("pf", pf);

    setFieldValue("disburse_amount", Math.round(vals.gold.valuation - pf));
  }

  function setGoldPacket(vals, setFieldValue) {
    setFieldValue("gold_packet", vals.loan_account);
  }

  async function onSubmit(fields, { setSubmitting }) {
    if (stage !== 4) setStage(stage + 1);
    else if (fields.gold.ornaments.length === 0) alert("Please Add Gold Items");
    else if (!fields.customer_pic) alert("Please Upload Customer Picture");
    else if (!fields.gold_pic) alert("Please Upload Ornaments Picture");
    else if (fields.gold.ornaments.length !== fields.gold_pics.length)
      alert("Please Capture all Ornaments");
    else {
      await kaabilService
        .create({
          ...fields,
          total_items: fields.gold.total_items,
          valuation: fields.gold.valuation,
          net_weight: fields.gold.net_weight,
          deduction: fields.gold.deduction,
          bank_name: fields.bank_account.bank_name,
          ornaments: fields.gold.ornaments,
          rpg: fields.gold.rpg,
          contact: fields.contact.mobile,
          name:
            fields.name.first_name +
            " " +
            fields.name.middle_name +
            " " +
            fields.name.last_name,
          address: fields.correspondence_address.address,
          gross_weight: fields.gold.gross_weight,
          sonof: fields.father,
          details: fields,
          loan_status: "Disbursed",
        })
        .then((loan_ac) =>{
          console.log(loan_ac);
          props.history.push("/home/application/print", fields)
        }) 
        .catch((err) => {
          console.log(err);
          alert("An Error Occured. Please Try Again.");
        });
      setSubmitting(false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue }) => (
        <Form>
          <SetValuationDependent />
          <SetSanctionDependent />
          {/* <SetPermanentStateDependent />
          <SetCorrespondenceStateDependent /> */}
          <h2 className="card-header">Submit Details</h2>
          <div className="card-body">
            {stage === 1 && (
              <div>
                <div className="form-group col-md-12">
                  <div className="d-flex justify-content-between">
                    <Typography style={{ marginTop: "2%" }} variant="h4">
                      Customer Profile Form
                    </Typography>
                    <div className="form-group col-md-3">
                      <Field
                        placeholder="Loan Number"
                        name="loan_account"
                        type="text"
                        className={
                          "form-control" +
                          (errors.loan_account && touched.loan_account
                            ? " is-invalid"
                            : "")
                        }
                        disabled={disabledInput}
                      />
                      <ErrorMessage
                        name="loan_account"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <br />
                  <Paper
                    style={{
                      padding: 20,
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <Typography variant="h5">Upload ID</Typography>
                      <div>
                        <input
                          style={{ marginRight: 5 }}
                          type="radio"
                          id="aadhaar"
                          name="id"
                          value="aadhaar"
                          checked={id === "aadhaar"}
                          onChange={() => setId("aadhaar")}
                        />
                        <label style={{ marginRight: 10 }} htmlFor="aadhaar">
                          Aadhaar
                        </label>
                        <input
                          style={{ marginRight: 5 }}
                          type="radio"
                          id="pan"
                          name="id"
                          value="pan"
                          checked={id === "pan"}
                          onChange={() => setId("pan")}
                        />
                        <label style={{ marginRight: 10 }} htmlFor="pan">
                          PAN
                        </label>
                        <input
                          style={{ marginRight: 5 }}
                          type="radio"
                          id="dl"
                          name="id"
                          value="dl"
                          checked={id === "dl"}
                          onChange={() => setId("dl")}
                        />
                        <label htmlFor="dl">DL</label>
                      </div>
                      <div>
                        <Button
                          variant="outlined"
                          onClick={() => fetchIdentity(setFieldValue)}
                        >
                          Fetch Details
                        </Button>
                      </div>
                    </div>
                    <br />
                    <div className="d-flex justify-content-around">
                      <div>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="h5"
                        >
                          Front Side
                        </Typography>
                        <div className="d-flex justify-content-center">
                          {idpic[0] === "" ? (
                            <Webcam
                              height={230}
                              audio={false}
                              ref={frontidcamRef}
                              screenshotFormat="image/jpeg"
                              width={350}
                              videoConstraints={IDvideoConstraints}
                            />
                          ) : (
                            <img
                              src={idpic[0]}
                              height="230"
                              width="350"
                              alt=""
                            ></img>
                          )}
                        </div>
                        <br />
                        <div className="d-flex justify-content-around">
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={idpic[0] ? true : false}
                            type="button"
                            onClick={() => captureFrontId(values.loan_account)}
                          >
                            Capture Photo
                          </Button>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() => discardId("frontside")}
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                      <div>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="h5"
                        >
                          Back Side (Not required for PAN)
                        </Typography>
                        <div className="d-flex justify-content-center">
                          {idpic[1] === "" && id !== "pan" ? (
                            <Webcam
                              height={230}
                              audio={false}
                              ref={backidcamRef}
                              screenshotFormat="image/jpeg"
                              width={350}
                              videoConstraints={IDvideoConstraints}
                            />
                          ) : (
                            <img
                              src={idpic[1]}
                              height="230"
                              width="350"
                              alt=""
                            ></img>
                          )}
                        </div>
                        <br />
                        <div className="d-flex justify-content-around">
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={idpic[1] || id === "pan"}
                            type="button"
                            onClick={() => captureBackId(values.loan_account)}
                          >
                            Capture Photo
                          </Button>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() => discardId("backside")}
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <b>{verificationMessage}</b>
                  </Paper>
                  <Paper
                    style={{
                      padding: 20,
                      marginTop: 20,
                    }}
                  >
                    <Typography variant="h5">Personal Details</Typography>
                    <br />
                    <div className="d-flex justify-content-between">
                      <div className="form-group col-md-2">
                        <label>Title</label>
                        <Field
                          name="name.title"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                        </Field>
                      </div>
                      <div className="form-group col-md-4">
                        <FormElement
                          label="First Name"
                          name="name.first_name"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-2">
                        <FormElement
                          label="Middle Name"
                          name="name.middle_name"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-4">
                        <FormElement
                          label="Last Name"
                          name="name.last_name"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Father's Name"
                          name="father"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Mother's Name"
                          name="mother"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Date of Birth"
                          name="dob"
                          type="date"
                          onKeyDown={(e) => e.preventDefault()}
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Place of Birth"
                          name="birth_place"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-group col-md-3">
                        <label>Gender</label>
                        <Field
                          name="gender"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </Field>
                      </div>
                      <div className="form-group col-md-3">
                        <label>Citizenship</label>
                        <Field
                          name="citizen"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="Indian">Indian</option>
                          <option value="Others">Others</option>
                        </Field>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Mother Tounge"
                          name="mother_tounge"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Preferred Communication Language"
                          name="communication_lang"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-group col-md-4">
                        <label>Category</label>
                        <Field
                          name="category"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="General">General</option>
                          <option value="OBC">OBC</option>
                          <option value="SC">SC</option>
                          <option value="ST">ST</option>
                          <option value="Weaker Section">Weaker Section</option>
                        </Field>
                      </div>
                      <div className="form-group col-md-4">
                        <label>Educational Qualification</label>
                        <Field
                          name="education"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="Below SSC">Below SSC</option>
                          <option value="SSC">SSC</option>
                          <option value="HSC">HSC</option>
                          <option value="Graduate">Graduate</option>
                          <option value="Masters">Masters</option>
                          <option value="Professional">
                            Professional (CA,CS,CMA,Others)
                          </option>
                          <option value="Illiterate">Illiterate</option>
                        </Field>
                      </div>
                      <div className="form-group col-md-2">
                        <label>Physically Challanged</label>
                        <Field
                          name="physically_challenged"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Field>
                      </div>
                      <div className="form-group col-md-2">
                        <label>Mentally Challanged</label>
                        <Field
                          name="mentally_challenged"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Field>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="form-group col-md-3">
                        <label>Religion</label>
                        <Field
                          name="religion"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="Hindu">Hindu</option>
                          <option value="Muslim">Muslim</option>
                          <option value="Christian">Christian</option>
                          <option value="Sikh">Sikh</option>
                          <option value="Others">Others</option>
                        </Field>
                      </div>
                      <div className="form-group col-md-3">
                        <label>Marital Status</label>
                        <Field
                          name="marital_status"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                        </Field>
                      </div>
                      {values.marital_status === "Married" && (
                        <React.Fragment>
                          <div className="form-group col-md-3">
                            <FormElement
                              label="Name of Spouse"
                              name="spouse"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-3">
                            <FormElement
                              label="Wedding Date"
                              name="wedding_date"
                              type="date"
                              onKeyDown={(e) => e.preventDefault()}
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      padding: 20,
                      marginTop: 30,
                    }}
                  >
                    <Typography variant="h5">Identification</Typography>
                    <br />
                    <div className="d-flex justify-content-between">
                      <div className="form-group col-md-4">
                        <FormElement
                          label="PAN No."
                          name="pan_no"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-4">
                        <FormElement
                          label="Address/ID Proof"
                          name="kyc"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-4">
                        <FormElement
                          label="Adhaar No."
                          name="adhaar_no"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="form-group col-md-4">
                        <Form60Field
                          label="Form 60"
                          type="text"
                          errors={errors}
                          touched={touched}
                          name="form60"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <FormElement
                          label="Other PID"
                          name="other_pid"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                  </Paper>
                </div>
                <div className="form-row">
                  <div className="form-group col d-flex justify-content-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
            {stage === 2 && (
              <div>
                <Paper
                  style={{
                    padding: 20,
                  }}
                >
                  <Typography variant="h5">Address</Typography>
                  <br />
                  <Typography variant="h6">Permanent</Typography>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <label>Address Type</label>
                      <Field
                        name="permanent_address.address_type"
                        as="select"
                        className={"form-control"}
                      >
                        <option value="Residential">Residential</option>
                        <option value="Residential/Business">
                          Residential/Business
                        </option>
                        <option value="Business">Business</option>
                        <option value="Registered Office">
                          Registered Office
                        </option>
                        <option value="Residential">Unspecified</option>
                      </Field>
                    </div>
                    <div className="form-group col-md-8">
                      <FormElement
                        label="Address"
                        name="permanent_address.address"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-3">
                      <label>State</label>
                      <Field
                        name="permanent_address.state"
                        as="select"
                        className={"form-control"}
                      >
                        {State.getStatesOfCountry("IN").map((item) => (
                          <option
                            selected={item.isoCode === "RJ"}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group col-md-3">
                      <label>District</label>
                      <Field
                        name="permanent_address.district"
                        as="select"
                        className={"form-control"}
                      >
                        {City.getCitiesOfState(
                          "IN",
                          values.permanent_address.state
                        ).map((item) => (
                          <option
                            selected={item.name === "Jaipur"}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group col-md-3">
                      <FormElement
                        label="City"
                        name="permanent_address.city"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-3">
                      <FormElement
                        label="Pin Code"
                        name="permanent_address.pin_code"
                        type="number"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex justify-content-between">
                    <Typography variant="h6">Correspondence</Typography>
                    <div>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setCorrespondence(e, setFieldValue, values)
                        }
                      />
                      <label>&nbsp;Same as above</label>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <label>Address Type</label>
                      <Field
                        disabled={sameAddresses}
                        name="correspondence_address.address_type"
                        as="select"
                        className={"form-control"}
                      >
                        <option value="Residential">Residential</option>
                        <option value="Residential/Business">
                          Residential/Business
                        </option>
                        <option value="Business">Business</option>
                        <option value="Registered Office">
                          Registered Office
                        </option>
                        <option value="Residential">Unspecified</option>
                      </Field>
                    </div>
                    <div className="form-group col-md-8">
                      <FormElement
                        disabled={sameAddresses}
                        label="Address"
                        name="correspondence_address.address"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-3">
                      <label>State</label>
                      <Field
                        disabled={sameAddresses}
                        name="correspondence_address.state"
                        as="select"
                        className={"form-control"}
                      >
                        {State.getStatesOfCountry("IN").map((item) => (
                          <option
                            selected={item.isoCode === "RJ"}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group col-md-3">
                      <label>District</label>
                      <Field
                        disabled={sameAddresses}
                        name="correspondence_address.district"
                        as="select"
                        className={"form-control"}
                      >
                        {City.getCitiesOfState(
                          "IN",
                          values.correspondence_address.state
                        ).map((item) => (
                          <option
                            selected={item.name === "Jaipur"}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group col-md-3">
                      <FormElement
                        disabled={sameAddresses}
                        label="City"
                        name="correspondence_address.city"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-3">
                      <FormElement
                        disabled={sameAddresses}
                        label="Pin Code"
                        name="correspondence_address.pin_code"
                        type="number"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                </Paper>
                <Paper
                  style={{
                    padding: 20,
                    marginTop: 30,
                  }}
                >
                  <Typography variant="h5">Contact Details</Typography>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Mobile No."
                        name="contact.mobile"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Phone No."
                        name="contact.phone"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Email ID"
                        name="contact.email"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                </Paper>
                <Paper
                  style={{
                    padding: 20,
                    marginTop: 30,
                  }}
                >
                  <Typography variant="h5">Employment Details</Typography>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <label>Annual Income</label>
                      <Field
                        name="annual_income"
                        as="select"
                        className={"form-control"}
                      >
                        <option value="<1 Lakh">Less than 1 Lakh</option>
                        <option value="1 to <5 Lakh">1 to 5 Lakh</option>
                        <option value="5 to <10 Lakh">5 to 10 Lakh</option>
                        <option value="10 to <25 Lakh">10 to 25 Lakh</option>
                        <option value=">25 Lakh">25 Lakh and above</option>
                      </Field>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Occupation Type</label>
                      <Field
                        name="occupation_type"
                        as="select"
                        className={"form-control"}
                      >
                        <option value="Private Sector">Private Sector</option>
                        <option value="Public Sector">Public Sector</option>
                        <option value="Govt Sector">Govt Sector</option>
                        <option value="Business">Business</option>
                        <option value="Profession">Profession</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Retired">Retired</option>
                        <option value="Housewife">Housewife</option>
                        <option value="Traders">Traders</option>
                        <option value="Student">Student</option>
                        <option value="Others">Others</option>
                      </Field>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Source of Income</label>
                      <Field
                        name="income_source"
                        as="select"
                        className={"form-control"}
                      >
                        <option value="None">None</option>
                        <option value="Salary">Salary</option>
                        <option value="Business">Business</option>
                        <option value="Professional">Professional</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="Pension">Pension</option>
                        <option value="Others">Others</option>
                      </Field>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Organisation"
                        name="organisation"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Designation"
                        name="designation"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Net Worth(₹)"
                        name="net_worth"
                        type="number"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                </Paper>
                <div style={{ marginTop: 10 }} className="form-row">
                  <div className="form-group col d-flex justify-content-center">
                    <button
                      style={{ marginRight: 10 }}
                      onClick={() => setStage(stage - 1)}
                      className="btn btn-primary"
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
            {stage === 3 && (
              <div>
                <Paper
                  style={{
                    padding: 20,
                  }}
                >
                  <Typography variant="h5">Assets</Typography>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Land & Building (Property Wise Value)"
                        name="assets.land"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Plant and Machinery"
                        name="assets.machinery"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Investment in shares (Institution & amount wise reqd)"
                        name="assets.investment"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Stock in Trade (Mandatory in case of Traders)"
                        name="assets.stock"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Value of Gold (Wt & Amt)"
                        name="assets.gold"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Income"
                        name="assets.income"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Term Disposits"
                        name="assets.term_deposits"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Other Assets"
                        name="assets.other_assets"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Total Assets(₹)"
                        name="assets.total_assets"
                        type="number"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                </Paper>
                <Paper
                  style={{
                    padding: 20,
                    marginTop: 30,
                  }}
                >
                  <Typography variant="h5">Liabilities</Typography>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Borrowings from CSB"
                        name="liabilities.csb_borrowings"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Borrowings from Other Banks/FI (Name of the Institution
                        and Amt is to be specified seperately)"
                        name="liabilities.other_borrowings"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Other Liabilities(Pls Specify)"
                        name="liabilities.other_liabilities"
                        type="text"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around">
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Total Liabilities(₹)"
                        name="liabilities.total_liabilities"
                        type="number"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                    <div className="form-group col-md-4">
                      <FormElement
                        label="Total Net Worth(₹)"
                        name="liabilities.total_net_worth"
                        type="number"
                        errors={errors}
                        touched={touched}
                      ></FormElement>
                    </div>
                  </div>
                </Paper>
                <div style={{ marginTop: 10 }} className="form-row">
                  <div className="form-group col d-flex justify-content-center">
                    <button
                      style={{ marginRight: 10 }}
                      onClick={() => setStage(stage - 1)}
                      className="btn btn-primary"
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}

            {stage === 4 && (
              <div>
                <div className="form-group col-md-12">
                  <Typography variant="h4">
                    Gold Loan Application cum Ledger Form{" "}
                  </Typography>
                  <br />
                  <Paper
                    style={{
                      padding: 20,
                    }}
                  >
                    <Typography variant="h5">Loan Details</Typography>
                    <br />
                    <div className="d-flex">
                      <div style={{ width: "80%" }}>
                        <div className="d-flex">
                          <div className="form-group col-md-6">
                            <label>Branch Name</label>
                            <Field
                              name="branch_name"
                              as="select"
                              className={
                                "form-control" +
                                (errors.branch_name && touched.branch_name
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              {branches.map((branch) => (
                                <option
                                  key={branch.branch_id}
                                  value={branch.branch_name}
                                >
                                  {branch.branch_name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="branch_name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group col-md-6">
                            <label>Branch Code</label>
                            <Field
                              name="branch_code"
                              as="select"
                              className={
                                "form-control" +
                                (errors.branch_code && touched.branch_code
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              {branches.map((branch) => (
                                <option
                                  key={branch.branch_id}
                                  value={branch.branch_id}
                                >
                                  {branch.branch_id}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="branch_code"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group col-md-4">
                            <FormElement
                              label="Client ID"
                              name="client_id"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-4">
                            <FormElement
                              label="Product"
                              name="product"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-4">
                            <div>
                              <label>Tenure (Months)</label>
                              <Field
                                name="period"
                                as='select'
                                className={
                                  "form-control" +
                                  (getIn(errors, "period") &&
                                    getIn(touched, "period")
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyUp={() =>
                                  setAuctionClosure(values, setFieldValue)
                                }
                              >
                                <option value=''>
                                </option>
                                <option value='3'>
                                  3
                                </option>
                                <option value='6'>
                                  6
                                </option>
                                <option value='9'>
                                  9
                                </option>
                                <option value='12'>
                                  12
                                </option>
                              </Field>
                              <ErrorMessage
                                name="period"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group col-md-4">
                            <FormElement
                              label="Interest Rate (%)"
                              name="roi"
                              type="number"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-4">
                            <FormElement
                              label="Penal Interest Rate (%)"
                              name="penal_roi"
                              type="number"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-4">
                            <label>Disbursement Mode</label>
                            <Field
                              name="disbursement_mode"
                              as="select"
                              className={"form-control"}
                            >
                              <option value="Cash">Cash</option>
                              <option value="Cheque">Cheque</option>
                              <option value="NEFT">NEFT</option>
                              <option value="RTGS">RTGS</option>
                              <option value="IMPS">IMPS</option>
                              <option value="Other">Other</option>
                            </Field>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group col-md-6">
                            <FormElement
                              label="Purpose of the Loan"
                              name="purpose"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              In case of Agri Loans, Details of activity
                              undertaken
                            </label>
                            <Field
                              name="agri_purpose"
                              as="select"
                              className={"form-control"}
                            >
                              <option value=""></option>
                              <option value="Cultivation of Crops">
                                Cultivation of Crops
                              </option>
                              <option value="Allied Activity">
                                Allied Activity
                              </option>
                              <option value="Processing Unit">
                                Processing Unit
                              </option>
                              <option value="Others">Others</option>
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginLeft: 40 }}>
                        <Typography
                          style={{ textAlign: "center", fontSize: 20 }}
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Customer
                        </Typography>
                        <div className="d-flex justify-content-center">
                          {values.customer_pic === "" ? (
                            <Webcam
                              height={300}
                              audio={false}
                              ref={custcamRef}
                              screenshotFormat="image/jpeg"
                              width={300}
                              videoConstraints={videoConstraints}
                            />
                          ) : (
                            <img
                              src={values.customer_pic}
                              height="300"
                              width="300"
                              alt=""
                            ></img>
                          )}
                        </div>
                        <br />
                        <div className="d-flex justify-content-around">
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={values.customer_pic ? true : false}
                            type="button"
                            onClick={() =>
                              capture(
                                setFieldValue,
                                "customer_pic",
                                "cust",
                                values.loan_account
                              )
                            }
                          >
                            Capture Photo
                          </Button>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() =>
                              discard(setFieldValue, "customer_pic")
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      padding: 20,
                      marginTop: 30,
                    }}
                  >
                    <Typography variant="h5">Bank Account Details</Typography>
                    <br />
                    <div className="d-flex">
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Bank Name"
                          name="bank_account.bank_name"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Account Number"
                          name="bank_account.account_no"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="IFSC Code"
                          name="bank_account.ifsc_code"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                      <div className="form-group col-md-3">
                        <FormElement
                          label="Account Holder"
                          name="bank_account.acc_holder"
                          type="text"
                          errors={errors}
                          touched={touched}
                        ></FormElement>
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      padding: 20,
                      marginTop: 30,
                    }}
                  >
                    <Typography variant="h5">Gold Details</Typography>
                    <br />
                    <div style={{ border: "1px dotted black", padding: 15 }}>
                      <JobSheet
                        totalOrnaments={totalOrnaments}
                        setTotalOrnaments={setTotalOrnaments}
                        setFieldValue={setFieldValue}
                      ></JobSheet>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <div style={{ width: "80%" }}>
                        <br />
                        <div className="d-flex">
                          <div className="form-group col-md-4">
                            <FormElement
                              label="Sanctioned Loan Amount(₹)"
                              name="loan_amount"
                              type="number"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-4">
                            <FormElement
                              label="Eligible Value of Advance (In case of Agri, eligibility varies)(₹)"
                              name="gold.eligible_advance"
                              type="number"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-4">
                            <FormElement
                              label="Existing Loans with CSB (Under Different Products)"
                              name="external_loans"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group col-md-6">
                            <FormElement
                              label="External appraisal done by: (Name and Code)"
                              name="external_appraisal"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-6">
                            <FormElement
                              label="Packet Details"
                              name="gold_packet"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group col-md-6">
                            <FormElement
                              label="Rate Per Gram (₹)"
                              name="gold.rpg"
                              type="number"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                          <div className="form-group col-md-6">
                            <FormElement
                              label="Source Code/Name"
                              name="source_code"
                              type="text"
                              errors={errors}
                              touched={touched}
                            ></FormElement>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginLeft: 40 }}>
                        <Typography
                          style={{ textAlign: "center", fontSize: 20 }}
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Gold Ornaments
                        </Typography>
                        <div className="d-flex justify-content-center">
                          {values.gold_pic === "" ? (
                            <Webcam
                              height={300}
                              audio={false}
                              ref={goldcamRef}
                              screenshotFormat="image/jpeg"
                              width={300}
                              videoConstraints={videoConstraints}
                            />
                          ) : (
                            <img
                              src={values.gold_pic}
                              height="300"
                              width="300"
                              alt=""
                            ></img>
                          )}
                        </div>
                        <br />
                        <div className="d-flex justify-content-around">
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={values.gold_pic ? true : false}
                            type="button"
                            onClick={() =>
                              capture(
                                setFieldValue,
                                "gold_pic",
                                "gold",
                                values.loan_account
                              )
                            }
                          >
                            Capture Photo
                          </Button>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() => discard(setFieldValue, "gold_pic")}
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      padding: 20,
                      marginTop: 20,
                    }}
                  >
                    <div>
                      <Typography variant="h5">
                        Disbursement Details
                        {/* <div
                          className="d-flex justify-content-end"
                          style={{ float: "right", width: "50%" }}
                        >
                          <div style={{ width: 150 }} className="d-flex">
                            <label style={{ fontSize: 15 }}>PF Charge(%)</label>
                            <div>
                              <Field
                                name="pf_charge"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.pf_charge && touched.pf_charge
                                    ? " is-invalid"
                                    : "")
                                }
                                onKeyUp={() =>
                                  handleCharges(values, setFieldValue)
                                }
                              />
                            </div>
                          </div>
                        </div> */}
                      </Typography>
                    </div>
                    <br />
                    <div className="d-flex justify-content-around">
                      <div className="form-group col-md-3">
                        <label>Interest Payment Frequency</label>
                        <Field
                          name="payment_frequency"
                          type="text"
                          className={
                            "form-control" +
                            (errors.payment_frequency &&
                              touched.payment_frequency
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="payment_frequency"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Interest/EMI Amount (₹)</label>
                        <Field
                          name="emi"
                          type="text"
                          className={
                            "form-control" +
                            (errors.emi && touched.emi ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="emi"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Total PF (₹)</label>
                        <Field
                          name="pf"
                          type="text"
                          className={
                            "form-control" +
                            (errors.pf && touched.pf ? " is-invalid" : "")
                          }

                          onKeyUp={() => updateDisburse(values, setFieldValue)}
                        />
                        <ErrorMessage
                          name="pf"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Disbursement Amount (₹)</label>
                        <Field
                          name="disburse_amount"
                          type="text"
                          className={
                            "form-control" +
                            (errors.disburse_amount && touched.disburse_amount
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="disburse_amount"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div className="form-group col-md-3">
                        <label>Auction Date</label>
                        <Field
                          name="auction_date"
                          type="date"
                          onKeyDown={(e) => e.preventDefault()}
                          className={
                            "form-control" +
                            (errors.auction_date && touched.auction_date
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="auction_date"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>EMI/Interest Due date</label>
                        <Field
                          name="emi_due_date"
                          type="date"
                          onKeyDown={(e) => e.preventDefault()}
                          className={
                            "form-control" +
                            (errors.emi_due_date && touched.emi_due_date
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="emi_due_date"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Closure Due Date</label>
                        <Field
                          name="closure_due_date"
                          type="date"
                          onKeyDown={(e) => e.preventDefault()}
                          className={
                            "form-control" +
                            (errors.closure_due_date && touched.closure_due_date
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="closure_due_date"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      padding: 20,
                      marginTop: 30,
                    }}
                  >
                    <Typography variant="h5">Capture Ornaments</Typography>
                    <br />
                    <div className="row justify-content-md-center">
                      {totalOrnaments.map((ornament, idx) => (
                        <div style={{ margin: 10 }} key={idx}>
                          <div className="d-flex">
                            {ornament.image === "" ? (
                              <Webcam
                                height={300}
                                audio={false}
                                ref={(el) => (itemsRef.current[idx] = el)}
                                screenshotFormat="image/jpeg"
                                width={300}
                                videoConstraints={videoConstraints}
                              />
                            ) : (
                              <img
                                src={ornament.image}
                                height="300"
                                width="300"
                                alt=""
                              ></img>
                            )}
                          </div>
                          <br />
                          <div className="d-flex justify-content-around">
                            <Button
                              color="primary"
                              variant="outlined"
                              disabled={ornament.image}
                              type="button"
                              onClick={() => {
                                captureGold(
                                  setFieldValue,
                                  idx,
                                  values.loan_account
                                );
                              }}
                            >
                              Capture Photo
                            </Button>
                            <Button
                              color="secondary"
                              type="button"
                              onClick={() => discardGold(setFieldValue, idx)}
                            >
                              Discard
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Paper>
                </div>
                <div className="form-row">
                  <div className="form-group col d-flex justify-content-center">
                    <button
                      style={{ marginRight: 10 }}
                      onClick={() => setStage(stage - 1)}
                      className="btn btn-primary"
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
